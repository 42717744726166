import { Interface } from "ethers/lib/utils";
import { Contract } from "ethers";
import useCallsGracefully from "./useCallsGracefully";
import LOCKER_ABI from '../ethers/abis/LiquidLocker.json';

const lockerInterface = new Interface(
    LOCKER_ABI
);

export const useRefundCheck = (
    account,
    lockerAddress
) => {

    const lockerContract = new Contract(
        lockerAddress,
        lockerInterface
    );

    const thisUsersContributionCall = {
        contract: lockerContract,
        method: "contributions",
        args: [account],
    };

    const singleProviderCall = {
        contract: lockerContract,
        method: "singleProvider",
    };

    const calls = [
        thisUsersContributionCall,
        singleProviderCall
    ];

    const args = account
        ? calls
        : [];

    const [
        thisUsersContribution,
        singleProvider,
    ] = useCallsGracefully(args);

    return [
        thisUsersContribution,
        singleProvider
    ];
};
