import React, { useState, useEffect, useMemo } from "react";
import { useParams, useHistory } from "react-router";
import { useSelector } from "react-redux";
import cn from "classnames";
import 'react-perfect-scrollbar/dist/css/styles.css';

import styles from "./Search01.module.sass";
import Icon from "../../components/Icon";
import Dropdown from "../../components/Dropdown";
import Listing from "./Listing";
import { getListingDetails } from "../../utils/query/locker";
import PerfectScrollbar from 'react-perfect-scrollbar';
import Checkbox from "../../components/Checkbox";
import ExtraOptions from "../../components/ExtraOptions/ExtraOptions";
import Loader from "../../components/Loader";

import {
    BORROW_TIMES_OPTION,
    SECONDS_IN_DAY,
    PENDING_STATUS,
    TERMINATED_STATUS,
    EXPIRED_STATUS,
    TEST_NETWORK_ID,
} from "../../utils";

import { COLLECTION_ADDRESSES_MAINNET } from "../../ethers/nftCollections";
import { COLLECTION_ADDRESSES_TESTNET } from "../../ethers/nftCollections/testnet";

const tokenCollectionsRopsten = {
    "All Collections": "all",
    "Bored Apes": "bored-apes",
    "Mutant Apes": "mutant-apes",
    // "Cryptopunks": "cryptopunks",
    // "The Humanoids": "humanoids",
    "Doodles": "doodles",
    "Cool Cats": "cool-cats",
};

const tokenCollectionsMainnet = {
    "All Collections": "all",
    "Bored Apes": "bored-apes",
    "Mutant Apes": "mutant-apes",
    "Doodles": "doodles",
    // "Cryptopunks": "cryptopunks",
    // "Mutant Apes": "bored-apes",
    // "The Humanoids": "humanoids",
    // "Satoshibles": "satoshibles",
    "Cool Cats": "cool-cats",
};

const collectionRouteToAddressRopsten = {
    "all": "All Collections",
    // "humanoids": COLLECTION_ADDRESSES_TESTNET.THE_HUMANOIDS,
    "cryptopunks": COLLECTION_ADDRESSES_TESTNET.CRYPTO_PUNKS,
    "bored-apes": COLLECTION_ADDRESSES_TESTNET.BORED_APES,
    "mutant-apes": COLLECTION_ADDRESSES_TESTNET.MUTANT_APES,
    "cool-cats": COLLECTION_ADDRESSES_TESTNET.COOL_CATS,
    // "satoshibles": COLLECTION_ADDRESSES_MAINNET.SATOSHIBLES,
    "doodles": COLLECTION_ADDRESSES_TESTNET.DOODLES
};

const collectionRouteToAddressMainnet = {
    "all": "All Collections",
    // "humanoids": COLLECTION_ADDRESSES_TESTNET.THE_HUMANOIDS,
    "cryptopunks": COLLECTION_ADDRESSES_TESTNET.CRYPTO_PUNKS,
    "bored-apes": COLLECTION_ADDRESSES_TESTNET.BORED_APES,
    "mutant-apes": COLLECTION_ADDRESSES_MAINNET.MUTANT_APES,
    "cool-cats": COLLECTION_ADDRESSES_MAINNET.COOL_CATS,
    // "satoshibles": COLLECTION_ADDRESSES_MAINNET.SATOSHIBLES,
    "doodles": COLLECTION_ADDRESSES_MAINNET.DOODLES
};

const sortOptions = [
    "Creation Time",
    "Expiration Time",
    "Annual Percent Yield",
    "Total Interest Percent"
];
// const priceOptions = ["Most expensive", "Least expensive"];

const paymentTokenOptions = [
    "Any Currency",
    "WETH Token",
    "WISE Token",
    "USDC Token"
];

// const MAXIMUM_BLOCKS = BLOCKS_PER_DAY * CONTRIBUTION_DAYS;
// const STEP_SIZE = 30;

const ALL = "all";
const MIN_RATE = 100000000000;
const MAX_FLOOR = 10000000000000000000000000;

const BLACK_LIST = [
    '0x92079bfDBdc9faE5085eCF61464Abb7b891ed007'
];

const Search = ({
    existingLockers,
    account,
    chainId,
    config,
    library
}) => {

    const showFilters = useSelector(
        (state) => state.settings.hiddenOptions.showFilters
    );

    const [sortSelection, setSortSelection] = useState(
        sortOptions[0]
    );

    // const [priceSelection, setPriceSelection] = useState(priceOptions[0]);
    const [lengthSelection, setLengthSelection] = useState(
        BORROW_TIMES_OPTION[0]
    );

    const [paymentTokenSelection, setPaymentTokenSelection] = useState(
        paymentTokenOptions[0]
    );

    const [pendingOnly, setPendingOnly] = useState(false);
    const [hideFinalized, setHideFinalized] = useState(false);
    // const [size, setSize] = useState(STEP_SIZE);
    const [lockers, setLockers] = useState([]);
    const { collection = ALL } = useParams();
    const history = useHistory();

    const tokenCollections = chainId === TEST_NETWORK_ID
        ? tokenCollectionsRopsten
        : tokenCollectionsMainnet;

    const collectionRouteToAddress = chainId === TEST_NETWORK_ID
        ? collectionRouteToAddressRopsten
        : collectionRouteToAddressMainnet;

    const resetFilters = () => {
        if (existingLockers.length > 0) {
            // setLockers(existingLockers.slice(0, STEP_SIZE));
            setLockers((prev) => {
                return prev; //.slice(0, STEP_SIZE);
            });

            setSortSelection(
                sortOptions[0]
            );

            setLengthSelection(
                BORROW_TIMES_OPTION[0]
            );

            setPaymentTokenSelection(
                paymentTokenOptions[0]
            );

            setPendingOnly(false);
            // setSize(STEP_SIZE);
        }
    };

    const hideLocker = (locker, index) => {
        if (!collection || collection.includes(ALL)) return false;
        return locker?.tokenAddress !== collectionRouteToAddress[collection];
    };

    useEffect(() => {
        const getLockers = async () => {
            // console.log(existingLockers, 'existingLockers');
            // .slice(0, STEP_SIZE)
            const fetchedLockerData = existingLockers.map(
                async (locker) => (
                    await getListingDetails(
                        locker.lockerAddress,
                        library,
                        config.getCollectionNameByAddress,
                        config.tokenAddressToNameMapping,
                        locker.ownersAddress,
                        chainId
                    )
                )
            );

            setLockers(
                await Promise.all(
                    fetchedLockerData
                )
            );
        }
        if (existingLockers) getLockers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [existingLockers, account, chainId, library]);

    const filteredLockers = useMemo(() => {

        const isLengthFilter = lengthSelection !== BORROW_TIMES_OPTION[0];
        const isTokenFilter = paymentTokenSelection !== paymentTokenOptions[0];

        // if (!isLengthFilter && !isTokenFilter && !pendingOnly) {
            // return sortedLockers;
        // }

        return lockers.filter((locker) => {

            if (isTokenFilter) {

                const included = paymentTokenSelection.includes(
                    locker.paymentTokenName
                );

                if (included === false) {
                    return false;
                }
            }

            if (isLengthFilter) {
                let selection = lengthSelection.split(" ")[0];
                selection = parseInt(selection) * SECONDS_IN_DAY;
                if (parseInt(locker?.paymentTime.toString()) !== selection) {
                    return false;
                }
            }

            if (!locker) return false;
            if (locker.paymentRate < MIN_RATE) return false; // @TODO: convert into filter!
            if (locker.floorAsked > MAX_FLOOR) return false; // @TODO: convert into filter!
            if (BLACK_LIST.includes(locker.lockerAddress)) return false;

            const { loanStatus, totalAsked, totalCollected } = locker;
            // if (loanStatus === EXPIRED_STATUS || loanStatus === TERMINATED_STATUS) return false;
            if (loanStatus === TERMINATED_STATUS && hideFinalized === true) return false;
            if (loanStatus === EXPIRED_STATUS) return false;

            if (pendingOnly) {
                const isPending = loanStatus === PENDING_STATUS;
                const totalReached = (
                    parseFloat(totalAsked.toString()) === parseFloat(totalCollected.toString())
                );
                return isPending && !totalReached;
            }
            return true;
        });
    }, [
        lockers,
        lengthSelection,
        paymentTokenSelection,
        hideFinalized,
        pendingOnly
    ]);

    const sortedLockers = useMemo(() => (
        filteredLockers.sort((a, b) => {
            // sort by latest first
            if (sortSelection === sortOptions[0]) {
                return parseInt(a.creationTime.toString()) < parseInt(b.creationTime.toString())
                    ? 1 : -1;
            }
            // sort by oldest first
            else if (sortSelection === sortOptions[1]) {
                return parseInt(a.creationTime.toString()) > parseInt(b.creationTime.toString())
                    ? 1 : -1;
            }
            // sort by highest APY first
            else if (sortSelection === sortOptions[2]) {
                return parseInt(a.borrowingAPY) < parseInt(b.borrowingAPY)
                    ? 1 : -1;
            }
            // sort by highest TIP first
            else if (sortSelection === sortOptions[3]) {
                return parseInt(a.paymentRate.toString()) < parseInt(b.paymentRate.toString())
                    ? 1 : -1;
            }
            // default
            else return true;
        })
    ), [
        sortSelection,
        // lengthSelection,
        // paymentTokenSelection,
        filteredLockers,
    ]);

    const handleTab = (newRoute) => {
        history.push(`/custom-listings/${newRoute}`);
    };

    return (
        <>
            {/* <Control className={styles.control} item={breadcrumbs} /> */}
            <div className={cn("section-pt40", styles.section)}>
                <div className={cn("container", styles.container)}>
                    <div className={styles.top}>
                        <div className={styles.head}>
                            <div>
                                <div className={cn("h2", styles.title)}>
                                    Custom Listings
                                </div>
                                <div style={{ marginBottom: "5px" }} className={styles.text}>
                                    Earn interest by funding NFT loans
                                </div>
                            </div>
                        </div>
                        <div className={styles.sorting}>
                            <div className={styles.nav}>
                                {Object.keys(tokenCollections).map((key, index) => (
                                    <button
                                        className={cn(styles.link, {
                                            [styles.active]: tokenCollections[key] === collection,
                                        })}
                                        onClick={() => handleTab(tokenCollections[key])}
                                        key={index}
                                    >
                                        { key }
                                    </button>
                                ))}
                                <ExtraOptions screen={"search"} />
                            </div>
                        </div>
                        {/*<div className={styles.dropdown}>
                            <Dropdown
                                className={styles.dropdown}
                                value={sortSelection}
                                setValue={setSortSelection}
                                options={sortOptions}
                            />
                        </div>*/}
                        {/*<form className={styles.search} action="" onSubmit={() => handleSubmit()}>
                            <input
                                className={styles.input}
                                type="text"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                name="search"
                                placeholder="Search ..."
                                required
                            />
                            <button className={styles.result}>
                                <Icon name="search" size="16" />
                            </button>
                        </form>*/}
                    </div>
                    <div className={styles.row}>
                        <div
                            style={{ display: showFilters ? "" : "none" }}
                            className={styles.filters}
                        >
                            {/*<div className={styles.range}>
                                <div className={styles.label}>Funded</div>
                                <Range
                                    values={values}
                                    step={STEP}
                                    min={MIN}
                                    max={MAX}
                                    onChange={setValues}
                                    renderTrack={({ props, children }) => (
                                        <div
                                            onMouseDown={props.onMouseDown}
                                            onTouchStart={props.onTouchStart}
                                            style={{
                                                ...props.style,
                                                height: "36px",
                                                display: "flex",
                                                width: "100%",
                                            }}
                                        >
                                            <div
                                                ref={props.ref}
                                                style={{
                                                    height: "8px",
                                                    width: "100%",
                                                    borderRadius: "4px",
                                                    background: getTrackBackground({
                                                        values,
                                                        colors: ["#c73e77", "#E6E8EC"],
                                                        min: MIN,
                                                        max: MAX,
                                                    }),
                                                    alignSelf: "center",
                                                }}
                                            >
                                                {children}
                                            </div>
                                        </div>
                                    )}
                                    renderThumb={({ props, isDragged }) => (
                                        <div
                                            {...props}
                                            style={{
                                                ...props.style,
                                                height: "24px",
                                                width: "24px",
                                                borderRadius: "50%",
                                                backgroundColor: "#c73e77",
                                                border: "4px solid #FCFCFD",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    position: "absolute",
                                                    top: "-33px",
                                                    color: "#fff",
                                                    fontWeight: "600",
                                                    fontSize: "14px",
                                                    lineHeight: "18px",
                                                    fontFamily: "Poppins",
                                                    padding: "4px 8px",
                                                    borderRadius: "8px",
                                                    backgroundColor: "#141416",
                                                }}
                                            >
                                                {values[0].toFixed(1)}0%
                                            </div>
                                        </div>
                                    )}
                                />
                                <div className={styles.scale}>
                                    <div className={styles.number}>0.00 %</div>
                                    <div className={styles.number}>100.00 %</div>
                                </div>
                            </div>*/}
                            {/*<div className={styles.group}>
                                <div className={styles.item} style={{display: "flex"}}>
                                    <Checkbox
                                        className={styles.checkbox}
                                        value={pendingOnly}
                                        onChange={() => setPendingOnly(!pendingOnly)}
                                        content="Hide Funded Loans"
                                    />
                                </div>
                            </div>*/}
                            <div className={styles.group}>
                                <div className={styles.item} style={{display: "flex"}}>
                                    <Checkbox
                                        className={styles.checkbox}
                                        value={pendingOnly}
                                        onChange={() => setPendingOnly(!pendingOnly)}
                                        content="Hide Funded Loans"
                                    />
                                </div>
                            </div>
                            <div className={styles.group}>
                                <div className={styles.item}>
                                    <div className={styles.label}>
                                        Sort Listings
                                    </div>
                                    <Dropdown
                                        className={styles.dropdown}
                                        value={sortSelection}
                                        setValue={setSortSelection}
                                        options={sortOptions}
                                    />
                                </div>
                                <div className={styles.item}>
                                    <div className={styles.label}>Loan currency</div>
                                    <Dropdown
                                        className={styles.dropdown}
                                        value={paymentTokenSelection}
                                        setValue={setPaymentTokenSelection}
                                        options={paymentTokenOptions}
                                    />
                                </div>
                                <div className={styles.item}>
                                    <div className={styles.label}>Loan Length</div>
                                    <Dropdown
                                        className={styles.dropdown}
                                        value={lengthSelection}
                                        setValue={setLengthSelection}
                                        options={BORROW_TIMES_OPTION}
                                    />
                                </div>
                            </div>
                            <div className={styles.group}>
                                <div className={styles.item} style={{display: "flex"}}>
                                    <Checkbox
                                        className={styles.checkbox}
                                        value={hideFinalized}
                                        onChange={() => setHideFinalized(!hideFinalized)}
                                        content="Hide Finalized Loans"
                                    />
                                </div>
                            </div>
                            <div onClick={resetFilters} className={styles.reset}>
                                <Icon name="close-circle-fill" size="24" />
                                <span>Reset filter</span>
                            </div>
                        </div>
                        <div className={cn({ [styles.fullWidth]: !showFilters }, styles.wrapper)}>
                            <div
                                className={styles.loader}
                                style={{ display: sortedLockers && !sortedLockers.length ? "flex" : "none" }}
                            >
                                <Loader color="white" />
                            </div>
                            <PerfectScrollbar>
                                <div className={styles.list}>
                                    {sortedLockers && sortedLockers.map((locker, index) => ( // existingLockers.map((locker, index) => (
                                        <Listing
                                            className={cn(styles.card, {
                                                [styles.hidden]: hideLocker(sortedLockers[index], index),
                                            })}
                                            filterToken={paymentTokenSelection}
                                            lockerAddress={locker.lockerAddress}
                                            key={index}
                                            data={locker}
                                        />
                                    ))}
                                    <span className={styles.placeholder}>
                                        hidden
                                    </span>
                                </div>
                            </PerfectScrollbar>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Search;
