import React, { useState, useEffect } from "react";
import cn from "classnames";
import PerfectScrollbar from 'react-perfect-scrollbar';

import styles from "./LockerList.module.sass";
import LockerCard from "../LockerCard";
import { getListingDetails } from "../../utils/query/locker";
import useConfig from "../../customHooks/useConfig";
import NoData from "../NoData";
import Loader from "../Loader";

const LockerList = ({
    className,
    lockerData,
    chainId,
    library,
    optionalAddress,
 }) => {

    const [lockerList, setLockerList] = useState();
    const config = useConfig();

    useEffect(() => {
        let mounted = true;

        const getLockers = async () => {
            try {
                const fetchedLockerData = lockerData
                    .map(async (locker) => {
                        // optionalAddress and checking locker.lockerAddress
                        // to handle arr of addresses or arr of objects
                        const lockerAddressSafe = locker?.lockerAddress || locker;
                        const ownerAddressSafe = optionalAddress || locker?.ownersAddress;
                        return await getListingDetails(
                            lockerAddressSafe,
                            library,
                            config.getCollectionNameByAddress,
                            config.tokenAddressToNameMapping,
                            ownerAddressSafe,
                            chainId
                        )
                    });
                    if (mounted) setLockerList(
                        await Promise.all(fetchedLockerData)
                    );
                } catch (err) {
                    console.log(err);
                    // @TODO: send toast message?
                    // signer.provider.off(getLockers);
                }
            }
        if (lockerData.length) getLockers();
        return () => {
            mounted = false;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lockerData, chainId, optionalAddress]);

    useEffect(() => {
        // display noData if no lockerData returns
        let mounted = true;
        const timeout = setTimeout(() => {
            if (lockerData && !lockerList && mounted) {
                setLockerList([]);
            }
        }, 15000);

        // changing profile from having lockers to no lockers
        if (lockerList && lockerList.length && !lockerData.length && mounted) {
            console.log('here', mounted)
            setLockerList(null);
        }
        return () => {
            clearTimeout(timeout);
            mounted = false;
        }
    }, [lockerData, lockerList]);

    return (
        <div className={cn(styles.items, className)}>
            <PerfectScrollbar>
                <div className={styles.list}>
                    {lockerList && lockerList.map((locker, index) => {
                        if (!locker) return (<div key={index}></div>);
                        return (
                            <LockerCard
                                filterToken="Any currency"
                                className={styles.card}
                                locker={locker}
                                key={index}
                            />
                        )
                    })}
                    <div
                        className={styles.loader}
                        style={{ display: !lockerList ? "block" : "none" }}
                    >
                        <Loader color="white" />
                    </div>
                    <div
                        className={styles.loader}
                        style={{ display: lockerList && !lockerList.length ? "flex" : "none" }}
                    >
                        <NoData buttonEndpoint="discover" label="You don't have any custom listings" />
                    </div>
                </div>
            </PerfectScrollbar>
            {/*<Loader className={styles.loader} />*/}
        </div>
    );
};

export default LockerList;
