// import React from "react";
import React, { useState, useMemo } from "react";
import { useHistory } from "react-router";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/opacity.css";
import cn from "classnames";
import Slider from "react-slick";
import { useEthers } from "@usedapp/core";
import styles from "./Popular.module.sass";
// import Add from "./Add";

import Icon from "../../../components/Icon";
import Dropdown from "../../../components/Dropdown";
import useCollections from "../../../customHooks/useCollections";
import useTVL from "../../../customHooks/useTVL";
import { Link } from "react-router-dom";
import { getPrettyValue } from "../../../utils";
import supportedPools from "../../../screens/InstantPools/Pools/data";
import PricingTreeModal from "../../../components/PricingTreeModal";
import useCollectionPricingData from "../../../customHooks/useCollectionPricingData";
import useChainLink from "../../../customHooks/useChainLink";
import {
    PREFERRED_NFT_CONTRACTS,
    COLLECTION_ADDRESSES_MAINNET,
    getMainNetAddress,
    getMainNetChainLinkAddress
} from "../../../ethers/nftCollections";
import {
    WETH_POOL_MAINNET,
    WETH_POOL_TESTNET,
    USDC_POOL_MAINNET,
    USDC_POOL_TESTNET,
    TEST_NETWORK_ID,
    UNWANTED_PRICING_LIST
} from "../../../utils";
import useConfig from "../../../customHooks/useConfig";

// import { getRarifySmartFloor } from "../../../utils/query/tokens";

/*const collectionsToRoute = {
    "The Humanoids": "humanoids",
    "Bored Ape Yacht Club": "bored-apes",
    // "CryptoPunks": "cryptopunks",
    "Cool Cats NFT": "cool-cats",
    "Satoshibles": "satoshibles",
    "Doodles": "doodles",
    "Mutant Ape Yacht Club": "mutant-apes"
};*/

// Pulled from nftbank
// const TEMP_CRYPTOPUNKS_PRICE = "64.79";

const SlickArrow = ({ currentSlide, slideCount, children, ...props }) => (
    <button {...props}>{children}</button>
);

const sortOptions = [
    "Floor Price",
    "Pool Balance"
];

const Popular = () => {

    const { chainId } = useEthers();

    const config = useConfig();

    const pools = supportedPools.filter(
        (pool) => pool.chainId === chainId
    );

    const settings = {
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 3,
        adaptiveHeight: true,
        nextArrow: (
            <SlickArrow>
                <Icon name="arrow-next" size="14" />
            </SlickArrow>
        ),
        prevArrow: (
            <SlickArrow>
                <Icon name="arrow-prev" size="14" />
            </SlickArrow>
        ),
        responsive: [
            {
                breakpoint: 1179,
                settings: {
                    slidesToShow: 4,
                    infinite: false
                },
            },
            {
                breakpoint: 1130,
                settings: {
                    slidesToShow: 3,
                    infinite: false
                },
            },
            {
                breakpoint: 795,
                settings: {
                    slidesToScroll: 1,
                    variableWidth: true,
                },
            },
        ],
    };

    const [sortOption, setSortOption] = useState(sortOptions[1]);

    // const sortOption = sortOptions[0];
    const [collections, loading] = useCollections(
        PREFERRED_NFT_CONTRACTS
    );

    const collectionPricingData = useCollectionPricingData(
        PREFERRED_NFT_CONTRACTS
    );

    const sortFilters = {
        "Loan count": (a, b) => b.number - a.number,
        "Floor price": (a, b) => (b.floorPrice || 0) - (a.floorPrice || 0),
    };

    const UNWANTED = [
        'Wrapped Cryptopunks'
    ];

    const HIDDEN_DISPLAY_CARD_ADDRESSES = [
        COLLECTION_ADDRESSES_MAINNET.WRAPPED_PUNKS
    ]

    const sortedCollections = collections.sort(
        sortFilters[sortOption]
    ).filter((collection) => {
        return UNWANTED.includes(collection?.name) === false
            && !HIDDEN_DISPLAY_CARD_ADDRESSES.includes(collection.address);
    });

    const filteredPreferredNftContracts = PREFERRED_NFT_CONTRACTS
        .filter(
            collection => !HIDDEN_DISPLAY_CARD_ADDRESSES.includes(collection.address),
        );

    const displayedCards = sortedCollections.length
        ? sortedCollections
        : filteredPreferredNftContracts
            .map(contract => ({
                ...filteredPreferredNftContracts,
                imageUrl: config.localCollectionImages[contract?.name]
            }));

    const data = useTVL(
        pools
    );

    const displayValue = data.totalValueLocked
        ? getPrettyValue(data.totalValueLocked)
        : "...";

    return (
        <div className={cn("section-bg", styles.section)}>
            <div className={cn("container", styles.container)}>
                <div className={styles.top}>
                    <div className={styles.box}>
                        <div className={styles.stage}>Total Value Locked</div>
                        <h3 className={cn("h3", styles.title)}>
                            {displayValue} USD
                        </h3>
                    </div>
                    <div className={styles.box}>
                        <div className={styles.stageRight}>Instantly Supports</div>
                        <h3 className={cn("h3", styles.title)}>14 Collections</h3>
                    </div>
                    <div className={styles.field} style={{ display: "none" }}>
                        <div className={styles.label}>Sort collections by</div>
                        <Dropdown
                            className={styles.dropdown}
                            value={sortOption}
                            setValue={setSortOption}
                            options={sortOptions}
                        />
                    </div>
                </div>
                <div className={styles.wrapper}>
                    {displayedCards && (
                        <Slider className="popular-slider" {...settings}>
                            {displayedCards.map((collection, index) => (
                                <CollectionGroup
                                    collection={collection}
                                    collectionIndex={collection[index]}
                                    // cryptoPunksPrice={cryptoPunksPrice}
                                    collectionPricingData={collectionPricingData}
                                    key={`c-${index}`}
                                    loading={loading}
                                />
                            ))}
                        </Slider>
                    )}
                </div>
            </div>
        </div>
    );
};

const CollectionGroup = ({ collection, collectionIndex, collectionPricingData, loading }) => {

    const history = useHistory();
    const [visibleModal, setVisibleModal] = useState(false);

    const {
        // color,
        // sign,
        // number,
        name,
        // imageUrl,
        floorPrice,
        tokensAddress,
        address
    } = collectionIndex;

    const { chainId } = useEthers();
    // const collectionLink = collectionsToRoute[collection.name];

    const INSTANT_POOLS_COLLECTIONS = [
        COLLECTION_ADDRESSES_MAINNET.BORED_APES,
        COLLECTION_ADDRESSES_MAINNET.MUTANT_APES,
        COLLECTION_ADDRESSES_MAINNET.COOL_CATS,
        COLLECTION_ADDRESSES_MAINNET.DOODLES,
        COLLECTION_ADDRESSES_MAINNET.AZUKI,
        COLLECTION_ADDRESSES_MAINNET.MOON_BIRDS,
        COLLECTION_ADDRESSES_MAINNET.CRYPTO_PUNKS,
        COLLECTION_ADDRESSES_MAINNET.WRAPPED_PUNKS,
        COLLECTION_ADDRESSES_MAINNET.CLONE_X,
        COLLECTION_ADDRESSES_MAINNET.PUNKS_VERSION_ONE
    ];

    const customLoan =
        INSTANT_POOLS_COLLECTIONS
            .includes(tokensAddress || address) === false;

    const color = customLoan
        ? 'rgb(151, 87, 215)'
        : 'rgb(199, 62, 119)';

    const dynamicBorder = customLoan
        ? styles.custom
        : styles.instant;

    const poolType = customLoan
        ? 'Custom Loans'
        : 'Supported Pools';

    const poolLink = customLoan
        ? "loan-details"
        : "instant-pools";

    const wethPoolLink = chainId === TEST_NETWORK_ID
        ? WETH_POOL_TESTNET
        : WETH_POOL_MAINNET;

    const usdcPoolLink = chainId === TEST_NETWORK_ID
        ? USDC_POOL_TESTNET
        : USDC_POOL_MAINNET;

    const replacer = (name) => {
        return name === 'CryptoPunks'
            ? 'Wrapped CryptoPunks'
            : name;
    };

    const defaultSelectedPricingCollection = useMemo(() => {
        if (!collectionPricingData) return;

        const addressValue = tokensAddress || address;

        // Handle "switch" from CryptoPunks to wrapped punks in UI
        const tokenAddress = addressValue.toLowerCase() === COLLECTION_ADDRESSES_MAINNET.CRYPTO_PUNKS.toLowerCase()
            ? COLLECTION_ADDRESSES_MAINNET.WRAPPED_PUNKS
            : addressValue;

        const addressMainnet = chainId === TEST_NETWORK_ID
            ? getMainNetAddress(tokenAddress)
            : tokenAddress;

        return collectionPricingData?.find((pricingData) => (
            pricingData.address?.trim()?.toLowerCase() === addressMainnet?.trim()?.toLowerCase()
        ));

    }, [collectionPricingData, address, tokensAddress, chainId]);

    const supportedCollections = useMemo(() => (
        collectionPricingData?.filter((collection) => {
            return UNWANTED_PRICING_LIST.includes(collection.collection) === false
                && INSTANT_POOLS_COLLECTIONS.includes(collection.address);
        })
            .reduce(
                (collectionList, collection) =>
                    collectionList?.some(item => item.address === collection.address)
                        ? collectionList
                        : [...collectionList, collection],
                [])
            .sort((a, b) => {
                return a.collection.localeCompare(
                    b.collection
                );
            })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ), [collectionPricingData]);

    const handleOpenModal = () => {
        if (customLoan) {
            history.push(`/loan-details/${tokensAddress}`);
        } else if (collectionPricingData) {
            setVisibleModal(true);
        }
    };

    const handleImageClick = (e) => {
        if (customLoan) {
            e.stopPropagation();
            e.preventDefault();
            history.push(`/loan-details/${tokensAddress}`);
        }
    };

    const chainLinkAddress = getMainNetChainLinkAddress(
        tokensAddress || address
    );

    const chainLinkFloorPrice = useChainLink(
        chainLinkAddress
    );

    const loadingFloorPrice = loading || (chainLinkAddress && !chainLinkFloorPrice);

    return (
        <div>
            <div
                onClick={handleOpenModal}
                className={styles.slide}
                style={{ width: "100%", height: "auto" }}
            >
                <div className={cn(dynamicBorder, styles.item)}>
                    <div className={styles.head}>
                        <Link to={poolLink}>
                            <div className={styles.rating} style={{ backgroundColor: color }}>
                                <div className={styles.icon}>
                                    {/*<img src={sign} alt="Rating" />*/}
                                </div>
                                <div className={styles.number}>{poolType}</div>
                            </div>
                        </Link>
                        {customLoan === false && (
                            <div className={styles.control}>
                                <Link to={`pool/${wethPoolLink}`}>
                                    <img
                                        className={styles.tokenLogo}
                                        src={"/images/icons/eth.svg"}
                                        alt="Logo"
                                    />
                                </Link>
                                <Link to={`pool/${usdcPoolLink}`}>
                                    <img
                                        className={styles.tokenLogo}
                                        src={"/images/icons/usdc.svg"}
                                        alt="Logo"
                                    />
                                </Link>
                            </div>
                        )}
                    </div>
                    <div className={styles.body}>
                        {collection.imageUrl ? (
                            <LazyLoadImage
                                onClick={handleImageClick}
                                className={styles.avatar}
                                alt="Avatar"
                                height={90}
                                src={collection.imageUrl}
                                width={90}
                                effect="opacity"
                                delayTime="500"
                                style={{ borderRadius: "50%", background: "black" }}
                            />
                        ) : (
                            <div className={styles.loader}>
                            </div>
                        )}
                        <div className={styles?.name}>{replacer(name)}</div>
                        {!loadingFloorPrice && (<div className={styles.price}>Floor Price: <span>
                            {!loadingFloorPrice
                                ? (parseFloat(chainLinkFloorPrice || floorPrice)).toFixed(2)
                                : "..."
                            }
                        </span> ETH</div>)}
                    </div>
                </div>
            </div>
            <PricingTreeModal
                item={defaultSelectedPricingCollection}
                defaultIpfsHash={defaultSelectedPricingCollection?.ipfsHash}
                supportedCollections={supportedCollections}
                visibleModal={visibleModal}
                onClose={() => setVisibleModal(false)}
            />
        </div>
    );
};

export default Popular;
