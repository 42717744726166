import React from "react";
import HotInstant from "./HotInstant";
import useBorrowEvents from "../../customHooks/useBorrowEvents";

const HotWrapper = ({ classSection }) => {

    const [borrowEvents, borrowEventsTest] = useBorrowEvents();

    return (
        <HotInstant
            classSection={classSection}
            borrowEvents={borrowEvents && borrowEvents}
            borrowEventsTest={borrowEventsTest}
        />
    );
};

export default HotWrapper;
