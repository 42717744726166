import React, { useState, useMemo, useEffect } from "react";
import cn from "classnames";
import { useEthers, useTokenBalance } from '@usedapp/core';
import CurrencyInput from 'react-currency-input-field';
import { formatUnits } from "ethers/lib/utils";
// import { usePoolUserInfo } from "../../customHooks/usePoolUserInfo";
import useConfig from "../../customHooks/useConfig";
import styles from "./Invest.module.sass";
import InvestSteps from "./InvestSteps";
import FormattedAmount from "../FormattedAmount";
import useGetPoolRates from "../../customHooks/useGetPoolRates";

import {
    PERCENT_OPTIONS,
    ONE_HUNDRED,
    getPreciseValue,
    FETCHING_DATA_MESSAGE
} from "../../utils";
// import { BigNumber } from "ethers";

const Invest = ({
    className,
    title,
    apy,
    poolAddress,
    paymentToken,
    closeModal,
    ltv
}) => {

    const config = useConfig();
    const { account } = useEthers();

    const decimals = config.decimals[paymentToken];
    const paymentTokenAddress = config.paymentTokens[paymentToken];

    const [amount, setAmount] = useState("");
    const [invalidAmount, setInvalidAmount] = useState(false);
    const [transactionHash, setTransactionHash] = useState("");
    const [activeQuickSelect, setActiveQuickSelect] = useState();

    const balance = useTokenBalance(
        config.paymentTokens[paymentToken],
        account
    );

    const poolRates = useGetPoolRates(
        poolAddress
    );

    // const { userTokensDeposited } = usePoolUserInfo(
        // poolAddress,
        // / account
    // );

    useEffect(() => {
        if (!amount) {
            handleQuickSelect('max');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(balance)]);

    const ltvValue = useMemo(() => {
        // edge case guard for human error
        if (!ltv) return "";
        const ltvSplit = ltv.split("%");
        return ltvSplit[0];
    }, [ltv]);

    const loanInfo = useMemo(
        () => [
            /*{
                title: "Pool Details",
                value: `${ltvValue}% LTV`,
            },*/
            {
                title: "Expected Interest",
                value: (
                    poolRates?.lendStatusValue != null
                        ? `${poolRates.lendStatusValue}% APY`
                        : FETCHING_DATA_MESSAGE
                ),
            },
        ], [poolRates?.lendStatusValue]
    );

    const handleAmountChange = (val) => {
        if (invalidAmount) setInvalidAmount(false);

        // clear quickSelect
        setActiveQuickSelect(null)

        // early return for deleted value
        if (!val) {
            setAmount("");
            return;
        }
        // @TODO: add guard for attempt to add more than balance?
        setAmount(val);

        if (balance) {

            const total = formatUnits(
                balance,
                decimals
            );

            // error if input is greater than wallet balance
            if (Number(val) > total) {
                setInvalidAmount(true);
            }

            let percent = val / total;
            let percentOption = Math.ceil(percent * ONE_HUNDRED);

            if (percentOption === ONE_HUNDRED) {
                percentOption = 'max';
            }

            if (!PERCENT_OPTIONS[percentOption]) {
                return;
            }

            if (getPercentageFromTotal(percentOption) === Number(val)) {
                setActiveQuickSelect(percentOption.toString())
            }
        }
    };

    const getPercentageFromTotal = (option) => {

        const total = formatUnits(
            balance,
            decimals
        );

        let formattedNum = total;

        if (option !== 'max') {
            const percent = option / ONE_HUNDRED;
            formattedNum = total * percent;
        }

        return getPreciseValue(formattedNum);
    }

    const errorStyle = invalidAmount
        ? {borderBottom: "solid 1px #c73e77"}
        : {};

    const handleQuickSelect = (option) => {

        if (!balance) return;

        const formattedNum = getPercentageFromTotal(
            option
        );

        setAmount(
            formattedNum.toString()
        );

        setActiveQuickSelect(
            option
        );
    };

    const tweetMessage = useMemo(() => {
        // generate share message
        const url = window.location.href;
        return `I just lent ${paymentToken} to LiquidNFTs instant pool. Check it out here! ` +
        `${url}`;
    }, [paymentToken]);

    return (
        <div className={cn(className, styles.sale)}>
            <div style={{ display: transactionHash ? "none" : "block" }}>
                {/*<div className={styles.main}>
                    Lending funds allows NFT owners to instantly take out loans against the value of their NFTs
                </div>*/}
                {
                    account && (
                        <div className={styles.main}>
                            {/*You hold
                            {" "}
                            {paymentTokenAddress && (
                                <FormattedAmount
                                    // noTruncate={true}
                                    amount={userTokensDeposited}
                                    token={paymentTokenAddress}
                                    label={true}
                                />
                            )} in this pool and extra*/}
                            Based on your wallet balance you can deposit up to
                            {" "}{paymentTokenAddress && (
                                <span className={styles.green}>
                                    <FormattedAmount
                                        // optionalPrecision={4}
                                        amount={balance}
                                        // noTruncate={true}
                                        token={paymentTokenAddress}
                                        label={true}
                                    />
                                </span>
                            )} instantly and start earning
                        </div>
                    )
                }
                <div className={styles.table} style={{marginTop: "24px"}}>
                    <div className={styles.row} style={errorStyle}>
                        <div className={styles.col}>Lending Amount</div>
                        <div className={styles.col}>
                            <CurrencyInput
                                autoComplete="off"
                                id="input-contribution"
                                name="contribution"
                                allowNegativeValue={false}
                                className={styles.input}
                                placeholder="Enter Value"
                                decimalsLimit={2}
                                value={amount}
                                decimalSeparator="."
                                groupSeparator=","
                                onValueChange={(value) => handleAmountChange(value)}
                            />
                        </div>
                        { amount && (
                            <div
                            className={styles.col}>
                                { paymentToken || "ETH" }
                            </div>
                        )}
                    </div>
                    {
                        account && (
                            <>
                                <div
                                    className={styles.row}
                                    style={{ marginTop: "8px" }}
                                >
                                    {Object.keys(PERCENT_OPTIONS).map((key) => (
                                        <button
                                            key={key}
                                            className={cn('button-stroke', styles.option,
                                                {
                                                    'button-active': activeQuickSelect === key
                                                })}
                                            onClick={() => handleQuickSelect(key)}
                                        >
                                            {PERCENT_OPTIONS[key]}
                                        </button>
                                    ))}
                                </div>
                                <div style={{ marginBottom: "16px" }}>
                                    {loanInfo.map((x, index) => (
                                        <div className={styles.row} key={index}>
                                            <div className={styles.col}>{x.title}</div>
                                            <div className={styles.col}>{x.value}</div>
                                        </div>
                                    ))}
                                </div>
                                {/*<div className={styles.main} style={{ marginBottom: "0px" }}>
                                    Pool allows to borrow up to 25% from NFT value
                                    APY value depends on the pool utilization level
                                </div>*/}
                                {
                                    account && (
                                        <div className={styles.main} style={{ marginBottom: "0px" }}>
                                            Borrowers can only take <span className={styles.white}>up to {ltvValue}%</span> of their NFT value when borrowing funds from the pool
                                        </div>
                                    )
                                }
                            </>
                        )
                    }
                </div>
                {
                    !account && (
                        <div style={{ marginTop: "16px" }}>
                            <div className={styles.main} style={{ marginBottom: "0px" }}>
                                User should connect their wallet first so they can use this Instant Pools feature as expected
                            </div>
                        </div>
                    )
                }
            </div>
            <div className={styles.steps}>
                <InvestSteps
                    poolAddress={poolAddress}
                    setInvalidAmount={setInvalidAmount}
                    amount={amount}
                    balance={balance}
                    paymentToken={paymentToken}
                    title={title}
                    tweetMessage={tweetMessage}
                    transactionHash={transactionHash}
                    setTransactionHash={setTransactionHash}
                    setAmount={setAmount}
                    closeModal={closeModal}
                />
            </div>
        </div>
    );
};

export default Invest;
