import { Interface } from "ethers/lib/utils";
import { Contract } from "ethers";
import ERC721_ABI from "../ethers/abis/ERC721.json";
import useCallGracefully from './useCallGracefully';

const ERC721Interface = new Interface(
    ERC721_ABI
);

export const useApprovedForAll = (
    nftAddress,
    userAddress,
    operatorAddress
) => {

    const haveParamsForA = nftAddress && userAddress;
    const hasApprovalForAll = useCallGracefully(haveParamsForA &&
        {
            contract: new Contract(nftAddress, ERC721Interface),
            method: "isApprovedForAll",
            args: [userAddress, operatorAddress],
        }
    ) ?? null;

    return [
        hasApprovalForAll
    ];
};
