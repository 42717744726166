import React, { useState, useMemo } from "react";
import cn from "classnames";

import styles from "./Item.module.sass";
import {
    PINATA_CLOUD_URL,
    shortenHash,
    MS_IN_SECOND,
    getTimeAgo,
} from "../../../utils";
import Icon from "../../../components/Icon";
import PricingTreeModal from "../../../components/PricingTreeModal";
import useGetPendingRoots from "../../../customHooks/useGetPendingRoots";
import { useFinishUpdateRoot } from "../../../customHooks/useFinishUpdateRoot";
import CountdownTimer from "../../../components/CountdownTimer";
import useConfig from "../../../customHooks/useConfig";
import { COLLECTION_ADDRESSES_MAINNET } from "../../../ethers/nftCollections";
import Loader from "../../../components/Loader";

const IPFS_KEY = "ipfsHash";
const MERKLE_KEY = "merkleRoot";

const Preview = ({ className, item, supportedCollections, logs }) => {

    // pretend for testing
    // item.upcomingHash = "QmAmcpnNg4aqAS88PRgmXjfPoExh1DPuNhTqkTcpZ1VadV";
    // item.upcomingRoot = "0xa52f3e8575a1d8f1860fd475654876b7fa49cf0ca2958723faeaa6e7566291cb";

    const [visible, setVisible] = useState(false);
    const [visibleModal, setVisibleModal] = useState(false);
    const [displayedHash, setDisplayedHash] = useState(IPFS_KEY);

    const config = useConfig();

    const COLLECTION_LOG = {
        [COLLECTION_ADDRESSES_MAINNET.PUNKS_VERSION_ONE]: '0x16e3694dbdbcef64c09b153b4b51dc5489268d8bc550cf6cc022b8ab325ea06d',
        [COLLECTION_ADDRESSES_MAINNET.WRAPPED_PUNKS]: '0xc1e4be106cbb70fc6e6206f57282e5405a39cbb46f9e36d3ec6a0836fbe2cdeb',
        [COLLECTION_ADDRESSES_MAINNET.COOL_CATS]: '0x1a7df07b25f8c107a1c89deb06ca5f840ee8c90be19fc68cfc09ed9f72dbfeb3',
        [COLLECTION_ADDRESSES_MAINNET.MUTANT_APES]: '0x84951d7c1d579a5585298eb71ce2d28c92c259f405ccaeece82010b5e376531a'
    };

    const COLLECTION_TIMES = {
        [COLLECTION_ADDRESSES_MAINNET.PUNKS_VERSION_ONE]: 1669262390,
        [COLLECTION_ADDRESSES_MAINNET.WRAPPED_PUNKS]: 1666002248,
        [COLLECTION_ADDRESSES_MAINNET.COOL_CATS]: 1666002248,
        [COLLECTION_ADDRESSES_MAINNET.MUTANT_APES]: 1666002248
    };

    const finishUpdateRoot = useFinishUpdateRoot();

    const pendingRoot = useGetPendingRoots(
        item.collectionAddress
    );

    const pendingRootTime = useMemo(() => {

        if (!pendingRoot) return;

        const updateTimeInSeconds = Number(
            pendingRoot.updateTime.toString()
        );

        const upcomingDateMS = updateTimeInSeconds
            * MS_IN_SECOND;

        return upcomingDateMS;

    }, [pendingRoot]);

    const switchHash = () => {
        const hash = displayedHash === IPFS_KEY
            ? MERKLE_KEY
            : IPFS_KEY;
        setDisplayedHash(hash);
    };

    const toggleMessage = displayedHash === MERKLE_KEY
        ? "IPFS Hash"
        : "Merkle Root";

    const displayMessage = displayedHash === IPFS_KEY
        ? "IPFS Hash"
        : "Merkle Root";

    const upcomingValue = displayedHash === IPFS_KEY
        ? pendingRoot?.ipfsAddress
        : pendingRoot?.merkleRoot

    const alertIcon = pendingRootTime
        ? styles.orange
        : styles.green;

    const latestUpdateLog = logs
            ? logs.reduce((latestLog, log) =>
                log.updateTime > latestLog.updateTime ? log : latestLog
            )
            : {
                updateTime: COLLECTION_TIMES[item.collectionAddress],
                transactionHash: COLLECTION_LOG[item.collectionAddress]
            };

    const handleTimerOnClick = async (collectionAddress) => {
        if (!finishUpdateRoot.loading) {
            await finishUpdateRoot.call(
                collectionAddress
            );
        }
    };

    const timerCompleteLabel = useMemo(() => {
        if (finishUpdateRoot.loading) {
            return 'Updating Prices...';

        } else if (finishUpdateRoot.confirm) {
            return "Confirm Execution";

        }
        return "Ready to Execute";

    }, [
        finishUpdateRoot.loading,
        finishUpdateRoot.confirm
    ]);

    return (
        <div className={cn(className, styles.item, { [styles.active]: visible })}>
            <div className={styles.head} onClick={() => setVisible(!visible)}>
                <span className={
                    cn(styles.box, alertIcon)
                }>
                    {item.title}
                </span>
                {
                    finishUpdateRoot.loading === true && (
                        <Loader color="white" className={styles.headerLoader} />
                    )
                }
            </div>
            <div className={styles.body}>
                <div>
                    <div className={styles.flexRow}>
                        <span>
                            Current { displayMessage }
                        </span>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href={`${PINATA_CLOUD_URL}/ipfs/${item.ipfsHash}`}
                        >
                            {shortenHash(item[displayedHash])}
                            <span className={styles.icon}>
                                <Icon name="external" size="20" viewBoxLarge={true} />
                            </span>
                        </a>
                    </div>
                    { pendingRootTime > 0 && (
                        <>
                            <div className={styles.flexRow}>
                                <span>
                                    Upcoming { displayMessage }
                                </span>
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href={`${PINATA_CLOUD_URL}/ipfs/${pendingRoot.ipfsAddress}`}
                                >
                                    {shortenHash(upcomingValue)}
                                    <span className={styles.icon}>
                                        <Icon name="external" size="20" viewBoxLarge={true} />
                                    </span>
                                </a>
                            </div>
                            {
                                alertIcon && (
                                    <div className={styles.flexRow}>
                                        <span>Scheduled Update</span>
                                        <CountdownTimer
                                            targetDate={pendingRootTime}
                                            onClick={() => {
                                                handleTimerOnClick(
                                                    item.collectionAddress
                                                );
                                            }}
                                            completeLabel={timerCompleteLabel}
                                        />
                                    </div>
                                )
                            }
                        </>)}
                    {
                        !pendingRootTime && (
                            <div className={styles.flexRow}>
                                <span>Last Time Updated</span>
                                {
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href={`${config.etherscanAddress}/tx/${latestUpdateLog.transactionHash}`}
                                    >
                                        {
                                            getTimeAgo(latestUpdateLog.updateTime * 1000)
                                        }
                                        <span className={styles.icon}>
                                            <Icon name="external" size="20" viewBoxLarge={true} />
                                        </span>
                                    </a>
                                }
                            </div>
                        )
                    }
                </div>
                <div className={styles.buttonGroup}>
                    <div>
                    <button
                        onClick={() => setVisibleModal(true)}
                        className={cn("button-stroke button-small", styles.button)}
                    >
                        {pendingRootTime ? "Compare" : "Show"} Pricing Data
                    </button>
                        <button
                            onClick={switchHash}
                            className={cn("button-stroke button-small", styles.button)}
                        >
                            Show { toggleMessage }
                        </button>
                    </div>
                </div>
            </div>
            <PricingTreeModal
                item={item}
                supportedCollections={supportedCollections}
                visibleModal={visibleModal}
                onClose={() => setVisibleModal(false)}
            />
        </div>
    );
};

export default Preview;
