import indexedTransferABI from "./abis/indexedTransferABI.json";
import enumerableABI from "./abis/enumerableABI.json";
import cryptoPunksABI from "./abis/cryptoPunksABI.json";
// import { cryptoPunksStyleFetch } from "./index";

export const COLLECTION_ADDRESSES_TESTNET = {
    CRYPTO_PUNKS: "0x59BDDAB3E5115a1678E6314584d0D1bC2737381E",
    WRAPPED_PUNKS: "0x25D6C6FC05302DfcDbf2d100C73A86293F174878",
    // MUTANT_APES: "0xDF19b3ac89306b77bceCB156Df6DB08D9810e2A4",
    COOL_CATS: "0xFd8A5bae00e67246B123e18D838A20Cc8Cf7395f",
    DOODLES: "0x6f66D6520EAAD07b6b754E6f4Fe077fA76624bA2",
    BORED_APES: "0x6C5eEfce5d71bE9B9d21A91fc2567756F5d73d09",
    // PUDGY_PENGUIN: "", //
    // THE_HUMANOIDS: "0x27Abc49fcE8EfE5eab430A54E0bE0805D737D861",
    // AZUKI: "0x42d20012F2996B7a8d91E02EcdAc35447B9194f0",
    // BORED_KENNELS: "0x0b3b2F8c5A440b83DF650B26eB3435487a4E58e4",
    // DEAD_FELLAZ: "0xABaC00fa2c2242B89b04082621479D045368680f",
    // WONDER_PALS: "0xb32a844E0C24BAB10dCa05DBef03D3b794BCc149",
    // CYBER_KONGZ: "0xE3A4a3939C37E0a5a0bdB30eB7e57809C4d40180",
    // INVISIBLE_FRIENDS: "0x0530409965622Cd1f5f8aB14E347288530DD1e88",
    // MOON_BIRDS: "0x6b354b6c4AC6B30dffb103C39e88283659e0CCD5",
    // KARAFURU: "0x5F391F000CD3fcB743fb80c0fcF696234C56c7A3",
}

export const testnetEnumerable = [
    {
        name: "Doodles",
        singleName: "Doodle",
        address: COLLECTION_ADDRESSES_TESTNET.DOODLES,
    },
    {
        name: "Bored Apes",
        singleName: "Bored Ape",
        address: COLLECTION_ADDRESSES_TESTNET.BORED_APES,
    },/*
    {
        name: "Mutant Apes",
        address: COLLECTION_ADDRESSES_TESTNET.MUTANT_APES,
    },*/
    {
        name: "Cool Cats",
        singleName: "Cool Cat",
        address: COLLECTION_ADDRESSES_TESTNET.COOL_CATS,
    },
    {
        name: "Wrapped Punks",
        singleName: "Wrapped Punk",
        address: COLLECTION_ADDRESSES_TESTNET.WRAPPED_PUNKS,
    },
    /*
    {
        name: "Azuki ",
        address: COLLECTION_ADDRESSES_TESTNET.AZUKI,
    },
    {
        name: "Bored Kennels",
        address: COLLECTION_ADDRESSES_TESTNET.BORED_KENNELS,
    },
    {
        name: "DeadFellaz",
        address: COLLECTION_ADDRESSES_TESTNET.DEAD_FELLAZ,
    },
    {
        name: "Pudgy Penguins",
        singleName: "Pudge Penguin",
        address: COLLECTION_ADDRESSES_TESTNET.PUDGY_PENGUIN
    }
    */
];
export const testnetIndexedTransfer = [
    /*{
        name: "WonderPals",
        singleName: "WonderPal",
        address: COLLECTION_ADDRESSES_TESTNET.WONDER_PALS,
    },
    {
        name: "The Humanoids",
        singleName: "Humanoid",
        address: COLLECTION_ADDRESSES_TESTNET.THE_HUMANOIDS,
    },
    {
        name: "CyberKongz",
        singleName: "CyberKongz",
        address: COLLECTION_ADDRESSES_TESTNET.CYBER_KONGZ,
    },
    {
        name: "Invisible Friends",
        singleName: "Invisible Friend",
        address: COLLECTION_ADDRESSES_TESTNET.INVISIBLE_FRIENDS,
    },
    {
        name: "Moon Birds",
        singleName: "Moon Bird",
        address:COLLECTION_ADDRESSES_TESTNET.MOON_BIRDS,
    },
    {
        name: "Karafuru",
        singleName: "Karafuru",
        address: COLLECTION_ADDRESSES_TESTNET.KARAFURU,
    },*/
    /*{
        name: "Chemistry Club",
        singleName: "Chemistry Club",
        address: "0x705Fd6AE9BB31eBb679BDf6Defbc7AC654986E6a",
    },*/
];

export const testnetPeculiarContracts = [
    {
        name: "CryptoPunks",
        singleName: "CryptoPunk",
        address: COLLECTION_ADDRESSES_TESTNET.CRYPTO_PUNKS,
    },
];

export const allNftContracts = [
    ...testnetPeculiarContracts,
    ...testnetEnumerable,
    ...testnetIndexedTransfer,
];

export const testnetNftAddressToNameMapping = allNftContracts.reduce((obj, acc) => (
    {
        ...obj,
        [acc.address]: {
            name: acc.name,
            singleName: acc.singleName
        }
    }
), {});

export const testnetGetCollectionNameByAddress = (address) => {
    const collectionAddress = Object.keys(testnetNftAddressToNameMapping)
        .find(collectionAddress =>
            collectionAddress?.trim().toLowerCase() === address?.trim().toLowerCase()
        );
    return testnetNftAddressToNameMapping[collectionAddress];
}

export const testnetNftNameToAddressMapping = allNftContracts.reduce(
    (obj, acc) => ({ ...obj, [acc.name]: acc.address }),
    {}
);

const indexedTransferAddresses = testnetIndexedTransfer.map((c) => c.address);
const peculiarAddresses = testnetPeculiarContracts.map((c) => c.address);

export const testnetGetAbi = (address) => {
    if (indexedTransferAddresses.includes(address)) return indexedTransferABI;
    if (peculiarAddresses.includes(address)) return cryptoPunksABI;
    return enumerableABI;
};
