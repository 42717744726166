import {
    nftAddressToNameMapping,
    nftNameToAddressMapping,
    getCollectionNameByAddress
} from "../ethers/nftCollections/";

import {
    testnetNftAddressToNameMapping,
    testnetNftNameToAddressMapping,
    testnetGetCollectionNameByAddress,
} from "../ethers/nftCollections/testnet";

import { getAbi } from "../ethers/nftCollections";
import { testnetGetAbi } from "../ethers/nftCollections/testnet";

export const OPENSEA_API = "https://api.opensea.io";
export const OPENSEA_API_KEY = process.env.REACT_APP_OPENSEA_API_KEY;
export const OWL_MY_BABIES_ADDRESS = "0x495f947276749ce646f68ac8c248420045cb7b5e";

const supportedChains = [
    1,
    5
];

// mainnet
const mainNetTokens = {
    WISE: "0x66a0f676479Cee1d7373f3DC2e2952778BfF5bd6",
    USDT: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
    USDC: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
    WETH: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
};

// goerli
const testNetTokens = {
    WISE: "0xD87Ba7A50B2E7E660f678A895E4B72E7CB4CCd9C",
    USDT: "0xD87Ba7A50B2E7E660f678A895E4B72E7CB4CCd9C",
    USDC: "0xD87Ba7A50B2E7E660f678A895E4B72E7CB4CCd9C",
    WETH: "0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6",
};

const customCollectionImages = {
    Satoshibles: "https://satoshibles.com/token/27/small/image.png"
};

const localCollectionImages = {
    Azuki: "/images/collections/Azuki.avif",
    "Bored Kennels":  "/images/collections/Bored Kennels.avif",
    "Bored Apes":  "/images/collections/Bored Apes.avif",
    CloneX: "/images/collections/CloneX.avif",
    "Cool Cats": "/images/collections/Cool Cats.avif",
    CyberKongz: "/images/collections/CyberKongz.avif",
    CryptoPunks: "/images/collections/CryptoPunks.avif",
    DeadFellaz: "/images/collections/DeadFellaz.avif",
    Doodles: "/images/collections/Doodles.avif",
    "Invisible Friends": "/images/collections/Invisible Friends.avif",
    mfers: "/images/collections/mfers.avif",
    "Moon Birds": "/images/collections/Moon Birds.avif",
    "Mutant Apes": "/images/collections/Mutant Apes.avif",
    "Pudgy Penguins": "/images/collections/Pudgy Penguins.avif",
    "Wrapped Punks V1": "/images/collections/Wrapped Punks V1.avif",
    "Wrapped Punks": "/images/collections/Wrapped Punks.avif",
}

const reverseMapping = (obj) =>
    Object.keys(obj).reduce((o, key) => ({ ...o, [obj[key]]: key }), {});

const mainNetTokenAddressToNameMapping = reverseMapping(
    mainNetTokens
);

const testNetTokenAddressToNameMapping = reverseMapping(
    testNetTokens
);

const decimals = {
    WISE: 18,
    WETH: 18,
    USDT: 6,
    USDC: 6,
};

export const getConfig = (chainId) => {
    const chains = {
        1: {
            liquidNFTContract: "0x9961f05a53A1944001C0dF650A5aFF65B21A37D0",
            routerAddress: "0xB4d2484EF7f91c8D4cd019460Ccbc384C1C24297",
            paymentTokens: mainNetTokens,
            nftAddressToNameMapping,
            nftNameToAddressMapping,
            tokenAddressToNameMapping: mainNetTokenAddressToNameMapping,
            getCollectionNameByAddress,
            getAbi,
            chainId: 1,
            inceptionBlock: 14338403,
            inceptionBlockPools: 15667415,
            inceptionTimeRouter: 1666002248,
            decimals,
            customCollectionImages,
            localCollectionImages,
            uniswapV2Router2: "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D",
            chainlinkPricingContract: "0x5f4ec3df9cbd43714fe2740f5e3616155c5b8419",
            etherscanAddress: "https://etherscan.io",
            wrappedEtherAddress: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2"
        },
        5: {
            liquidNFTContract: "0xE981F2E9636c5e1A819CCA920DD2242D186A991C",
            // routerAddress: "0x7f1c970A239465E26c8be8054b6E7225B2Bff6Fc",
            routerAddress: "0xEFc7B85430EA7bCdfE067aa82D4F8916D1B8b585",
            paymentTokens: testNetTokens,
            chainId: 5,
            nftAddressToNameMapping: testnetNftAddressToNameMapping,
            nftNameToAddressMapping: testnetNftNameToAddressMapping,
            tokenAddressToNameMapping: testNetTokenAddressToNameMapping,
            getCollectionNameByAddress: testnetGetCollectionNameByAddress,
            getAbi: testnetGetAbi,
            inceptionBlock: 6021767,
            inceptionBlockPools: 6021767,
            inceptionTimeRouter: 1666002248,
            decimals,
            customCollectionImages,
            localCollectionImages,
            uniswapV2Router2: "0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506",
            chainlinkPricingContract: "0xF01B5C0895331B547070202BBE67bA2bBC12600C",
            etherscanAddress: "https://goerli.etherscan.io",
            wrappedEtherAddress: "0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6"
        },
    };

    const chain = supportedChains.includes(chainId)
        ? chainId
        : 1;

    return chains[chain];
    /* return supportedChains.includes(chainId)
        ? chains[chainId]
        : {
            ...chains[0],
            unsupported: true
        };
    */
};

export const SUGGESTED_OWL_ADDRESSES = [
    '80854571046587284139654852168886073402943122695944337267750195096502344351745', // 147
    '80854571046587284139654852168886073402943122695944337267750195200955948990465', // 244
    '80854571046587284139654852168886073402943122695944337267750194987650693201921', // 54
    '80854571046587284139654852168886073402943122695944337267750195143781344346113', // 189
    '80854571046587284139654852168886073402943122695944337267750194951366809485313', // 27
    '80854571046587284139654852168886073402943122695944337267750195107497460629505', // 157
    '80854571046587284139654852168886073402943122695944337267750195147079879229441', // 192
    '80854571046587284139654852168886073402943122695944337267750195134985251323905', // 181
    '80854571046587284139654852168886073402943122695944337267750195188861321084929', // 229
    '80854571046587284139654852168886073402943122695944337267750195153676948996097', // 198
    '80854571046587284139654852168886073402943122695944337267750195070114065285121', // 123
    '80854571046587284139654852168886073402943122695944337267750195132786228068353', // 179
    '80854571046587284139654852168886073402943122695944337267750195105298437373953', // 155
];
