import React, { useState, useMemo } from "react";
import { useDispatch } from "react-redux";
import cn from "classnames";
import { useEthers } from "@usedapp/core";
import { Contract } from "ethers";
import toast, { Toaster } from "react-hot-toast";

import styles from "./Liquidate.module.sass";
import lockerAbi from "../../ethers/abis/LiquidLocker.json";
import { setUpdateLatestTx } from '../../redux/settingsSlice';
import Loader from "../../components/Loader";
import { addDays } from "../../utils";
import Success from "../../components/Transaction";

import {
    MS_IN_SECOND,
    PENDING_STATUS,
    READY_STATUS,
    getToastSettings,
    getToastErrorMessage,
} from "../../utils";

const DEFAULT_TEXT = "Proceed with Liquidation";
const IN_PROGRESS = "Liquidating...";
const CONFIRM_TEXT = 'Confirm in Metamask';
const DONE_TEXT = "Done";

const DEFAULT_INFO_PREFIX = "Loan is eligible for liquidation because";

const Liquidate = ({
  closeModal,
  lockerAddress,
  totalCollected,
  floorAsked,
  creationTime,
  nextDueTime,
  loanStatus,
  timelineItems
}) => {

    const { library, account } = useEthers();
    const dispatch = useDispatch();
    const [liquidationProcessing, setLiquidationProcessing] = useState(false);
    const [loading, setLoading] = useState(false);
    const [transactionHash, setTransactionHash] = useState();
    const [buttonText, setButtonText] = useState(DEFAULT_TEXT);

    const signer = library.getSigner(
        account
    );

    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    };

    // get next due time for activated listings
    const nextDueTimeStamp = nextDueTime ? nextDueTime.toNumber() : 0;
    const nextDueTimestampMs = nextDueTimeStamp * MS_IN_SECOND;

    const nextDueDateObj = new Date(
        nextDueTimestampMs
    );

    const timelineDateObj = new Date(
        timelineItems[3].date
    );

    const trueNextDueDate = nextDueDateObj > timelineDateObj
        ? timelineDateObj
        : nextDueDateObj;

    const liquidationDate = addDays(
        trueNextDueDate,
        7 // @TODO: replace with config value
    );

    const pastLiquidationDate = (
        new Date().valueOf() >= liquidationDate.valueOf()
    );

    // get creation time for pending listings
    const creationTimeStamp = creationTime ? creationTime.toNumber() : 0;
    const creationTimestampMs = creationTimeStamp * MS_IN_SECOND;

    // inactive lockers terminated after 15 days (end of creation time plus 10 days)
    const createTimeObj = new Date(creationTimestampMs);

    const activationDate = addDays(
        createTimeObj,
        15 // @TODO: replace with config value
    );

    const pastActivateDate = (
        new Date().valueOf() >= activationDate.valueOf()
    );

    const reachedFloor = totalCollected && totalCollected.gte(
        floorAsked
    );

    // check for eligibility
    const canLiquidate = (
        (nextDueTimeStamp && pastLiquidationDate)
        || (reachedFloor && pastActivateDate)
    );

    const infoText = useMemo(() => {

        const unpaidTime = liquidationDate.toLocaleString(
            'en-US',
            { hour12: false }
        ).split(" ")[1];

        const inactiveTime = activationDate.toLocaleString(
            'en-US',
            { hour12: false }
        ).split(" ")[1];

        const unpaidDate = liquidationDate.toLocaleDateString(
            undefined,
            options
        );

        const inactiveDate = activationDate.toLocaleDateString(
            undefined,
            options
        );

        const inactiveStr = `${inactiveDate} at ${inactiveTime}`;
        const unpaidStr = `${unpaidDate} at ${unpaidTime}`;

        const isPending = loanStatus === PENDING_STATUS || loanStatus === READY_STATUS;

        if (canLiquidate) {
            return isPending
                ? `${DEFAULT_INFO_PREFIX} the loan past it's latest activation date of ${inactiveStr}`
                : `${DEFAULT_INFO_PREFIX} it has been seven days since the last payment due on ${unpaidStr}`;
        }

        return isPending
            ? `Loan can be liquidated if floor amount reached and not activated by ${inactiveStr}`
            : `Loan is eligible for liquidation if due payment is not made before ${unpaidStr}`;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        canLiquidate,
        pastLiquidationDate,
        activationDate,
        liquidationDate,
        loanStatus
    ]);

    const handleLiquidate = async () => {

        if (buttonText === DONE_TEXT) {
            closeModal();
            return;
        }

        if (liquidationProcessing) return;

        setLiquidationProcessing(true);
        // setErrorMessage(EMPTY_STRING);

        try {

            const tokenContract = new Contract(
                lockerAddress,
                lockerAbi,
                signer
            );

            setButtonText(CONFIRM_TEXT);
            const tx = await tokenContract.liquidateLocker();

            const transactionHash = tx.hash;
            setLoading(true);
            setButtonText(IN_PROGRESS);
            setTransactionHash(transactionHash);

            const liquidateReceipt = await tx.wait();
            dispatch(setUpdateLatestTx(transactionHash));
            setLoading(false);
            setButtonText(DONE_TEXT);

            if (liquidateReceipt) {
                setTransactionHash(
                    liquidateReceipt.transactionHash
                );
            }

        } catch (err) {

            setLoading(false);
            setButtonText(DEFAULT_TEXT);
            setLiquidationProcessing(false);

            // if (err.code === 4001) return;

            toast.error(
                getToastErrorMessage(err.code),
                getToastSettings("🚫")
            );
        }

        setLiquidationProcessing(false);
    };

    return (
        <div className={styles.transfer}>
            <div className={cn("h4", styles.title)}>
                Liquidate Loan
            </div>
            { transactionHash
                ? (
                  <Success
                      txHash={transactionHash}
                      title={"You successfully liquidated this loan"}
                      showButton={false}
                      celebrate={true}
                  />
                )
                : (
                  <div className={styles.text}>
                      { infoText }
                  </div>
              )}
            <div className={styles.btns}>
                <button
                    className={cn(
                        "button",
                        styles.button,
                        (buttonText === DEFAULT_TEXT || buttonText === DONE_TEXT) ? "" : "loading",
                        canLiquidate ? "" : "disabled"
                    )}
                    onClick={handleLiquidate}
                >
                    { loading && <Loader className={styles.loaderSmall} color="white" /> }
                    { buttonText }
                </button>
            </div>
            <Toaster />
        </div>
    );
};

export default Liquidate;
