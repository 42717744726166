import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./Search02.module.sass";
import Image from "../../components/Image";
// import Form from "../../components/Form";
import Control from "../../components/Control";

const loans = [
    {
        title: "Satoshibles",
        content: "38 Loans",
        image: "https://satoshibles.com/token/27/small/image.png",
    },
    {
        title: "Bored Apes",
        content: "18 Loans",
        image: "https://watcher.guru/news/wp-content/uploads/2021/08/unnamed.png",
    },
    {
        title: "Cool Cats",
        content: "13 Loans",
        image: "https://coolcatstron.com/img/9933.jpg",
    },
    {
        title: "Cryptopunks",
        content: "138 Loans",
        image: "https://d32dm0rphc51dk.cloudfront.net/bMc5MLiwBlFOJrNZSRakWg/larger.jpg",
    },
];

const breadcrumbs = [
    {
        title: "Home",
        url: "/",
    },
    {
        title: "Search",
    },
];

const Search = () => {
    // const [search, setSearch] = useState("");

    // const handleSubmit = (e) => {
    //     alert();
    // };


    return (
        <>
            <Control className={styles.control} item={breadcrumbs} />
            <div className={cn("section", styles.section)}>
                <div className={cn("container", styles.container)}>
                    <div className={styles.preview}>
                        <Image
                            srcSet="/images/content/token.png"
                            srcSetDark="/images/content/token.png"
                            src="/images/content/token.png"
                            srcDark="/images/content/token.png"
                            alt="Cone"
                            className={styles.img}
                        />
                    </div>
                    <div className={styles.wrap}>
                        <h2 className={cn("h2", styles.title)}>
                            Sorry, we couldn’t find any supportd NFT in your wallet
                        </h2>
                        <div className={styles.info}>Consider to fund loans instead or buy tokens!</div>
                        <div className={styles.btns}>
                            <Link className={cn("button", styles.button)} to="/custom-listings">
                                Custom Listings
                            </Link>
                        </div>
                    </div>
                    <div className={styles.subtitle}>Supported Collections</div>
                    <div className={styles.list}>
                        {loans.map((loan, index) => (
                            <div className={styles.item} key={index}>
                                <div className={styles.picture}>
                                    <img src={loan.image} alt="Category" />
                                </div>
                                <div className={styles.details}>
                                    <div className={styles.category}>{loan.title}</div>
                                    <div style={{display: "flex", justifyContent: "space-between", gap: "5px"}}>
                                        <div className={styles.text}>350 ETH / </div>
                                        <div className={styles.text}>{loan.content}</div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Search;
