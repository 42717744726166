import React, { useMemo } from "react";
// import cn from "classnames";
import { useEthers } from "@usedapp/core";
import { formatUnits } from "ethers/lib/utils";
// import styles from "../../screens/InstantPools/Pools/Pools.module.sass";
import { usePoolUserInfo } from "../../customHooks/usePoolUserInfo";
import { usePoolGeneralInfo } from "../../customHooks/usePoolGeneralInfo";

import {
    toPrecision,
    LEND_RATE_DECIMALS,
    SHORT_FETCH_MESSAGE,
    DEFAULT_FIXED_DECIMALS,
} from "../../utils";

const PoolDeposited = ({ poolInfo, account }) => {

    const { account: currentAccount } = useEthers();

    const { userTokensDeposited, userInternalShares  } = usePoolUserInfo(
        poolInfo.address,
        account
    );

    const { depositApy } = usePoolGeneralInfo(
        poolInfo.address
    );

    const formattedToken = useMemo(() => {

        if (!userTokensDeposited) return "0.00";

        const formatted = formatUnits(
            userTokensDeposited,
            poolInfo.tokenDecimals
        );

        // Precise Way (Cut-down)
        return toPrecision(
            formatted,
            DEFAULT_FIXED_DECIMALS
        );

        // Dirty Way (Round-up)
        // return parseFloat(formatted).toFixed(2);

    }, [userTokensDeposited, poolInfo]);

    const isCurrentUser = currentAccount === account;

    const formattedBorrowRate = depositApy && formatUnits(
        depositApy.toString(),
        LEND_RATE_DECIMALS
    );

    const userPronounce = isCurrentUser
        ? "You own"
        : "Owns";

    // @TODO: avoid showing you own 0.00...

    const balanceValueDisplay = formattedToken && userInternalShares
        ? `${userPronounce} ${formattedToken} ${poolInfo.currency}`
        : SHORT_FETCH_MESSAGE

    const lendRateDisplay = formattedBorrowRate
        ? `${parseFloat(formattedBorrowRate).toFixed(2)}% APY`
        : SHORT_FETCH_MESSAGE

    return (
        <div>
            <div
                style={{marginLeft: "0px"}}
                className={"status-pink"}
            >
                {balanceValueDisplay}
            </div>
            <div
                style={{marginLeft: "5px", marginBottom: "10px"}}
                className={"status-black"}
            >
                {lendRateDisplay}
            </div>
        </div>
    );
};

export default PoolDeposited;
