import React from "react";
import { useSelector } from "react-redux";
import cn from "classnames";
import styles from "./Steps.module.sass";
import ScrollParallax from "../../../components/ScrollParallax";

const Steps = ({ scrollToRef, items, header, info, fade, nextDueTime }) => {

    const showTimeline = useSelector((state) => state.settings.hiddenOptions.showTimeline);

    const extra = fade === true
        ? 0
        : 0.7;

    const classChoice = nextDueTime
        ? styles.extra
        : styles.item;

    return (
        <div 
            className={cn({ [styles.hidden]: !showTimeline }, "section", styles.section)} 
            ref={scrollToRef}
        >
            <div className={cn("container", styles.container)}>
                { header && (
                    <div className={styles.head}>
                        <h2 className={cn("h4", styles.title)}>Listing Timeline</h2>
                        <div className={styles.info}>
                            Here is how the schedule for this loan listing
                            looks like and what should happnen next
                        </div>
                    </div>
                )}
                <div className={styles.list}>
                    {items.map((x, index) => (
                        <ScrollParallax className={cn(styles.item, classChoice)} key={index}>
                            <div style={{opacity: 1.7 - x.gray}} className={styles.number}>{x.date}</div>
                            <div
                                className={styles.preview}
                                style={{ backgroundColor: x.color, filter: `grayscale(${x.gray})` }}
                            >
                                <img src={x.images} alt={`Step ${index}`} />
                            </div>
                            { info && (
                                <div>
                                    <div style={{opacity: 1.7 - x.gray}} className={styles.subtitle}>{x.title}</div>
                                    <div style={{opacity: extra + 1.0 - x.gray}} className={styles.content}>{x.content}</div>
                                </div>
                            )}
                        </ScrollParallax>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Steps;
