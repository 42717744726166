import React, { useState } from "react";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import toast from 'react-hot-toast';

import { 
    getToastSettings, 
    BASE_TWITTER_URL, 
    NEW_PAGE_SETTINGS, 
} from "../../utils/";
import styles from "./Share.module.sass";
// import Transfer from "../Transfer";
import Icon from "../Icon";
// import Modal from "../../components/Modal";

const TWITTER_MESSAGE = `Check it out! Just created an NFT loan with LiquidNFTs.com - ${window.location.href}`;

const Share = ({ className }) => {
    const [visible, setVisible] = useState(false);
    // const [visibleModalTransfer, setVisibleModalTransfer] = useState(false);
    // const [visibleModalActivate, setVisibleModalActivate] = useState(false);
    // const [visibleModalRemoveLoan, setVisibleModalRemoveLoan] = useState(false);
    // const [visibleModalCancel, setVisibleModalCancel] = useState(false);
    // const [visibleModalReport, setVisibleModalReport] = useState(false);

    const items = [
        {
            title: "Share On Twitter",
            icon: "twitter",
            action: () => window.open(BASE_TWITTER_URL + encodeURIComponent(TWITTER_MESSAGE), '', NEW_PAGE_SETTINGS),
        },
        {
            title: "Copy Direct Link",
            icon: "copy",
            action: async () => {
                await navigator.clipboard.writeText(window.location.href);
                toast.success(
                    "Copied to Clipboard!",
                    getToastSettings("✅")
                );
            },
        },
        /*{
            title: "Liquidate Loan",
            icon: "close-circle",
            action: () => setVisibleModalRemoveLoan(true),
        },*/
        /*{
            title: "Report Loan",
            icon: "info-circle",
            action: () => setVisibleModalReport(true),
        },*/
    ];

    return (
        <>
            <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
                <div
                    className={cn(styles.actions, className, {
                        [styles.active]: visible,
                    })}
                >
                    <button
                        className={cn("button-circle-stroke", styles.button)}
                        onClick={() => setVisible(!visible)}
                    >
                        <Icon name="share" size="24" />
                    </button>
                    <div className={styles.body}>
                        {items.map((x, index) => (
                            <div className={styles.item} key={index} onClick={x.action}>
                                <Icon name={x.icon} size="20" />
                                <span>{x.title}</span>
                            </div>
                        ))}
                    </div>
                </div>
            </OutsideClickHandler>
        </>
    );
};

export default Share;
