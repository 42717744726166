// import { useEffect, useState } from "react";
// import { useState } from "react";
// import axios from "axios";
// import { getMainNetAddress } from "../ethers/nftCollections";
// import useConfig from "./useConfig";
// import { OPENSEA_API, OPENSEA_API_KEY } from "../utils/config";
// import useExistingLockerFetch from "./useExistingLockerFetch";

const useCollections = (nftContracts) => {

    let collections = [];
    let loading = true;
    let error = null;

    // const config = useConfig();
    // const lockers = useExistingLockerFetch();

    return [collections, loading, error];
};

export default useCollections;
