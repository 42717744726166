import { configureStore } from "@reduxjs/toolkit";

import settingsReducer from "./settingsSlice";
import conversionsReducer from "./conversionsSlice";

export const store = configureStore({
    reducer: {
        settings: settingsReducer,
        conversions: conversionsReducer
    },
});
