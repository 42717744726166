import { useState, useEffect } from "react";
import axios from "axios";
import { Interface } from "ethers/lib/utils";
import { useEthers } from "@usedapp/core";
import { BigNumber, Contract } from "ethers";
import useConfig from "./useConfig";
import { isPunk, getMainNetAddress } from "../ethers/nftCollections";
import { determineStatus, TEST_NETWORK_ID } from "../utils";
import useExistingLockerFetch from "./useExistingLockerFetch";
import useCallsGracefully from "./useCallsGracefully";
import LIQUID_LOCKER_ABI from "../ethers/abis/LiquidLocker.json";

import {
    DEFAULT_COLLECTION_NAME,
    LISTING_DETAILS_METHODS
} from "../utils/index";

import { OPENSEA_API, OPENSEA_API_KEY } from "../utils/config";
import { getCollectionData } from "../utils/query/tokens";

const lockerInterface = new Interface(
    LIQUID_LOCKER_ABI
);


const useListingDetails = (lockerAddress) => {

    const { chainId } = useEthers();
    const config = useConfig();

    const locker = useExistingLockerFetch(
        lockerAddress
    );

    const base = {
        contract: new Contract(lockerAddress, lockerInterface),
        address: lockerAddress,
        args: [],
    };

    const loanDetailsCalls = LISTING_DETAILS_METHODS.map((method) => ({
        ...base,
        method,
    }));

    const details = useCallsGracefully(
        loanDetailsCalls
    );

    const [
        floorAsked,
        totalAsked,
        getTokens,
        globals,
        paymentToken,
        totalCollected,
        remainingBalance,
        floorNotReached,
        creationTime,
        contributionPhase,
        nextDueTime,
        singleProvider,
        claimableBalance,
        belowFloorAsked,
    ] = details;

    const {
        paymentRate,
        paymentTime,
        tokenAddress,
        lockerOwner,
    } = globals || {};

    const tokenIsPunk = isPunk(
        tokenAddress
    );

    const stringifiedTokens = JSON.stringify(
        getTokens
    );

    const [tokensWithData, setTokensWithData] = useState([]);
    const [floorPrice, setFloorPrice] = useState("--");

    useEffect(() => {
        let mounted = true;
        const getData = async () => {

            const tokenIds = getTokens
                ? [...getTokens]
                : [];

            const size = 30;
            const chunks = [];

            while (tokenIds.length) {
                chunks.push(
                    tokenIds.splice(
                        0,
                        size
                    )
                );
            }

            const collectionAddress = chainId === TEST_NETWORK_ID && tokenAddress
                    ? getMainNetAddress(tokenAddress)
                    : tokenAddress;

            const chunkResponses = await Promise.all(
                chunks.map(async (chunk) => {
                    const tokenIds = chunk.map((id) => `token_ids=${id.toNumber()}`).join("&");
                    const opensea = `${OPENSEA_API}/api/v1/assets?${tokenIds}&asset_contract_address=${collectionAddress}&order_direction=desc`;
                    const res = await axios.get(opensea, {
                        crossdomain: true,
                        headers: {
                            "X-API-KEY": OPENSEA_API_KEY,
                            "Access-Control-Allow-Origin": "*",
                            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
                        },
                    });
                    const { assets } = res.data;
                    const dataChunk = assets.map((a) => ({
                        address: tokenAddress,
                        id: BigNumber.from(a.token_id),
                        data: { image: a.image_url },
                    }));
                    return dataChunk;
                })
            );
            const response = chunkResponses.flat();
            if (mounted) setTokensWithData(response);
        };

        if (!tokenIsPunk) getData();
        return () => (mounted = false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stringifiedTokens]);

    useEffect(() => {
        let mounted = true;
        const fetchFloorPrice = async () => {
            const collectionAddrMain = getMainNetAddress(tokenAddress);
            const collectionData = await getCollectionData(collectionAddrMain);
            const fetchedFloorPrice = collectionData && collectionData.floorPrice
                ? collectionData.floorPrice
                : "--";
            if (mounted) setFloorPrice(fetchedFloorPrice)
        };
        if (tokenAddress) fetchFloorPrice();
        return () => {
            mounted = false;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tokenAddress]);

    const punkTokens = getTokens
        ? getTokens.map((token) => ({
                src: token,
                data: { punk: true, punkIndex: token },
                address: tokenAddress,
            }))
        : [];

    const tokens = tokenIsPunk
        ? punkTokens
        : tokensWithData;

    const collectionNames = config.getCollectionNameByAddress[tokenAddress]
        || DEFAULT_COLLECTION_NAME;

    const collectionName = collectionNames && collectionNames?.name;

    const paymentTokenName = paymentToken
        ? config.tokenAddressToNameMapping[paymentToken]
        : "";

    const isBundle = getTokens?.length > 1;
    const length = getTokens?.length
        ? getTokens?.length
        : 0;

    const getTitle = (names, tokens) => {
        if (!names) return "";
        if (!tokens) return "";

        return `${names.singleName} #${tokens[0]}`
    }

    const title = isBundle
        ? `${length} ${collectionName?.name.replace("The ", "")}`
        : getTitle(
            collectionNames,
            getTokens
        );

    const percentFloorAskedCollected = (
        totalCollected && totalCollected.gt(0)
            ? totalCollected.mul(100).div(floorAsked)
            : BigNumber.from(0)
        ).toNumber();

    const loanStatus = determineStatus({
        contributionPhase,
        floorNotReached,
        creationTime,
        nextDueTime,
        remainingBalance,
        lockerOwner,
        belowFloorAsked,
    });

    const ownersAddress = locker[0] && locker[0].ownersAddress;
    // console.log(loanStatus, 'loanStatus');

    const listingData = {
        tokens,
        paymentRate,
        paymentTime,
        tokenAddress,
        lockerOwner,
        floorAsked,
        totalAsked,
        paymentToken,
        totalCollected,
        remainingBalance,
        floorNotReached,
        creationTime,
        contributionPhase,
        collectionName,
        claimableBalance,
        paymentTokenName,
        nextDueTime,
        title,
        isBundle,
        percentFloorAskedCollected,
        ownersAddress,
        loanStatus,
        singleProvider,
        belowFloorAsked,
        floorPrice
    };

    return listingData;
};

export default useListingDetails;
