import React, { useEffect } from "react";
import { useParams, useHistory } from "react-router";
import { useEthers } from "@usedapp/core";

import useGetPoolRates from "../../customHooks/useGetPoolRates";
import Hero from "./Hero";
import supportedPools from "../InstantPools/Pools/data";
import { INSTANT_POOLS_ROUTE } from "../../utils";

const PoolWrapper = () => {

    const history = useHistory();
    const { chainId } = useEthers();
    const { poolAddress } = useParams();

    useEffect(() => {

        const currentPool = supportedPools.find((pool) => (
            pool.address === poolAddress
        ));

        const isPoolOnNetwork = currentPool && currentPool.chainId === chainId;

        if (isPoolOnNetwork === false) {
            history.push(
                INSTANT_POOLS_ROUTE
            );
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [poolAddress, chainId]);

    const pool = useGetPoolRates(
        poolAddress
    );

    return (
        <div>
            <Hero pool={pool} poolAddress={poolAddress} />
        </div>
    );
};

export default PoolWrapper;
