import React from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import { formatUnits } from "ethers/lib/utils";
import { useToken, useEthers } from "@usedapp/core";
import PerfectScrollbar from 'react-perfect-scrollbar';

import styles from "./Refunds.module.sass";
import { dollarsFormat, getChainById } from "../../../utils";
import Icon from "../../../components/Icon";
import EnsName from "../../../components/EnsName";
import Avatar from "../../../components/Avatar";

const Refunds = ({
    className,
    refundEvents,
    paymentTokenName,
    paymentToken
}) => {

    const { chainId } = useEthers();

    const chain = getChainById(
        chainId
    );

    const paymentTokenInfo = useToken(
        paymentToken
    );

    const totalRefundLabel = (
        `${refundEvents.length} Refund${refundEvents.length === 1 ? "" : "s"} Total`
    );

    return (
        <div className={cn(styles.users, className)}>
            <div className={styles.details}>
                <div className={styles.line}>
                    <PerfectScrollbar>
                        <div className={styles.list}>
                            <div className={styles.sum}>
                                <div className={styles.icon}>
                                    <Icon name="report" size="20" />
                                    <span className={styles.count}>
                                        { totalRefundLabel }
                                    </span>
                                </div>
                            </div>
                            {refundEvents && refundEvents.map((x, index) => (
                                <div key={index}>
                                    <div className={styles.item}>
                                        <Link to={`/profile/${x.user}`}>
                                            <Avatar
                                                className={styles.avatar}
                                                address={x.user}
                                            />
                                        </Link>
                                        <div className={styles.details}>
                                            <Link to={`/profile/${x.user}`}>
                                                <div className={styles.name}>
                                                    <EnsName address={x.user} />
                                                </div>
                                            </Link>
                                            {x.amount && paymentToken && paymentTokenInfo && (
                                                <div className={styles.amount}>
                                                    {dollarsFormat(
                                                        // @TODO: grab decimals from config
                                                        formatUnits(x.amount, paymentTokenInfo?.decimals)
                                                    )}{" "}
                                                    {paymentTokenName}
                                                </div>
                                            )}
                                        </div>
                                        <div className={styles.tx}>
                                            <a
                                                className={styles.link}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                href={chain.getExplorerTransactionLink(x.transactionHash)}
                                            >
                                                View Transaction
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </PerfectScrollbar>
                </div>
            </div>
        </div>
    );
};

export default Refunds;
