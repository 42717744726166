import React from "react";
import Hero from "./Hero";
// import Teams from "./Teams";
import Description from "./Description";
// import HotLoan from "../../components/HotLoan";
// import Control from "../../components/Control";
// import styles from "../../screens/LoanType/LoanType.module.sass";

const Questions = () => {
    return (
        <>
            {/*<Control className={styles.control} item={breadcrumbs} />*/}
            <Hero />
            <Description />
            {/* <HotLoan classSection="section-pba" /> */}
            {/*<Teams />*/}
        </>
    );
};

export default Questions;
