import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/opacity.css";

const LazyImage = ({ token, size, style, useIntersection, ...otherprops }) => {
    return (
        <LazyLoadImage
            alt={token.alt}
            height={size}
            src={token.data.image}
            width={size}
            effect="opacity"
            delayTime="500"
            style={style}
            useIntersectionObserver={useIntersection}
            wrapperProps={{ style: { overflow: "hidden" } }}
            {...otherprops}
        />
    );
};

export default LazyImage;
