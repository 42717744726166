import { useEthers } from "@usedapp/core";
import { useState } from "react";
import { useEffect } from "react";
import { getTokenAddressFromName } from "../ethers/nftCollections";
import supportedPools from "../screens/InstantPools/Pools/data";
import { getRouterContract } from "../utils/query/interfaces";
import useConfig from "./useConfig";

export const useCollectionsWithIpfsHash = () => {
    const { chainId, library } = useEthers();
    const config = useConfig();
    const [supportedCollections, setSupportedCollections] = useState([]);

    const routerContract = getRouterContract(
        config.routerAddress,
        library
    );

    const pools = supportedPools.filter(
        (pool) => pool.chainId === chainId
    );

    useEffect(() => {
        const collections = [
            ...new Set(
                pools?.map(pool => pool.supportedCollections)?.flat()
            )
        ];

        const collectionDetails = collections?.map(
            async (collection) => {

                let ipfsHash;
                let merkleRoot;
                let pendingIpfsHash;
                let pendingMerkleRoot;

                const collectionAddress = getTokenAddressFromName(
                    chainId,
                    [collection]
                )?.[0];


                if (collectionAddress) {
                    ipfsHash = await routerContract.merkleIPFS(
                        collectionAddress
                    );

                    merkleRoot = await routerContract.merkleRoot(
                        collectionAddress
                    );

                    const pendingRoot = await routerContract.pendingRoots(
                        collectionAddress
                    );
                    pendingIpfsHash = pendingRoot?.ipfsAddress;
                    pendingMerkleRoot = pendingRoot?.merkleRoot;

                }
                return {
                    collection,
                    address: collectionAddress,
                    ipfsHash,
                    merkleRoot,
                    pendingIpfsHash,
                    pendingMerkleRoot
                };
            }
        );

        if (collectionDetails?.length) {

            return Promise.all(collectionDetails).then(result => {
                setSupportedCollections(result);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chainId]);

    return supportedCollections;

}