import React, { useState } from "react";
import Form from "../Form";
import styles from "./MailChimp.module.sass";
import MailchimpSubscribe from "react-mailchimp-subscribe";

/*
const id = findIt(
    param,
    page,
    nft_collection (coolcats)
);*/

// -- Possible to move to CONFIG --

const ID = '5cf9b29989';
const MAILCHIMP_API = 'https://liquidnfts.us5.list-manage.com/subscribe/';
const USER = '61e26c9aac8f35828dccb5970';

// -- Possible to move to CONFIG --

const url = `${MAILCHIMP_API}post?u=${USER}&amp;id=${ID}`;

// -- TO-DO: addToast service for toast messages

const MailChimp = ({ className }) => {
    const [email, setEmail] = useState("");

    return (
        <MailchimpSubscribe
            url={url}
            render={({ subscribe, status, message }) => (
                <div>
                    <Form
                        className={className}
                        value={email}
                        setValue={setEmail}
                        onSubmit={(e) => {
                            e.preventDefault();
                            subscribe({ EMAIL: email });
                        }}
                        placeholder="Enter your email"
                        type="email"
                        name="email"
                        icon="arrow-next"
                    />
                    {status === "sending" && <p className={styles.processing}>sending...</p>}
                    {console.log("message: ", message)}
                    {status === "error" && (
                        <div
                            className={styles.error}
                            dangerouslySetInnerHTML={{ __html: message }}
                        />
                    )}
                    {status === "success" && <p className={styles.success}>Subscribed!</p>}
                </div>
            )}
        />
    );
};

export default MailChimp;
