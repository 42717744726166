import React from "react";
import cn from "classnames";
import { useEthers } from "@usedapp/core";
import { getChainById } from "../../../../utils";

import styles from "./SuccessfullyFunded.module.sass";
// import Icon from "../../../../components/Icon";

/*
const socials = [
    {
        title: "facebook",
        url: "https://www.facebook.com/wise.net/",
    },
    {
        title: "twitter",
        url: "https://twitter.com/wise",
    },
    {
        title: "share",
        url: "https://www.instagram.com/wisenet/",
    },
    {
        title: "pinterest",
        url: "https://www.pinterest.com/wise/",
    },
];
*/

const SuccessfullyFunded = ({ 
    className, 
    txHash, 
    title,
    buttonCallback
}) => {
    const { chainId } = useEthers();
    const chain = getChainById(chainId);

    return (
        <div className={cn(className, styles.wrap)}>
            <div className={cn("h2", styles.title)}>
                Yay!{" "}
                <span role="img" aria-label="firework">
                    🎉
                </span>
            </div>
            <div className={styles.info}>
                You successfully contributed to <span>{title}</span> loan
            </div>
            <div className={styles.table}>
                <div className={styles.row}>
                    <div className={styles.col}>Status</div>
                    <div className={styles.col}>Transaction ID</div>
                </div>
                <div className={styles.row}>
                    <div className={styles.col}>Processing</div>
                    <div className={styles.col}>
                        <a
                            className={styles.link}
                            target="_blank"
                            rel="noopener noreferrer"
                            href={chain.getExplorerTransactionLink(txHash)}
                        >
                            { txHash }
                        </a>
                    </div>
                </div>
            </div>
            {/*<div className={styles.stage}>Time to show-off</div>
            <div className={styles.socials}>
                {socials.map((x, index) => (
                    <a
                        className={styles.social}
                        href={x.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        key={index}
                    >
                        <Icon name={x.title} size="24" />
                    </a>
                ))}
            </div>*/}
            <button 
                style={{width: "100%"}} 
                className={cn("button", styles.button)}
                onClick={buttonCallback}
            >
                Done
            </button>
        </div>
    );
};

export default SuccessfullyFunded;
