import React, { useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useEthers, useToken, shortenIfTransactionHash } from "@usedapp/core";
import cn from 'classnames';
import { ethers } from 'ethers';
import { formatUnits, parseUnits } from 'ethers/lib/utils';
import toast, { Toaster } from "react-hot-toast";
import CurrencyInput from 'react-currency-input-field';

import { setUpdateLatestTx } from '../../redux/settingsSlice';
import Success from '../Transaction';
import styles from './Payback.module.sass';
import Icon from '../Icon';
import Loader from '../Loader';
import useConfig from '../../customHooks/useConfig';
import FACTORY_ABI from '../../ethers/abis/LiquidFactory.json';

import {
    PERCENT_OPTIONS,
    ONE_HUNDRED,
    getToastSettings,
    getChainById,
} from '../../utils';

import { useCustomAllowance } from '../../customHooks/useCustomAllowance';
import { useERC20Approval } from '../../customHooks/useERC20Approval';

const DEFAULT_TEXT = 'Proceed with Payback';
const CONFIRM_TEXT = 'Confirm in Metamask';
const DONE_TEXT = 'Done';
const UNLOCK_TEXT = 'Unlocking...';
const PAYING_TEXT = 'Paying Back...';

const Payback = ({
    className,
    paymentToken,
    title,
    totalCollected,
    remainingBalance,
    borrowingFee,
    closeModal
}) => {
    const config = useConfig();
    const { account, library, chainId } = useEthers();
    const chain = getChainById(chainId);
    const dispatch = useDispatch();

    const [amount, setAmount] = useState('');
    const [activeQuickSelect, setActiveQuickSelect] = useState();
    const [loading, setLoading] = useState(false);
    const [hasBegunSteps, setHasBegunSteps] = useState(false);
    const [transactionHash, setTransactionHash] = useState("");
    const [successfulApproval, setSuccessfulApproval] = useState(false);
    const [buttonText, setButtonText] = useState(DEFAULT_TEXT);
    const [invalidAmount, setInvalidAmount] = useState(false);
    const [paybackTx, setPaybackTx] = useState();
    const [errorMessage, setErrorMessage] = useState('');

    const signer = library.getSigner(account);
    const { lockerAddress } = useParams();

    const paymentTokenName = paymentToken && config.tokenAddressToNameMapping[paymentToken];

    const paymentTokenInfo = useToken(
        config.paymentTokens[paymentTokenName]
    );

    const balanceRemaining = formatUnits(
        remainingBalance,
        paymentTokenInfo?.decimals
    );

    const allowance = useCustomAllowance(
        paymentToken,
        account,
        config.liquidNFTContract
    );

    const loanInfo = useMemo(() => {

        /*const parsedTotal = parseFloat(
            formatUnits(
                totalCollected,
                paymentTokenInfo?.decimals
            )
        );*/

        // const fee = parsedTotal * (parseFloat(borrowingFee) / NUM_ONE_HUNDRED);
        // const totalAmount = parsedTotal + fee;
        // const amountPaid = totalAmount - balanceRemaining;
        return [
            /*{
                title: 'Borrowed Amount',
                value: `${totalAmount} ${paymentTokenName}`,
            },
            {
                title: 'Returned Amount',
                value: `${amountPaid} ${paymentTokenName}`,
            },*/
            {
                title: 'Remaining Payback',
                value: `${balanceRemaining} ${paymentTokenName}`,
            },
            /*{
                title: 'Next Payment Due',
                value: `February 22, 2022`,
            },*/
        ];
    }, [
        // totalCollected,
        // borrowingFee,
        paymentTokenName,
        // paymentTokenInfo,
        balanceRemaining
    ]);

    const amountToBigNum = parseUnits(amount.toString() || '0', paymentTokenInfo?.decimals || 18);

    const handleError = (err) => {
        // @TODO use toast
        if (err.message.includes("intrinsic gas too low")) {
            setErrorMessage("Gas too low");
        } else if (err.message.includes("transaction failed")) {
            setErrorMessage("Transaction failed, try again with higher gas");
            // const txHash = err.message.split('"hash":"')[1].split('"')[0];
        } else {
            setErrorMessage(err.message);
        }
    };

    const approval = useERC20Approval({
        onConfirmation: () => setButtonText(CONFIRM_TEXT),
        onApproving: (transactionHash) => {
            setLoading(true);
            setHasBegunSteps(true);
            setButtonText(UNLOCK_TEXT);
            setTransactionHash(transactionHash);
        },
        onApprovalComplete: approval => {
            setLoading(false);
            if (approval) {
                setSuccessfulApproval(true);
                handlePaybackLoan();
            }
        },
        onError: error => {
            setLoading(false);
            handleError(error);
            setButtonText(DEFAULT_TEXT);
            setHasBegunSteps(false);
            setTransactionHash("");
            console.log(error, 'err');
            toast.error(
                error.message,
                getToastSettings("🚫")
            );
        }
    })

    const handleApprove = async () => {

        if (buttonText === DONE_TEXT) {
            closeModal();
        }

        if (!amount) {
            setInvalidAmount(true);
            return;
        }

        if (errorMessage) setErrorMessage('');
        if (buttonText !== DEFAULT_TEXT) return;

        if (preApproved) {
            setSuccessfulApproval(true);
            handlePaybackLoan();
            return;
        }

        await approval.call(
            config.liquidNFTContract,
            paymentToken
        );
    };

    const handlePaybackLoan = async () => {

        if (!amount) {
            setInvalidAmount(true);
            return;
        }

        if (errorMessage) setErrorMessage('');
        setButtonText(CONFIRM_TEXT);

        try {

            const factoryContract = new ethers.Contract(
                config.liquidNFTContract,
                FACTORY_ABI,
                signer
            );

            const paybackToLocker = await factoryContract.paybackToLocker(
                lockerAddress,
                amountToBigNum,
            );

            setLoading(true);
            setHasBegunSteps(true);
            setButtonText(PAYING_TEXT);
            setTransactionHash(
                paybackToLocker.hash
            );

            const paybackToLockerTx = await paybackToLocker.wait();
            setLoading(false);
            setButtonText(DONE_TEXT);

            if (paybackToLockerTx) {
                dispatch(
                    setUpdateLatestTx(
                        paybackToLockerTx.transactionHash
                    )
                );

                setPaybackTx(
                    paybackToLockerTx
                );

                setAmount('');
            }

        } catch (err) {
            setHasBegunSteps(false);
            setLoading(false);
            setButtonText(DEFAULT_TEXT);
            handleError(err);
            setTransactionHash("");

            // @TODO: create a method that would map messages
            const errMessage = err.message.includes('Payoff')
                ? 'Low Amount - Increase Payback Amount!'
                : err.message;

            toast.error(
                errMessage,
                getToastSettings("🚫")
            );
        }
    };

    const handleAmount = (val) => {
        if (invalidAmount) setInvalidAmount(false);

        setActiveQuickSelect(null);

        if (!val) {
            setAmount("");
            return;
        }
        setAmount(val);

        let percent = val / balanceRemaining * ONE_HUNDRED;
        if (percent === ONE_HUNDRED) {
            percent = 'max';
        }

        const quickValue = PERCENT_OPTIONS[percent]
            ? percent.toString()
            : null;

        setActiveQuickSelect(
            quickValue
        );
    };

    const handleQuickSelect = (option) => {

        if (!balanceRemaining) return;

        setActiveQuickSelect(
            option
        );

        let formattedNum = balanceRemaining;

        if (option !== 'max') {
            const percent = option / ONE_HUNDRED;
            formattedNum = balanceRemaining * percent;
        }

        setAmount(
            formattedNum.toString()
        );
    };

    // dynamic styling constants
    // probably good to place in a config / class
    // duplicate found in CollaterizeSteps
    const preApproved = allowance && allowance.gte(
        amountToBigNum
    );
    // const preApproved = false;
    const approvalDone = preApproved || successfulApproval;

    const iconColor = approvalDone
        ? "#45B26B"
        : "#9757D7";

    const iconColorB = approvalDone && (buttonText.includes("...")|| buttonText === DONE_TEXT)
        ? "#9757D7"
        : "";

    const iconName = approvalDone
        ? 'check'
        : 'lock'

    const iconSize = approvalDone
        ? '20'
        : '24'

    const errorStyle = invalidAmount
        ? {borderBottom: "solid 1px #c73e77"}
        : {}


    return (
        <div>
            <div className={cn("h4", styles.title)}>
                Make a Payback
            </div>
            <div className={styles.body}>
                <span>
                    Payment amount determines next payment date based on the remaining payback balance
                </span>
            </div>
            { hasBegunSteps === true && (
                <div style={{marginBottom: "30px"}} className={styles.row}>
                    <div>View Transaction</div>
                    <div>
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={chain.getExplorerTransactionLink(transactionHash)}
                            className={styles.link}
                        >
                            {shortenIfTransactionHash(transactionHash)}
                        </a>
                    </div>
                </div>
            )}
            <div className={cn(className, styles.steps)}>
                <div className={styles.list}>
                    { paybackTx
                        ? (
                        <Success
                            txHash={transactionHash}
                            title={"You successfully returned funds for the LiquidNFTs loan"}
                            showButton={false}
                            celebrate={true}
                        /> )
                        : (
                        <div>
                            <div
                                style={{ display: hasBegunSteps ? 'none' : 'block' }}
                                className={styles.table}
                            >
                                { hasBegunSteps && (
                                    <div className={styles.body} >
                                        { buttonText === DONE_TEXT ? (
                                            <span>
                                                Congratulations! Transaction is now completed, you have successfully contributed to this loan
                                            </span>
                                        ) : (
                                            <span>
                                                Your transaction is now in progress, follow the steps below to finalize your loan contribution
                                            </span>
                                        )}
                                    </div>
                                )}
                                <div className={styles.row} style={errorStyle}>
                                    <div className={styles.col}>
                                        Amount
                                    </div>
                                    <div className={styles.col}>
                                        <CurrencyInput
                                            id="input-payback"
                                            name="payback"
                                            autoComplete="off"
                                            allowNegativeValue={false}
                                            className={styles.input}
                                            placeholder="Enter Payment"
                                            decimalsLimit={18}
                                            value={amount}
                                            decimalSeparator="."
                                            groupSeparator=","
                                            onValueChange={(value) => handleAmount(value)}
                                        />
                                    </div>
                                    { amount && (
                                        <div className={styles.col}>
                                            { paymentTokenName || 'ETH' }
                                        </div>
                                    )}
                                </div>
                                <div className={styles.row} style={{marginTop: "8px"}}>
                                    { Object.keys(PERCENT_OPTIONS).map((key) => (
                                            <button
                                                key={key}
                                                className={
                                                    cn('button-stroke',
                                                        styles.option,
                                                        {
                                                            'button-active': activeQuickSelect === key
                                                        }
                                                    )
                                                }
                                                onClick={() => handleQuickSelect(key)}
                                            >
                                                { PERCENT_OPTIONS[key] }
                                            </button>
                                    ))}
                                </div>
                                {loanInfo.map((x, index) => (
                                    <div className={styles.row} key={index}>
                                        <div className={styles.col}>{x.title}</div>
                                        <div className={styles.col}>{x.value}</div>
                                    </div>
                                ))}
                            </div>
                            <div
                                className={styles.item}
                                style={{display: hasBegunSteps ? 'block' : 'none' }}
                            >
                                <div className={styles.head}>
                                    <div
                                        className={styles.icon}
                                        style={{ background: iconColor, borderColor: iconColor }}
                                    >
                                        <Icon name={iconName} size={iconSize} />
                                    </div>
                                    <div className={styles.details}>
                                        <div className={styles.info}>
                                            Step 1 - Unlock Tokens
                                        </div>
                                        <div className={styles.text}>
                                            Send transaction giving permission to LiquidNFTs contract transferring {paymentTokenName} tokens on your behalf
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.stepLine} />
                                <div className={styles.head}>
                                    <div
                                        style={{ background: iconColorB, borderColor: iconColorB }}
                                        className={styles.icon}>
                                        <Icon
                                            name="wallet"
                                            size="24"
                                        />
                                    </div>
                                    <div className={styles.details}>
                                        <div className={styles.info}>
                                            Step 2 - Confirm Payback
                                        </div>
                                        <div className={styles.text}>
                                            Send transaction to confirm loan payback with exclusive {paymentTokenName} token as
                                            a payment method asset
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {hasBegunSteps === false && (
                                <div className={styles.body} style={{marginTop: "20px", marginBottom: "0px"}}>
                                    <span>
                                        Once the remaining payback is completely payed back the NFT is returned to the owner
                                        {/*Missing scheduled payment date results in extra penalties added to the payback amount*/}
                                    </span>
                                </div>
                            )}
                        </div>
                    )}
                    <div className={styles.item}>
                        <button
                            style={{marginTop: "30px"}}
                            onClick={handleApprove}
                            className={cn(
                                "button",
                                styles.button,
                                (buttonText === DEFAULT_TEXT || buttonText === DONE_TEXT) ? "" : "loading",
                            )}
                        >
                            { loading && <Loader className={styles.loaderSmall} color="white" /> }
                            { buttonText }
                        </button>
                    </div>
                </div>
                <Toaster />
            </div>
        </div>
    );
};

export default Payback;
