import { Interface } from "ethers/lib/utils";
import { Contract } from "ethers";
import ERC20 from '../ethers/abis/ERC20.json';
import useCallGracefully from './useCallGracefully';

const ERC20Interface = new Interface(ERC20);

export const useCustomAllowance = (
    tokenAddress,
    holderAddress,
    spenderAddress
) => {
    const haveParams = holderAddress && tokenAddress && spenderAddress;
    const allowance = useCallGracefully(haveParams &&
        {
            contract: new Contract(tokenAddress, ERC20Interface),
            method: "allowance",
            args: [holderAddress, spenderAddress],
        }
    ) ?? null;
    return allowance;
};
