import { useState, useEffect } from "react";
import { Interface } from "ethers/lib/utils";
import { useEthers } from "@usedapp/core";
import useConfig from "./useConfig";
import LIQUID_ROUTER_ABI from "../ethers/abis/LiquidRouter.json";
import { getRouterContract } from "../utils/query/interfaces";

const parseEtherJsLog = (parsed) => {

    let parsedEvent = {details: {}}
    for (let i = 0; i < parsed.args.length; i++) {

        const input = parsed.eventFragment.inputs[i];
        const ref = input["name"];
        const arg = parsed.args[i];

        parsedEvent[ref] = arg;
        parsedEvent.details[ref] = {
            ...input,
            ...{"value": arg}
        };
    }
    return parsedEvent;
}

const routerInterface = new Interface(
    LIQUID_ROUTER_ABI
);

const useIpfsHashEvents = () => {

    const config = useConfig();
    const { chainId, library } = useEthers();
    const [nftUpdateRootLogs, setNftUpdateRootLogs] = useState();

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchLogs = async () => {

            if (!chainId) return;
            if (!library) return;
            if (config.chainId !== chainId) return;

            setLoading(true);

            const routerContract = getRouterContract(
                config.routerAddress
            );

            const filter = routerContract.filters["RootUpdated"](
                // ...args // for filtering by indexed params
            );

            filter.fromBlock = config.inceptionBlockPools;
            filter.toBlock = "latest";

            const logs = await library.getLogs(
                filter
            );

            const events = [];
            for (let i in logs) {

                const etherParsed = routerInterface.parseLog(
                    logs[i]
                );
                const customParsed = parseEtherJsLog(
                    etherParsed,
                );

                events.push(
                    {
                        ...customParsed,
                        blockNumber: logs[i].blockNumber,
                        transactionHash: logs[i].transactionHash
                    }
                );
            }

            const rootUpdatesLogsByNftAddress = events.reduce((
                collectionLogs, log
            ) => {
                const logs = collectionLogs?.[log.nftAddress];
                collectionLogs[log.nftAddress] = [...(logs || []), log];
                return collectionLogs;
            }, {});

            setNftUpdateRootLogs(
                rootUpdatesLogsByNftAddress
            )

            setLoading(false);
        };

        if (library) fetchLogs();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [library]);

    return {
        nftUpdateRootLogs,
        loading
    };
};

export default useIpfsHashEvents;
