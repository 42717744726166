import { useState, useEffect } from "react";
import { useEthers } from "@usedapp/core";
import { Interface } from "ethers/lib/utils";
import { getTokenCollections } from "../ethers/nftCollections";
import { Contract } from "ethers";
import { parseLog } from "./useTokenFetch";

const punkInterface = new Interface([
    "event Assign(address indexed to, uint256 punkIndex)",
    "event PunkBought(uint indexed punkIndex, uint value, address indexed fromAddress, address indexed toAddress)",
    "event PunkTransfer(address indexed from, address indexed to, uint256 punkIndex)",
]);

const usePunkedAddressTokens = (userAccount, chainId) => {
    const { library } = useEthers();
    const [loading, setIsLoading] = useState(false);
    const [punkedAddressTokens, setPunkedAddressTokens] = useState([]);

    const {
        peculiar
    } = getTokenCollections(chainId);

    const parsePunkLog = (log) => parseLog(
        log,
        punkInterface
    );

    const getMostRecentEvents = (events, field) => {
        return events.reduce((prev, curr) => {
            const index = curr.args[field];
            const entryAtIndex = prev[index];
            if (!entryAtIndex || curr.blockNumber > entryAtIndex.blockNumber) {
                return { ...prev, [index]: curr };
            }
            return prev;
        }, {});
    };

    const getMostRecentPunkEvents = (events) => getMostRecentEvents(
        events,
        "punkIndex"
    );

    const getFilteredLogs = (filter) => library.getLogs({
        ...filter,
        fromBlock: 0 // @TODO: put inception block
    });

    const punkContractAddress = peculiar[0].address;
    const punkContract = new Contract(punkContractAddress, punkInterface);
    const punkAssignEventFilter = punkContract.filters.Assign(userAccount);
    const punkTransferToEventFilter = punkContract.filters.PunkTransfer(null, userAccount);
    const punkTransferFromEventFilter = punkContract.filters.PunkTransfer(userAccount);
    const punkBoughtFromEventFilter = punkContract.filters.PunkBought(null, null, userAccount);
    const punkBoughtToEventFilter = punkContract.filters.PunkBought(null, null, null, userAccount);

    const getPunkEvents = async () => {
        try {
            if (peculiar?.length) {
                setIsLoading(true);
                setPunkedAddressTokens([]);
            }

            const punkAssignLogs = await getFilteredLogs(punkAssignEventFilter);
            const punkTransferToLogs = await getFilteredLogs(punkTransferToEventFilter);
            const punkTransferFromLogs = await getFilteredLogs(punkTransferFromEventFilter);
            const punkBoughtFromLogs = await getFilteredLogs(punkBoughtFromEventFilter);
            const punkBoughtToLogs = await getFilteredLogs(punkBoughtToEventFilter);

            const punkAssignEvents = punkAssignLogs.map(parsePunkLog);
            const punkTransferToEvents = punkTransferToLogs.map(parsePunkLog);
            const punkTransferFromEvents = punkTransferFromLogs.map(parsePunkLog);
            const punkBoughtFromEvents = punkBoughtFromLogs.map(parsePunkLog);
            const punkBoughtToEvents = punkBoughtToLogs.map(parsePunkLog);

            const mostRecentPunkTransferTo = getMostRecentPunkEvents(punkTransferToEvents);
            const mostRecentPunkTransferFrom = getMostRecentPunkEvents(punkTransferFromEvents);
            const mostRecentPunkBoughtFrom = getMostRecentPunkEvents(punkBoughtFromEvents);
            const mostRecentPunkBoughtTo = getMostRecentPunkEvents(punkBoughtToEvents);

            const currentlyOwnedPunkEvents = punkAssignEvents.reduce((prev, curr) => {
                const { punkIndex } = curr.args;
                const correspondingPunkBoughtFrom = mostRecentPunkBoughtFrom[punkIndex];
                const correspondingPunkTransferFrom = mostRecentPunkTransferFrom[punkIndex];
                if (!correspondingPunkBoughtFrom && !correspondingPunkTransferFrom) {
                    return [...prev, curr];
                }
                return prev;
            }, []);

            const addToOwnedListIfNotTransferredOut = (events) => {
                Object.keys(events).forEach((punkIndex) => {
                    const evt = events[punkIndex];
                    const correspondingPunkBoughtFrom = mostRecentPunkBoughtFrom[punkIndex];
                    const correspondingPunkTransferFrom = mostRecentPunkTransferFrom[punkIndex];
                    if (
                        (!correspondingPunkBoughtFrom ||
                            evt.blockNumber > correspondingPunkBoughtFrom?.blockNumber) &&
                        (!correspondingPunkTransferFrom ||
                            evt.blockNumber > correspondingPunkTransferFrom?.blockNumber)
                    ) {
                        currentlyOwnedPunkEvents.push(evt);
                    }
                });
            };

            addToOwnedListIfNotTransferredOut(
                mostRecentPunkTransferTo
            );

            addToOwnedListIfNotTransferredOut(
                mostRecentPunkBoughtTo
            );

            const reducedPunkEvents = currentlyOwnedPunkEvents.reduce(
                (prev, e) => ({
                    ...prev,
                    [e.args.punkIndex]: {
                        uri: null, // why?
                        owner: userAccount,
                        data: {
                            image: `https://www.larvalabs.com/public/images/cryptopunks/punk${e.args.punkIndex
                                .toString()
                                .padStart(4, "0")}.png`,
                            punk: true,
                            punkIndex: e.args.punkIndex,
                        },
                        address: punkContractAddress,
                    },
                }),
                {}
            );
            const ownedPunks = Object.values(
                reducedPunkEvents
            );
            setPunkedAddressTokens(
                ownedPunks?.length
                    ? ownedPunks
                    : []
            );
        } catch (ex) {
            console.log(ex);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        let mounted = true;

        if (library && userAccount && chainId && mounted) {
            getPunkEvents();
        }

        return () => (mounted = false);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chainId, userAccount, library]);

    return {
        punkedAddressTokens,
        loading,
        refetch: getPunkEvents
    }
}

export default usePunkedAddressTokens;