import React, { useState, useMemo } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { useSelector } from "react-redux";
import cn from "classnames";
import { useEthers } from '@usedapp/core';
import CurrencyInput from 'react-currency-input-field';
import { formatUnits } from "ethers/lib/utils";
import { ethers } from "ethers";
import toast, { Toaster } from "react-hot-toast";
import Loader from "../Loader";
import { usePoolUserInfo } from "../../customHooks/usePoolUserInfo";
import useConfig from "../../customHooks/useConfig";
import styles from "../Invest/Invest.module.sass";
import FormattedAmount from "../FormattedAmount";
// import { setUpdateLatestTx, setClosedFundedPools } from "../../redux/settingsSlice";
import Success from "../Transaction";
import { usePoolGeneralInfo } from "../../customHooks/usePoolGeneralInfo";

import {
    PERCENT_OPTIONS,
    getToastSettings,
    EMPTY_ADDRESS,
    ONE_HUNDRED,
    // toFixedNoRound,
    // toPrecision,
    getPreciseValue,
    produceAccurateValue,
    getPrettyValue,
    DEFAULT_FIXED_DECIMALS
} from "../../utils";

import {
    getPoolContract,
    getRouterContract,
} from "../../utils/query/interfaces";

const CONFIRM_TEXT = "Confirm in Metamask";
const DEFAULT_TEXT = "Withdraw Funds";
const WITHDRAWING_TEXT = "Withdrawing Funds...";
const DONE_TEXT = "Done";
const NO_FUNDS = "0.00";

const PoolWithdraw = ({
    className,
    poolAddress,
    paymentToken,
    closeModal,
    // withdrawDeduction,
    title
}) => {

    // const dispatch = useDispatch();
    const { account, library } = useEthers();
    const [amount, setAmount] = useState("");
    const [activeQuickSelect, setActiveQuickSelect] = useState();
    const [buttonText, setButtonText] = useState(DEFAULT_TEXT);
    const [invalidAmount, setInvalidAmount] = useState(false);
    const [transactionHash, setTransactionHash] = useState("");
    // const closedFundedPools = useSelector((state) => state.settings.closedFundedPools);
    const config = useConfig();

    const paymentTokenAddress = config.paymentTokens[paymentToken];
    const decimals = config.decimals[paymentToken];

    const { userTokensDeposited } = usePoolUserInfo(
        poolAddress,
        account
    );

    const userTokensMax = userTokensDeposited && Number(
        formatUnits(
            userTokensDeposited,
            decimals
        )
    );

    const { tokensHeld, tokenDebt } = usePoolGeneralInfo(
        poolAddress
    );

    const balance = produceAccurateValue(
        tokensHeld,
        decimals
    );

    const loanInfo = useMemo(() => {

        const debt = produceAccurateValue(
            tokenDebt,
            decimals
        );

        // check if amount is greater than pool balance
        const amountInvalid = Number(amount) > Number(balance);

        return [
            {
                title: "Current Pool Debt",
                value: `${debt} ${paymentToken}`,
                error: false
            },
            {
                title: "Current Pool Balance",
                value: `${balance} ${paymentToken}`,
                error: balance ? amountInvalid : false
            },
        ]
    }, [tokenDebt, decimals, balance, amount, paymentToken]);

    const handleAmountChange = (val) => {

        if (invalidAmount) setInvalidAmount(false);

        // clear quickSelect
        setActiveQuickSelect(null);

        // early return for deleted value
        if (!val) {
            setAmount("");
            return;
        }

        setAmount(val);

        // WHAT IS THIS?
        const amountInvalid = (
            userTokensDeposited && Number(val) > userTokensMax) || (Number(val) > Number(balance)
        );

        if (amountInvalid) {
            setInvalidAmount(true);
        }

        if (userTokensDeposited) {

            const total = formatUnits(
                userTokensDeposited,
                decimals
            );

            let percent = val / total * ONE_HUNDRED;
            if (percent === ONE_HUNDRED) {
                percent = 'max';
            }

            const quickValue = PERCENT_OPTIONS[percent]
                ? percent.toString()
                : null;

            setActiveQuickSelect(
                quickValue
            );
        }
    };

    const noWrap = {
        whiteSpace: "nowrap"
    }

    const handleQuickSelect = (option) => {
        if (!userTokensDeposited) return;

        const total = formatUnits(
            userTokensDeposited,
            decimals
        );

        const currentPoolBalance = tokensHeld && formatUnits(
            tokensHeld,
            decimals
        );

        const maxWithdrawAmount = Number(total) > currentPoolBalance
            ? currentPoolBalance
            : total;

        let formattedNum = maxWithdrawAmount;
        if (option !== 'max') {
            const percent = option / 100;
            formattedNum = maxWithdrawAmount * percent;
        }

        if (Number(formattedNum) > currentPoolBalance) formattedNum = currentPoolBalance;

        formattedNum = getPreciseValue(
            formattedNum
        );

        setAmount(formattedNum);
        setActiveQuickSelect(option)
    };

    const handleWithdraw = async () => {

        if (buttonText === DONE_TEXT) {
            closeModal();
        }

        if (!amount || invalidAmount) {
            setInvalidAmount(true);
            if (amount > balance) {
                toast.error(
                    `Withdraw Amount Exceeds Current Pool Balance`,
                    getToastSettings("🚫")
                );
            }
            return;
        }

        try {

            if (buttonText !== DEFAULT_TEXT) return;

            const signer = library && library.getSigner(
                account
            );

            const routerContract = getRouterContract(
                config.routerAddress,
                signer
            );

            const poolContract = getPoolContract(
                poolAddress,
                signer
            );

            const currentPoolShare = await poolContract.getCurrentPoolShares();
            const pseudoTotalTokens = await poolContract.pseudoTotalTokensHeld();

            setButtonText(
                CONFIRM_TEXT
            );

            const amountWithdraw = ethers.utils.parseUnits(
                amount,
                decimals
            );

            const withdrawShares = amountWithdraw
                .mul(currentPoolShare)
                .div(pseudoTotalTokens);

            const configuration = process.env.NODE_ENV === "development"
                ? { gasLimit: "220000" }
                : { gasLimit: "220000" };

            const withdrawTransaction = await routerContract.withdrawFunds(
                withdrawShares,
                poolAddress,
                configuration
            );

            setButtonText(
                WITHDRAWING_TEXT
            );

            const withdrawTX = await withdrawTransaction.wait();
            setButtonText(DONE_TEXT);

            if (withdrawTX) {
                setTransactionHash(
                    withdrawTX.transactionHash
                );
            }

        } catch (err) {
            toast.error(
                err.code,
                getToastSettings("🚫")
            );

            setButtonText(
                DEFAULT_TEXT
            );
        }
    }

    const isSubmitButtonDisabled = buttonText !== DONE_TEXT && (
        (!invalidAmount && amount?.length && !Number(amount))
        || !userTokensDeposited
        || !userTokensDeposited._isBigNumber
        || produceAccurateValue(userTokensDeposited, decimals) === NO_FUNDS
    );

    return (
        <div className={cn(className, styles.sale)}>
            <div style={{ display: transactionHash ? "none" : "block" }}>
                <div className={styles.main}>
                    Based on your shares, you can withdraw up to
                    {" "}
                    {paymentTokenAddress && (
                        <span className={styles.pink}>
                            <FormattedAmount
                                // noTruncate={true}
                                optionalPrecision={DEFAULT_FIXED_DECIMALS}
                                amount={userTokensDeposited}
                                token={paymentTokenAddress}
                                label={true}
                            />
                        </span>
                    )} capped by current pool balance
                </div>
                <div className={styles.table} style={{ marginTop: "24px" }}>
                    <div
                        className={cn(styles.row,
                            {
                                [styles.borderError]: invalidAmount
                            })}
                    >
                        <div className={styles.col} style={noWrap}>
                            Withdraw Amount
                        </div>
                        <div className={styles.col}>
                            <CurrencyInput
                                autoComplete="off"
                                id="input-contribution"
                                name="contribution"
                                allowNegativeValue={false}
                                className={styles.input}
                                placeholder="Enter Value"
                                decimalsLimit={2}
                                value={amount}
                                decimalSeparator="."
                                groupSeparator=","
                                onValueChange={(value) => handleAmountChange(value)}
                            />
                        </div>
                        { amount && (
                            <div
                            className={styles.col}>
                                { paymentToken || "ETH" }
                            </div>
                        )}
                    </div>
                    <div className={styles.row} style={{ marginTop: "8px" }}>
                        { Object.keys(PERCENT_OPTIONS).map((key) => (
                            <button
                                key={key}
                                className={cn('button-stroke', styles.option, {
                                    'button-active': activeQuickSelect === key
                                })}
                                onClick={() => handleQuickSelect(key)}
                            >
                                { PERCENT_OPTIONS[key] }
                            </button>
                        ))}
                    </div>
                    <div style={{ marginBottom: "16px" }}>
                        {loanInfo.map((x, index) => (
                            <div
                                className={styles.row}
                                key={index}
                                style={{ borderColor: x.error ? "#c73e77" : "#23262F" }}
                            >
                                <div className={styles.col}>{x.title}</div>
                                <div className={styles.col}>{x.value}</div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className={styles.main} style={{marginBottom: "0px"}}>
                    The total available amount you can withdraw depends on the current pool debt and balance
                </div>
            </div>
            {transactionHash && (
                <div>
                    <Success
                        txHash={transactionHash}
                        title={`You successfully removed ${getPrettyValue(amount)} ${paymentToken} from the LiquidNFTs pool`}
                        showButton={false}
                        celebrate={false}
                        lockerAddress={EMPTY_ADDRESS}
                        displaySocials={false}
                        tweet={""}
                        isWithdraw={true}
                    />
                </div>
            )}
            <div className={styles.item}>
                <button
                    style={{ marginTop: "24px" }}
                    onClick={handleWithdraw}
                    disabled={buttonText === WITHDRAWING_TEXT || isSubmitButtonDisabled}
                    className={cn(
                        "button",
                        styles.button,
                        (buttonText === DEFAULT_TEXT || buttonText === DONE_TEXT) ? "" : "loading",
                        {
                            "disabled": isSubmitButtonDisabled
                        }
                    )}
                >
                    {buttonText === WITHDRAWING_TEXT
                        && <Loader className={styles.loaderSmall} color="white" />}
                    {buttonText}
                </button>
            </div>
            <Toaster />
        </div>
    );
};

export default PoolWithdraw;
