import React, { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useEthers, shortenIfTransactionHash } from "@usedapp/core";
import cn from "classnames";
// import { useHistory, useLocation } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { setUpdateLatestTx, setClosedFundedPools } from "../../redux/settingsSlice";
import { useCustomAllowance } from "../../customHooks/useCustomAllowance";
import { useERC20Approval } from '../../customHooks/useERC20Approval';
import styles from "./Invest.module.sass";
import Icon from "../Icon";
import Loader from "../Loader";
import useConfig from "../../customHooks/useConfig";
import Success from "../Transaction";
import { parseUnits } from "ethers/lib/utils";

import {
    getRouterContract,
} from "../../utils/query/interfaces";

import {
    getToastSettings,
    getToastErrorMessage,
    getChainById,
    // produceAccurateValue,
    getPrettyValue,
    MIN_CONFIRMATIONS,
    produceAccurateValue
} from "../../utils";

const CONFIRM_TEXT = "Confirm in Metamask";
const UNLOCK_TEXT = "Unlocking...";
const IN_PROGRESS_TEXT = "Lending...";
const DEFAULT_TEXT = "Proceed with Deposit";
const DONE_TEXT = 'Done';
const CONNECT_WALLET_TEXT = "Connect Wallet";
const NO_FUNDS = "0.00";

const InvestSteps = ({
    className,
    title,
    setInvalidAmount,
    amount,
    setAmount,
    balance,
    paymentToken,
    transactionHash,
    setTransactionHash,
    closeModal,
    tweetMessage,
    poolAddress
}) => {
    const config = useConfig();

    const {
        account,
        library,
        chainId,
        activateBrowserWallet
    } = useEthers();

    const chain = getChainById(
        chainId
    );

    // const history = useHistory();
    // const location = useLocation();

    const [loading, setLoading] = useState(false);
    const [successfulApproval, setSuccessfulApproval] = useState(false);
    const [buttonText, setButtonText] = useState(DEFAULT_TEXT);
    const [contributionTx, setContributionTx] = useState("");
    const closedFundedPools = useSelector((state) => state.settings.closedFundedPools);
    const signer = library.getSigner(account);
    const dispatch = useDispatch();

    const approval = useERC20Approval({
        onConfirmation: () => setButtonText(CONFIRM_TEXT),
        onApproving: (transactionHash) => {
            setLoading(true);
            setButtonText(UNLOCK_TEXT);
            setTransactionHash(transactionHash);
        },
        onApprovalComplete: approval => {
            setLoading(false);
            if (approval) {
                setSuccessfulApproval(true);
                handleMakeDeposit();
            }
        },
        onError: error => {
            setLoading(false);
            handleError(error);
            setTransactionHash("");
            setButtonText(DEFAULT_TEXT);
            toast.error(
                error.message,
                getToastSettings("🚫")
            );
        }
    })

    const allowance = useCustomAllowance(
        config.paymentTokens[paymentToken],
        account,
        config.routerAddress
    );

    const decimals = config.decimals[paymentToken];
    const contributionAmount = parseUnits(amount.toString() || "0", decimals || 18);

    const preApproved = allowance && allowance.gte(
        contributionAmount
    );

    const handleError = (err) => {
        // @TODO: just fire the toaster
    };

    const handleApprove = async () => {

        if (!account) {
            // history.push({
                // pathname: '/connect-wallet',
                // search: `?redirectTo=${location.pathname}`
            // });
            activateBrowserWallet();
            return;
        }

        if (buttonText === DONE_TEXT) {
            setAmount("");
            closeModal();
        }

        if (!amount) {
            setInvalidAmount(true);
            return;
        }

        if (buttonText !== DEFAULT_TEXT) return;


        if (preApproved) {
            setSuccessfulApproval(true);
            handleMakeDeposit();
            return;
        }

        approval.call(
            config.routerAddress,
            config.paymentTokens[paymentToken]
        );

    };

    const handleMakeDeposit = async () => {

        if (!amount) {
            setInvalidAmount(true);
            return;
        }

        if (buttonText !== DEFAULT_TEXT) return;

        setButtonText(CONFIRM_TEXT);

        try {

            const routerContract = getRouterContract(
                config.routerAddress,
                signer
            );

            const depositTransaction = await routerContract.depositFunds(
                contributionAmount,
                poolAddress
            );

            setTransactionHash(depositTransaction.hash);
            setLoading(true);
            setButtonText(IN_PROGRESS_TEXT);
            const depositedToPool = await depositTransaction.wait(
                MIN_CONFIRMATIONS
            );
            setLoading(false);
            setButtonText(DONE_TEXT);

            if (depositedToPool) {
                dispatch(setUpdateLatestTx(depositedToPool.transactionHash));
                setContributionTx(depositedToPool);
                if (closedFundedPools.length) {
                    const newFundedPools = closedFundedPools.filter((address) => (
                        address !== poolAddress
                    ));
                    dispatch(setClosedFundedPools(newFundedPools));
                }
            }

        } catch (err) {
            setLoading(false);
            setButtonText(DEFAULT_TEXT);
            handleError(err);
            setTransactionHash("");
            toast.error(
                getToastErrorMessage(err.code),
                getToastSettings("🚫")
            );
        }
    };

    // dynamic styling constants
    // probably good to place in a config / class
    // duplicate found in PaybackSteps

    const canActivate = true;
    const approvalDone = preApproved || successfulApproval;

    const iconColor = approvalDone
        ? "#45B26B"
        : "#9757D7";

    const iconColorB = approvalDone && (buttonText.includes("...")|| buttonText === DONE_TEXT)
        ? "#9757D7"
        : "";

    const iconName = approvalDone
        ? 'check'
        : 'lock'

    const iconSize = approvalDone
        ? '20'
        : '24'

    const isSubmitButtonDisabled = buttonText !== DONE_TEXT && (
        (amount?.length && !Number(amount))
        || !balance
        || !balance._isBigNumber
        || produceAccurateValue(balance, decimals) === NO_FUNDS
    );

    return (
        <div className={cn(className, styles.steps)}>
            <div className={styles.list}>
                {transactionHash && (
                    <div style={{marginBottom: "30px"}}>
                        <div className={styles.body} >
                            { buttonText === DONE_TEXT ? (
                                <span>
                                    Congratulations! Your lending transaction is confirmed and funds included into this pool
                                </span>
                            ) : (
                                <span>
                                    Your transaction is now in progress, follow the steps below to finalize your pool deposit
                                </span>
                            )}
                        </div>
                        <div className={styles.row}>
                            <div>View Transaction</div>
                            <div>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={chain.getExplorerTransactionLink(transactionHash)}
                                    className={styles.link}
                                >
                                    {shortenIfTransactionHash(transactionHash)}
                                </a>
                            </div>
                        </div>
                    </div>
                )}
                { contributionTx
                    ? (
                        <Success
                            txHash={transactionHash}
                            title={`You successfully lent ${getPrettyValue(amount)} ${paymentToken} to the LiquidNFTs ${title} ${paymentToken} pool`}
                            showButton={false}
                            celebrate={true}
                            lockerAddress={poolAddress}
                            displaySocials={true}
                            tweet={tweetMessage}
                            shareUrl={window.location.href}
                        /> )
                    : (
                        <div
                            className={styles.item}
                            style={{ display: transactionHash ? 'block' : 'none' }}
                        >
                            <div className={styles.head}>
                                <div
                                    className={styles.icon}
                                    style={{ background: iconColor, borderColor: iconColor }}
                                >
                                    <Icon name={iconName}  size={iconSize} />
                                </div>
                                <div className={styles.details}>
                                    <div className={styles.info}>
                                        Step 1 - Unlock Tokens
                                    </div>
                                    <div className={styles.text}>
                                        Sends a transaction allowing LiquidNFTs router contract to transfer {paymentToken} on your behalf
                                    </div>
                                </div>
                            </div>
                            <div className={styles.stepLine} />
                            <div className={styles.head}>
                                <div
                                    style={{ background: iconColorB, borderColor: iconColorB }}
                                    className={styles.icon}>
                                    <Icon
                                        name="wallet"
                                        size="24"
                                    />
                                </div>
                                <div className={styles.details}>
                                    <div className={styles.info}>
                                        Step 2 - Confirm Deposit
                                    </div>
                                    <div className={styles.text}>
                                        Send transaction to confirm depositing {paymentToken} tokens
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
                <div className={styles.item}>
                    <button
                        style={{marginTop: "30px"}}
                        onClick={handleApprove}
                        disabled={isSubmitButtonDisabled}
                        className={cn(
                            "button",
                            styles.button,
                            (buttonText === DEFAULT_TEXT || buttonText === DONE_TEXT) ? "" : "loading",
                            {
                                "disabled": isSubmitButtonDisabled || !canActivate
                            }
                        )}
                    >
                        { loading && <Loader className={styles.loaderSmall} color="white" /> }
                        {
                            account
                                ? buttonText
                                : CONNECT_WALLET_TEXT
                        }
                    </button>
                </div>
            </div>
            <Toaster />
        </div>
    );
};

export default InvestSteps;
