import { useEffect, useState } from "react";
import axios from "axios";

import { PINATA_CLOUD_URL } from "../utils";

const useGetPricingData = (ipfsHash) => {

    const [merkleRoot, setMerkleRoot] = useState();

    useEffect(() => {
        let mounted = true;

        const getMerkleRoot = async () => {

            try {

                const url = `${PINATA_CLOUD_URL}/ipfs/${ipfsHash}`;
                const result = await axios.get(url);
                
                if (mounted) {
                    setMerkleRoot(result?.data);
                }

            } catch(err) {
                console.log(err);
            }
        }
        if (ipfsHash) {
            getMerkleRoot();
        } else {
            setMerkleRoot(null);
        }

        return () => {
            mounted = false;
        }
    }, [ipfsHash]);

    return merkleRoot;
}

export default useGetPricingData;
