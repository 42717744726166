import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { formatUnits } from "ethers/lib/utils";
import cn from "classnames";
import styles from "./Details.module.sass";
import Icon from "../../../components/Icon";
import FormattedAmount from "../../../components/FormattedAmount";
import useConfig from "../../../customHooks/useConfig";
import EnsName from "../../../components/EnsName";
import Avatar from "../../../components/Avatar";

import {
  // CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles
} from "react-circular-progressbar";

import "react-circular-progressbar/dist/styles.css";

import {
    // SECONDS_IN_DAY,
    // ACTIVE_STATUS,
    ZERO_ADDRESS,
    // PENDING_STATUS,
    // READY_STATUS,
    // ACTIVE_STATUS,
    // EXPIRED_STATUS,
    // LIQUIDATED_STATUS,
    DEFAULT_DECIMALS,
    FINISHED_STATUS,
} from "../../../utils";

const ProgressCircle = ({progressTitle, progressAmount, progressPercent, paymentToken}) => {
    return (
        <div style={{display: "flex", marginRight: "13px"}}>
            <div style={{width: "45px", marginRight: "13px"}}>
                <CircularProgressbarWithChildren
                    value={progressPercent}
                    strokeWidth={8}
                    styles={buildStyles({
                        pathColor: "#c73e77",
                        trailColor: "#333",
                    })}
                >
                    <div className={progressPercent < 100
                        ? styles.normal
                        : styles.active
                    }>
                        <div className={styles.circle}>
                            <Icon name="check" size="16" />
                        </div>
                    </div>
                </CircularProgressbarWithChildren>
            </div>
            <div>
                <div className={styles.label}>
                    {progressTitle}
                </div>
                <div className={styles.value}>
                    <FormattedAmount
                        amount={progressAmount}
                        token={paymentToken}
                        label={true}
                        noTruncate={true}
                    />
                </div>
            </div>
        </div>
    )
}

const Details = ({
    className,
    users,
    lockerOwner,
    ownersAddress,
    paymentTokenName,
    paymentToken,
    totalAsked,
    floorAsked,
    borrowingFee,
    finalDate,
    totalCollected,
    nextDueTime,
    loanStatus,
    remainingBalance,
    claimableBalance,
    timelineItems,
    isStageOne,
}) => {

    const conversionRates = useSelector((state) => state.conversions);

    const config = useConfig();

    const noOwner = lockerOwner === ZERO_ADDRESS;
    const activeClass = !lockerOwner || lockerOwner === ZERO_ADDRESS
        ? styles.grayscale
        : styles.normal;

    const ownerTitle = noOwner
        ? "Listing Owner"
        : "Listing Owner";

    const time = noOwner
        ? "was"
        : "is";

    const floorProgress = totalCollected ? totalCollected.mul(100).div(floorAsked) : 0;
    const totalProgress = totalCollected ? totalCollected.mul(100).div(totalAsked) : 0;

    const parsedTotalCollected = totalCollected
        ? formatUnits(totalCollected.toString(), config.decimals[paymentTokenName])
        : 0;

    const parsedTotalPayedBack = totalCollected
        ? formatUnits(claimableBalance.toString(), config.decimals[paymentTokenName])
        : 0;

    const usdEquivalentCollected = parsedTotalCollected * conversionRates[paymentTokenName]; // need to take decimals in account
    const usdEquivalentPayedBack = parsedTotalPayedBack * conversionRates[paymentTokenName]; // need to take decimals in account

    let nextDueDateTime = 'Fetching Date...';

    if (nextDueTime) {

        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        };

        const date = new Date(nextDueTime.toString() * 1000).toLocaleString(
            'en-US',
            { hour12: false }
        ).split(" ");

        const time = date[1];

        nextDueDateTime = new Date(nextDueTime.toString() * 1000).toLocaleDateString(
            undefined,
            options
        );

        nextDueDateTime = new Date(nextDueDateTime) > new Date(timelineItems[3].date)
            ? timelineItems[3].date
            : nextDueDateTime;

        nextDueDateTime = `${nextDueDateTime} at ${time}`;
    }

    if (loanStatus === FINISHED_STATUS) {
        nextDueDateTime = 'Loan is payed back successfully'
    }

    const interestPay = totalCollected && totalCollected
        .mul(borrowingFee)
        .div("1000000000000000000") // fee is in 1E18 format
        .div(100);

    const interestProgress = interestPay > 0 && claimableBalance
        .mul(100)
        .div(interestPay);

    const penaltyAmount = remainingBalance && remainingBalance
        .add(claimableBalance)
        .sub(totalCollected)
        .sub(interestPay);

    const totalReturnValue = interestPay && interestPay
        .add(totalCollected)
        .add(penaltyAmount);

    const overallProgress = totalReturnValue > 0 && claimableBalance
        .mul(100)
        .div(totalReturnValue);

    const borrowingFeeFormated = formatUnits(
        borrowingFee.toString(),
        DEFAULT_DECIMALS
    );

    return (
        <>
        {(isStageOne === true) && (
            <div className={styles.info}>
                Total asked for this loan {time} {" "}
                {paymentToken && (
                    <FormattedAmount
                        amount={totalAsked}
                        token={paymentToken}
                        label={true}
                    />
                )}{" "}
                with the total interest of {borrowingFeeFormated}% expected to be paid back by the owner before {finalDate}
            </div>
        )}
        {(isStageOne === false) && (
            <div className={styles.info}>
                Total raised for this loan {time} {" "}
                {paymentToken && (
                    <FormattedAmount
                        amount={totalCollected}
                        token={paymentToken}
                        label={true}
                    />
                )}{" "}
                with the total interest of {borrowingFeeFormated}% expected to be paid back by the owner before {finalDate}
            </div>
        )}
        <div className={cn(styles.users, className)}>
            <div className={styles.starter}>
                <div className={activeClass} style={{display: "flex", alignItems: "center"}}>
                    <Link to={`/profile/${ownersAddress}`}>
                        <Avatar
                            className={styles.avatar}
                            address={ownersAddress}
                        />
                    </Link>
                    <div>
                        <div className={styles.label}>
                            {ownerTitle}
                        </div>
                        <Link to={`/profile/${ownersAddress}`}>
                            <div className={styles.value}>
                                <EnsName address={ownersAddress} />
                            </div>
                        </Link>
                    </div>
                </div>
                {(isStageOne === true) && (
                    <>
                        <ProgressCircle
                            progressTitle={"Floor Asked"}
                            progressAmount={floorAsked}
                            progressPercent={floorProgress}
                            paymentToken={paymentToken}

                        />
                        <ProgressCircle
                            progressTitle={"Total Asked"}
                            progressAmount={totalAsked}
                            progressPercent={totalProgress}
                            paymentToken={paymentToken}

                        />
                    </>
                )}
                {(isStageOne === false) && (
                    <>
                        <ProgressCircle
                            progressTitle={"Interest Pay"}
                            progressAmount={interestPay}
                            progressPercent={interestProgress}
                            paymentToken={paymentToken}

                        />
                        <ProgressCircle
                            progressTitle={"Overall Pay"}
                            progressAmount={totalReturnValue}
                            progressPercent={overallProgress}
                            paymentToken={paymentToken}

                        />
                    </>
                )}
            </div>
            <div className={styles.details}>
                <div className={styles.line}>
                    {(isStageOne === true) && (
                        <div className={styles.item}>
                            <div className={styles.icon}>
                                <Icon name="wallet" size="24" />
                                {" "}Total Contributed
                            </div>
                            <div className={styles.total}>
                                <FormattedAmount
                                    amount={totalCollected}
                                    token={paymentToken}
                                    label={true}
                                /> ~ {usdEquivalentCollected > 0 && parseFloat(usdEquivalentCollected).toFixed(2)} USD
                            </div>
                        </div>
                    )}
                    {(isStageOne === false) && (
                        <div className={styles.item}>
                            <div className={styles.icon}>
                                <Icon name="wallet" size="24" />
                                {" "}Total Returned
                            </div>
                            <div className={styles.total}>
                                <FormattedAmount
                                    amount={claimableBalance}
                                    token={paymentToken}
                                    label={true}
                                /> ~ {usdEquivalentPayedBack > 0 && parseFloat(usdEquivalentPayedBack).toFixed(2)} USD
                            </div>
                        </div>
                    )}
                    {(isStageOne === true) && (
                        <div className={styles.item}>
                            <div className={styles.icon}>
                                <Icon name="clock" size="24" />
                                {" "}Contribution Ends
                            </div>
                            <div className={styles.total}>{timelineItems[1].date} {timelineItems[1].time && "at"} {timelineItems[1].time}</div>
                        </div>
                    )}
                    {(isStageOne === false) && (
                        <div className={styles.item}>
                            <div className={styles.icon}>
                                <Icon name="clock" size="24" />
                                {" "}Next Payment Due
                            </div>
                            <div className={styles.total}>
                                {nextDueDateTime && nextDueDateTime.toString()}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
        </>
    );
};

export default Details;
