import { useState, useEffect } from "react";
import { useEthers } from "@usedapp/core";
import { ethers } from 'ethers';
import CHAINLINK_PRICING_CONTRACT_ABI from "../ethers/abis/ChainlinkPricingContract.json";

const useChainLink = (chainAddress) => {
    const { library } = useEthers();

    const [floorPrice, setFloorPrice] = useState();

    useEffect(() => {
        let mounted = true;

        const getFloorPrice = async () => {

            try {
                const pricingContract = new ethers.Contract(
                    chainAddress,
                    CHAINLINK_PRICING_CONTRACT_ABI,
                    library
                );

                const chainlinkFloorPrice = await pricingContract.latestAnswer();
                const decimals = await pricingContract.decimals();

                const floorPriceETH = chainlinkFloorPrice / 10 ** decimals;

                if (mounted) {
                    setFloorPrice(floorPriceETH);
                }

            } catch(err) {
                console.log(err);
            }
        }
        if (chainAddress && library) {
            getFloorPrice();
        }

        return () => {
            mounted = false;
        }

    }, [chainAddress, library]);

    return floorPrice;
};

export default useChainLink;
