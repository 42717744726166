import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./LockerCard.module.sass";
import Icon from "../Icon";
import { formatUnits } from "ethers/lib/utils";
import TokenImage from "../TokenImage";
import {
    SECONDS_IN_DAY,
    EXPIRED_STATUS,
    // ZERO_ADDRESS,
    FINISHED_STATUS,
    TERMINATED_STATUS,
    READY_STATUS,
    ACTIVE_STATUS,
    DEFAULT_DECIMALS,
} from "../../utils";

import FormattedAmount from "../FormattedAmount";
import loaderImage from "../../assets/images/loaders/golden.svg";
import useExistingLockerFetch from "../../customHooks/useExistingLockerFetch";
import TipHolder from "../../components/TipHolder/TipHolder";
import Avatar from "../../components/Avatar";

const LockerCard = ({ className, filterToken, locker }) => {
    const {
        lockerAddress,
        total,
        events,
        tokens,
        paymentRate,
        paymentTime,
        floorAsked,
        totalAsked,
        totalCollected,
        paymentToken,
        paymentTokenName,
        contributionPhase,
        floorNotReached,
        title,
        // lockerOwner,
        ownersAddress,
        loanStatus,
        // floorPrice
    } = locker;

    const ANY_TOKEN = "Any currency";

    const res = useExistingLockerFetch(
        lockerAddress
    );

    const actualAddress = !!ownersAddress
        ? ownersAddress
        : res[0]?.ownersAddress;

    const fundedLocker = () => {
        return (
            floorNotReached === false &&
            contributionPhase === false
        );
    }

    const hideToken = () => {
        return filterToken.includes(ANY_TOKEN)
            || filterToken.includes(paymentTokenName);
    };

    const finishedLocker = () => {
        return loanStatus === FINISHED_STATUS;
    }

    const expiredLocker = () => {
        return loanStatus === EXPIRED_STATUS;
    }

    const terminatedListing = () => {
        return loanStatus === TERMINATED_STATUS;
    }

    const fundedListing = () => {
        return loanStatus === READY_STATUS || progress === 100;
    }

    const activeListing = () => {
        return loanStatus === ACTIVE_STATUS;
    }

    const progress = (totalCollected * 100) / totalAsked;
    const isGray = finishedLocker()
        || expiredLocker()
        || terminatedListing();

    const paymentRateFormated = formatUnits(
        paymentRate.toString(),
        DEFAULT_DECIMALS
    );

    let buttonText = "Show Details"

    if (expiredLocker() ===  true) {
        buttonText = "Expired Listing";
    }

    if (terminatedListing() ===  true) {
        buttonText = "Terminated Listing";
    }

    if (finishedLocker() ===  true) {
        buttonText = "Finalized Listing";
    }

    if (fundedListing() ===  true) {
        buttonText = "Fully Funded";
    }

    if (activeListing() ===  true) {
        buttonText = "Activated Listing";
    }

    if (title.includes("undefined")) {
        return (<></>);
    }

    return (
        <div
            className={cn(styles.card, className, {
                [styles.funded]: fundedLocker(),
                [styles.expired]: fundedLocker(),
                [styles.hidden]: hideToken() === false,
            })}
        >
            <div className={cn(styles.preview, {
                    [styles.finished]: isGray,
                })}>
                <img
                    alt={"loader"}
                    src={loaderImage}
                    style={{
                        width: "100%",
                        transform: "scale(0.2)",
                        filter: "hue-rotate(300deg)",
                        position: "relative",
                    }}
                />
                {tokens && tokens[0] && (
                    <TokenImage
                        token={tokens[0]}
                        useIntersection={false}
                        className={styles.img}
                        style={{
                            position: "absolute",
                            top: "0px",
                            left: "0px",
                            backgroundColor: "#638596",
                            borderRadius: "25px",
                            width: "100%",
                        }}
                    />
                )}
                {tokens?.length > 1 && (
                    <div className={styles.categories}>
                        <div className={"status-main"}>bundle</div>
                    </div>
                )}
                <div className={styles.control}>
                    <Link
                        to={{ pathname: `https://etherscan.io/address/${lockerAddress}` }}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <button className={cn(styles.external)}>
                            <div className={styles.open}>
                                <Icon name="arrow-next" size="20" />
                            </div>
                        </button>
                    </Link>
                    <Link target="_blank" rel="noopener noreferrer" to={`/item/${lockerAddress}`}>
                        <button className={cn("button-small", styles.button, {
                                [styles.finished]: buttonText !== 'Show Details',
                            }
                        )}>
                            <span>{buttonText}</span>
                            <Icon name="search" size="16" />
                        </button>
                    </Link>
                </div>
            </div>
            <div className={styles.control}>
                <Link
                    to={{ pathname: `https://etherscan.io/address/${lockerAddress}` }}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <button className={cn(styles.external)}>
                        <div className={styles.open}>
                            <Icon name="arrow-next" size="20" />
                        </div>
                    </button>
                </Link>
                <Link target="_blank" rel="noopener noreferrer" to={`/item/${lockerAddress}`}>
                    <button className={cn("button-small", styles.button)}>
                        <span>Show Details</span>
                        <Icon name="search" size="16" />
                    </button>
                </Link>
            </div>
            <div className={styles.details} to={`/loan-details/${lockerAddress}`}>
                <div className={styles.body}>
                    <div className={styles.line}>
                        <div className={styles.title}>{title}</div>
                        <TipHolder
                            borrowingRate={paymentRateFormated}
                            borrowingTime={paymentTime}
                        />
                    </div>
                    <div className={styles.line}>
                        <div className={styles.users}>
                            <Link className={styles.link} to={`/profile/${actualAddress}`}>
                                <Avatar
                                    className={styles.avatar}
                                    address={actualAddress}
                                    showAddress={true}
                                />
                            </Link>
                        </div>
                        <div className={styles.counter}>
                            {paymentTime && Math.floor(paymentTime.toNumber() / SECONDS_IN_DAY)}
                            {""} Day Loan
                        </div>
                    </div>
                </div>
                <div className={styles.progress} style={{ width: `${progress}%` }}></div>
                <div className={styles.foot}>
                    <div className={styles.status}>
                        <div>Floor Asked</div>
                        <span>
                            <FormattedAmount token={paymentToken} amount={floorAsked} label />
                        </span>
                    </div>
                    <div className={styles.right}>
                        <div>Total Asked</div>
                        <span>
                            <FormattedAmount token={paymentToken} amount={totalAsked} label />
                        </span>
                    </div>
                </div>
                { events?.length && (
                    <div className={styles.foot}>
                        <div className={styles.status}>
                            <div>Contributions</div>
                            <span>{Number(events?.length)}</span>
                        </div>
                        <div className={styles.right}>
                            <div>Total Contribution</div>
                            <span>
                                <FormattedAmount token={paymentToken} amount={total} label />
                            </span>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default LockerCard;
