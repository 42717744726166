import React, { useState } from 'react';
import cn from 'classnames';
import { useParams } from 'react-router-dom';
import { useEthers } from '@usedapp/core';
// import { ethers } from 'ethers';
import Modal from '../../../../components/Modal';
import styles from './ClaimRefund.module.sass';
import { useRefundCheck } from '../../../../customHooks/useRefundCheck';
import RefundSteps from './RefundSteps';
import { ZERO_ADDRESS, PENDING_STATUS } from '../../../../utils';

const ClaimRefund = ({ paymentToken, contributorData, loanStatus }) => {

    const { account } = useEthers();
    const { lockerAddress } = useParams();
    const [visibleModal, setVisibleModal] = useState(false);

    const [thisUsersContribution, singleProvider] = useRefundCheck(
        account,
        lockerAddress
    );

    const noSingleProvider = singleProvider === ZERO_ADDRESS;

    // const isNotUserOwner = account !== lockerOwner;
    // const isSingleAndNotUser = isSingleProvider && isNotUserOwner && hasRefundToClaim;
    // const isOwnerZero = (ethers.constants.AddressZero === lockerOwner) && hasRefundToClaim;
    // const showButton = isSingleAndNotUser || isOwnerZero;

    const refundType = noSingleProvider
        ? 'refundDueExpired'
        : 'refundDueSingle';

    return (
        <>
            <button
                className={cn(
                    'button',
                    styles.button,
                    ((singleProvider === account && noSingleProvider === false) || loanStatus === PENDING_STATUS) ? "disabled" : ""
                )}
                onClick={() => setVisibleModal(true)}
            >
                Claim Refund
            </button>
            <Modal visible={visibleModal} onClose={() => setVisibleModal(false)}>
                <RefundSteps
                    contributorData={contributorData}
                    closeFunction={setVisibleModal}
                    userContribution={thisUsersContribution}
                    refundType={refundType}
                    paymentToken={paymentToken}
                    singleProvider={singleProvider}
                />
            </Modal>
        </>
    );
};

export default ClaimRefund;
