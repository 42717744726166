import { ethers } from "ethers";
// import axios from "axios";
import { DEFAULT_SUPPORTED_CHAINS } from "@usedapp/core";
import { formatUnits } from "ethers/lib/utils";
import TimeAgo from "javascript-time-ago"
import timeLocaleEn from "javascript-time-ago/locale/en"
// import { OPENSEA_API, OPENSEA_API_KEY } from "./config";
import { COLLECTION_ADDRESSES_MAINNET } from "../ethers/nftCollections";
import { COLLECTION_ADDRESSES_TESTNET } from "../ethers/nftCollections/testnet";

TimeAgo.addDefaultLocale(
    timeLocaleEn
);

export const getTimeAgoInstance = () => new TimeAgo("en-US");

export const TEST_NETWORK_ID = 5;

export const toTitleCase = (text) => {
    return text.replace(
        /\w\S*/g,
        (word) => word.charAt(0).toUpperCase() + word.substr(1).toLowerCase()
    );
}

export const dollarsFormat = (dollars, optionalPrecision) => {

    if (dollars >= 1000000) {
        return `${toPrecision(dollars / 1000000, 2)}M`;
    }

    if (dollars >= 100000) {
        return `${toPrecision(dollars / 1000, 0)}K`;
    }

    if (dollars >= 10000) {
        return `${toPrecision(dollars / 1000, 1)}K`;
    }

    if (dollars >= 1000) {
        return `${toPrecision(dollars, 0)}`;
    }

    if (dollars >= 100) {
        return `${toPrecision(dollars, optionalPrecision || 0)}`;
    }

    if (isNaN(toPrecision(dollars, 2))) return "0";
    return `${toPrecision(dollars, 2)}` !== "0.00"
        ? `${toPrecision(dollars, 2)}`
        : `${toPrecision(dollars, 2)}`;
};

export const getPreciseValue = (numericValue) => {

    if (!numericValue) return "0.00";

    const startPosition = 0;
    const numericString = numericValue.toString();
    const strippedValue = parseInt(numericString).toString();
    const finalPosition = strippedValue.length + 3;

    return numericString.substr(
        startPosition,
        finalPosition
    );
}

export const getTimeAgo = (timestamp) => {
    return timestamp && toTitleCase(
        getTimeAgoInstance().format(
            new Date(
                timestamp
            )
        )
    );
};

export const produceAccurateValue = (numericValue, valueDecimals) => {

    if (!numericValue) return "0.00";

    const formated = formatUnits(
        numericValue,
        valueDecimals
    );

    const stringValue = getPreciseValue(
        formated
    );

    return getPrettyValue(
        stringValue
    );
}

export const getPrettyValue = (uglyValue, precision = 2) => {

    if (!uglyValue) return "0.00";

    return parseFloat(
        uglyValue
    ).toLocaleString("en-US", {
        minimumFractionDigits: precision,
        maximumFractionDigits: precision
    });
}

export const formatNumber = (num) => {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

export const removeNonNumeric = (num) => {
    return num.toString().replace(/[^0-9]/g, "");
};

export const addThousandsSeparator = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const getTodayAndFiveDaysFromIt = () => {
    const today = new Date();
    const todayMs = today.getTime();
    const fiveDaysMs = CONTRIBUTION_PHASE_IN_SECONDS * MS_IN_SECOND;
    const fiveDaysFromTodayMs = todayMs + fiveDaysMs;
    const fiveDaysFromToday = new Date(fiveDaysFromTodayMs);
    return { today, fiveDaysFromToday };
};

const getSecondsDiff = (startDate, endDate) => {
    const msInSecond = 1000;
    return Math.round(
        Math.abs(endDate - startDate) / msInSecond
    );
}


export const getDifferenceBetweenDates = (firstDate, secondDate) => {

    const timeDifference = secondDate.getTime()
        - firstDate.getTime();

    const daysValue = timeDifference
        / (
            MS_IN_SECOND *
            SECONDS_IN_DAY
        );

    const days = Math.floor(
        daysValue
    );

    const hours = Math.floor((timeDifference % (MS_IN_SECOND * SECONDS_IN_DAY)) / (MS_IN_SECOND * SECONDS_IN_HR));
    const minutes = Math.floor((timeDifference % (MS_IN_SECOND * SECONDS_IN_HR)) / (MS_IN_SECOND * SECONDS_IN_MN));
    const seconds = Math.floor((timeDifference % (MS_IN_SECOND * SECONDS_IN_MN)) / MS_IN_SECOND);

    const daysRemaining = Math.ceil(
        daysValue
    );

    const secondsRemaining = getSecondsDiff(
        firstDate,
        secondDate
    );

    return {
        days,
        hours,
        minutes,
        seconds,
        secondsRemaining,
        daysRemaining
    };
};

export const formatDaysPast = (date) => {
    const daysDiff = getDifferenceBetweenDates(
        date,
        new Date()
    )?.days

    if (daysDiff === 0) {
        return "Today";
    }

    if (daysDiff === 1) {
        return "1 Day Ago";
    }

    return `${daysDiff} Days Ago`;
}

export const toPrecision = (numericValue) => {

    if (!numericValue) return "0.00";

    const stringValue = getPreciseValue(
        numericValue
    );

    return getPrettyValue(
        stringValue
    );
}

export const getRelativeTimestamp = (daysAgo = 0) => {
    return new Date().setDate(new Date().getDate() - daysAgo);
}

export const toFixedNoRound = (
    value,
    precision
) => {

    const result = parseFloat(value).toFixed(
        precision
    );

    const adjusted = result - Math.pow(
        0.1,
        precision
    );

    return result <= value
        ? result
        : adjusted.toFixed(precision);
};

export const formatDateFormal = (date) => {
    return date.toLocaleDateString("en-us", {
        year: "numeric",
        month: "short",
        day: "numeric"
    });
};

export const shortenHash = (hash) => {
    if (!hash) return;
    return `${hash.slice(0, 5)}...${hash.slice(hash.length - 5)}`;
};

export const itemsDefault = [
    {
        title: "Contributions Phase",
        date: "Fetching Date...",
        time: "",
        color: "#9757D7",
        gray: 0,
        images: "/images/content/download.svg",
        content:
            "Listing is opened for contributions, a floor asked amount must be collected in 5 days",
    },
    {
        title: "Activation Phase",
        date: "Fetching Date...",
        time: "",
        color: "#3772FF",
        gray: 1,
        images: "/images/content/whistle.svg",
        content:
            "If floor asked is reached the loan can be activated and the NFT owner gets their collected funds",
    },
    {
        title: "Payback Phase",
        date: "Fetching Date...",
        time: "",
        color: "#EF466F",
        gray: 1,
        images: "/images/content/stopwatch.svg",
        content:
            "NFT owner is responsible for paying out the loan interest linearly for the duration of the loan",
    },
    {
        title: "Claiming Phase",
        date: "Fetching Date...",
        fullDate: "",
        color: "#45B26B",
        gray: 1,
        images: "/images/content/medal.svg",
        content:
            "If entire loan is payed back on time, the NFT is returned, and contributors receive their full earnings",
    },
];

// const owlIndexes = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 27, 28, 29, 30, 33, 34, 66, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210];

export const getOwlImage = async (address) => {

    if (!address) return;

    // const intAddr = parseInt(address);
    // const owlIndex = intAddr % owlIndexes.length;

    if (address) {
        const { image } = await fetchOpenSea(
            address
        );

        if (image) {
            return {
                image,
                isOwned: true
            };
        }
    }

    return {
        // image: `/images/baby-owls/${owlIndexes[owlIndex]}.jpg`,
        image: `/images/baby-owls/shadow-owl.png`,
        isOwned: false
    };
};

export const addApprox = (value) => {
    if (!value) return "...";
    if (parseInt(value) === 0) {
        return `>${value}`;
    }
    return value;
}

export const BACKEND_URL_LIVE = "https://backend.liquidnfts.com";
export const BACKEND_URL_DEMO = "http://localhost:3001/";
export const PINATA_CLOUD_URL = "https://liquidnfts.mypinata.cloud";

export const BACKEND_URL = process.env.REACT_APP_STAGE === "local"
    ? BACKEND_URL_DEMO
    : BACKEND_URL_LIVE;

export const DEFAULT_FALLBACK_NUM = 0;
export const RARIFY_BASE_URL = "https://api.rarify.tech/data/";
export const ALCHEMY_BASE_URL_MAINNET = "https://eth-mainnet.g.alchemy.com/nft/v2/";
export const ALCHEMY_BASE_URL_GOERLI = "https://eth-goerli.g.alchemy.com/nft/v2/";
export const DISCORD_LINK = "https://discord.gg/gFK3jbgRQu";

export const USDC_LABEL = "USDC";
export const ETH_LABEL = "ETH";

export const ZERO = 0;
export const TEN = 10;
export const PARSE_HEX_RADIX = 16;
export const MIN_CONFIRMATIONS = 2;
export const DEFAULT_TIMEOUT = 5000;
export const DEFAULT_FIXED_DECIMALS = 2;
export const MIN_PRECISION_THRESHOLD = 0.01;
export const ETH_DECIMALS = 18;
export const THIRTY_MIN_MS = 1800000;
export const SECONDS_IN_MN = 60;
export const SECONDS_IN_HR = SECONDS_IN_MN * 60;
export const DAYS_IN_YEAR = 365;
export const PAYBACK_TIME = 35;
export const BORROW_RATE_DECIMAL = 20;
export const BLOCKS_PER_DAY = 7000;
export const CONTRIBUTION_DAYS = 5;
export const MS_IN_SECOND = 1000;
export const THIRY_SECOND_MS = 30000;
export const SECONDS_IN_DAY = 86400;
export const SECONDS_IN_YEAR = SECONDS_IN_DAY * 365;
export const HOURS_IN_DAY = 24;
export const APPROVE_MAX_VALUE = ethers.constants.MaxUint256;
export const ZERO_ADDRESS = "0x0000000000000000000000000000000000000000";
export const EMPTY_ADDRESS = "0x0101010101010101010101020304050607080900";
export const CONTRIBUTION_PHASE_IN_SECONDS = CONTRIBUTION_DAYS * SECONDS_IN_DAY;
export const MINIMUM_THRESHOLD = 100000000;
export const ONE_HUNDRED = 100;
export const AUDIT_LINK = "https://github.com/wise-foundation/liquidnfts-audit-scope/tree/master/audit";
// export const AUDIT_LINK = "https://github.com/0xGuard-com/audit-reports/blob/master/liquidnfts/LiquidLocker_final-audit-report.pdf";
export const SHOW_TIP = false;
export const TIP_STORAGE_KEY = "showTIP";
export const HIDDEN_OPTIONS_STORAGE_KEY = "hiddenOptions";
export const COLLECTION_STORAGE_KEY= "collectionPrefV3";
export const TOKEN_STORAGE_KEY = "tokenPrefV3";
export const AIRDROP_STORAGE_KEY = 'airdropBannerPref';
export const BANNER_STORAGE_KEY = 'banner';
export const SHOW_SUPPORTED_KEY = "showSupported";
export const PRICING_OPTIONS_STORAGE_KEY = 'pricingPref';
export const SHOW_PAST_LOANS_KEY = "showPastLoans";
export const POOL_PAGE_STORAGE_KEY = 'poolPageOptions'
export const PENDING_STATUS = "Pending"; // DEFAULT
export const READY_STATUS = "Ready";
export const ACTIVE_STATUS = "Active";
export const EXPIRED_STATUS = "Expired";
export const FINISHED_STATUS = "Finalized";
export const LIQUIDATED_STATUS = "Liquidated";
export const TERMINATED_STATUS = "Terminated";
export const FETCHING_DATA_MESSAGE = "Fetching Data...";
export const SHORT_FETCH_MESSAGE = "Fetching...";

// export const READ_CONTRACT_LINK = "https://etherscan.io/address/0x076546b64cbfc30e60a952bf9473d32288ed93f6#code"; // FACTORY
export const READ_CONTRACT_LINK = "https://etherscan.io/address/0xB4d2484EF7f91c8D4cd019460Ccbc384C1C24297#code"; // ROUTER
export const BASE_TWITTER_URL = "http://twitter.com/share?url=";
export const NEW_PAGE_SETTINGS = "left=0,top=0,width=550,height=450,personalbar=0,toolbar=0,scrollbars=0,resizable=0";
export const ITEM_VIEW_URL = "https://liquidnfts.com/item/";

export const WETH_POOL_MAINNET = "0x3f302D860b27FB06C794eE0916abC1D3A362Bf7e";
export const WETH_POOL_TESTNET = "0xDdd66B3bcc22103b58518974DA66F3BF7EF53DD5";
export const USDC_POOL_MAINNET = "0x85162c81D0Ef84b21230eB4bf3cAb073B71F3c38";
export const USDC_POOL_TESTNET = "0x72D75295ecEbEE27b362Be81324D89388E7D2231";

export const DEFAULT_DECIMALS = 18;
export const BORROW_RATE_DECIMALS = 16;
export const LEND_RATE_DECIMALS = 16;

export const GLOBAL_STR = "Global";
export const OLD_STR = "Old";
export const ETHEREUM_STR = "Ethereum";
export const STABLE_STR = "Stable";
export const WETH_STR = "WETH";
export const USDC_STR = "USDC";

export const COLLECTIONS_DROPDOWN_LIST = [
    GLOBAL_STR,
    OLD_STR,
    ETHEREUM_STR,
    STABLE_STR,
    WETH_STR,
    USDC_STR
];

// ROUTES
export const INSTANT_POOLS_ROUTE = "/instant-pools";

export const MINIMUM_THRESHOLDS = [{
    decimals: 18,
    token: "WISE",
    value: 10000000000000000000
},
{
    decimals: 6,
    token: "USDC",
    value: 100000000
}];

export const addDays = (dateObj, days) => {
    const date = new Date(dateObj);
    return new Date(date.setDate(date.getDate() + days));
};

export const determineStatus = ({
    contributionPhase,
    floorNotReached,
    creationTime,
    paymentTime,
    nextDueTime,
    remainingBalance,
    lockerOwner,
    belowFloorAsked,
}) => {

    let status = PENDING_STATUS;
    if (nextDueTime > 0) status = ACTIVE_STATUS;

    if (
        contributionPhase === false &&
        floorNotReached === false &&
        nextDueTime.toString() === "0" &&
        lockerOwner !== ZERO_ADDRESS
    ) {
        status = READY_STATUS;
    }

    if (floorNotReached === true) status = EXPIRED_STATUS;
    if (floorNotReached === false && lockerOwner === ZERO_ADDRESS) {
        status = remainingBalance.toString() === "0"
            ? FINISHED_STATUS
            : LIQUIDATED_STATUS

        if (belowFloorAsked) status = TERMINATED_STATUS;
    }

    return status;
};

export const DEFAULT_TOKEN_PREFERENCES = {
    // 'WISE': true,
    "USDC": true,
    "WETH": true
};

export const DEFAULT_COLLECTION_PREFERENCES = {
    "Bored Apes": true,
    // "Mutant Apes": true,
    "Doodles": true,
    // 'Cool Cats': true,
    // 'Satoshibles': true,
    // 'CyberKongz': true
};

export const DEFAULT_POOL_VIEW = "GRID";

export const DEFAULT_BANNER_PREFERENCES = {
    promotion: false
}
// @TODO: this currently works only on test-net
export const collectionAddressToNameTestnet = {
    [COLLECTION_ADDRESSES_TESTNET.BORED_APES]: "BoredApes",
    [COLLECTION_ADDRESSES_TESTNET.COOL_CATS]: "CoolCats",
    [COLLECTION_ADDRESSES_TESTNET.DOODLES]: "Doodles",
    [COLLECTION_ADDRESSES_TESTNET.WRAPPED_PUNKS]: "WrappedPunks"
};

export const collectionAddressToNameMainnet = {
    [COLLECTION_ADDRESSES_MAINNET.AZUKI]: "Azuki",
    [COLLECTION_ADDRESSES_MAINNET.BORED_APES]: "BoredApes",
    [COLLECTION_ADDRESSES_MAINNET.CLONE_X]: "CloneX",
    [COLLECTION_ADDRESSES_MAINNET.COOL_CATS]: "CoolCats",
    [COLLECTION_ADDRESSES_MAINNET.DOODLES]: "Doodles",
    [COLLECTION_ADDRESSES_MAINNET.MOON_BIRDS]: "MoonBirds",
    [COLLECTION_ADDRESSES_MAINNET.MUTANT_APES]: "MutantApes",
    [COLLECTION_ADDRESSES_MAINNET.WRAPPED_PUNKS]: "Wrapped Punks",
    [COLLECTION_ADDRESSES_MAINNET.PUNKS_VERSION_ONE]: "PunksV1",
};

export const UNWANTED_PRICING_LIST = [
    "CryptoPunks",
    "Bored Kennels",
    "DeadFellaz",
    "mfers",
    "Invisible Friends",
    "CyberKongz"
];

export const getCollectionNameFromAddress = (address, chainId) => {
    if (!address) return;

    const chainCollection = chainId === TEST_NETWORK_ID
        ? collectionAddressToNameTestnet
        : collectionAddressToNameMainnet;

    const addressesLowerCase = Object.keys(chainCollection).map((addr) => (
        addr.toLowerCase()
    ));

    const addressIndex = addressesLowerCase.findIndex((addr) => (
        addr === address.toLowerCase()
    ));

    const collectionNames = Object.values(
        chainCollection
    );

    return collectionNames[addressIndex];
};

export const DEFAULT_COLLECTION_NAME = "Tokens";
export const LISTING_DETAILS_METHODS = [
    "floorAsked",
    "totalAsked",
    "getTokens",
    "globals",
    "PAYMENT_TOKEN",
    "totalCollected",
    "remainingBalance",
    "floorNotReached",
    "creationTime",
    "contributionPhase",
    "nextDueTime",
    "singleProvider",
    "claimableBalance",
    "belowFloorAsked",
];

export const PERCENT_OPTIONS = {
    25: "25%",
    50: "50%",
    75: "75%",
    max: "Max"
};

// @TODO: move to separate file A
export const BORROW_TIMES = [
    "14 Days",
    "30 Days",
    "45 Days",
    "60 Days",
    "90 Days",
    "180 Days",
    "270 Days",
    "365 Days"
];

// @TODO: move to separate file A
export const BORROW_TIMES_OPTION = [
    "Any Length",
    ...BORROW_TIMES
];

// @TODO: move to separate file A
export const BORROW_RATE_OPTION = {
    "WETH": {
        "14 Days": [
            "1.00%",
            "2.00%",
            "3.00%",
            "5.00%",
            "8.00%"
        ],
        "30 Days": [
            "2.00%",
            "3.00%",
            "4.00%",
            "7.00%",
            "10.00%"
        ],
        "45 Days": [
            "2.00%",
            "3.00%",
            "4.00%",
            "7.00%",
            "10.00%"
        ],
        "60 Days": [
            "2.00%",
            "3.00%",
            "4.00%",
            "7.00%",
            "10.00%"
        ],
        "90 Days": [
            "3.00%",
            "4.00%",
            "5.00%",
            "7.00%",
            "10.00%"
        ],
        "180 Days": [
            "5.00%",
            "7.00%",
            "9.00%",
            "12.00%",
            "15.00%"
        ],
        "270 Days": [
            "8.00%",
            "10.00%",
            "13.00%",
            "16.00%",
            "20.00%"
        ],
        "365 Days": [
            "10.00%",
            "12.00%",
            "15.00%",
            "18.00%",
            "21.00%"
        ],
    },
    "USDC": {
        "14 Days": [
            "2.00%",
            "3.00%",
            "5.00%",
            "8.00%",
            "12.00%"
        ],
        "30 Days": [
            "3.00%",
            "5.00%",
            "8.00%",
            "11.00%",
            "15.00%"
        ],
        "45 Days": [
            "4.00%",
            "5.00%",
            "7.00%",
            "10.00%",
            "13.00%"
        ],
        "60 Days": [
            "5.00%",
            "6.00%",
            "8.00%",
            "11.00%",
            "14.00%"
        ],
        "90 Days": [
            "6.00%",
            "8.00%",
            "11.00%",
            "14.00%",
            "17.00%"
        ],
        "180 Days": [
            "10.00%",
            "12.00%",
            "15.00%",
            "20.00%",
            "25.00%"
        ],
        "270 Days": [
            "15.00%",
            "18.00%",
            "22.00%",
            "26.00%",
            "30.00%"
        ],
        "365 Days": [
            "20.00%",
            "22.00%",
            "24.00%",
            "27.00%",
            "30.00%"
        ],
    },
    "WISE": {
        "14 Days": [
            "1.00%",
            "2.00%",
            "3.00%",
            "5.00%",
            "8.00%"
        ],
        "30 Days": [
            "2.00%",
            "3.00%",
            "4.00%",
            "7.00%",
            "10.00%"
        ],
        "45 Days": [
            "2.00%",
            "3.00%",
            "4.00%",
            "7.00%",
            "10.00%"
        ],
        "60 Days": [
            "2.00%",
            "3.00%",
            "4.00%",
            "7.00%",
            "10.00%"
        ],
        "90 Days": [
            "3.00%",
            "4.00%",
            "5.00%",
            "7.00%",
            "10.00%"
        ],
        "180 Days": [
            "5.00%",
            "7.00%",
            "9.00%",
            "12.00%",
            "15.00%"
        ],
        "270 Days": [
            "8.00%",
            "10.00%",
            "13.00%",
            "16.00%",
            "20.00%"
        ],
        "365 Days": [
            "10.00%",
            "12.00%",
            "15.00%",
            "18.00%",
            "21.00%"
        ],
    }
};

export const getToastSettings = (emoji) => {
    return {
        // duration: 4000,
        position: "right-bottom",
        // Styling
        style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
        },
        className: "",
        // Custom Icon
        icon: emoji,
        // Change colors of success/error/loading icon
        iconTheme: {
            primary: "#000",
            secondary: "#fff",
        },
        // Aria
        ariaProps: {
            role: "status",
            "aria-live": "polite",
        },
    }
};

// @TODO: Gather error messages and desired messages
const ERROR_CODE_TRANSLATIONS = {
    UNSUPPORTED_OPERATION: "Please check that you have correctly connected your wallet",
    4001: "Cancelled transaction",
    4100: "Unauthorized transaction",
    4200: "Unsupported transaction",
    4900: "Provider is disconnected from all chains",
    4901: "Provider is disconnected from specified chain",
    32700: "Bad request",
    32600: "Bad request",
    32601: "Unsupported transaction",
    32602: "Invalid request parameters",
    32603: "Internal JSON-RPC error",
    32000: "Invalid input",
    32001: "Resource not found",
    32002: "Resource unavailable",
    32003: "Transaction rejected",
    32004: "Method not supported",
    32005: "Request limit Exceeded",
};

const FALLBACK_ERROR_MESSAGE = "Make sure your wallet has enough funds available!";

export const getToastErrorMessage = (code) => {
    const errorDescription = ERROR_CODE_TRANSLATIONS[code]
        || FALLBACK_ERROR_MESSAGE;

    return `${code} - ${errorDescription}`;
};

const DEFAULT_SLUG = "owlmybabies";

const findAssets = (data, slug) => {
    return data && data.assets.find(
        (x) => x.collection.slug === slug
    );
}

const findResponse = (address) => {
    return memory.find(
        (x) => x.config.url.includes(
            address
        )
    );
};

const memory = [];

const fetchOpenSea = async (address) => {

    const response = findResponse(
        address
    );

    if (response) {

        const asset = findAssets(
            response.data,
            DEFAULT_SLUG
        );

        const image = asset
            ? `${asset.nft.image_url}=s500`
            : undefined;

        const token = asset
            ? asset.token_id
            : undefined;

        return { image, token };
    }

    try {

        if (!address) {
            return;
        }

        return {
            image: "https://liquidnfts.com/images/baby-owls/shadow-owl.png",
            token: "80854571046587284139654852168886073402943122695944337267750194890893669957633"
        }

    } catch (e) {
        // @TODO: show toast
        console.log(e);
        return {
            image: "https://lh3.googleusercontent.com/yeRogaNzjFlVKiz7YpC6N4iQju6BJEMNQGRsCFRwCiz9Ho9O3yIq7ARBXF7fg0iUxZn6f5kJvW5ypHJ9pGim3flsh2nLp73QU5VLA3s",
            token: "80854571046587284139654852168886073402943122695944337267750194890893669957633"
        }
    }
}

export const getChainById = chainId =>
    DEFAULT_SUPPORTED_CHAINS.find((network) => network.chainId === chainId);

export const getDeadline = () => Math.round(
    (Date.now() + THIRTY_MIN_MS) / MS_IN_SECOND
);
