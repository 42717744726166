// import Web3 from 'web3';
// import axios from 'axios';

import cryptoPunksABI from "./abis/cryptoPunksABI.json";
// import cryptoKittiesABI from './abis/cryptoKittiesABI.json';
import indexedTransferABI from "./abis/indexedTransferABI.json";
import enumerableABI from "./abis/enumerableABI.json";

// import { TEST_NETWORK_ID } from "../../utils";

import {
    COLLECTION_ADDRESSES_TESTNET,
    testnetEnumerable,
    testnetIndexedTransfer,
    testnetPeculiarContracts
} from "./testnet";

//import { element } from "prop-types";

const TEST_NETWORK_ID = 5;

export const COLLECTION_ADDRESSES_MAINNET = {
    THE_HUMANOIDS: "0x3a5051566b2241285BE871f650C445A88A970edd",
    SATOSHIBLES: "0x0B0b186841C55D8a09d53Db48dc8cab9dbf4Dbd6",
    WONDER_PALS: "0x3Acce66cD37518A6d77d9ea3039E00B3A2955460",
    CYBER_KONGZ: "0x57a204AA1042f6E66DD7730813f4024114d74f37",
    INVISIBLE_FRIENDS: "0x59468516a8259058baD1cA5F8f4BFF190d30E066",
    RAID_HERO: "0x966731dFD9b9925DD105FF465687F5aA8f54Ee9f",
    RAID_FIGHTERS: "0x87E738a3d5E5345d6212D8982205A564289e6324",
    MOON_BIRDS: "0x23581767a106ae21c074b2276D25e5C3e136a68b",
    KARAFURU: "0xd2F668a8461D6761115dAF8Aeb3cDf5F40C532C6",
    BORED_APES: "0xBC4CA0EdA7647A8aB7C2061c2E118A18a936f13D",
    MUTANT_APES: "0x60E4d786628Fea6478F785A6d7e704777c86a7c6",
    COOL_CATS: "0x1A92f7381B9F03921564a437210bB9396471050C",
    DOODLES: "0x8a90CAb2b38dba80c64b7734e58Ee1dB38B8992e",
    BORED_KENNELS: "0xba30E5F9Bb24caa003E9f2f0497Ad287FDF95623",
    AZUKI: "0xED5AF388653567Af2F388E6224dC7C4b3241C544",
    CLONE_X: "0x49cF6f5d44E70224e2E23fDcdd2C053F30aDA28B",
    DEAD_FELLAZ: "0x2acAb3DEa77832C09420663b0E1cB386031bA17B",
    MFERS: "0x79FCDEF22feeD20eDDacbB2587640e45491b757f",
    CRYPTO_PUNKS: "0xb47e3cd837dDF8e4c57F05d70Ab865de6e193BBB",
    WRAPPED_PUNKS: "0xb7F7F6C52F2e2fdb1963Eab30438024864c313F6",
    PUNKS_VERSION_ONE: "0x282BDD42f4eb70e7A9D9F40c8fEA0825B7f68C5D",
    // OPEN_SEA: "0x495f947276749Ce646f68AC8c248420045cb7b5e",
    PUDGY_PENGUIN: "0xbd3531da5cf5857e7cfaa92426877b022e612cf8"
};

export const enumerableContracts = [
    {
        name: "Bored Apes",
        singleName: "Bored Ape",
        address: COLLECTION_ADDRESSES_MAINNET.BORED_APES,
    },
    {
        name: "Mutant Apes",
        singleName: "Mutant Ape",
        address: COLLECTION_ADDRESSES_MAINNET.MUTANT_APES,
    },
    {
        name: "Cool Cats",
        singleName: "Cool Cat",
        address: COLLECTION_ADDRESSES_MAINNET.COOL_CATS,
    },
    {
        name: "Doodles",
        singleName: "Doodle",
        address: COLLECTION_ADDRESSES_MAINNET.DOODLES,
    },
    {
        name: "Bored Kennels",
        singleName: "Bored Kennel",
        address: COLLECTION_ADDRESSES_MAINNET.BORED_KENNELS,
    },
    {
        name: "Azuki",
        singleName: "Azuki",
        address: COLLECTION_ADDRESSES_MAINNET.AZUKI,
    },
    {
        name: "CloneX",
        singleName: "CloneX",
        address: COLLECTION_ADDRESSES_MAINNET.CLONE_X,
    },
    {
        name: "Moon Birds",
        singleName: "Moon Bird",
        address: COLLECTION_ADDRESSES_MAINNET.MOON_BIRDS,
    },
    {
        name: "DeadFellaz",
        singleName: "DeadFellaz",
        address: COLLECTION_ADDRESSES_MAINNET.DEAD_FELLAZ,
    },
    {
        name: "mfers",
        singleName: "mfer",
        address: COLLECTION_ADDRESSES_MAINNET.MFERS,
    },
    {
        name: "Wrapped Punks",
        singleName: "Wrapped Punk",
        address: COLLECTION_ADDRESSES_MAINNET.WRAPPED_PUNKS,
    },
    {
        name: "Pudgy Penguins",
        singleName: "Pudgy Penguin",
        address: COLLECTION_ADDRESSES_MAINNET.PUDGY_PENGUIN,
    },
    // {
    //     name: "Open Sea",
    //     address: COLLECTION_ADDRESSES_MAINNET.OPEN_SEA
    // },
    // {
    //     name: "HeadDAO",
    //     address: "0xf62c6a8e7bcdc96cda11bd765b40afa9ffc19ab9",
    // },
    // {
    //     name: "Meebits",
    //     address: "0x7Bd29408f11D2bFC23c34f18275bBf23bB716Bc7",
    // },
    // {
    //     name: "Sorare",
    //     address: "0x629A673A8242c2AC4B7B8C5D8735fbeac21A6205",
    // },
    // {
    //     name: "Art Blocks",
    //     address: "0x059EDD72Cd353dF5106D2B9cC5ab83a52287aC3a",
    // },
    // {
    //     name: "On1Force",
    //     address: "0x3bf2922f4520a8BA0c2eFC3D2a1539678DaD5e9D",
    // },
    // {
    //     name: "Cryptoadz",
    //     address: "0x1CB1A5e65610AEFF2551A50f76a87a7d3fB649C6",
    // },
    // {
    //     name: "Pudgy Penguins",
    //     address: "0xBd3531dA5CF5857e7CfAA92426877b022e612cf8",
    // },
    // {
    //     name: "MekaVerse",
    //     address: "0x9A534628B4062E123cE7Ee2222ec20B86e16Ca8F",
    // },
    // {
    //     name: "PUNKS Comic",
    //     address: "0x5ab21Ec0bfa0B29545230395e3Adaca7d552C948",
    // },
    // {
    //     name: "CyberKongz VX",
    //     address: "0x7EA3Cca10668B8346aeC0bf1844A49e995527c8B",
    // },
    // {
    //     name: "CyberKongz",
    //     address: "0x57a204aa1042f6e66dd7730813f4024114d74f37",
    // },
    // {
    //     name: "VeeFriends",
    //     address: "0xa3AEe8BcE55BEeA1951EF834b99f3Ac60d1ABeeB",
    // },
    // {
    //     name: "MyCryptoHeroes",
    //     address: "0x273f7F8E6489682Df756151F5525576E322d51A3",
    // },
    // {
    //     name: "CreatureWorld",
    //     address: "0xc92cedDfb8dd984A89fb494c376f9A48b999aAFc",
    // },
    // {
    //     name: "WorldOfWomen",
    //     address: "0xe785E82358879F061BC3dcAC6f0444462D4b5330",
    // },
    // {
    //     name: "EmblemVault",
    //     address: "0x50537F073416d3F478F052F5D5FbcC188CA6C075",
    // },
    // {
    //     name: "Gods Unchained",
    //     address: "0x6EbeAf8e8E946F0716E6533A6f2cefc83f60e8Ab",
    // },
    // {
    //     name: "The Doge Pound",
    //     address: "0xF4ee95274741437636e748DdAc70818B4ED7d043",
    // },
    // {
    //     name: "CryptoVoxels",
    //     address: "0x79986aF15539de2db9A5086382daEdA917A9CF0C",
    // },
    // {
    //     name: "Sneaky Vampire Syndicate",
    //     address: "0x219b8ab790decc32444a6600971c7c3718252539",
    // },
    // {
    //     name: "Autoglyphs",
    //     address: "0xd4e4078ca3495DE5B1d4dB434BEbc5a986197782",
    // },
    // {
    //     name: "Lazy Lions",
    //     address: "0x8943C7bAC1914C9A7ABa750Bf2B6B09Fd21037E0",
    // },
    // {
    //     name: "FLUF World",
    //     address: "0xCcc441ac31f02cD96C153DB6fd5Fe0a2F4e6A68d",
    // },
    // {
    //     name: "Galactic Apes",
    //     address: "0x12d2D1beD91c24f878F37E66bd829Ce7197e4d14",
    // },
    // {
    //     name: "Kaiju Kings",
    //     address: "0x1685133a98e1d4fc1fe8e25b7493d186c37b6b24",
    // },
    // {
    //     name: "Fat Ape Club",
    //     address: "0xF3114DD5c5b50a573E66596563D15A630ED359b4",
    // },
    // {
    //     name: "Vox",
    //     address: "0xad9Fd7cB4fC7A0fBCE08d64068f60CbDE22Ed34C",
    // },
    // {
    //     name: "Creature Toadz",
    //     address: "0xa4631a191044096834ce65d1ee86b16b171d8080",
    // },
    // {
    //     name: "Farmers Metaverse",
    //     address: "0xea3171718DC324D8258dCdB92268Cb62649bd0c1",
    // },
    // {
    //     name: "Shiboshis",
    //     address: "0x11450058d796B02EB53e65374be59cFf65d3FE7f",
    // },
    // {
    //     name: "Metasaurs by Dr DMT",
    //     address: "0xF7143Ba42d40EAeB49b88DaC0067e54Af042E963",
    // },
    // {
    //     name: "Party Ape Billionare Club",
    //     address: "0x5DF340b5D1618c543aC81837dA1C2d2B17b3B5d8",
    // },
    // {
    //     name: "Desperate Ape Wives",
    //     address: "0xf1268733c6fb05ef6be9cf23d24436dcd6e0b35e",
    // },
    // {
    //     name: "Mutant Cats",
    //     address: "0xaAdBA140Ae5e4c8a9eF0Cc86EA3124b446e3E46A",
    // },
    // {
    //     name: "Anonymice",
    //     address: "0xbad6186E92002E312078b5a1dAfd5ddf63d3f731",
    // },
    // {
    //     name: "Treeverse",
    //     address: "0x1B829B926a14634d36625e60165c0770C09D02b2",
    // },
    // {
    //     name: "NeoTokyo Identities",
    //     address: "0x86357A19E5537A8Fba9A004E555713BC943a66C0",
    // },
    // {
    //     name: "Forgotten Runes Wizards Club",
    //     address: "0x521f9C7505005CFA19A8E5786a9c3c9c9F5e6f42",
    // },
    // {
    //     name: "DeadFellaz",
    //     address: COLLECTION_ADDRESSES.DEAD_FELLAZ,
    // },
    // {
    //     name: "FoxFam",
    //     address: "0x7cBa74d0B16C8E18a9e48D3B7404D7739bb24F23",
    // },
    // {
    //     name: "CryptoDads",
    //     address: "0xECDD2F733bD20E56865750eBcE33f17Da0bEE461",
    // },
    // {
    //     name: "Galaxy Fight Club",
    //     address: "0x3702f4C46785BbD947d59A2516ac1ea30F2BAbF2",
    // },
    // {
    //     name: "CryptoFlyz",
    //     address: "0xf8b0a49dA21e6381f1cd3CF43445800abe852179",
    // },
    // {
    //     name: "Fluffy Polarbears",
    //     address: "0xDebBC3691d42090d899Cafe0C4ED80897A7C9d6a",
    // },
    // {
    //     name: "Yakuza Cat Society",
    //     address: "0x454cBC099079DC38b145E37e982e524AF3279c44",
    // },
    // {
    //     name: "SipherINU",
    //     address: "0x9c57D0278199c931Cf149cc769f37Bb7847091e7",
    // },
    // {
    //     name: "MetaHero",
    //     address: "0x6dc6001535e15b9def7b0f6A20a2111dFA9454E2",
    // },
    // {
    //     name: "Winter Bears",
    //     address: "0xC8BcbE0E8ae36D8f9238cd320ef6dE88784B1734",
    // },
    // {
    //     name: "Lonely Frog Lambo Club",
    //     address: "0x549d38F104AC46d856C1b2bF2A20D170EfdB2a8D",
    // },
    // {
    //     name: "Rebel Seals",
    //     address: "0xc051aAb77Edf25119b1A37740636DdBfb803C4e5",
    // },
    // {
    //     name: "OnChain Monkey",
    //     address: "0x960b7a6BCD451c9968473f7bbFd9Be826EFd549A",
    // },
    // {
    //     name: "Rumble Kong League",
    //     address: "0xEf0182dc0574cd5874494a120750FD222FdB909a",
    // },
    // {
    //     name: "Galaxy Eggs",
    //     address: "0xA08126f5E1ED91A635987071E6FF5EB2aEb67C48",
    // },
    // {
    //     name: "NFTrees",
    //     address: "0xEAA4c58427C184413b04dB47889b28b5C98EBb7b",
    // },
    // {
    //     name: "The Wanderers",
    //     address: "0x8184a482A5038B124d933B779E0Ea6e0fb72F54E",
    // },
    // {
    //     name: "Sora's Dreamworld",
    //     address: "0x4e2781e3aD94b2DfcF34c51De0D8e9358c69F296",
    // },
    // {
    //     name: "Adam Bomb Squad",
    //     address: "0x7AB2352b1D2e185560494D5e577F9D3c238b78C5",
    // },
    // {
    //     name: "The Heart Project",
    //     address: "0xce50f3cA1F1Dbd6Fa042666bC0e369565dda457D",
    // },
    // {
    //     name: "Genzee",
    //     address: "0x201675fBFAAAC3A51371E4C31FF73Ac14ceE2A5A",
    // },
    // {
    //     name: "The Crypto Moms",
    //     address: "0x99654fd49C0E51b8029d2ba7DE5b99734aB7AFEC",
    // },
    // {
    //     name: "Uninterested Unicorns",
    //     address: "0xC4a0b1E7AA137ADA8b2F911A501638088DFdD508",
    // },
    // {
    //     name: "NFT Boxes",
    //     address: "0x6d4530149e5B4483d2F7E60449C02570531A0751",
    // },
    // {
    //     name: "Purrnelopes Country Club",
    //     address: "0x9759226B2F8ddEFF81583e244Ef3bd13AAA7e4A1",
    // },
    // {
    //     name: "Ether Cards Founder Series",
    //     address: "0x97CA7FE0b0288f5EB85F386FeD876618FB9b8Ab8",
    // },
    // {
    //     name: "Cupcats",
    //     address: "0x8Cd8155e1af6AD31dd9Eec2cEd37e04145aCFCb3",
    // },
];

export const indexedTransferContracts = [
    {
        name: "The Humanoids",
        singleName: "Humanoid",
        address: COLLECTION_ADDRESSES_MAINNET.THE_HUMANOIDS,
    },
    {
        name: "Satoshibles",
        singleName: "Satoshible",
        address: COLLECTION_ADDRESSES_MAINNET.SATOSHIBLES,
    },
    {
        name: "WonderPals",
        singleName: "WonderPal",
        address: COLLECTION_ADDRESSES_MAINNET.WONDER_PALS,
    },
    {
        name: "CyberKongz",
        singleName: "CyberKongz",
        address: COLLECTION_ADDRESSES_MAINNET.CYBER_KONGZ,
    },
    {
        name: "Invisible Friends",
        singleName: "Invisible Friend",
        address: COLLECTION_ADDRESSES_MAINNET.INVISIBLE_FRIENDS,
    },
    {
        name: "Wrapped Punks V1",
        singleName: "Punk V1",
        address: COLLECTION_ADDRESSES_MAINNET.PUNKS_VERSION_ONE,
    },
    /*{
        name: "Raid Hero",
        singleName: "Raid Hero",
        address: COLLECTION_ADDRESSES_MAINNET.RAID_HERO,
    },*/
    /*{
        name: "Raid Fighters",
        singleName: "Raid Fighter",
        address: COLLECTION_ADDRESSES_MAINNET.RAID_FIGHTERS,
    },*/
    {
        name: "Karafuru",
        singleName: "Karafuru",
        address: COLLECTION_ADDRESSES_MAINNET.KARAFURU,
    },
    /*{
        name: "Chemistry Club",
        address: "0x22c36BfdCef207F9c0CC941936eff94D4246d14A",
    },*/
    // {
    //     name: "Monster Satoshibles",
    //     address: "0xeCfcf35750129322C6D746b37d6399FE9cF17e8b",
    // },
    // {
    //     name: "Alpacadabraz",
    //     address: "0x3DB5463A9e2d04334192C6f2DD4B72DeF4751A61",
    // },
    // {
    //     name: "Peaceful Groupies",
    //     address: "0x4f89Cd0CAE1e54D98db6a80150a824a533502EEa",
    // },
    // {
    //     name: "Chill Frogs",
    //     address: "0xa3b7CEe4e082183E69a03Fc03476f28b12c545A7",
    // },
    // {
    //     name: "The Surreals",
    //     address: "0xa406489360A47Af2C74fc1004316A64e469646A5",
    // },
    // {
    //     name: "CryptoHoots Steampunk Parliament",
    //     address: "0x5754f44bC96F9F0Fe1a568253452a3F40F5e9F59",
    // },
    // {
    //     name: "BYOPills",
    //     address: "0xbD275ce24f32d6cE4e9d9519C55ABe9Bc0ed7fCf",
    // },
    // {
    //     name: "Voxies",
    //     address: "0xE3435EdBf54b5126E817363900234AdFee5B3cee",
    // },
    // {
    //     name: "Goop Troop",
    //     address: "0x15A2d6C2b4B9903C27f50Cb8B32160ab17F186E2",
    // },
    // {
    //     name: "NFT Worlds",
    //     address: "0xBD4455dA5929D5639EE098ABFaa3241e9ae111Af",
    // },
    // {
    //     name: "Dysto Punks",
    //     address: "0xbEA8123277142dE42571f1fAc045225a1D347977",
    // },
];

export const peculiarContracts = [
    {
        name: "CryptoPunks",
        singleName: "CryptoPunk",
        address: COLLECTION_ADDRESSES_MAINNET.CRYPTO_PUNKS,
    },
    // {
    //   name: 'CryptoKitties',
    //   address: '0x06012c8cf97BEaD5deAe237070F9587f8E7A266d',
    // },
];

// export const getFilteredEvents = async (eventName, filter, value, contract) => {
//   const events = await contract.getPastEvents(eventName, {
//     filter: {
//       [filter]: value,
//     },
//     fromBlock: 0,
//     toBlock: 'latest',
//   });
//   return events;
// };

// const getMostRecentPunkEvents = (transfers) => {
//   const evtObj = {};
//   transfers.forEach((event) => {
//     const { punkIndex } = event.returnValues;
//     if (
//       !evtObj[punkIndex] ||
//       event.blockNumber > evtObj[punkIndex].blockNumber
//     ) {
//       evtObj[punkIndex] = event;
//     }
//   });
//   return evtObj;
// };

// export const cryptoPunksStyleFetch = async (account, address) => {
//   const connectedContract = new web3.eth.Contract(cryptoPunksABI, address);
//   const assignmentEvents = await getFilteredEvents(
//     'Assign',
//     'to',
//     account,
//     connectedContract
//   );
//   const transfersTo = await getFilteredEvents(
//     'PunkBought',
//     'toAddress',
//     account,
//     connectedContract
//   );
//   const transfersFrom = await getFilteredEvents(
//     'PunkBought',
//     'fromAddress',
//     account,
//     connectedContract
//   );
//   const toEvents = getMostRecentPunkEvents(transfersTo);
//   const fromEvents = getMostRecentPunkEvents(transfersFrom);
//   const currentlyOwnedPunkEvents = [];

//   assignmentEvents.forEach((event) => {
//     const { punkIndex } = event.returnValues;
//     const correspondingTransferFrom = fromEvents[punkIndex];
//     if (!correspondingTransferFrom) currentlyOwnedPunkEvents.push(event);
//   });

//   const toKeys = Object.keys(toEvents);
//   toKeys.forEach((key) => {
//     if (
//       !fromEvents[key] ||
//       toEvents[key].blockNumber > fromEvents[key].blockNumber
//     ) {
//       currentlyOwnedPunkEvents.push(toEvents[key]);
//     }
//   });

//   const currentlyOwnedPunksIndexes = currentlyOwnedPunkEvents.map(
//     (e) => e.returnValues.punkIndex
//   );
//   return currentlyOwnedPunksIndexes;
// };

// export const cryptoPunksFetch = (account) =>
//   cryptoPunksStyleFetch(account, '0xb47e3cd837dDF8e4c57F05d70Ab865de6e193BBB');

// export const getFilteredTransferEvents = async (filter, value, contract) =>
//   getFilteredEvents('Transfer', filter, value, contract);

// const getMostRecentEvents = (transfers) => {
//   const evtObj = {};
//   transfers.forEach((event) => {
//     const tokenId = event.returnValues.tokenId || event.raw.topics[3];
//     if (!evtObj[tokenId] || event.blockNumber > evtObj[tokenId].blockNumber) {
//       evtObj[tokenId] = event;
//     }
//   });
//   return evtObj;
// };

// const knownToBlockCors = (uri) => {
//   return (
//     uri.includes('lazylionsnft') ||
//     uri.includes('nftioexpress-oeuwa') ||
//     uri.includes('lonelyfroglamboclub') ||
//     uri.includes('boredapeyachtclub.com')
//   );
// };

// export const editMetaData = async (data) => {
//   const metadata = await Promise.all(
//     data.map(async (token) => {
//       if (token.punk || !token.tokenUri) return token;
//       let URL = token.tokenUri.includes('ipfs://')
//         ? `https://ipfs.io/ipfs/${token.tokenUri.split('//')[1]}`
//         : token.tokenUri;
//       if (token.tokenUri.includes('gateway.pinata')) {
//         URL = token.tokenUri.replace('gateway.pinata.cloud', 'ipfs.io');
//       } else if (
//         process.env.NODE_ENV === 'development' &&
//         knownToBlockCors(token.tokenUri)
//       ) {
//         URL = `https://gimmesomecors.herokuapp.com/${token.tokenUri}`;
//       }
//       const res = await axios.get(URL);
//       return { ...res.data, id: token.id };
//     })
//   );

//   const editedMetaData = metadata.map((token) => {
//     const url = token.image;
//     if (!url)
//       // TODO remove this:
//       return {
//         imageUrl: wiseLogo,
//         // ...token,
//       };
//     let imageUrl = url.includes('ipfs://')
//       ? `https://ipfs.io/ipfs/${url.split('ipfs://')[1]}`
//       : url;
//     if (url.includes('gateway.pinata')) {
//       imageUrl = url.replace('gateway.pinata.cloud', 'ipfs.io');
//     } else if (
//       process.env.NODE_ENV === 'development' &&
//       knownToBlockCors(url)
//     ) {
//       imageUrl = `https://gimmesomecors.herokuapp.com/${url}`;
//     }
//     return { imageUrl, ...token };
//   });
//   return editedMetaData;
// };

// export const indexedTransferTokenFetch = async ({
//   account,
//   nftContract,
//   underscores,
// }) => {
//   const connectedContract = new web3.eth.Contract(
//     indexedTransferABI,
//     nftContract.address
//   );

//   const transfersTo = await getFilteredTransferEvents(
//     underscores ? '_to' : 'to',
//     account,
//     connectedContract
//   );
//   const transfersFrom = await getFilteredTransferEvents(
//     underscores ? '_from' : 'from',
//     account,
//     connectedContract
//   );

//   const toEvents = getMostRecentEvents(transfersTo);
//   const fromEvents = getMostRecentEvents(transfersFrom);
//   const currentlyOwnedTokenEvents = [];
//   const toKeys = Object.keys(toEvents);
//   toKeys.forEach((key) => {
//     if (
//       !fromEvents[key] ||
//       toEvents[key].blockNumber > fromEvents[key].blockNumber
//     ) {
//       currentlyOwnedTokenEvents.push(toEvents[key]);
//     }
//   });
//   const currentlyOwnedTokenIds = currentlyOwnedTokenEvents.map(
//     (e) => e.raw.topics[3]
//   );
//   const currentlyOwnedTokens = await Promise.all(
//     currentlyOwnedTokenIds.map(async (id) => {
//       const metadata = await connectedContract.methods.tokenURI(id).call();
//       return { tokenUri: metadata };
//     })
//   );
//   const metadata = await editMetaData(currentlyOwnedTokens);

//   return metadata;
// };

export const allNftContracts = [
    ...peculiarContracts,
    ...enumerableContracts,
    ...indexedTransferContracts,
];

export const nftAddressToNameMapping = allNftContracts.reduce((obj, acc) => (
    {
        ...obj,
        [acc.address]: {
            name: acc.name,
            singleName: acc.singleName
        }
    }
), {});

export const getCollectionNameByAddress = (address = '') => {
    const collectionAddress = Object.keys(nftAddressToNameMapping)
        .find(collectionAddress =>
            collectionAddress?.trim().toLowerCase() === address?.trim().toLowerCase()
        );
    return nftAddressToNameMapping[collectionAddress];
}

export const nftNameToAddressMapping = allNftContracts.reduce(
    (obj, acc) => ({ ...obj, [acc.name]: acc.address }),
    {}
);

// const enumerableAddresses = enumerableContracts.map((c) => c.address);
const indexedTransferAddresses = indexedTransferContracts.map((c) => c.address);
const peculiarAddresses = peculiarContracts.map((c) => c.address);
export const getAbi = (address) => {
    if (indexedTransferAddresses.includes(address)) return indexedTransferABI;
    if (peculiarAddresses.includes(address)) return cryptoPunksABI;
    return enumerableABI;
    // if (enumerableAddresses.includes(address)) return enumerableABI;
};

export const isPunk = (address) => {
    const punkAddresses = [
        COLLECTION_ADDRESSES_MAINNET.CRYPTO_PUNKS,
        COLLECTION_ADDRESSES_TESTNET.CRYPTO_PUNKS,
    ];
    return punkAddresses.includes(
        address
    );
};

const checkListForNames = (collectionArray, collectionNames) => {
    let res = [];
    for (let i = 0; i < collectionArray.length; i++) {
        const element = collectionArray[i];
        if (collectionNames.includes(element.name)) {
            res.push(element.address);
        }
    }
    return res;
};

export const getTokenAddressFromName = (chainId, collectionName) => {
    if (chainId === TEST_NETWORK_ID) {
        return checkListForNames(testnetEnumerable, collectionName).concat(
            checkListForNames(testnetIndexedTransfer, collectionName),
            checkListForNames(testnetPeculiarContracts, collectionName)
        );
    } else {
        return checkListForNames(enumerableContracts, collectionName).concat(
            checkListForNames(indexedTransferContracts, collectionName),
            checkListForNames(peculiarContracts, collectionName)
        );
    }
};

export const getTokenCollections = (chainId) => {
    if (chainId === TEST_NETWORK_ID) {
        return {
            enumerable: testnetEnumerable,
            indexedTransfer: testnetIndexedTransfer,
            peculiar: testnetPeculiarContracts,
        };
    } else {
        return {
            enumerable: enumerableContracts,
            indexedTransfer: indexedTransferContracts,
            peculiar: peculiarContracts,
        };
    }
};

const allTestNetContracts = [
    ...testnetEnumerable,
    ...testnetIndexedTransfer,
    ...testnetPeculiarContracts,
];

export const getMainNetAddress = (address) => {
    const testnetVersion = allTestNetContracts.filter((c) => (
        c.address?.toLowerCase() === address?.toLowerCase()
    ))[0];
    if (!testnetVersion) return address;
    const { name } = testnetVersion;
    const mainNetVersion = allNftContracts.filter((c) => c.name === name)[0];
    if (!mainNetVersion) return address;
    return mainNetVersion.address;
};

const EXCLUDE_COLLECTION_MAINNET = [
    COLLECTION_ADDRESSES_MAINNET.THE_HUMANOIDS, // Humanoids
    COLLECTION_ADDRESSES_MAINNET.SATOSHIBLES, // Satoshibles
    COLLECTION_ADDRESSES_MAINNET.WONDER_PALS, // WonderPals
    COLLECTION_ADDRESSES_MAINNET.RAID_HERO, // Raid Hero
    COLLECTION_ADDRESSES_MAINNET.RAID_FIGHTERS, // Raid Fighters
    COLLECTION_ADDRESSES_MAINNET.KARAFURU, // Karafuru
    COLLECTION_ADDRESSES_MAINNET.CYBER_KONGZ, // CyberKongz
    // COLLECTION_ADDRESSES_MAINNET.WRAPPED_PUNKS, // WrappedPunks
];

export const PREFERRED_NFT_CONTRACTS = allNftContracts.filter((nftObj) => (
    !EXCLUDE_COLLECTION_MAINNET.includes(nftObj.address)
));

// toLowerCase to match Alchemy format
export const NFT_ADDRESSES_MAINNET = allNftContracts
    .map((obj) => obj.address?.toLowerCase());

export const NFT_ADDRESSES_GOERLI = allTestNetContracts
    .map((obj) => obj.address?.toLowerCase());


export const COLLECTION_CHAINLINK_ADDRESSES_MAINNET = {
    CRYPTO_PUNKS: "0x01B6710B01cF3dd8Ae64243097d91aFb03728Fdd",
    AZUKI: "0xA8B9A447C73191744D5B79BcE864F343455E1150",
    BORED_APES: "0x352f2Bc3039429fC2fe62004a1575aE74001CfcE",
    CLONE_X: "0x021264d59DAbD26E7506Ee7278407891Bb8CDCCc",
    COOL_CATS: "0xF49f8F5b931B0e4B4246E4CcA7cD2083997Aa83d",
    DOODLES: "0x027828052840a43Cc2D0187BcfA6e3D6AcE60336",
    MUTANT_APES: "0x1823C89715Fe3fB96A24d11c917aCA918894A090",
};

export const chainLinkContracts = [
    {
        name: "CryptoPunks",
        singleName: "CryptoPunk",
        address: COLLECTION_ADDRESSES_MAINNET.CRYPTO_PUNKS,
        chainLinkAddress: COLLECTION_CHAINLINK_ADDRESSES_MAINNET.CRYPTO_PUNKS,
    },
    {
        name: "Azuki",
        singleName: "Azuki",
        address: COLLECTION_ADDRESSES_MAINNET.AZUKI,
        chainLinkAddress: COLLECTION_CHAINLINK_ADDRESSES_MAINNET.AZUKI,
    },
    {
        name: "Bored Apes",
        singleName: "Bored Ape",
        address: COLLECTION_ADDRESSES_MAINNET.BORED_APES,
        chainLinkAddress: COLLECTION_CHAINLINK_ADDRESSES_MAINNET.BORED_APES,
    },
    {
        name: "CloneX",
        singleName: "CloneX",
        address: COLLECTION_ADDRESSES_MAINNET.CLONE_X,
        chainLinkAddress: COLLECTION_CHAINLINK_ADDRESSES_MAINNET.CLONE_X,
    },
    {
        name: "Cool Cats",
        singleName: "Cool Cat",
        address: COLLECTION_ADDRESSES_MAINNET.COOL_CATS,
        chainLinkAddress: COLLECTION_CHAINLINK_ADDRESSES_MAINNET.COOL_CATS,
    },
    {
        name: "Doodles",
        singleName: "Doodle",
        address: COLLECTION_ADDRESSES_MAINNET.DOODLES,
        chainLinkAddress: COLLECTION_CHAINLINK_ADDRESSES_MAINNET.DOODLES,
    },
    {
        name: "Mutant Apes",
        singleName: "Mutant Ape",
        address: COLLECTION_ADDRESSES_MAINNET.MUTANT_APES,
        chainLinkAddress: COLLECTION_CHAINLINK_ADDRESSES_MAINNET.MUTANT_APES,
    },
];

export const getMainNetChainLinkAddress = (address) => {
    const mainNetVersion = chainLinkContracts.filter((c) => c.address === address)[0];
    if (!mainNetVersion) return;
    return mainNetVersion.chainLinkAddress;
};
