import React, { useState, useEffect, useMemo } from "react";
import { useDispatch } from 'react-redux';
import { useEthers, shortenIfTransactionHash } from "@usedapp/core";
// import { useHistory, useLocation } from "react-router-dom";
import cn from "classnames";
import { ethers } from "ethers";
import toast, { Toaster } from "react-hot-toast";
import { formatUnits } from "ethers/lib/utils";

import { setSelectedTokenId } from "../../redux/settingsSlice";
import styles from "./Borrow.module.sass";
import Icon from "../Icon";
import Loader from "../Loader";
import useConfig from "../../customHooks/useConfig";
import Success from "../Transaction";
import { useApprovedForAll } from "../../customHooks/useApprovedForAll";
import {
    getToastSettings,
    EMPTY_ADDRESS,
    getChainById,
    MIN_CONFIRMATIONS,
    toFixedNoRound,
    DEFAULT_FIXED_DECIMALS,
    getToastErrorMessage
} from "../../utils";
import { getRouterContract } from "../../utils/query/interfaces";
import { useNftApproval } from "../../customHooks/useNftApproval";

const CONFIRM_TEXT = "Confirm in Metamask";
const APPROVING_TEXT = "Approving...";
const BORROW_TEXT = "Borrowing Funds...";
const DEFAULT_TEXT = "Proceed with Borrow";
const DONE_TEXT = 'Done';
const CONNECT_WALLET_TEXT = "Connect Wallet";

const BorrowSteps = ({
    className,
    amount,
    paymentToken,
    setInvalidAmount,
    currentTokenId,
    selectedPool,
    tokenData,
    currentTokenAddress,
    loadingData,
    transactionHash,
    setTransactionHash,
    poolTokenBalance,
    closeModal,
    title,
    noTokens,
    displayedCollections,
    cappedBorrow,
    collectionDisplayNames
}) => {

    const config = useConfig();

    // const { account, library, chainId } = useEthers();
    const { account, library, chainId, activateBrowserWallet } = useEthers();

    const chain = getChainById(chainId);
    const [loading, setLoading] = useState(false);
    const [borrowTx, setBorrowTx] = useState("");
    const [borrowedAmount, setBorrowedAmount] = useState("");
    // const history = useHistory();
    // const location = useLocation()

    const [buttonText, setButtonText] = useState(DEFAULT_TEXT);
    const [tokenApproved, setTokenApproved] = useState(false);
    const signer = library.getSigner(account);
    const dispatch = useDispatch();

    const decimals = config.decimals[paymentToken];

    const [collectionApproved] = useApprovedForAll(
        currentTokenAddress,
        account,
        config.routerAddress
    );

    useEffect(() => {

        if (collectionApproved === true && tokenApproved === false) {
            setTokenApproved(true);
        }

    }, [collectionApproved, tokenApproved]);


    const handleError = (err) => {
        toast.error(
            getToastErrorMessage(err.code),
            getToastSettings("🚫")
        );
    };

    const nftApproval = useNftApproval({
        onConfirmation: () => setButtonText(CONFIRM_TEXT),
        onApproving: (transactionHash) => {
            setButtonText(APPROVING_TEXT);
            setTransactionHash(transactionHash);
        },
        onApprovalComplete: () => setTokenApproved(true),
        onError: handleError
    })

    const doNftApproval = async () => {
        await nftApproval.call(
            currentTokenAddress,
            currentTokenId.toString()
        );
    }

    const Borrow = async () => {

        if (buttonText === DONE_TEXT) {
            closeModal();
            return;
        }

        const formattedCap = Number(
            formatUnits(
                cappedBorrow.toString(),
                decimals
            )
        );

        if (!amount || amount > formattedCap) {
            setInvalidAmount(true);
            return;
        }
        if (!poolTokenBalance) return;

        // Early return guard for max available borrow
        const tokenDecimals = config.decimals[paymentToken];

        const exceededBalance = Number(amount) > ethers.utils.formatUnits(
            poolTokenBalance.toString(),
            tokenDecimals
        );

        const exceededCap = Number(amount) > ethers.utils.formatUnits(
            cappedBorrow.toString(),
            tokenDecimals
        );

        if (exceededBalance || exceededCap) {
            setInvalidAmount(true);
            handleError({ code: "Requested Amount It Too High" });
            return;
        }

        if (collectionApproved === false) {
            setLoading(true);
            const approved = await doNftApproval()
            setLoading(false);
            if (approved === false) return;
        }

        try {

            const routerContract = getRouterContract(
                config.routerAddress,
                signer
            );

            setButtonText(
                CONFIRM_TEXT
            );

            const borrowTransaction = await routerContract.borrowFunds(
                selectedPool.address,
                ethers.utils.parseUnits(
                    amount,
                    tokenDecimals
                ),
                currentTokenAddress,
                currentTokenId,
                tokenData.index,
                tokenData.amount,
                tokenData.proof
            );

            setButtonText(
                BORROW_TEXT
            );

            setTransactionHash(
                borrowTransaction.hash
            );

            setLoading(true);

            const confirmedBorrowTx = await borrowTransaction.wait(
                MIN_CONFIRMATIONS
            );

            setLoading(false);

            // Get amount borrowed from completed tx data
            const eventsLength = confirmedBorrowTx.events.length - 1;
            const confirmedAmount = confirmedBorrowTx.events[eventsLength]?.args?.amount;

            const amountFormatted = formatUnits(
                confirmedAmount,
                tokenDecimals
            );

            const amountTruncated = toFixedNoRound(
                Number(amountFormatted),
                DEFAULT_FIXED_DECIMALS
            );

            setBorrowedAmount(
                amountTruncated
            );

            setBorrowTx(
                confirmedBorrowTx.transactionHash
            );

            if (confirmedBorrowTx) {
                // send id to redux to filter the token if successfully used
                dispatch(
                    setSelectedTokenId(
                        currentTokenId.toString()
                    )
                );
            }

            setButtonText(DONE_TEXT);

        }
        catch (err) {
            console.log("Error in Borrow", err);
            setButtonText(DEFAULT_TEXT);
            handleError(err);
        }

    }

    // dynamic styling constants
    // probably good to place in a config / class
    // duplicate found in PaybackSteps

    const iconColor = tokenApproved
        ? "#45B26B"
        : "#9757D7";

    const iconColorB = tokenApproved && (buttonText.includes("...")|| buttonText === DONE_TEXT)
        ? "#9757D7"
        : "";

    const iconName = tokenApproved
        ? 'check'
        : 'lock'

    const iconSize = tokenApproved
        ? '20'
        : '24'

    const buttonStatus = useMemo(() => {
        if (!account) return CONNECT_WALLET_TEXT;
        if (!displayedCollections) return "Checking Your Tokens...";
        if (loadingData) return "Fetching Price...";
        return buttonText
    }, [displayedCollections, loadingData, buttonText, account]);

    const disableButton = (loadingData || noTokens || !displayedCollections) && Boolean(account?.length);

    const handleBorrowOnClick = () => {
        if (account) {
            Borrow();
        } else {
            // history.push({
               // pathname: "/connect-wallet",
               // search: `?redirectTo=${location.pathname}`,
            // });
            activateBrowserWallet();
        }
    };

    // console.log(collectionDisplayNames, 'collectionDisplayNames');

    return (
        <div className={cn(className, styles.steps)}>
            <div className={styles.list}>
                {transactionHash && (
                    <div style={{marginBottom: "24px"}}>
                        <div className={styles.body} >
                            { buttonText === DONE_TEXT ? (
                                <span>
                                    Congratulations! Your borrow transaction has been confirmed and NFT sent to the liquid pool
                                </span>
                            ) : (
                                <span>
                                    Your transaction is now in progress. Follow the steps below to finalize your instant NFT loan
                                </span>
                            )}
                        </div>
                        <div className={styles.row} style={{ display: borrowTx ? "none" : "flex" }}>
                            <div>View Transaction</div>
                            <div>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={chain.getExplorerTransactionLink(transactionHash)}
                                    className={styles.link}
                                >
                                    {shortenIfTransactionHash(transactionHash)}
                                </a>
                            </div>
                        </div>
                    </div>
                )}
                { borrowTx
                    ? (
                        <Success
                            txHash={borrowTx}
                            title={`You successfully borrowed ${borrowedAmount} ${paymentToken} from the ${title} ${paymentToken} pool`}
                            showButton={false}
                            celebrate={true}
                            lockerAddress={EMPTY_ADDRESS}
                            displaySocials={false}
                            tweet={""}
                        /> )
                    : (
                        <div
                            className={styles.item}
                            style={{ display: transactionHash ? 'block' : 'none' }}
                        >
                            <div className={styles.head}>
                                <div
                                    className={styles.icon}
                                    style={{ background: iconColor, borderColor: iconColor }}
                                >
                                    <Icon name={iconName}  size={iconSize} />
                                </div>
                                <div className={styles.details}>
                                    <div className={styles.info}>
                                        Step 1 - Approve Token
                                    </div>
                                    <div className={styles.text}>
                                        Send transaction allowing LiquidNFTs router to transfer {collectionDisplayNames?.singleName} #{currentTokenId?.toString()} on your behalf to the pool
                                    </div>
                                </div>
                            </div>
                            <div className={styles.stepLine} />
                            <div className={styles.head}>
                                <div
                                    style={{ background: iconColorB, borderColor: iconColorB }}
                                    className={styles.icon}>
                                    <Icon
                                        name="wallet"
                                        size="24"
                                    />
                                </div>
                                <div className={styles.details}>
                                    <div className={styles.info}>
                                        Step 2 - Confirm Borrow
                                    </div>
                                    <div className={styles.text}>
                                        Send transaction to confirm your borrow amount
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
                <div className={styles.item2}>
                    <button
                        style={{marginTop: "24px"}}
                        onClick={handleBorrowOnClick}
                        disabled={loading || loadingData}
                        className={cn(
                            "button",
                            disableButton ? "disabled" : "",
                            styles.button,
                            (buttonText === DEFAULT_TEXT || buttonText === DONE_TEXT) ? "" : "loading",
                        )}
                    >
                        { (loading || loadingData) && <Loader className={styles.loaderSmall} color="white" /> }
                        { buttonStatus }
                    </button>
                </div>
            </div>
            <Toaster />
        </div>
    );
};

export default BorrowSteps;
