import React from "react";
import { getAddress } from "ethers/lib/utils";
import LazyImage from "./LazyImage";
import PunkImage from "./PunkImage";
import useConfig from "../../customHooks/useConfig";

const TokenImage = ({ token, size, style, useIntersection, className, ...props }) => {

    const config = useConfig();
    const { data, id, address } = token;

    const checksumAddr = address
        ? getAddress(address)
        : address;

    const collectionName = config.getCollectionNameByAddress(
        checksumAddr
    );

    const tokenIndex = id || data.punkIndex;

    const title = `${collectionName?.singleName} #${tokenIndex?.toString()}`;
    const { punk } = token.data;

    const ImageComponent = punk
        ? PunkImage
        : LazyImage;

    return (
        <ImageComponent
            className={className}
            token={{ ...token, alt: title }}
            size={size}
            style={style}
            otherprops={props}
        />
    );
};
export default TokenImage;
