// import { BigNumber } from "ethers";
import { formatUnits } from "ethers/lib/utils";
import useConfig from "../../customHooks/useConfig";
// import { dollarsFormat, produceAccurateValue } from "../../utils";
import { produceAccurateValue, getPrettyValue } from "../../utils";

const FormattedAmount = ({
    amount,
    token,
    noTruncate,
    label,
    optionalPrecision
}) => {

    const config = useConfig();

    const tokenName = config.tokenAddressToNameMapping[token];
    const tokenDecimals = config.decimals[tokenName];

    if (!amount || !amount._isBigNumber) {
        return `... ${tokenName}`;
    }

    const formatted = formatUnits(
        amount,
        tokenDecimals
    );

    const final = noTruncate
        ? getPrettyValue(formatted)
        : produceAccurateValue(
            amount,
            tokenDecimals
        );

    return `${final}${label ? " " + tokenName : ""}`;
};

export default FormattedAmount;
