import React, { useState } from "react";
import cn from "classnames";
import styles from "./NumberInput.module.sass";
import { addThousandsSeparator, removeNonNumeric } from "../../utils";

const NumberInput = ({ className, label, value, onChange, ...props }) => {
    const getFormattedValue = num => {
        return addThousandsSeparator(removeNonNumeric(num));
    }

    const [internalValue, setInternalValue] = useState(getFormattedValue(value));

    const handleOnChange = (event) => {
        const newValue = removeNonNumeric(event.target.value);

        setInternalValue(addThousandsSeparator(newValue));

        if (onChange) {
            onChange(newValue)
        }
    }

    return (
        <div className={cn(styles.field, className)}>
            {label && <div className={styles.label}>{label}</div>}
            <div className={styles.wrap}>
                <input
                    {...props}
                    type="text"
                    className={cn(styles.input, styles.important)}
                    value={internalValue}
                    onChange={handleOnChange}
                />
            </div>
        </div>
    );
};

export default NumberInput;
