import React from "react";
import { ChainId, DAppProvider } from "@usedapp/core";
import PropTypes from "prop-types";

const config = {
    readOnlyChainId: ChainId.Mainnet,
    readOnlyUrls: {
        [ChainId.Mainnet]: "https://mainnet.infura.io/v3/b17509e0e2ce45f48a44289ff1aa3c73",
        [ChainId.Goerli]: "https://goerli.infura.io/v3/b17509e0e2ce45f48a44289ff1aa3c73",
        // [ChainId.Ropsten]: "https://ropsten.infura.io/v3/b17509e0e2ce45f48a44289ff1aa3c73",
    },
    multicallVersion: 2,
    refresh: 'everyBlock',
};

const DappProvider = ({ children }) => (
    <DAppProvider config={config}>{children}</DAppProvider>
);

DappProvider.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
};

export default DappProvider;
