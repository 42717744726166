import React from "react";
import cn from "classnames";
import styles from "./Options.module.sass";
import Actions from "../../../components/Actions";
import More from "../../../components/More";
import Share from "../../../components/Share";

const Options = ({ className, listingDetails, items, tokenIndex }) => {
    return (
        <div className={cn(styles.options, className)}>
            <Share
                className={styles.actions}
                listingDetails={listingDetails}
            />
            <div className={styles.main}>
                <Actions
                    items={items}
                    className={styles.actions}
                    listingDetails={listingDetails}
                />
            </div>
            <More
                tokenIndex={tokenIndex}
                className={styles.actions}
                listingDetails={listingDetails}
            />
        </div>
    );
};

export default Options;
