import React from "react";
import cn from "classnames";
import { useEthers } from "@usedapp/core";

import styles from "./Preview.module.sass";
import Icon from "../../../components/Icon";
import useConfig from "../../../customHooks/useConfig";
import TokenImage from "../../../components/TokenImage";
import { dollarsFormat, SECONDS_IN_DAY } from "../../../utils";
import TipHolder from "../../../components/TipHolder/TipHolder";
import Avatar from "../../../components/Avatar";

const Preview = ({
    className,
    onClose,
    loanData,
    tokens,
    showPreview,
    visiblePreview
}) => {

    const {
        selectedTokenIds,
        selectedCollection,
        paymentToken,
        minimumAmount,
        maximumAmount,
        // amountRange,
        borrowingRate,
        borrowingTime,
    } = loanData;

    const config = useConfig();
    const { account } = useEthers();

    const borrowingSeconds = parseInt(borrowingTime) * SECONDS_IN_DAY;
    const collectionNames = config.getCollectionNameByAddress(selectedCollection) || "Tokens";

    const mostRecentlySelectedToken = tokens.filter((t) => {
        const id = selectedTokenIds[selectedTokenIds.length - 1];
        const matchingId = t.data.punk ? id === t.data.punkIndex : id === t.id;
        return t.address === selectedCollection && matchingId;
    })[0];

    const ids = selectedTokenIds
    const title = ids?.length === 1
        ? `${collectionNames?.singleName} #${ids[0].toString()}`
        : `${ids?.length} ${collectionNames?.name?.replace("The ", "")}`;

    return (
        <div
            style={{ display: !showPreview && !visiblePreview ? "none" : "" }}
            className={cn(className, styles.wrap)}
        >
            <div className={styles.inner}>
                <button className={styles.close} onClick={onClose}>
                    <Icon name="close" size="14" />
                </button>
                <div className={styles.category}>
                    <p style={{ marginLeft: "5px" }}>
                        Collateral Preview
                    </p>
                </div>
                <div style={{ marginBottom: "30px" }} className={styles.text}>
                    This is how contributors will see your loan
                </div>
                <div className={styles.card}>
                    <div className={styles.preview}>
                        {mostRecentlySelectedToken ? (
                            <TokenImage style={{background: "#508394"}} token={mostRecentlySelectedToken} size="260" />
                        ) : (
                            <div className={styles.imgPlaceholder} />
                        )}
                        { selectedTokenIds?.length > 1 && (
                            <div className={styles.bundle}>
                                <div className={'status-main'}>
                                    bundle
                                </div>
                            </div>
                        )}
                    </div>
                    <div className={styles.link}>
                        <div className={styles.body}>
                            <div className={styles.line}>
                                <div className={styles.title}>
                                    {title}
                                </div>
                                <TipHolder
                                    borrowingRate={borrowingRate}
                                    borrowingTime={borrowingSeconds}
                                />
                            </div>
                            <div className={styles.line}>
                                <div className={styles.users}>
                                    <Avatar
                                        className={styles.avatar}
                                        address={account}
                                        showAddress={true}
                                    />
                                </div>
                                <div className={styles.counter}>
                                    {parseInt(borrowingTime)} Day Loan
                                </div>
                            </div>
                        </div>
                        <div className={styles.foot}>
                            <div className={styles.status}>
                                <div>Floor asked</div>
                                <span>
                                    {dollarsFormat(minimumAmount) || 0} {paymentToken}
                                </span>
                            </div>
                            <div className={cn(styles.status, styles.right)}>
                                <div>Total asked</div>
                                <span>
                                    {dollarsFormat(maximumAmount) || 0} {paymentToken}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Preview;
