import { useState, useEffect, useMemo } from "react";
import axios from "axios";
import { useEthers } from "@usedapp/core";
import { NFT_ADDRESSES_GOERLI, NFT_ADDRESSES_MAINNET, getMainNetAddress } from "../ethers/nftCollections";
import { BigNumber } from "ethers";
import { TEST_NETWORK_ID } from "../utils";
// import { OPENSEA_API, OPENSEA_API_KEY } from "../utils/config";
import useEnumerableAddressTokens from "./useEnumerableAddressTokens";
import useIndexedAddressTokens from "./useIndexedAddressTokens";
import usePunkedAddressTokens from "./usePunkedTokenAddress";

export const getMetaData = async (collections, chainId, userAccount) => {
    // console.log(collections, 'collections');
    const results = await Promise.all(
        Object.keys(collections).map(async (address) => {
            // console.log(address, 'address--');
            const collection = collections[address];
            // console.log(collection, 'collection--');

            const size = 30;
            const chunks = [];

            while (collection.length) {
                chunks.push(
                    collection.splice(
                        0,
                        size
                    )
                );
            }
            // console.log(chunks.length, 'll');
            const responseChunks = await Promise.all(
                chunks.map(async (chunk) => {

                    const contractAddress = chainId === TEST_NETWORK_ID
                        ? getMainNetAddress(address)
                        : address;

                    // console.log(contractAddress, 'contractAddress');
                    const base = "https://backend.liquidnfts.com";
                    const opensea = `${base}/wallet/${userAccount}/${contractAddress}`;

                    const res = await axios.get(
                        opensea,
                    );

                    const { assets } = res.data;

                    const dataChunk = assets && assets.map((a) => ({
                        address,
                        id: BigNumber.from(a.token_id),
                        data: { image: a.image_url },
                        owner: userAccount,
                    }));

                    return dataChunk || [];
                })
            );
            const flattened = responseChunks.flat();
            return flattened;
        })
    );
    return results;
};

export const parseLog = (log, contractInterface) => ({
    blockNumber: log.blockNumber,
    ...contractInterface.parseLog(log),
});

const useTokenFetch = (optionalAddress) => {

    const { account, chainId } = useEthers();
    const [tokens, setTokens] = useState([]);

    const userAccount = optionalAddress
        ? optionalAddress
        : account;

    const supportedNFTAddresses = chainId === TEST_NETWORK_ID
        ? NFT_ADDRESSES_GOERLI
        : NFT_ADDRESSES_MAINNET;

    const {
        loading: isEnumerableAddressTokensLoading,
        enumerableAddressTokens,
        refetch: refetchEnumerableTokens
    } = useEnumerableAddressTokens(userAccount, chainId);

    const {
        loading: isIndexedAddressTokensLoading,
        indexedAddressTokens,
        refetch: refetchIndexedAddressTokens
    } = useIndexedAddressTokens(userAccount, chainId);

    const {
        loading: isPunkedAddressTokensLoading,
        punkedAddressTokens,
        refetch: refetchPunkedAddressTokens
    } = usePunkedAddressTokens(userAccount, chainId);

    /* punk whale address for testing mainnet: */
    // const userAccount = "0xc352b534e8b987e036a93539fd6897f53488e56a";

    // const enumAddresses = enumerable.map((e) => e.address);
    // const indexedAddresses = indexedTransfer.map((i) => i.address);

    const refetch = () => {
        refetchEnumerableTokens();
        refetchIndexedAddressTokens();
        refetchPunkedAddressTokens();
    }

    const isLoading = useMemo(() =>
        isEnumerableAddressTokensLoading
        || isIndexedAddressTokensLoading
        || isPunkedAddressTokensLoading,
        [
            isEnumerableAddressTokensLoading,
            isIndexedAddressTokensLoading,
            isPunkedAddressTokensLoading,
        ]);

    const addSupportedAttribute = (collection = []) => {
        return collection?.map(item => {
            const isSupported = supportedNFTAddresses.find(
                (addr) => addr.toLowerCase() === item.address.toLowerCase()
            );
            return {
                ...item,
                isSupported
            }
        })
    }

    useEffect(() => {
        let mounted = true;
        if (mounted) {
            setTokens([
                ...addSupportedAttribute(enumerableAddressTokens),
                ...addSupportedAttribute(indexedAddressTokens),
                ...addSupportedAttribute(punkedAddressTokens)
            ]);
        }
        return () => { mounted = false; }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        enumerableAddressTokens,
        indexedAddressTokens,
        punkedAddressTokens
    ]);

    return {
        loading: isLoading,
        tokens,
        refetch
    };
};

export default useTokenFetch;
