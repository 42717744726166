import React, { useState } from "react";
import cn from "classnames";
import styles from "./DeployPool.module.sass";
import TextInput from "../../components/TextInput";
import { Contract } from "ethers";
import { useEthers } from "@usedapp/core";

import INSTANT_FACTORY_ABI from "../../ethers/abis/PoolFactory.json";

const DeployPool = () => {

    const [nftContractAddress, setNftContractAddress] = useState("0x0");
    const [erc20ContractAddress, setErc20ContractAddress] = useState("0x0");
    const [maxBorrow, setMaxBorrow] = useState(0);
    const [multiplicationFactor, setMultiplicationFactor] = useState(0);
    const [collateralFactor, setCollateralFactor] = useState(0);
    const [merkleRoot, setMerkleRoot] = useState("0x0");
    const [ipfsUrl, setIpfsUrl] = useState("");
    const [poolTokenName, setPoolTokenName] = useState("");
    const [poolTokenSymbol, setPoolTokenSymbol] = useState("");
    const [lastDeployedPool, setLastDeployedPool] = useState("");

    const { library } = useEthers();

    const handleDeployPool = async () => {

        const factoryContract = new Contract(
            "0x94b12CCeb498Abf201bb9A30614446B5fcE44802", //@TODO: move to config
            INSTANT_FACTORY_ABI,
            library.getSigner()
        );

        const poolCount = await factoryContract.poolCount();
        const poolTarget = await factoryContract.defaultPoolTarget();

        await factoryContract.createLiquidPool(
            erc20ContractAddress,
            maxBorrow,
            multiplicationFactor,
            collateralFactor,
            nftContractAddress,            
            merkleRoot,
            ipfsUrl,
            poolTokenName,
            poolTokenSymbol
        );

        const poolAddress = await factoryContract.predictPoolAddress(
            poolCount,
            erc20ContractAddress,
            nftContractAddress,
            factoryContract.address,
            poolTarget
        );

        setLastDeployedPool(
            poolAddress
        );
    }

    return (
        <div className={styles.page}>
            <div className={cn("container", styles.container, "create-holder")}>
                <h1>
                    Deploy Instant Pools
                </h1>
                <TextInput
                    className={styles.field}
                    placeholder="NFT Contract Address"
                    onChange={( e ) => setNftContractAddress(e.target.value)}
                />
                <TextInput
                    className={styles.field}
                    placeholder="ERC20 for Borrowing Contract Address"
                    onChange={( e ) => setErc20ContractAddress(e.target.value)}
                />
                <TextInput
                    className={styles.field}
                    placeholder="Maximum Borrow for Pool"
                    onChange={( e ) => setMaxBorrow(e.target.value)}
                />
                <TextInput
                    className={styles.field}
                    placeholder="Multiplication Factor"
                    onChange={( e ) => setMultiplicationFactor(e.target.value)}
                />
                <TextInput
                    className={styles.field}
                    placeholder="Collateral Factor"
                    onChange={( e ) => setCollateralFactor(e.target.value)}
                />
                <TextInput
                    className={styles.field}
                    placeholder="Merkle Root"
                    onChange={( e ) => setMerkleRoot(e.target.value)}
                />
                <TextInput
                    className={styles.field}
                    placeholder="Merkle Tree IPFS URL"
                    onChange={( e ) => setIpfsUrl(e.target.value)}
                />
                <h2>
                    Pool Share Token Information
                </h2>
                <TextInput
                    className={styles.field}
                    placeholder="Pool Name"
                    onChange={( e ) => setPoolTokenName(e.target.value)}
                />
                <TextInput
                    className={styles.field}
                    placeholder="Pool Symbol"
                    onChange = {( e ) => setPoolTokenSymbol(e.target.value)}
                    value={poolTokenSymbol}
                />
                <button
                    className={cn("button", styles.button)}
                    onClick = {handleDeployPool}
                >
                    Deploy Instant Pool
                </button>
                <br />
                <br />
                {lastDeployedPool && (
                    <h2>Address of last deployed pool: {lastDeployedPool}</h2>
                )}
            </div>
        </div>
    );
};

export default DeployPool;
