import { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import cn from "classnames";
import { setPricingPreferences } from "../../redux/settingsSlice";

import {
    PINATA_CLOUD_URL,
    shortenHash
} from "../../utils";

import useGetPricingData from "../../customHooks/useGetPricingData";
import { PricingTree } from "../PricingTree";
import Modal from "../Modal"
import Dropdown from "../Dropdown";
import Checkbox from "../Checkbox";
import Loader from "../Loader";
import Icon from "../Icon";

import styles from "./PricingTreeModal.module.sass";
import { appLocalStorage } from "../../utils/localstorage";

const TRAIT_TOGGLE = "trait";
const USDC_TOGGLE = "usdc";

const PricingTreeModal = ({
    defaultIpfsHash,
    visibleModal,
    onClose,
    item,
    supportedCollections
}) => {

    const dispatch = useDispatch();
    const [selectedIpfsHash, setSelectedIpfsHash] = useState();
    const [selectedCollection, setSelectedCollection] = useState();
    const [selectedUpcomingIpfsHash, setSelectedUpcomingIpfsHash] = useState();

    const pricingData = useGetPricingData(
        selectedIpfsHash
    );

    const upcomingPricingData = useGetPricingData(
        selectedUpcomingIpfsHash
    );

    const showAllTraits = useSelector(
        (state) => state.settings.pricingPreferences?.showAllTraits
    );

    const showUsdcPrices = useSelector(
        (state) => state.settings.pricingPreferences?.showUsdcPrices
    );

    const ipfsCollectionOptions = useMemo(() => {
        return supportedCollections?.map(
            (collection) => ({
                value: collection.ipfsHash,
                label: collection.collection,
            })
        );
    }, [supportedCollections]);

    const getUpcomingIpfsHash = ipfsHash => {
        return supportedCollections
            ?.find(collection =>
                collection.ipfsHash === ipfsHash
            )?.pendingIpfsHash;
    }

    const handleOnCollectionChange = (value, index) => {
        const selectedIpfsCollection = ipfsCollectionOptions?.[index];
        setSelectedCollection(value);
        setSelectedIpfsHash(
            selectedIpfsCollection?.value
        );
        setSelectedUpcomingIpfsHash(
            getUpcomingIpfsHash(
                selectedIpfsCollection?.value
            )
        );
    }

    const handleToggle = (toggleType) => {
        const newConfig = {
            showAllTraits: toggleType === TRAIT_TOGGLE ? !showAllTraits : showAllTraits,
            showUsdcPrices: toggleType === USDC_TOGGLE ? !showUsdcPrices : showUsdcPrices
        };
        dispatch(
            setPricingPreferences(
                newConfig
            )
        );
        appLocalStorage.PRICING_OPTIONS.update(
            newConfig
        );
    }

    const verifyModalContent = useMemo(() => {
        if (!pricingData) {
            return (
                <div className={styles.loader}>
                    <Loader />
                </div>
            );
        }
        return (
            <div className={styles.main}>
                <PricingTree
                    pricingData={pricingData}
                    upcomingPricingData={upcomingPricingData}
                    tokenName={item.tokenName}
                    showAllTraits={showAllTraits}
                    showUsdcPrices={showUsdcPrices}
                />
            </div>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        pricingData,
        upcomingPricingData,
        showAllTraits,
        showUsdcPrices
    ]);

    useEffect(() => {
        if (defaultIpfsHash) {
            setSelectedIpfsHash(
                defaultIpfsHash
            );
            const selectedCollection = ipfsCollectionOptions?.find(
                (option) => {
                    return option.value === defaultIpfsHash
                }
            )?.label;


            setSelectedUpcomingIpfsHash(
                getUpcomingIpfsHash(
                    defaultIpfsHash
                )
            )
            setSelectedCollection(
                selectedCollection
            );
            return;
        }
        setSelectedIpfsHash(
            item?.ipfsHash
        );
        setSelectedUpcomingIpfsHash(
            getUpcomingIpfsHash(
                item?.ipfsHash
            )
        )
        setSelectedCollection(
            ipfsCollectionOptions?.find(
                (option) => {
                    return option.value === item?.ipfsHash
                }
            )?.label
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visibleModal, defaultIpfsHash]);

    return (
        <Modal visible={visibleModal} onClose={onClose} outerClassName={"extended"}>
            <div className={cn("h4", styles.title)}>
                Token Trait Values
            </div>
            <div className={styles.field}>
                <div className={styles.label}>
                    Select NFT Collection
                </div>
                <Dropdown
                    value={selectedCollection}
                    options={
                        ipfsCollectionOptions?.map(option => option.label)
                        || []
                    }
                    setValue={handleOnCollectionChange}
                />
            </div>
            <Checkbox
                className={styles.checkbox}
                value={showAllTraits}
                onChange={() => handleToggle(TRAIT_TOGGLE)}
                content="Show All Traits"
            />
            <Checkbox
                className={styles.checkbox}
                value={showUsdcPrices}
                onChange={() => handleToggle(USDC_TOGGLE)}
                content="Show USDC Prices"
            />
            {verifyModalContent}
            <div className={styles.source}>
                <a
                    target="_blank"
                    rel="noreferrer"
                    href={`${PINATA_CLOUD_URL}/ipfs/${selectedIpfsHash}`}
                >
                    Pricing by LiquiqNFTs stored at {shortenHash(selectedIpfsHash)}
                    <span className={styles.icon}>
                        <Icon name="external" size="20" viewBoxLarge={true} />
                    </span>
                </a>
            </div>
        </Modal>
    )
}

export default PricingTreeModal;
