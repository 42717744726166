import { useTokenBalance } from "@usedapp/core";
import { useEffect, useState } from "react";
import { getPoolContract } from "../utils/query/interfaces";
import useCallsGracefully from "./useCallsGracefully";

export const usePoolGeneralInfo = (
    poolAddress
) => {

    const poolContract = getPoolContract(
        poolAddress
    );

    const utilizationCall = {
        contract: poolContract,
        method: "utilization",
        args: [],
    };

    const depositApyCall = {
        contract: poolContract,
        method: "getCurrentDepositAPY",
        args: [],
    };

    const pseudoTotalTokensHeldCall = {
        contract: poolContract,
        method: "pseudoTotalTokensHeld",
        args: [],
    };

    const totalInternalSharesCall = {
        contract: poolContract,
        method: "totalInternalShares",
        args: [],
    };

    const markovMeanCall = {
        contract: poolContract,
        method: "markovMean",
        args: [],
    };

    const poolTokenCall = {
        contract: poolContract,
        method: "poolToken",
        args: [],
    };

    const borrowRateCall = {
        contract: poolContract,
        method: "borrowRate",
        args: [],
    };

    const [
        utilization,
        depositApy,
        pseudoTotalTokensHeld,
        totalInternalShares,
        markovMean,
        poolToken,
        borrowRate
    ] = useCallsGracefully(
        poolAddress && poolContract
            ? [
                utilizationCall,
                depositApyCall,
                pseudoTotalTokensHeldCall,
                totalInternalSharesCall,
                markovMeanCall,
                poolTokenCall,
                borrowRateCall
            ]
            : []
    );

    const haveParams = poolToken && poolAddress;

    const tokensHeld = useTokenBalance(
        poolToken,
        poolAddress
    );

    const [tokenDebt, setTokenDebt] = useState("0");

    useEffect(() => {
        setTokenDebt(
            haveParams
            && tokensHeld
            && pseudoTotalTokensHeld
            && pseudoTotalTokensHeld.sub(tokensHeld)
        );
    }, [
        tokensHeld,
        haveParams,
        pseudoTotalTokensHeld
    ]);

    return {
        utilization,
        depositApy,
        pseudoTotalTokensHeld,
        totalInternalShares,
        markovMean,
        tokensHeld,
        tokenDebt,
        poolToken,
        borrowRate,
        // maxCollateralFactor
    };
};
