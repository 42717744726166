import React, { useEffect } from "react";
import cn from "classnames";
import { useHistory } from "react-router-dom";
import styles from "./Hero.module.sass";
// import ScrollButton from "../../../components/ScrollButton";
import Image from "../../../components/Image";
// import ScrollParallax from "../../../components/ScrollParallax";
// import ComingSoon from "../../../components/ComingSoon";
// import Modal from "../../../components/Modal";
// import { READ_CONTRACT_LINK } from "../../../utils";
import Modal from "../../../components/Modal";
import { useState } from "react";
import Borrow from "../../../components/Borrow";
import supportedPools from "../../InstantPools/Pools/data";
// import useGetOwnerNFTs from "../../../customHooks/useGetOwnerNFTs";
import { processCollections } from "../../../utils/query/tokens";
import useConfig from "../../../customHooks/useConfig";
import { useEthers } from "@usedapp/core";
import useTokenFetch from "../../../customHooks/useTokenFetch";
// import ButtonTVL from "../../../components/ButtonTVL";

const Hero = ({ scrollToRef }) => {
    const { chainId } = useEthers();


    // const [visibleModalSoonA, setVisibleModalSoonA] = useState(false);
    // const [visibleModalSoonB, setVisibleModalSoonB] = useState(false);
    const [borrowFromPool, setBorrowfromPool] = useState();

    const config = useConfig();

    const history = useHistory();

    const wethPool = supportedPools?.find(pool => pool.title !== "Old" && pool.currency === "WETH" && pool.chainId === chainId);
    const usdcPool = supportedPools?.find(pool => pool.title !== "Old" && pool.currency === "USDC" && pool.chainId === chainId);

    // const { tokens } = useGetOwnerNFTs();

    const { tokens, refetch: refetchCollectionsData, loading: isCollectionsLoading } = useTokenFetch();

    const collectionsData = processCollections(
        tokens,
        config,
        false
    );

    useEffect(
        () => setBorrowfromPool(null),
        [chainId]
    );

    return (
        <div className={styles.hero}>
            <div className={styles.pool}></div>
            <div className={cn("container", styles.container)}>
                <div className={styles.wrap}>
                    {/*<div className={cn("stage", styles.stage)}>
                        Something awesome coming soon
                    </div>*/}
                    <h1 className={cn("h1", styles.title)}>
                        Get Instant Loans For NFTs
                    </h1>
                    <div className={styles.text}>
                        Borrow funds against the value of your NFTs from WETH or USDC liquidity pools
                    </div>
                    <div className={styles.buttonWrapper}>
                        <div className={styles.buttonParent}>
                            <div className={styles.btns}>
                                <button className={cn("button", styles.button)} onClick={() => setBorrowfromPool(wethPool)}>
                                    Borrow WETH Funds
                                    {/*Subscribe Now*/}
                                </button>
                                <button className={cn("button-stroke", styles.button)} onClick={() => setBorrowfromPool(usdcPool)}>
                                    Borrow USDC Funds
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                {/*<ScrollButton
                    onScroll={() =>
                        scrollToRef.current.scrollIntoView({ behavior: "smooth" })
                    }
                    className={styles.scroll}
                />*/}
                <div className={styles.gallery}>
                    <div className={styles.preview}>
                        <Image
                            srcSet="/images/content/investment-new.png"
                            srcSetDark="/images/content/investment-new.png"
                            src="/images/content/investment-new.png"
                            srcDark="/images/content/investment-new.png"
                            alt="Bank"
                        />
                    </div>
                    {/*<ScrollParallax
                        style={{visibility: "hidden"}}
                        className={styles.preview}
                        animateIn="fadeInUp"
                        delay={300}
                    >
                        <img
                            srcSet="/images/content/drop-new.png"
                            src="/images/content/drop-new.png"
                            alt="Drop"
                        />
                    </ScrollParallax>*/}
                    {/*<ScrollParallax
                        className={styles.preview}
                        animateIn="fadeInUp"
                        delay={600}
                    >
                        <img
                            srcSet="/images/content/coins.png"
                            src="/images/content/coins.png"
                            alt="small"
                        />
                    </ScrollParallax>*/}
                </div>
                <div className={styles.gallery} style={{zIndex: 5}}>
                    <div style={{visibility: "hidden"}} className={styles.preview}>
                        <Image
                            srcSet="/images/content/investment-new.png"
                            srcSetDark="/images/content/investment-new.png"
                            src="/images/content/investment-new.png"
                            srcDark="/images/content/investment-new.png"
                            alt="Bank"
                        />
                    </div>
                    {/*<ScrollParallax
                        className={styles.preview}
                        animateIn="fadeInUp"
                        delay={300}

                    >
                        <img
                            srcSet="/images/content/drop-new.png"
                            src="/images/content/drop-new.png"
                            alt="Drop"
                        />
                    </ScrollParallax>*/}
                    {/*<ScrollParallax
                        className={styles.preview}
                        animateIn="fadeInUp"
                        delay={600}
                    >
                        <img
                            srcSet="/images/content/coins.png"
                            src="/images/content/coins.png"
                            alt="small"
                        />
                    </ScrollParallax>*/}
                </div>
            </div>
            {/*<Modal visible={visibleModalSoonA} onClose={() => setVisibleModalSoonA(false)}>
                <ComingSoon featureDescription={"This will allow you to borrow funds instantly from liquidity pools against value of you NFTs"} />
            </Modal>*/}
            <Modal
                visible={borrowFromPool}
                // outerClassName={"extended"}
                onClose={() => setBorrowfromPool(null)}
            >
                {
                    borrowFromPool && (
                        <Borrow
                            poolAddress={borrowFromPool.address}
                            paymentToken={borrowFromPool.currency}
                            title={borrowFromPool.title}
                            closeModal={() => {
                                setBorrowfromPool(null);
                                history.push('/profile/instant-loans');
                            }}
                            collectionData={collectionsData}
                            onRefreshIconClick={refetchCollectionsData}
                            isCollectionsLoading={isCollectionsLoading}
                            selectedPool={borrowFromPool}
                            selectedCurrency={borrowFromPool.currency}
                            supportedCollections={borrowFromPool.supportedCollections}
                        />
                    )
                }
            </Modal>
        </div>
    );
};

export default Hero;
