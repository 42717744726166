import axios from "axios";
import { formatEther } from "ethers/lib/utils";
import supportedPools from "../../screens/InstantPools/Pools/data";

import { getMainNetAddress } from "../../ethers/nftCollections";
import { OPENSEA_API, OPENSEA_API_KEY } from "../config";
import { RARIFY_BASE_URL } from "../index";

const RARIFY_API_KEY = process.env.REACT_APP_RARIFY_API_KEY;

// TEMPORARY SUPPORTED POOL IN WALLET
const DEFAULT_SELECTED_POOL_MAINNET = '0x3f302D860b27FB06C794eE0916abC1D3A362Bf7e';
const DEFAULT_SELECTED_POOL_TESTNET = '0xDdd66B3bcc22103b58518974DA66F3BF7EF53DD5';

export const getCollectionData = async (tokensAddress) => {
    try {
        const { data: contractData } = await axios.get(
            `${OPENSEA_API}/api/v1/asset_contract/${tokensAddress}`,
            {
                crossdomain: true,
                headers: {
                    "X-API-KEY": OPENSEA_API_KEY,
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
                }
            }
        );
        const {
            collection: { slug },
            // image_url,
        } = contractData;

        const { data: collectionData } = await axios.get(
            `${OPENSEA_API}/api/v1/collection/${slug}/`,
            {
                crossdomain: true,
                headers: {
                    "X-API-KEY": OPENSEA_API_KEY,
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
                }
            }
        );

        const collectionObject = collectionData.collection;

        return {
            name: collectionObject.name,
            floorPrice: collectionObject.stats.floor_price,
            imageUrl: collectionObject.featured_image_url
        };
    } catch (err) {
        console.log(err);
        return null;
    }
};

export const processCollections = (tokens, config, notUser) => {
    return tokens.reduce((prev, token) => {

        const {
            data,
            address,
            name,
            isSupported
        } = token;

        // Ensure address strings match accross apis/references
        const collectionNameKey = Object.keys(config.nftAddressToNameMapping)
            .find((addr) => addr.toLowerCase() === address?.toLowerCase());

        const collectionNames = config.nftAddressToNameMapping[collectionNameKey] || name;

        const pools = supportedPools
            ?.filter(pool =>
                pool.supportedCollections?.includes(collectionNames?.name)
                && config.chainId === pool.chainId
                && [DEFAULT_SELECTED_POOL_MAINNET, DEFAULT_SELECTED_POOL_TESTNET].includes(pool.address)
            )?.map(pool => pool.address);

        const existingCollectionIndex = prev.findIndex(
            (collection) => collection.address?.trim()?.toLowerCase() === address?.trim()?.toLowerCase()
        );

        const prefix = notUser
            ? ''
            : 'Found ';

        if (existingCollectionIndex !== -1) {

            if (
                prev[existingCollectionIndex]
                    ?.gallery.some(existingToken =>
                        existingToken.id.toString() === token.id.toString())
            ) return prev;

            const existingCollection = prev[existingCollectionIndex];
            const increment = 1 + existingCollection.count++;

            existingCollection.counter = `${prefix}${increment} Token${increment > 1 ? "s" : ""}`;

            existingCollection.gallery.push(
                token
            );

            return prev;
        }

        return [
            ...prev,
            {
                address,
                name: collectionNames?.name,
                count: 1,
                counter: `${prefix}1 Token`,
                buttonClass: "stroke",
                buttonContent: pools?.length
                    ? "Create Instant Loan"
                    : "Create Custom Loan",
                avatar: token,
                gallery: [token],
                punk: !!data.punk,
                isSupported,
                pools
            },
        ];
    }, []);
};

export const getRarifySmartFloor = async (collectionAddress) => {
    try {

        const url = `${RARIFY_BASE_URL}contracts/ethereum:${collectionAddress}/smart-floor-price`;

        const config = {
            method: 'GET',
            url,
            headers: {
                'Authorization': `Bearer ${RARIFY_API_KEY}`
            }
        };

        const response = await axios(
            config
        );

        return formatEther(
            response.data?.data?.attributes?.price
        );

    } catch (err) {
        console.log(err);
        return 0;
    }
};

export const getNftImage = async (address, tokenId) => {
    try {

        const contractAddress = getMainNetAddress(address);

        const opensea = `${OPENSEA_API}/api/v1/asset/${contractAddress}/${tokenId}/?include_orders=false`;

        const result = await axios.get(opensea, {
            crossdomain: true,
            headers: {
                "X-API-KEY": OPENSEA_API_KEY,
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
            }
        });

        return result?.data?.image_url;

    } catch(err) {
        console.log(err);
        return null;
    }
};
