import React, { useState, useEffect, useMemo, useRef } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import { useEthers, useTokenBalance } from '@usedapp/core';
import CurrencyInput from 'react-currency-input-field';
import FormattedAmount from "../FormattedAmount";
import { formatUnits, getAddress } from "ethers/lib/utils";
import { BigNumber, Contract, ethers } from "ethers";
import axios from "axios";
import POOL_ABI from "../../ethers/abis/LiquidPool.json";
// import noDataImg from "../../assets/images/icons/searching-data.svg";
import noDataImg from "../../assets/images/icons/wallet-small.png";
import useConfig from "../../customHooks/useConfig";
import styles from "./Borrow.module.sass";
import BorrowSteps from "./BorrowSteps";
import Loader from "../Loader";
import {
    PERCENT_OPTIONS,
    BACKEND_URL,
    // DAYS_IN_YEAR,
    // PAYBACK_TIME,
    // BORROW_RATE_DECIMAL,
    ONE_HUNDRED,
    getDeadline,
    getPreciseValue,
    COLLECTIONS_DROPDOWN_LIST,
    getCollectionNameFromAddress,
    FETCHING_DATA_MESSAGE,
} from "../../utils";

import Collections from "../../screens/Profile/Collections";
import { getRouterContract } from "../../utils/query/interfaces";
import useGetPoolRates from "../../customHooks/useGetPoolRates";

const EMPTY_DATA = {
    index: 0,
    proof: [],
    amount: 0
};

const Borrow = ({
    poolAddress,
    paymentToken,
    closeModal,
    selectedPool,
    selectedCurrency,
    displayedCollections,
    title,
    defaultSelectedToken,
    showPoolDescription = false,
    onRefreshIconClick,
    isCollectionsLoading
}) => {

    //const tokenPreferences = useSelector((state) => state.settings.tokenPreferences);
    //const collectionPreferences = useSelector((state) => state.settings.collectionPreferences);

    const config = useConfig();
    const accountRef = useRef();
    const { account, library, chainId } = useEthers();
    const [loading, setLoading] = useState(false);
    const [transactionHash, setTransactionHash] = useState("");
    const [amount, setAmount] = useState();
    const [activeQuickSelect, setActiveQuickSelect] = useState();
    const [invalidAmount, setInvalidAmount] = useState(false);

    const [currentTokenId, setCurrentTokenId] = useState(
        defaultSelectedToken?.tokenId
    );

    const [currentTokenAddress, setCurrentTokenAddress] = useState(
        defaultSelectedToken?.tokenAddress
    );

    const [tokenData, setTokenData] = useState(EMPTY_DATA);
    const [maxBorrow, setMaxBorrow] = useState(0);
    // const [init, setInit] = useState(false);

    const decimals = config.decimals[
        paymentToken
    ];

    useEffect(() => {

        const getMaxBorrow = async () => {

            const poolContract = new Contract(
                poolAddress,
                POOL_ABI,
                library
            );

            const deadline = getDeadline();

            const trueMaxBorrow = await poolContract.getBorrowMaximum(
                currentTokenAddress,
                currentTokenId,
                tokenData.amount,
                deadline
            );

            setMaxBorrow(
                trueMaxBorrow
            );
        }

        const params = currentTokenAddress
            && currentTokenId
            && tokenData.amount
            && poolAddress;

        if (params) getMaxBorrow();

    }, [
        poolAddress,
        currentTokenAddress,
        currentTokenId,
        tokenData.amount,
        library
    ]);

    const paymentTokenAddr = config.paymentTokens[
        selectedCurrency
    ];

    const poolTokenBalance = useTokenBalance(
        paymentTokenAddr,
        selectedPool.address
    );

    const poolRates = useGetPoolRates(
        selectedPool.address
    );

    const cappedBorrow = useMemo(() => {

        if (!poolTokenBalance || !maxBorrow) return "0.00";

        return maxBorrow.gt(poolTokenBalance)
            ? poolTokenBalance
            : maxBorrow;

    }, [poolTokenBalance, maxBorrow]);

    // console.log(selectedPool, 'selectedPool');

    const loanInfo = useMemo(() => {
        return [
            {
                title: "Borrowing Rate",
                value: (
                    poolRates?.borrowStatusValue != null
                        ? `${poolRates.borrowStatusValue}% APY`
                        : FETCHING_DATA_MESSAGE
                ),
            },
            {
                title: "Payback Deadline",
                value: `In 35 Days`,
            },
        ];
    }, [poolRates?.borrowStatusValue]);

    const collectionName = getCollectionNameFromAddress(
        currentTokenAddress,
        chainId
    );

    const collectionDisplayNames = config.getCollectionNameByAddress(
        currentTokenAddress && getAddress(currentTokenAddress)
    );

    const handleQuickSelect = (percent) => {

        if (!invalidAmount) setInvalidAmount(false);
        if (!maxBorrow) {
            console.log('no past value is available');
            return;
        }

        setActiveQuickSelect(
            percent
        );

        if (percent === "max") percent = 100;

        const parseCappedBorrow = BigNumber.isBigNumber(cappedBorrow)
            ? cappedBorrow
            : BigNumber.from(Number(cappedBorrow));

        let res = ethers.utils.formatUnits(
            parseCappedBorrow.mul(percent).div(100),
            decimals
        );

        res = getPreciseValue(
            res
        );

        setAmount(res);
    };

    const setSelectedToken = (tokenAddress, tokenId) => {

        setCurrentTokenAddress(
            tokenAddress
        );

        setCurrentTokenId(
            tokenId
        );
    };

    const handleAmountChange = (val) => {

        if (invalidAmount) {
            setInvalidAmount(false);
        }

        // clear quickSelect
        setActiveQuickSelect(null);

        // early return for deleted value
        if (!val) {
            setAmount("");
            return;
        }

        setAmount(val);

        if (cappedBorrow) {
            const total = getPreciseValue(
                formatUnits(
                    cappedBorrow,
                    decimals
                )
            );

            let percent = val / total * ONE_HUNDRED;
            if (percent === ONE_HUNDRED) {
                percent = 'max';
            }

            const quickValue = PERCENT_OPTIONS[percent]
                ? percent.toString()
                : null;

            setActiveQuickSelect(
                quickValue
            );
        }
    };

    useEffect(() => {
        if (cappedBorrow) {
            handleQuickSelect(
                activeQuickSelect
                ?? 'max'
            );
        }
        //@TODO:
        // if (!activeQuickSelect && loading === false) {
            // handleQuickSelect("max");
        // }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cappedBorrow, loading, maxBorrow]);

    useEffect(() => {
        let mounted = true;

        const getPrice = async () => {

            const params = collectionName
                && currentTokenAddress
                && currentTokenId;

            if (params) {
                setLoading(true);
            }

            let collectionHash;

            try {

                const routerContract = getRouterContract(
                    config.routerAddress,
                    library
                );

                const merkleIPFS = await routerContract.merkleIPFS(
                    currentTokenAddress
                );

                if (merkleIPFS?.length) {
                    collectionHash = merkleIPFS;
                }

            } catch (ex) {
                console.log(ex);
            }

            // console.log(collectionName, 'collectionName');
            // console.log(collectionHash, 'collectionHash');
            // console.log(currentTokenId, 'currentTokenId');

            params && collectionHash && collectionName && currentTokenId && axios.get(
                `${BACKEND_URL}/collections/${collectionName}/${collectionHash}/${currentTokenId}`
            ).then((res) => {
                // console.log(res, 'res')
                // @TODO: if request fails, do try/catch or smth to show error TOAST notification
                // saying: failed to fetch price for this token or something like that
                if (!mounted) return;
                setLoading(false);
                // console.log(res, 'res');
                setTokenData(
                    res.data
                );
            })
        }

        if (currentTokenId) getPrice();
        return () => {
            mounted = false;
        }
    }, [currentTokenId, collectionName, currentTokenAddress]); // eslint-disable-line react-hooks/exhaustive-deps

    const noTokens = useMemo(() => (
        displayedCollections && !displayedCollections.length
    ), [displayedCollections]);

    const collectionsDisplay = useMemo(() => {

        if (!displayedCollections) {
            return (
                <div className={styles.collections}>
                    <Loader className={styles.loader} color="white" />
                </div>
            );
        }

        if (displayedCollections && !displayedCollections.length) {
            return (
                <div style={{marginTop: "100px"}}>
                <div className={styles.noToken}>
                    <img
                        src={noDataImg}
                        alt=""
                        height="200px"
                        width="200px"
                    />
                </div>
                <div className={styles.collections} style={{marginTop: "-40px", marginBottom: "-40px"}}>
                    Are you sure you have some NFTs?
                </div>
                <div className={styles.collections} style={{marginTop: "-60px", marginBottom: "-40px"}}>
                    <Link to={`/profile`}>Click here to double-check</Link>
                </div>
                </div>
            );
        }

        return (
            <Collections
                // className={styles.followers}
                items={displayedCollections}
                onlyTokens={true}
                setSelectedToken={setSelectedToken}
                tokenInfo={{
                    id: currentTokenId,
                    tokenId: currentTokenId,
                    tokenAddress: currentTokenAddress,
                    collection: defaultSelectedToken?.collection
                }}
                useDropdown={COLLECTIONS_DROPDOWN_LIST.includes(title)}
                onRefreshIconClick={onRefreshIconClick}
                loading={isCollectionsLoading}
            // notUser={hasParamAndNotUser}
            />
        );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [displayedCollections, currentTokenId, currentTokenAddress, title]);

    useEffect(() => {
        // reset selected token on account change
        if (!accountRef.current) {
            accountRef.current = account;
        } else if (accountRef.current !== account) {
            setSelectedToken(
                null,
                null
            );
        }
    }, [account]);

    const errorStyle = invalidAmount
        ? { borderBottom: "solid 1px #c73e77" }
        : {};

    return (
        <div>
            <div className={cn("h4", styles.title)}>Borrow {selectedCurrency} Funds</div>
            <div style={{ display: transactionHash ? "none" : "block" }}>
                {
                    showPoolDescription && (
                        <div className={styles.main}>
                            This pool holds{" "}
                            {paymentTokenAddr && (
                                <span className={styles.pink}>
                                    <FormattedAmount
                                        // noTruncate={true}
                                        amount={poolTokenBalance}
                                        token={paymentTokenAddr}
                                        label={true}
                                    />
                                </span>
                            )}{" "}
                            available funds to borrow. Choose from supported NFTs below
                        </div>
                    )
                }
                {
                    account && (
                        <>
                            {collectionsDisplay}
                            <br />
                        </>
                    )
                }
                <div
                    className={styles.main}
                    style={{ display: !displayedCollections || noTokens ? "none" : "block" }}
                >
                    Based on selected NFT and available funds, you can borrow up to
                    {" "}
                    {paymentTokenAddr && tokenData && (
                        <span className={styles.pink}>
                            <FormattedAmount
                                // noTruncate={true}
                                amount={cappedBorrow}
                                token={paymentTokenAddr}
                                label={true}
                            />
                        </span>
                    )}{" "}
                    from the pool
                </div>
                <div className={styles.table} style={{ marginTop: "24px" }}>
                    <div className={styles.row} style={errorStyle}>
                        <div className={styles.col}>Borrowing Amount</div>
                        <div className={styles.col}>
                            <CurrencyInput
                                id="input-borrow"
                                name="borrow"
                                autoComplete="off"
                                allowNegativeValue={false}
                                className={styles.input}
                                placeholder="Input Value"
                                // decimalsLimit={18}
                                value={amount}
                                decimalSeparator="."
                                groupSeparator=","
                                onValueChange={(value) => handleAmountChange(value)}
                            />
                        </div>
                        {amount && (
                            <div className={styles.col}>{selectedCurrency || "ETH"}</div>
                        )}
                    </div>
                    {
                        account && (
                            <>
                                <div className={styles.row} style={{ marginTop: "8px" }}>
                                    {Object.keys(PERCENT_OPTIONS).map((key) => (
                                        <button
                                            key={key}
                                            className={cn("button-stroke", styles.option, {
                                                'button-active': activeQuickSelect === key
                                            })}
                                            onClick={() => handleQuickSelect(key)}
                                        >
                                            {PERCENT_OPTIONS[key]}
                                        </button>
                                    ))}
                                </div>
                                <div>
                                    {loanInfo.map((x, index) => (
                                        <div className={styles.row} key={index}>
                                            <div className={styles.col}>{x.title}</div>
                                            <div className={styles.col}>{x.value}</div>
                                        </div>
                                    ))}
                                </div>
                                <div className={styles.main} style={{ marginTop: "16px", marginBottom: "0px" }}>
                                    You can close or extend this loan every 35 days paying due amount only without extra charge
                                </div>
                            </>
                        )
                    }
                </div>
                {
                    !account && (
                        <div className={styles.main} style={{ marginBottom: "0px", marginTop: "10px"}}>
                            User should connect their wallet first so they can use this Instant Pools
                            feature as expected
                        </div>
                    )
                }
            </div>
            <BorrowSteps
                amount={amount}
                paymentToken={paymentToken}
                closeModal={closeModal}
                poolAddress={poolAddress}
                currentTokenId={currentTokenId}
                selectedPool={selectedPool}
                tokenData={tokenData}
                currentTokenAddress={currentTokenAddress}
                loadingData={loading}
                transactionHash={transactionHash}
                setTransactionHash={setTransactionHash}
                setInvalidAmount={setInvalidAmount}
                poolTokenBalance={poolTokenBalance}
                title={title}
                noTokens={noTokens}
                displayedCollections={displayedCollections}
                cappedBorrow={cappedBorrow}
                collectionDisplayNames={collectionDisplayNames}
            />
        </div>
    );
};

export default Borrow;
