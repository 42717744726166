import React, { useState, useEffect } from "react";
import cn from "classnames";
// import { Link } from "react-router-dom";
import styles from "./CardSimple.module.sass";
import Icon from "../Icon";

const CardSimple = ({ 
    className, 
    item, 
    id, 
    isPunk, 
    selectedTokenIds,
    disabled
}) => {

    const selected = selectedTokenIds.includes(id);
    const [visible, setVisible] = useState(selected);
    // TO-DO: use punk color as global const
    const style = isPunk ? {background: "rgb(80 131 148)", imageRendering: "pixelated"} : {};
    if (disabled) style.filter = 'grayscale(1)';

    useEffect(() => {
        setVisible(selectedTokenIds.includes(id));
    }, [selectedTokenIds, id]);

    return (
        <div className={cn(styles.card, className)}>
            <div className={styles.preview}>
                <img style={style} className={styles.img} srcSet={`${item.image2x} 2x`} src={item.image} alt="Loan" />
                <div className={cn(styles.control, { [styles.active]: visible })}>
                    {/*<div
                        className={cn(
                            { "status-green": item.category === "green" },
                            styles.category
                        )}
                    >
                        SELECTED
                    </div>*/}
                    <button
                        type="button"
                        className={cn(styles.favorite, { [styles.active]: visible })}
                    >
                        {visible || disabled ? (
                            <div className="shrink">
                                <Icon name="check" size="16" />
                            </div>
                        ) : (
                            <Icon name="plus" size="20" />
                        )}
                    </button>
                </div>
            </div>
            <div className={styles.link}>
                <div className={styles.body}>
                    <div className={styles.line}>
                        <div className={styles.title}>{item.title}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CardSimple;
