import React, { useState, useEffect, useMemo } from "react";
// import { shortenIfAddress, useLookupAddress } from "@usedapp/core";
import { getOwlImage } from "../../utils";
import loaderImage from "../../assets/images/loaders/matrix-pink.svg";
import StatusBadge from "../../assets/images/icons/StatusBadge"
import EnsName from "../../components/EnsName";
import * as cn from "classnames";
import styles from "./Avatar.module.sass";

const Avatar = ({
    className,
    address,
    showAddress,
    showBadge,
    onClick
}) => {

    const [avatar, setAvatar] = useState(
        loaderImage
    );
    const [isVerified, setIsVerified] = useState(
        false
    );

    useEffect(() => {

        const checkAvatar = async (address) => {

            const result = await getOwlImage(
                address
            );

            if (result) {
                setAvatar(result.image);
                setIsVerified(result.isOwned);
            }
        }

        checkAvatar(address);

    }, [address]);

    const statusTooltipText = useMemo(() => {
        return isVerified
            ? `🟣 Verified NFT Status`
            : '⚪ Unverified NFT Status'
    }, [isVerified])

    return (
        <div
            className={cn(className,
                {
                    [styles.badgeContainer]: showBadge,
                    [styles.tooltip]: showBadge
                }
            )}
            data-text={statusTooltipText}
            onClick={onClick}
        >
            <img src={avatar} alt="Avatar" />
            {showBadge && (
                <div
                    className={cn(
                        styles.verifiedBadge,
                        {
                            [styles.verified]: isVerified
                        }
                    )}
                >
                    <StatusBadge width={30} height={30} />
                </div>
            )}
            {showAddress && (
                <div><EnsName address={address} /></div>
            )}
            {/* @TODO // badge to implement later, possibly for trust score
                {x.reward && (
                    <div className={styles.reward}>
                        <img src={x.reward} alt="Reward" />
                    </div>
                )}
            */}
        </div>
    );
};

export default Avatar;
