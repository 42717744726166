import { useState, useEffect } from "react";
import { useEthers } from "@usedapp/core";
import { Interface } from "ethers/lib/utils";
import { getTokenCollections } from "../ethers/nftCollections";
import { Contract } from "ethers";
import { getMetaData, parseLog } from "./useTokenFetch";

const indexedTransferInterface = new Interface([
    "event Transfer(address indexed from, address indexed to, uint256 indexed tokenId)",
    "function tokenURI(uint256 tokenId) returns (string)",
]);

const useIndexedAddressTokens = (userAccount, chainId) => {
    const { library } = useEthers();

    const [loading, setIsLoading] = useState(false);
    const [indexedAddressTokens, setIndexedAddressTokens] = useState([]);
    const [indexedTokenObjects, setIndexedTokenObjects] = useState([]);

    /* punk whale address for testing mainnet: */
    // const userAccount = "0xc352b534e8b987e036a93539fd6897f53488e56a";

    const {
        indexedTransfer,
    } = getTokenCollections(chainId);

    const indexedCollections = indexedTokenObjects.reduce((prev, obj) => {
        const { id, address } = obj;
        if (prev[address]) {
            return {
                ...prev,
                [address]: [...prev[address], id]
            };
        } else {
            return {
                ...prev,
                [address]: [id]
            };
        }
    }, {});

    const indexedStringified = JSON.stringify(
        indexedCollections
    );

    const getMostRecentEvents = (events, field) => {
        return events.reduce((prev, curr) => {
            const index = curr.args[field];
            const entryAtIndex = prev[index];
            if (!entryAtIndex || curr.blockNumber > entryAtIndex.blockNumber) {
                return { ...prev, [index]: curr };
            }
            return prev;
        }, {});
    };

    const getMostRecentTokenEvents = (events) => getMostRecentEvents(
        events,
        "tokenId"
    );

    const getFilteredLogs = (filter) => library.getLogs({
        ...filter,
        fromBlock: 16009153
    });

    const fetch = async () => {
        try {
            if (indexedTransfer?.length) {
                setIsLoading(true);
                setIndexedAddressTokens([]);
            }

            // console.log(indexedCollections, 'calling with index');

            const results = await getMetaData(
                indexedCollections,
                chainId,
                userAccount
            );

            setIndexedAddressTokens(
                results?.length
                    ? results.flat()
                    : []
            );
        } catch (ex) {
            console.log(ex);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        let mounted = true;

        const parseIndexedLog = (log) => parseLog(
            log,
            indexedTransferInterface
        );

        const getIndexedEventTokens = async () => {
            const indexedTokenCollections = await Promise.all(
                indexedTransfer.map(async (contract) => {
                    try {
                        const indexedTransferContract = new Contract(
                            contract.address,
                            indexedTransferInterface
                        );

                        const indexedFromFilter = indexedTransferContract.filters.Transfer(
                            userAccount
                        );

                        const indexedFromLogs = await getFilteredLogs(
                            indexedFromFilter
                        );

                        const indexedFromEvents = indexedFromLogs.map(
                            parseIndexedLog
                        );

                        const indexedToFilter = indexedTransferContract.filters.Transfer(
                            null,
                            userAccount
                        );
                        const indexedToLogs = await getFilteredLogs(
                            indexedToFilter
                        );

                        const indexedToEvents = indexedToLogs.map(
                            parseIndexedLog
                        );

                        const mostRecentTransfersTo = getMostRecentTokenEvents(
                            indexedToEvents
                        );

                        const mostRecentTransfersFrom = getMostRecentTokenEvents(
                            indexedFromEvents
                        );

                        const stillOwnedIndexedTokens = Object.keys(mostRecentTransfersTo).reduce(
                            (prev, key) => {
                                const current = mostRecentTransfersTo[key];
                                const correspondingFrom = mostRecentTransfersFrom[key];
                                if (
                                    !correspondingFrom ||
                                    current.blockNumber > correspondingFrom.blockNumber
                                ) {
                                    return [...prev, current];
                                }
                                return prev;
                            },
                            []
                        );
                        return {
                            tokens: stillOwnedIndexedTokens,
                            address: contract.address,
                        };
                    } catch (err) {
                        // console.log("getIndexedEventTokens error! contract: ", contract);
                    }
                })
            );
            const indexedTokens = indexedTokenCollections.reduce((prev, curr) => {
                const tokensOfCollection = curr.tokens.map((tokenEvent) => ({
                    id: tokenEvent.args.tokenId,
                    address: curr.address,
                }));
                return [
                    ...prev,
                    ...tokensOfCollection
                ];
            }, []);

            setIndexedTokenObjects(
                indexedTokens
            );
        };

        if (library && userAccount && chainId && mounted) {
            getIndexedEventTokens();
        }

        return () => (mounted = false);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chainId, userAccount, library]);

    useEffect(() => {
        let mounted = true;

        if (mounted && userAccount) {
            fetch();
        }
        return () => (mounted = false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [indexedStringified, userAccount]);

    return {
        indexedAddressTokens,
        loading,
        refetch: fetch
    }

}

export default useIndexedAddressTokens;