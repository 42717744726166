import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { parseUnits, formatUnits } from "ethers/lib/utils";
import { useEthers } from "@usedapp/core";
import cn from 'classnames';
import { ethers } from 'ethers';
import toast, { Toaster } from "react-hot-toast";

import Dropdown from "../../components/Dropdown";
import { setUpdateLatestTx } from '../../redux/settingsSlice';
import Success from '../Transaction';
import styles from './EditListing.module.sass';
import Loader from '../Loader';
import LOCKER_ABI from '../../ethers/abis/LiquidLocker.json';
import { getToastSettings } from '../../utils';
import {
    BORROW_RATE_OPTION,
    SECONDS_IN_DAY,
    BORROW_TIMES
 } from "../../utils";

const DEFAULT_TEXT = 'Confirm Updates';
const CONFIRM_TEXT = 'Confirm in Metamask';
const DONE_TEXT = 'Done';

const EditListing = ({
    className,
    paymentRate,
    paymentTime,
    paymentTokenName,
    closeModal
}) => {
    const { account, library } = useEthers();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [hasBegunSteps, setHasBegunSteps] = useState(false);
    const [transactionHash, setTransactionHash] = useState();
    const [buttonText, setButtonText] = useState(DEFAULT_TEXT);
    const [borrowingRate, setBorrowingRate] = useState();
    const [newPaymentTime, setNewPaymentTime] = useState();

    const configuration = process.env.NODE_ENV === "development"
    ? { gasLimit: "1000000" }
    : {};

    const signer = library.getSigner(account);
    const { lockerAddress } = useParams();

    // Get current rate and time / format
    const paymentTimeFormatted = paymentTime
        ? parseInt(paymentTime.toString()) / SECONDS_IN_DAY
        : "";

    const paymentRateFormatted = paymentRate
        ? parseFloat(formatUnits(paymentRate.toString(), 18))
        : "";

    // Check for state changes if item detail has been updated
    const shouldUpdateTime = (
        paymentTimeFormatted !== parseInt(newPaymentTime)
    );

    const shouldUpdateRate = paymentRateFormatted !== parseFloat(borrowingRate);

    // Displayed options based on current state on blockchain
    const timeOptions = useMemo(() => {
        const timeIndex = BORROW_TIMES.findIndex((time) => (
            time.includes(paymentTimeFormatted)
        ));
        return  BORROW_TIMES.slice(0, timeIndex + 1);
    }, [paymentTimeFormatted]);

    // Rates are based on duration selected
    const rateOptions = useMemo(() => {
       if (newPaymentTime) {
            const dynamicRates = BORROW_RATE_OPTION[paymentTokenName][newPaymentTime];
            const filteredRates = dynamicRates.filter((rate) => (
                parseFloat(rate) >= paymentRateFormatted
            ));
            // if selected payment time (duration) has no available rates 
            // include current percent and current + 1 percent TIPs
            return filteredRates.length
                ? filteredRates
                : [`${paymentRateFormatted}%`, `${paymentRateFormatted + 1}%`];
       }
    }, [paymentRateFormatted, newPaymentTime, paymentTokenName]);

    useEffect(() => {
        setNewPaymentTime(`${paymentTimeFormatted} Days`);
    }, [paymentTimeFormatted]);

    useEffect(() => {
        if (rateOptions) {
            const existingRate = rateOptions.find((rate) => parseFloat(rate) === paymentRateFormatted);
            const ratePreselect = existingRate
                ? `${paymentRateFormatted}%`
                : rateOptions[0];
            setBorrowingRate(ratePreselect);
        }
    }, [rateOptions, paymentRateFormatted]);

    const updateSettings = async (lockerContract, method, args) => {
        try {
            const updateTx = await lockerContract[method](
                ...args,
                configuration
            );
            setLoading(true);
            setTransactionHash(updateTx.hash);
            await updateTx.wait();
            setButtonText(DONE_TEXT);
            setHasBegunSteps(false);
            setLoading(false);
            dispatch(setUpdateLatestTx(updateTx.hash));
        } catch (err) {
            setLoading(false);
            setHasBegunSteps(false);
            setButtonText(DEFAULT_TEXT);
            toast.error(
                "Error Updating Listing",
                getToastSettings("🚫")
            );
        }
    }

    const handleUpdates = async () => {
        const lockerContract = new ethers.Contract(
            lockerAddress,
            LOCKER_ABI,
            signer
        );

        const timeInSeconds = parseInt(newPaymentTime) * SECONDS_IN_DAY;
        const formattedBorrowingRate = parseUnits(parseFloat(borrowingRate).toString(), 18);

        let updateMethod;
        const args = [];

        if (shouldUpdateTime & shouldUpdateRate) {
            updateMethod = "updateSettings";
            args.push(formattedBorrowingRate, timeInSeconds);
        } else if (shouldUpdateTime) {
            updateMethod = "decreasePaymentTime";
            args.push(timeInSeconds);
        } else if (shouldUpdateRate) {
            updateMethod = "increasePaymentRate";
            args.push(formattedBorrowingRate);
        }

        if (shouldUpdateRate || shouldUpdateTime) {
            updateSettings(lockerContract, updateMethod, args);
        }

        setButtonText(CONFIRM_TEXT);
        setHasBegunSteps(true);
    };

    return (
        <div>
            <div className={cn("h4", styles.title)}>
                Edit Listing Details
            </div>
            <div className={styles.body}>
                <span>
                    { transactionHash && hasBegunSteps === false
                        ? "Successfully updated listing"
                        : "Select listing terms to update"
                    }
                </span>
            </div>
            <div className={cn(className, styles.steps)}>
                <div className={styles.list}>
                    { transactionHash && hasBegunSteps === false
                        ? (
                        <Success
                            txHash={transactionHash}
                            title={"You successfully updated your listing"}
                            showButton={false}
                            celebrate={false}
                        /> )
                        : (
                        <div>
                            <div className={styles.item}>
                                <div className={styles.head}>
                                    <div className={styles.details}>
                                        <div className={styles.text}>
                                            <div className={styles.row}>
                                                <div className={styles.col}>Current TIP</div>
                                                <div className={styles.col}>{paymentRateFormatted}%</div>
                                            </div>
                                            <div className={styles.row}>
                                                <div className={styles.col}>Current Duration</div>
                                                <div className={styles.col}>{paymentTimeFormatted} Days</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.table}>
                                <div className={styles.row}>
                                    <div className={styles.col}>
                                        Increase Rate
                                    </div>
                                    <div
                                        className={styles.col}
                                        style={{ 
                                            pointerEvents: rateOptions?.length > 1 ? "auto" : "none" 
                                        }}
                                    >
                                        { rateOptions?.length && (
                                            <Dropdown
                                                value={borrowingRate}
                                                setValue={setBorrowingRate}
                                                options={rateOptions}
                                            />
                                        )}
                                    </div>
                                </div>
                                <div className={styles.row} style={{marginTop: "8px"}}>
                                    <div className={styles.col}>
                                        Decrease Duration
                                    </div>
                                    <div
                                        className={styles.col}
                                        style={{ 
                                            pointerEvents: timeOptions?.length > 1 ? "auto" : "none" 
                                        }}
                                    >
                                        <Dropdown
                                            value={newPaymentTime}
                                            setValue={setNewPaymentTime}
                                            options={timeOptions}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className={styles.item}>
                        <button
                            style={{marginTop: "30px"}}
                            onClick={buttonText === DONE_TEXT ? closeModal : handleUpdates}
                            disabled={loading}
                            className={cn(
                                "button",
                                styles.button,
                                loading ? "loading" : ""
                            )}
                        >
                            { loading && <Loader className={styles.loaderSmall} color="white" /> }
                            { buttonText }
                        </button>
                    </div>
                </div>
                <Toaster />
            </div>
        </div>
    );
};

export default EditListing;