import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import "./styles/app.sass";
import Page from "./components/Page";
import Home from "./screens/Home";
import LoanType from "./screens/LoanType";
import { CustomLoan, InstantLoan } from "./screens/LoanDetails";
import ConnectWallet from "./screens/ConnectWallet";
import Questions from "./screens/Questions";
import Instant from "./screens/Instant";
import Activity from "./screens/Activity";
import Search01 from "./screens/Search01";
import Search02 from "./screens/Search02";
import Profile from "./screens/Profile";
import Item from "./screens/Item";
import Pool from "./screens/Pool";
import PageList from "./screens/PageList";
import DeployPool from "./screens/DeployPool";
import InstantPools from "./screens/InstantPools";
import LoanSettings from "./screens/LoanSettings";
import TokenInfo from "./screens/TokenInfo";

import { useLocalStorage } from "./customHooks/useLocalStorage";

function App() {
    // const darkMode = useDarkMode(true);

    useLocalStorage();

    return (
        <Router>
            <Switch>
                <Route
                    exact
                    path="/"
                    render={() => (
                        <Page>
                            <Home />
                        </Page>
                    )}
                />
                <Route
                    exact
                    path="/loan-type"
                    render={() => (
                        <Page>
                            <LoanType />
                        </Page>
                    )}
                />
                <Route
                    exact
                    path="/loan-type"
                    render={() => (
                        <Page>
                            <LoanType />
                        </Page>
                    )}
                />
                <Route
                    exact
                    path="/new-loan"
                    render={() => (
                        <Page>
                            <InstantLoan />
                        </Page>
                    )}
                />
                <Route
                    exact
                    path="/loan-details/:collection?"
                    render={() => (
                        <Page>
                            <CustomLoan />
                        </Page>
                    )}
                />
                <Route
                    exact
                    path="/connect-wallet"
                    render={() => (
                        <Page>
                            <ConnectWallet />
                        </Page>
                    )}
                />
                <Route
                    exact
                    path="/how-it-works"
                    render={() => (
                        <Page>
                            <Questions />
                        </Page>
                    )}
                />
                <Route
                    exact
                    path="/instant"
                    render={() => (
                        <Page>
                            <Instant />
                        </Page>
                    )}
                />
                <Route
                    exact
                    path="/activity"
                    render={() => (
                        <Page>
                            <Activity />
                        </Page>
                    )}
                />
                <Route
                    exact
                    path="/custom-listings/:collection?"
                    render={() => (
                        <Page>
                            <Search01 />
                        </Page>
                    )}
                />
                <Route
                    exact
                    path="/search02"
                    render={() => (
                        <Page>
                            <Search02 />
                        </Page>
                    )}
                />
                <Route
                    exact
                    path="/profile"
                    render={() => (
                        <Page>
                            <Profile />
                        </Page>
                    )}
                />
                <Route
                    exact
                    path="/profile/wallet-nfts"
                    render={() => (
                        <Page>
                            <Profile />
                        </Page>
                    )}
                />
                <Route
                    exact
                    path="/profile/funded-listings"
                    render={() => (
                        <Page>
                            <Profile />
                        </Page>
                    )}
                />
                <Route
                    exact
                    path="/profile/funded-pools"
                    render={() => (
                        <Page>
                            <Profile />
                        </Page>
                    )}
                />
                <Route
                    exact
                    path="/profile/instant-loans"
                    render={() => (
                        <Page>
                            <Profile />
                        </Page>
                    )}
                />
                <Route
                    exact
                    path="/profile/custom-listings"
                    render={() => (
                        <Page>
                            <Profile />
                        </Page>
                    )}
                />
                <Route
                    exact
                    path="/profile/wallet-nfts/:address"
                    render={() => (
                        <Page>
                            <Profile />
                        </Page>
                    )}
                />
                <Route
                    exact
                    path="/profile/funded-listings/:address"
                    render={() => (
                        <Page>
                            <Profile />
                        </Page>
                    )}
                />
                <Route
                    exact
                    path="/profile/funded-pools/:address"
                    render={() => (
                        <Page>
                            <Profile />
                        </Page>
                    )}
                />
                <Route
                    exact
                    path="/profile/instant-loans/:address"
                    render={() => (
                        <Page>
                            <Profile />
                        </Page>
                    )}
                />
                <Route
                    exact
                    path="/profile/custom-listings/:address"
                    render={() => (
                        <Page>
                            <Profile />
                        </Page>
                    )}
                />
                <Route
                    exact
                    path="/profile/:address/:category?"
                    render={() => (
                        <Page>
                            <Profile />
                        </Page>
                    )}
                />
                <Route
                    exact
                    path="/loan-settings"
                    render={() => (
                        <Page>
                            <LoanSettings />
                        </Page>
                    )}
                />
                <Route
                    exact
                    path="/item/:lockerAddress"
                    render={() => (
                        <Page>
                            <Item />
                        </Page>
                    )}
                />
                <Route
                    exact
                    path="/item/:address"
                    render={() => (
                        <Page>
                            <Item />
                        </Page>
                    )}
                />
                <Route
                    path="/pagelist"
                    render={() => (
                        <Page>
                            <PageList />
                        </Page>
                    )}
                />
                <Route
                    path="/token-info/:collectionParam?/:tokenIdParam?"
                    render={() => (
                        <Page>
                            <TokenInfo />
                        </Page>
                    )}
                />
                <Route
                    exact
                    path="/deploy-pool"
                    render={() => (
                        <Page>
                            <DeployPool />
                        </Page>
                    )}
                />
                <Route
                    exact
                    path="/instant-pools"
                    render={() => (
                        <Page>
                            <InstantPools />
                        </Page>
                    )}
                />
                <Route
                    exact
                    path="/pools"
                    render={() => (
                        <Page>
                            <InstantPools />
                        </Page>
                    )}
                />
                <Route
                    exact
                    path="/instant-pools/:pool"
                    render={() => (
                        <Page>
                            <InstantPools />
                        </Page>
                    )}
                />
                <Route
                    exact
                    path="/pool/:poolAddress"
                    render={() => (
                        <Page>
                            <Pool />
                        </Page>
                    )}
                />
            </Switch>
        </Router>
    );
}

export default App;
