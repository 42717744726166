import React, { useState, useMemo } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useEthers } from "@usedapp/core";
import cn from "classnames";
import toast, { Toaster } from "react-hot-toast";
import { useCustomAllowance } from "../../customHooks/useCustomAllowance";
import styles from "./PaybackInstant.module.sass";
import Icon from "../Icon";
import Loader from "../Loader";
import useConfig from "../../customHooks/useConfig";
import Success from "../Transaction";
import {
    setClosedLoanTxHashes,
    setExtendedLoanIds
} from "../../redux/settingsSlice";


import {
    getToastSettings,
    getToastErrorMessage,
    // produceAccurateValue
} from "../../utils";

import { getRouterContract } from "../../utils/query/interfaces";
import { useERC20Approval } from "../../customHooks/useERC20Approval";

const CONFIRM_TEXT = "Confirm in Metamask";
const UNLOCK_TEXT = "Unlocking...";
const IN_PROGRESS_TEXT = "Payback in progress...";
const DEFAULT_TEXT = "Proceed with Payback";
const DONE_TEXT = 'Done';

const PaybackSteps = ({
    className,
    tokenTitle,
    title,
    extendLoanValue,
    endLoanValue,
    paymentToken,
    transactionHash,
    setTransactionHash,
    closeModal,
    poolAddress,
    tokenId,
    nftAddress,
    merkleProof,
    merklePrice,
    merkleIndex,
    optionSelected,
    extendLoadPayDate,
    amountDisplay,
    cardIndex
}) => {

    const config = useConfig();

    const {
        account, 
        library
    } = useEthers();

    const [loading, setLoading] = useState(false);
    const [successfulApproval, setSuccessfulApproval] = useState(false);
    const [buttonText, setButtonText] = useState(DEFAULT_TEXT);
    const [paybackTx, setPaybackTx] = useState("");
    const closedLoanTxHashes = useSelector((state) => state.settings.closedLoanTxHashes);
    const extendedLoanIds = useSelector((state) => state.settings.extendedLoanIds);
    const signer = library.getSigner(account);
    const dispatch = useDispatch();

    const approval = useERC20Approval({
        onConfirmation: () => setButtonText(CONFIRM_TEXT),
        onApproving: (transactionHash) => {
            setLoading(true);
            setButtonText(UNLOCK_TEXT);
            setTransactionHash(transactionHash);
        },
        onApprovalComplete: approval => {
            setLoading(false);
            if (approval) {
                setSuccessfulApproval(true);
                handleMakePayback();
            }
        },
        onError: error => {
            setLoading(false);
            handleError(error);
            setTransactionHash("");
            setButtonText(DEFAULT_TEXT);
        }
    })
    const amount = optionSelected === "1"
        ? endLoanValue
        : extendLoanValue;

    // Status of details fetched for payback
    const paybackDetailsFetched = useMemo(() => (
        merkleIndex >= 0 && merklePrice && merkleProof && amount
    ), [merkleIndex, merklePrice, merkleProof, amount]);

    const allowance = useCustomAllowance(
        config.paymentTokens[paymentToken],
        account,
        config.routerAddress
    );

    // check if allowance is already approved if so go to second step
    // const preApproved = false; //allowance && allowance.gte(paybackAmount);

    const preApproved = allowance && allowance.gte(
        amount
    );

    const handleError = (err) => {
        toast.error(
            getToastErrorMessage(err.code),
            getToastSettings("🚫")
        );
    };

    const handleClosedLoanState = ({
        nftAddress,
        tokenId,
        txHash,
        txClosedDate,
        txAmount
    }) => {
        dispatch(
            setClosedLoanTxHashes([
                ...closedLoanTxHashes,
                {
                    nftAddress,
                    tokenId,
                    txHash,
                    txClosedDate,
                    txAmount
                }
            ])
        );
    };

    const handleApprove = async () => {

        if (buttonText === DONE_TEXT) {
            closeModal();
        }

        if (!amount) {
            handleError({ code: "Invalid amount" })
            return;
        }

        if (buttonText !== DEFAULT_TEXT) return;


        if (preApproved) {
            setSuccessfulApproval(true);
            handleMakePayback();
            return;
        }

        approval.call(
            config.routerAddress,
            config.paymentTokens[paymentToken],
        );

    };

    const handleMakePayback = async () => {

        if (!amount) return;

        if (buttonText !== DEFAULT_TEXT) return;

        setButtonText(
            CONFIRM_TEXT
        );

        try {

            const routerContract = getRouterContract(
                config.routerAddress,
                signer
            );

            const paybackTransaction = await routerContract.paybackFunds(
                poolAddress,
                amount,
                nftAddress,
                tokenId,
                merkleIndex,
                merklePrice,
                merkleProof
            );

            setTransactionHash(
                paybackTransaction.hash
            );

            setLoading(true);
            setButtonText(IN_PROGRESS_TEXT);

            const paybackCompleted = await paybackTransaction.wait();

            setLoading(false);
            setButtonText(DONE_TEXT);

            // set tokenId to hide token used in successfully finalized loan or signify extended loan in card
            optionSelected === "1"
                ? handleClosedLoanState({
                    nftAddress,
                    tokenId,
                    txHash: paybackTransaction.hash,
                    txClosedDate: Math.floor(Date.now() / 1000),
                    txAmount: amount,
                })
                : dispatch(setExtendedLoanIds(
                    [
                        ...extendedLoanIds,
                        tokenId.toString()
                    ]
                ));

            if (paybackCompleted) {
                setPaybackTx(paybackCompleted);
            }

        } catch (err) {
            setLoading(false);
            setButtonText(DEFAULT_TEXT);
            handleError(err);
            setTransactionHash("");
        }
    };

    // dynamic styling constants
    // probably good to place in a config / class
    // duplicate found in PaybackSteps

    const canActivate = true;
    const approvalDone = preApproved || successfulApproval;

    const iconColor = approvalDone
        ? "#45B26B"
        : "#9757D7";

    const iconColorB = approvalDone && (buttonText.includes("...")|| buttonText === DONE_TEXT)
        ? "#9757D7"
        : "";

    const iconName = approvalDone
        ? 'check'
        : 'lock';

    const iconSize = approvalDone
        ? '20'
        : '24';

    const successMsg = useMemo(() => (
        optionSelected === "0"
            ? `Congratulations! Your transaction has been confirmed extending this loan until ${extendLoadPayDate}`
            : `Congratulations! Your transaction has been confirmed finalizing and closing this loan`
    ), [optionSelected, extendLoadPayDate]);

    const dupperMSG = useMemo(() => (
        optionSelected === "0"
            ? `You successfully extended your timeline for ${title} loan`
            : `You successfully payed your loan and retrieved ${title} back`
    ), [optionSelected, title]);

    return (
        <div className={cn(className, styles.steps)}>
            <div className={styles.list}>
                {transactionHash && (
                    <div style={{marginBottom: "30px"}}>
                        <div className={styles.body} >
                            { buttonText === DONE_TEXT ? (
                                <span>
                                    { successMsg }
                                </span>
                            ) : (
                                <span>
                                    Your transaction is now in progress, follow the steps below to finalize your loan payback
                                </span>
                            )}
                        </div>
                    </div>
                )}
                { paybackTx
                    ? (
                        <Success
                            txHash={transactionHash}
                            title={dupperMSG}
                            showButton={false}
                            celebrate={true}
                            displaySocials={false}
                        /> )
                    : (
                        <div
                            className={styles.item}
                            style={{ display: transactionHash ? 'block' : 'none' }}
                        >
                            <div className={styles.head}>
                                <div
                                    className={styles.icon}
                                    style={{ background: iconColor, borderColor: iconColor }}
                                >
                                    <Icon name={iconName}  size={iconSize} />
                                </div>
                                <div className={styles.details}>
                                    <div className={styles.info}>
                                        Step 1 - Unlock Tokens
                                    </div>
                                    <div className={styles.text}>
                                        Send transaction giving permission to LiquidNFTs contract transferring {paymentToken} tokens on your behalf
                                    </div>
                                </div>
                            </div>
                            <div className={styles.stepLine} />
                            <div className={styles.head}>
                                <div
                                    style={{ background: iconColorB, borderColor: iconColorB }}
                                    className={styles.icon}>
                                    <Icon
                                        name="wallet"
                                        size="24"
                                    />
                                </div>
                                <div className={styles.details}>
                                    <div className={styles.info}>
                                        Step 2 - Confirm Payback
                                    </div>
                                    <div className={styles.text}>
                                        Send transaction to confirm loan payback
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
                <div className={styles.item}>
                    <button
                        style={{marginTop: "24px"}}
                        onClick={handleApprove}
                        className={cn(
                            "button",
                            styles.button,
                            ((buttonText === DEFAULT_TEXT || buttonText === DONE_TEXT)
                                && paybackDetailsFetched
                            ) ? "" : "loading",
                            (canActivate && paybackDetailsFetched) ? "" : "disabled"
                        )}
                    >
                        { (loading || !paybackDetailsFetched) && <Loader className={styles.loaderSmall} color="white" /> }
                        { buttonText }
                    </button>
                </div>
            </div>
            <Toaster />
        </div>
    );
};

export default PaybackSteps;
