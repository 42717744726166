import PerfectScrollbar from 'react-perfect-scrollbar';
import cx from "classnames";
import { formatEther } from '@ethersproject/units';
import { useSelector } from 'react-redux';

import styles from "./PricingTree.module.sass";
import { Tree } from "../Tree/Tree";
import { USDC_LABEL, ETH_LABEL, getPrettyValue } from '../../utils';

const FLOOR_PRICE_KEY = "floor_price";
const CUSTOM_KEY = "custom_price";

const formattedPrice = (
    rawPrice,
    floorPriceRef,
    conversionRate,
    showUsdcPrices
) => {

    const priceValue = rawPrice === FLOOR_PRICE_KEY
        ? floorPriceRef
        : rawPrice;

    const priceEth = formatEther(
        priceValue
    );

    const price = showUsdcPrices
        ? Number(priceEth) * conversionRate
        : priceEth;

    const label = showUsdcPrices
        ? USDC_LABEL
        : ETH_LABEL;

    // shows conversion
    // const original = showUsdcPrices
    //     ? `(${getPrettyValue(priceEth)} ${ETH_LABEL})`
    //     : ``;

    return {
        price,
        label: `${getPrettyValue(price)} ${label}`
    };
};

const formatTree = (
    data,
    floorPriceRef,
    conversionRate,
    tokenName,
    showAllTraits,
    showUsdcPrices,
    isFloorPriceValue,
    upcomingPricingData,
    preOpenKeys = []
) => {

    // Hide custom_price key as it has no value
    if (data[CUSTOM_KEY]) {
        delete data[CUSTOM_KEY];
    }

    const keys = Object.keys(
        data
    );

    const getPercentageChange = (oldValue, newValue) => {
        const percentage = Math.abs(oldValue - newValue) / oldValue * 100;
        return getPrettyValue(
            percentage
        );
    };

    const hasPriceUpdates = (field, upcomingField) => {

        const hasChildren = Boolean(
            field && typeof field === "object"
        );

        if (hasChildren) {
            return Object.keys(field).some(
                (key) => {
                    return hasPriceUpdates(
                        field[key],
                        upcomingField?.[key]
                    );
                }
            );
        }

        const currentPrice = formattedPrice(
            field,
            floorPriceRef,
            conversionRate,
            showUsdcPrices
        );

        const upcomingPrice = upcomingField
            && formattedPrice(
                upcomingField,
                floorPriceRef,
                conversionRate,
                showUsdcPrices
            );

        return upcomingPrice?.price && currentPrice.price !== upcomingPrice.price;
    }

    const hasAboveFloorPriceValue = field => {
        const hasChildren = Boolean(
            field && typeof field === "object"
        );
        if (hasChildren) {
            return Object.keys(field).some(
                key => hasAboveFloorPriceValue(field[key])
            );
        } else {
            return Number(field) > Number(floorPriceRef)
        }
    }

    return keys
        .filter((key) => {
            if (showAllTraits) return true;
            return (
                key === FLOOR_PRICE_KEY
                || isFloorPriceValue
                || hasAboveFloorPriceValue(data[key])
            );
        })
        .map((key, index) => {

            const hasChildren = Boolean(
                data?.[key] && typeof data[key] === "object"
            );
            // track floor_price tree in next formatTree()
            const isFloorPriceValue = key === FLOOR_PRICE_KEY;

            const currentPrice = !hasChildren
                && formattedPrice(
                    data[key],
                    floorPriceRef,
                    conversionRate,
                    showUsdcPrices
                );

            const upcomingPrice = !hasChildren
                && upcomingPricingData?.[key]
                && formattedPrice(
                    upcomingPricingData[key],
                    floorPriceRef,
                    conversionRate,
                    showUsdcPrices
                );

            const hasPricingUpdates = hasPriceUpdates(
                data[key],
                upcomingPricingData?.[key]
            );

            const getSign = (currentPrice, upcomingPrice) => {
                return Number(currentPrice.price) < Number(upcomingPrice.price)
                    ? '+'
                    : '-';
            }

            return {
                label: (
                    <>
                        {
                            hasChildren
                                ? key === FLOOR_PRICE_KEY ? "Floor Price" : key
                                : (
                                    <div key={`${index}-${key}`}>
                                        <span className={styles.treeLabel}>
                                            {key}
                                        </span>
                                        <span className={styles.treeValue}>
                                            {
                                                currentPrice.label
                                            }
                                            {
                                                hasPricingUpdates && upcomingPrice && (
                                                    <>
                                                        <span className={styles.arrowIndicator}> -&gt; </span>
                                                        {
                                                            upcomingPrice.label
                                                        }
                                                        <span
                                                            className={
                                                                cx({
                                                                    [styles.increase]: Number(currentPrice.price) < Number(upcomingPrice.price),
                                                                    [styles.decrease]: Number(currentPrice.price) > Number(upcomingPrice.price),
                                                                })
                                                            }
                                                        > (
                                                            {getSign(
                                                                currentPrice,
                                                                upcomingPrice
                                                            )}
                                                            {getPercentageChange(
                                                                currentPrice.price,
                                                                upcomingPrice.price
                                                            )}%)
                                                        </span>
                                                    </>
                                                )
                                            }
                                        </span>
                                    </div>
                                )
                        }
                    </>
                ),
                children: hasChildren
                    ? formatTree(
                        data[key],
                        floorPriceRef,
                        conversionRate,
                        tokenName,
                        showAllTraits,
                        showUsdcPrices,
                        isFloorPriceValue,
                        upcomingPricingData?.[key],
                        preOpenKeys
                    )
                    : data[key],
                toggleVariant: hasPricingUpdates && 'warning',
                selected: isFloorPriceValue && preOpenKeys.includes(FLOOR_PRICE_KEY)
            }
        });
};

export const PricingTree = ({
    pricingData,
    upcomingPricingData,
    tokenName,
    showAllTraits,
    showUsdcPrices,
    preOpenKeys = [FLOOR_PRICE_KEY]
}) => {
    const conversionRates = useSelector((state) => state.conversions);
    const conversionRate = conversionRates?.CHAINLINK_ETH;

    return (
        <div>
            <PerfectScrollbar className={styles.scrollbar}>
                <Tree data={
                    formatTree(
                        pricingData,
                        pricingData?.floor_price?.value,
                        conversionRate,
                        tokenName,
                        showAllTraits,
                        showUsdcPrices,
                        false,
                        upcomingPricingData,
                        preOpenKeys
                    )
                } />
            </PerfectScrollbar>
        </div>
    );
}
