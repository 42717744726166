import React, { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import PerfectScrollbar from 'react-perfect-scrollbar';
// import axios from "axios";
// import { Link } from "react-router-dom";
import cn from "classnames";
import styles from "./Collections.module.sass";
import Loader from "../../../components/Loader";
import TokenImage from "../../../components/TokenImage";
import Dropdown from "../../../components/Dropdown";

// import noDataImg from "../../../assets/images/icons/searching-data.svg";
import noDataImg from "../../../assets/images/icons/wallet-small.png";
import Icon from "../../../components/Icon";

const Collections = ({
    className,
    items,
    notUser,
    onlyTokens,
    setSelectedToken,
    tokenInfo,
    useDropdown,
    loading,
    onPreviewTokenClick,
    onRefreshIconClick
}) => {
    const [dropdownItems, setDropdownItems] = useState([]);

    const [selectedCollection, setSelectedCollection] = useState(
        tokenInfo?.collection
    );

    const showSupportedOnly = useSelector((state) => {
        return true;
        // return state.settings.showSupportedOnly
    });

    const displayedItems = useMemo(() => {
        if (useDropdown && !dropdownItems.length) return [];
        if (useDropdown) {
            const itemObject = items.find((item) => (
                item.name === selectedCollection
            ));
            return [itemObject];
        }
        return showSupportedOnly
            ? items.filter((item) => item.isSupported)
            : items;
    }, [
        useDropdown,
        items,
        dropdownItems,
        selectedCollection,
        showSupportedOnly
    ]);

    useEffect(() => {
        const hasItems = displayedItems && displayedItems.length;
        const params = hasItems && setSelectedToken;
        const hasGallery = hasItems && displayedItems[0]?.gallery?.length > 0;
        if (params && hasGallery && !tokenInfo.tokenId) {
            setSelectedToken(
                displayedItems[0]?.gallery[0].address,
                displayedItems[0]?.gallery[0].id
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [displayedItems]);

    useEffect(() => {
        const hasItemsUpdate = items?.length !== dropdownItems.length;
        if (useDropdown && items && items.length && hasItemsUpdate) {

            const dropdownValues = items.map((item) => item.name);

            setDropdownItems(
                dropdownValues
            );

            setSelectedCollection(
                tokenInfo?.collection || items[0].name
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items, useDropdown]);

    const handleDropdown = (val) => {
        setSelectedCollection(val);
        const itemObject = items.find((item) => (
            item.name === val
        ));
        setSelectedToken(
            itemObject.gallery[0].address,
            itemObject.gallery[0].id
        );
    };

    const handleTokenClick = (token, items) => {
        if (onPreviewTokenClick) {
            onPreviewTokenClick(
                token,
                items,
                token.gallery?.[0]
            );
        }
    };

    return (
        <div className={cn(styles.followers, className)}>
            <div style={{ display: useDropdown ? "block" : "none" }} className={styles.field}>
                {dropdownItems?.length > 1 && !loading && (
                    <div>
                        <div className={styles.label}>
                            Select NFT Collection
                        </div>
                        <div className={styles.dropdownContainer}>
                            <Dropdown
                                className={styles.dropdown}
                                value={selectedCollection}
                                setValue={(val) => handleDropdown(val)}
                                options={dropdownItems}
                            />
                            {
                                onRefreshIconClick != null && (
                                    <div onClick={onRefreshIconClick} className={styles.refresh}>
                                        <div
                                            className={cn("tooltip top")}
                                            data-text="Don't see your NFT? Click here to re-fetch"
                                        >
                                            <Icon name="refresh" size={24} fill="white" />
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                )}
            </div>
            <div className={styles.list}>
                <div
                    className={styles.loader}
                    style={{ display: loading ? "flex" : "none" }}
                >
                    <Loader color="white" />
                </div>
                {
                    !loading && !displayedItems?.length && (
                        <div className={styles.emptyPlaceholder}>
                            <img
                                src={noDataImg}
                                alt="noData"
                                height="200px"
                                width="200px"
                            />
                            Are you sure you have some NFTs?
                        </div>
                    )
                }
                <div className={cn(styles.list, styles.collections)}>
                    {!loading && displayedItems && displayedItems.map((x, index) => (
                        <div key={index}
                            className={cn(styles.item, {
                                [styles.tokens]: onlyTokens
                            })}
                        >
                            {onlyTokens !== true && (
                                <div className={styles.follower}>
                                    <div
                                        className={styles.avatar}
                                        onClick={() => {
                                            handleTokenClick(x, items);
                                        }}
                                    >
                                        <TokenImage
                                            token={x.avatar}
                                            size={88}
                                            style={{ background: '#608191', borderRadius: "20%" }}
                                            alt="Avatar"
                                        />
                                    </div>
                                    <div className={styles.details}>
                                        <div className={styles.title}>{x.name}</div>
                                        <div className={styles.counter}>{x.counter}</div>
                                        {!notUser && (
                                            <div
                                                style={{ cursor: "pointer" }}
                                                className={cn(
                                                    { "button-small": x.buttonClass === "blue" },
                                                    { "button-stroke button-small": x.buttonClass === "stroke" },
                                                    styles.button
                                                )}
                                                onClick={() => {
                                                    handleTokenClick(x, items);
                                                }}
                                            >
                                                {x.buttonContent}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                            <div className={styles.wrap}>
                                <PerfectScrollbar className={styles.scrollbar}>
                                    <div className={styles.gallery}>
                                        {x && x.gallery.map((token, index) => (
                                            <div
                                                className={cn(styles.preview, {
                                                    [styles.inactive]: onlyTokens
                                                        && !(
                                                            token.id?.toString()?.toLowerCase() === tokenInfo.tokenId?.toString()?.toLowerCase()
                                                            && token.address?.toLowerCase() === tokenInfo.tokenAddress?.toLowerCase()
                                                        )
                                                })}
                                                key={index}
                                            >
                                                <div
                                                    className={styles.galleryToken}
                                                    style={{ pointerEvents: notUser ? 'none' : 'auto' }}
                                                    onClick={() => {
                                                        if (onPreviewTokenClick) {
                                                            onPreviewTokenClick(x, items, token);
                                                        }
                                                        if (setSelectedToken) {
                                                            setSelectedToken(
                                                                token.address,
                                                                token.id
                                                            );
                                                        }
                                                    }}
                                                >
                                                    <TokenImage
                                                        token={token}
                                                        size={112}
                                                        style={{ background: '#608191', borderRadius: "12px" }}
                                                        className={cn({
                                                            [styles.clickable]: onPreviewTokenClick
                                                        })}
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </PerfectScrollbar>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Collections;
