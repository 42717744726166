import React from "react";
import { useSelector, useDispatch } from "react-redux";
import cn from "classnames";
import { setShowTIP } from "../../redux/settingsSlice";
import styles from "./TipHolder.module.sass";
import { SECONDS_IN_YEAR } from "../../utils";
import { appLocalStorage } from "../../utils/localstorage";

const TipHolder = ({borrowingRate, borrowingTime, extraClass}) => {

    const dispatch = useDispatch();
    const showTIP = useSelector((state) => state.settings.showTIP);

    const borrowingAPY = parseInt(borrowingRate)
        * SECONDS_IN_YEAR
        / borrowingTime;

    const handleToggleClick = () => {
        appLocalStorage.TIP.update(
            !showTIP
        );
        dispatch(
            setShowTIP(
                !showTIP
            )
        );
    };

    return (
        <div>
            {showTIP === true && (
                <div className={cn(styles.tip, extraClass)}
                    style={{cursor: "pointer"}}
                    onClick={handleToggleClick}
                >
                    {parseInt(borrowingRate)}% TIP
                </div>
            )}
            {showTIP === false && (
                <div className={cn(styles.apy, extraClass)}
                    style={{cursor: "pointer"}}
                    onClick={handleToggleClick}
                >
                    {borrowingRate
                        && borrowingTime
                        && borrowingAPY
                        && parseInt(borrowingAPY)}% APY
                </div>
            )}
        </div>
    )
};

export default TipHolder
