import { useState, useEffect, useRef, useMemo } from "react";
import { useParams, useHistory } from 'react-router-dom';
import axios from "axios";
import { Contract } from "ethers";
import { isAddress, getAddress, formatUnits } from "ethers/lib/utils";
import { useDispatch, useSelector } from "react-redux";
import { ERC20Interface, useEthers } from "@usedapp/core";
import cn from "classnames";
import PerfectScrollbar from 'react-perfect-scrollbar';
import supportedPools from "../InstantPools/Pools/data";
import TextInput from "../../components/TextInput"
import Loader from "../../components/Loader";
import Checkbox from "../../components/Checkbox";
import Borrow from "../../components/Borrow";
import Dropdown from "../../components/Dropdown";
import Modal from "../../components/Modal";
import { PricingTree } from "../../components/PricingTree";
import { Tree } from "../../components/Tree/Tree";
import TokenImage from "../../components/TokenImage";
import ROUTER_ABI from "../../ethers/abis/LiquidRouter.json";
// import { OPENSEA_API, OPENSEA_API_KEY } from "../../utils/config";
import useConfig from "../../customHooks/useConfig";
import useCallsGracefully from "../../customHooks/useCallsGracefully";
// import useGetOwnerNFTs from "../../customHooks/useGetOwnerNFTs";
import useGetPricingData from "../../customHooks/useGetPricingData";
import { COLLECTION_ADDRESSES_MAINNET, nftAddressToNameMapping } from "../../ethers/nftCollections";
import { setPricingPreferences } from "../../redux/settingsSlice";
import {
    BACKEND_URL,
    collectionAddressToNameMainnet,
    collectionAddressToNameTestnet,
    getDeadline,
    getPrettyValue,
    OLD_STR,
    TEST_NETWORK_ID,
} from "../../utils";
import { appLocalStorage } from "../../utils/localstorage";
import { getPoolContract } from "../../utils/query/interfaces";
import { processCollections } from "../../utils/query/tokens";

import loaderImage from "../../assets/images/loaders/golden.svg";

import styles from "./TokenInfo.module.sass";
import useTokenFetch from "../../customHooks/useTokenFetch";
import { COLLECTION_ADDRESSES_TESTNET, testnetNftAddressToNameMapping } from "../../ethers/nftCollections/testnet";

const getTokenImageData = (openseaResponse) => (
    {
        data: {
            image: openseaResponse?.nft?.image_url
        }
    }
);

const MIN_RAND_TOKEN_ID = 1000;
const MAX_RAND_TOKEN_ID = 2000;

const TRAIT_TOGGLE = "trait";
const USDC_TOGGLE = "usdc";

const TokenInfo = () => {

    const { chainId, library, account } = useEthers();
    const hideOpensea = true;

    const dispatch = useDispatch();

    const history = useHistory();

    const conversionRates = useSelector((state) => state.conversions);
    // const [hideOpensea, setHideOpensea] = useState(true);

    const [collectionAddress, setCollectionAddress] = useState("");
    const [selectedCollectionName, setSelectedCollectionName] = useState("");
    const [selectedIpfsHash, setSelectedIpfsHash] = useState();
    const [selectedUpcomingIpfsHash, setSelectedUpcomingIpfsHash] = useState();
    const [tokenId, setTokenId] = useState("");
    const [openSeaResponse, setOpenSeaResponse] = useState();
    const [ipfsHashResponse, setIPFSHashResponse] = useState();
    const [tokenDetails, setTokenDetails] = useState();
    const [loading, setLoading] = useState(false);

    const [borrowFromPool, setBorrowfromPool] = useState();

    const config = useConfig();
    const { collectionParam, tokenIdParam } = useParams();
    const requestRef = useRef();

    const routerContract = new Contract(
        config.routerAddress,
        ROUTER_ABI,
        library
    );

    // get supported pools in chain
    const pools = supportedPools
        .filter(pool => pool.chainId === chainId && pool.title !== OLD_STR);

    // const { tokens } = useGetOwnerNFTs();

    const { tokens, refetch: refetchCollectionsData ,loading: isCollectionsLoading } = useTokenFetch();

    const collectionsData = processCollections(
        tokens,
        config,
        false
    );

    const wethPool = pools?.find(pool => pool.currency === "WETH");
    const usdcPool = pools?.find(pool => pool.currency === "USDC");

    // get balance for each pool
    const enumerableTokenBalanceCalls = pools?.length ?
        pools.map((pool) => {
            const paymentTokenAddr = config.paymentTokens[pool.currency];
            return {
                contract: new Contract(paymentTokenAddr, ERC20Interface),
                method: "balanceOf",
                args: [pool.address],
            };
        }
        ) : [];

    const enumerablePoolBalances = useCallsGracefully(
        enumerableTokenBalanceCalls
    );

    const currentChainCollectionAddressToName = useMemo(
        () => chainId === TEST_NETWORK_ID
            ? collectionAddressToNameTestnet
            : collectionAddressToNameMainnet,
        [chainId]);

    const currentChainNftAddressToNameMapping = useMemo(
        () => chainId === TEST_NETWORK_ID
            ? testnetNftAddressToNameMapping
            : nftAddressToNameMapping,
        [chainId]);

    useEffect(() => {

        const fetchDetails = async () => {

            const getMaxBorrowAmount = async (
                poolAddress,
                tokenAddress,
                tokenId,
                amount
            ) => {

                const poolContract = new getPoolContract(
                    poolAddress,
                    library
                );

                const deadline = getDeadline();

                const trueMaxBorrow = await poolContract.getBorrowMaximum(
                    tokenAddress,
                    tokenId,
                    amount,
                    deadline
                );

                return trueMaxBorrow;
            }

            const getPriceLabel = (amount, currency) => {

                const amountInUSD = amount
                    * conversionRates.WETH;

                const usdLabel = `(${getPrettyValue(amountInUSD, 0)} USD)`;
                return `${getPrettyValue(amount)} ${currency} ${usdLabel}`
            }

            let poolBorrowDetails = {};

            if (pools?.length) {
                const poolBorrowDetailValues = await Promise.all(
                    pools.map(async (pool, index) => {

                        const maxBorrow = await getMaxBorrowAmount(
                            pool.address,
                            collectionAddress,
                            tokenId,
                            ipfsHashResponse.amount
                        );

                        const balance = enumerablePoolBalances?.[index];

                        const cappedBorrow = (!balance || !maxBorrow)
                            ? 0
                            : maxBorrow.gt(balance)
                                ? balance
                                : maxBorrow;

                        return {
                            ltv: pool.ltv,
                            currency: pool.currency,
                            cappedBorrow: formatUnits(
                                cappedBorrow,
                                config.decimals[pool.currency]
                            ),
                        }
                    }));

                const getRow = (pricing, ipfsHashResponse, config) => {
                    const merklePricing = parseFloat(ipfsHashResponse.amount);
                    const ltvMultiplier = parseFloat(pricing.ltv) / 100;
                    const tokenDecimals = (10 ** config.decimals.WETH);

                    const res = merklePricing
                        * ltvMultiplier
                        / tokenDecimals;

                    return `${getPriceLabel(res, "ETH")}`;
                }

                poolBorrowDetails = poolBorrowDetailValues?.reduce(
                    (details, pricing) => ({
                        ...details,
                        [`Borrow ${pricing.ltv}`]: `${getRow(pricing, ipfsHashResponse, config)}`
                    }), {})
            }

            const pricedValue = ipfsHashResponse.amount;
            const formattedPricedValue = Boolean(pricedValue?.length)
                && parseFloat(pricedValue) / (10 ** config.decimals.WETH);

            setTokenDetails(
                {
                    ...(ipfsHashResponse.amount && {
                        'Priced Value': getPriceLabel(formattedPricedValue, 'ETH'),
                    }),
                    ...poolBorrowDetails,
                    // proof: ipfsHashResponse.proof,
                }
            )

        }
        if (ipfsHashResponse) {
            fetchDetails();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ipfsHashResponse]);


    const formatTree = data => {
        const keys = Object.keys(data);

        return keys.map(key => {
            const hasChildren = Boolean(data?.[key] && typeof data[key] === "object");
            return {
                label: (
                    <>
                        {hasChildren
                            ? key
                            : (
                                <>
                                    <span className={styles.treeLabel}>{key}</span>
                                    <span className={styles.treeValue}>{data[key]}</span>
                                </>
                            )
                        }
                    </>
                ),
                children: hasChildren ? formatTree(data[key]) : data[key]
            }
        })
    }

    const handleIPFSHashRequest = async (optionalAddr, optionalId) => {

        setLoading(true);

        // On first load state will be empty and default to param from useEffect invocation
        const requestAddress = collectionAddress || optionalAddr;
        const requestID = tokenId || optionalId;

        try {


            const collectionKey = Object.keys(currentChainCollectionAddressToName)
                .find(key =>
                    key.toLowerCase().trim() === requestAddress.toLowerCase().trim()
                );

            const collectionName = currentChainCollectionAddressToName[collectionKey];

            const collectionHash = await routerContract.merkleIPFS(
                collectionKey
            );

            const response = await axios.get(
                `${BACKEND_URL}/collections/${collectionName}/${collectionHash}/${requestID}`
            );

            setIPFSHashResponse(
                response.data
            );

        } catch (ex) {
            console.log('error', ex);
        } finally {
            setLoading(false);
        }

    }

    const handleOnCollectionChange = value => {
        setSelectedCollectionName(value);
        const address = Object
            .keys(currentChainNftAddressToNameMapping)
            .find(address => currentChainNftAddressToNameMapping[address]?.name === value);
        setCollectionAddress(address);
    }

    const handleRequest = (optionalAddr, optionalId) => {

        handleOpenSeaRequest(
            optionalAddr,
            optionalId
        );

        handleIPFSHashRequest(
            optionalAddr,
            optionalId
        );
    }

    const handleOpenSeaRequest = async (optionalAddr, optionalId) => {

        setTokenDetails(null);
        setIPFSHashResponse(null);
        setOpenSeaResponse(null);
        setLoading(true);

        // On first load state will be empty and default to param from useEffect invocation
        const requestAddress = collectionAddress || optionalAddr;
        const requestID = tokenId || optionalId;

        try {

            const response = await axios.get(
                // `${OPENSEA_API}/api/v1/asset/${requestAddress.trim()}/${requestID.trim()}/?include_orders=false`,
                `${BACKEND_URL}/picture/${requestAddress}/${requestID}`,
                {
                    headers: {
                        // "X-API-KEY": OPENSEA_API_KEY,
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
                    }
                }
            );

            setOpenSeaResponse(
                response.data
            );

        } catch (ex) {
            console.log('error', ex);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {

        // Chose the most popular for default?
        const defaultCollectionAddr = chainId === TEST_NETWORK_ID
            ? COLLECTION_ADDRESSES_TESTNET.BORED_APES
            : COLLECTION_ADDRESSES_MAINNET.BORED_APES;

        if (collectionParam && isAddress(collectionParam)) {

            const checksumAddr = getAddress(
                collectionParam
            );

            const supportedAddress = currentChainCollectionAddressToName[checksumAddr];

            if (checksumAddr && supportedAddress) {

                const name = config.getCollectionNameFromAddress(
                    checksumAddr,
                    chainId
                );

                setCollectionAddress(
                    checksumAddr
                );

                setSelectedCollectionName(
                    name
                );

                // if valid collection check for id and invoke requests
                if (tokenIdParam) {
                    // ID validation?
                    setTokenId(
                        tokenIdParam
                    );

                    handleRequest(
                        checksumAddr,
                        tokenIdParam
                    );
                }
            }

            return;
        }

        const randomTokenId = Math.floor(
            Math.random()
            * (MAX_RAND_TOKEN_ID - MIN_RAND_TOKEN_ID + 1) + MIN_RAND_TOKEN_ID
        ).toString();

        setTokenId(randomTokenId);

        setCollectionAddress(
            defaultCollectionAddr
        );

        setSelectedCollectionName(
            currentChainNftAddressToNameMapping[defaultCollectionAddr]?.name
        );

        handleRequest(defaultCollectionAddr, randomTokenId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [collectionParam, tokenIdParam, chainId, account]);

    const isRequestBtnDisabled = !collectionAddress?.length
        || !tokenId?.length
        || loading;

    const poolDetailsLoadingTree =
        useMemo(
            () => pools?.reduce(
                (details, pricing) => ({
                    ...details,
                    [`Borrow ${pricing.ltv}`]: '-'
                }),
                {
                    'Priced Value': '-'
                }
            )
            , [pools]);

    const collectionOptions =
        useMemo(() => {
            const visibleCollectionAddresses =
                Object.keys(
                    currentChainCollectionAddressToName
                );

            return Object.keys(currentChainNftAddressToNameMapping)
                .filter(
                    address => visibleCollectionAddresses.some(visibleCollectionAddress =>
                        visibleCollectionAddress?.trim()?.toLowerCase() === address?.trim()?.toLowerCase()
                    )
                )
                .map(
                    nft => currentChainNftAddressToNameMapping[nft]?.name
                )
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [chainId]);

    useEffect( () => {
        const fetchIpfsHash = async () => {

            const collectionList = chainId === TEST_NETWORK_ID
                ? collectionAddressToNameTestnet
                : collectionAddressToNameMainnet;

            const collectionKey = Object.keys(collectionList).find((key) => {
                return key.toLowerCase().trim() === collectionAddress.toLowerCase().trim();
            });

            const ipfsHash = await routerContract.merkleIPFS(
                collectionKey
            );

            setSelectedIpfsHash(
                ipfsHash
            );

            const pendingRoot = await routerContract.pendingRoots(
                collectionKey
            );

            const pendingIpfsHash = pendingRoot?.ipfsAddress;

            setSelectedUpcomingIpfsHash(
                pendingIpfsHash
            );
        }

        if (collectionAddress) {
            fetchIpfsHash();
            handleRequest(
                collectionAddress,
                tokenId
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        // chainId,
        collectionAddress,
        // handleRequest,
        // tokenId
    ]);

    const pricingData = useGetPricingData(
        selectedIpfsHash
    );

    const upcomingPricingData = useGetPricingData(
        selectedUpcomingIpfsHash
    );

    const showAllTraits = useSelector(
        (state) => state.settings.pricingPreferences?.showAllTraits
    );

    const showUsdcPrices = useSelector(
        (state) => state.settings.pricingPreferences?.showUsdcPrices
    );

    const handleToggle = (toggleType) => {

        const newConfig = {
            showAllTraits: toggleType === TRAIT_TOGGLE ? !showAllTraits : showAllTraits,
            showUsdcPrices: toggleType === USDC_TOGGLE ? !showUsdcPrices : showUsdcPrices
        };
        dispatch(
            setPricingPreferences(
                newConfig
            )
        );
        appLocalStorage.PRICING_OPTIONS.update(
            newConfig
        );
    }

    const pricingTreeContent = useMemo(() => {
        if (!pricingData) {
            return (
                <img
                    alt={"loader"}
                    src={loaderImage}
                    className={styles.loader}
                />
            );
        }
        return (
            <div className={styles.main}>
                <PricingTree
                    pricingData={pricingData}
                    upcomingPricingData={upcomingPricingData}
                    tokenName={selectedCollectionName}
                    showAllTraits={showAllTraits}
                    showUsdcPrices={showUsdcPrices}
                />
            </div>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        pricingData,
        upcomingPricingData,
        showAllTraits,
        showUsdcPrices
    ]);

    return (
        <>
        <div style={{ paddingTop: "32px", paddingBottom: "0px" }} className={cn("section", styles.section)}>
            <div className={styles.head}>
                <div style={{ justifyContent: "space-between" }} className={cn("container", styles.container)}>
                    <div className={styles.top}>
                        <div>
                            <div className={cn("h2", styles.title)}>
                                Pricing Data
                            </div>
                            <div style={{ marginBottom: "5px" }} className={styles.slogan}>
                                Check how much you can borrow
                            </div>
                        </div>
                        <div className={styles.sorting}>
                            <div className={styles.nav}>
                                {/*<ExtraOptions screen={"pools"} />*/}
                                <Checkbox
                                    className={styles.checkbox}
                                    value={showAllTraits}
                                    onChange={() => handleToggle(TRAIT_TOGGLE)}
                                    content="Show All Traits"
                                />
                                <Checkbox
                                    className={styles.checkbox}
                                    value={showUsdcPrices}
                                    onChange={() => handleToggle(USDC_TOGGLE)}
                                    content="Show USDC Prices"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className={cn("container", styles.containerMo)}>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    handleRequest(collectionAddress, tokenId);
                }}
            >
                <div className={styles.content}>
                    <div className={cn(styles.column)}>
                        <div className={styles.field}>
                            <div className={styles.label}>
                                Collection
                            </div>
                            <Dropdown
                                value={selectedCollectionName}
                                options={collectionOptions}
                                setValue={handleOnCollectionChange}
                            />
                        </div>
                    </div>
                    <div className={cn(styles.column)}>
                        <div className={cn(styles.field)}>
                            <TextInput
                                label="Token ID"
                                value={tokenId}
                                onChange={(e) => setTokenId(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className={cn(styles.column)}>
                        <button
                            className={
                                cn(
                                    "button",
                                    "button-small",
                                    styles.button,
                                    styles.requestButton,
                                    {
                                        disabled: isRequestBtnDisabled
                                    }
                                )}
                            type="button"
                            onClick={handleRequest}
                            ref={requestRef}
                            disabled={isRequestBtnDisabled}
                        >
                            {loading && <Loader className={styles.loaderSmall} color="white" />}
                            Request Token Info
                        </button>
                    </div>
                </div>
            </form>
            <div className={styles.content}>
                <div className={cn(styles.column)}>
                    {pricingTreeContent}
                </div>
                <div className={cn(styles.column)}>
                    <div className={cn(styles.box)}>
                        <div className={styles.imgContainer}>
                            <img
                                alt={"loader"}
                                src={loaderImage}
                                className={styles.loader}
                            />
                            {openSeaResponse && (
                                <TokenImage
                                    token={getTokenImageData(openSeaResponse)}
                                    useIntersection={false}
                                    className={cn(styles.img, styles.tokenImage)}
                                />
                            )}
                        </div>
                    </div>
                </div>
                <div className={cn(styles.column)}>
                    <div className={styles.box}>
                        <div>
                            <PerfectScrollbar className={styles.scrollbar}>
                                <Tree data={
                                    formatTree(tokenDetails ? tokenDetails : poolDetailsLoadingTree)
                                } />
                            </PerfectScrollbar>
                        </div>
                    </div>
                    {
                        openSeaResponse && !hideOpensea && (
                            <>
                                <div className={cn("h4", styles.title)}>
                                    Opensea
                                </div>
                                <div className={styles.box}>
                                    <div>
                                        <PerfectScrollbar className={styles.scrollbar}>

                                            <Tree data={
                                                formatTree(openSeaResponse)
                                            } />
                                        </PerfectScrollbar>
                                    </div>
                                </div>
                            </>
                        )
                    }
                    <button
                        className={
                            cn(
                                "button",
                                "button-small",
                                styles.button
                            )}
                        type="button"
                        onClick={() => setBorrowfromPool(wethPool)}
                    >
                        Borrow WETH Funds
                    </button>
                    <button
                        className={
                            cn(
                                "button",
                                "button-small",
                                styles.button
                            )}
                        type="button"
                        onClick={() => setBorrowfromPool(usdcPool)}
                    >
                        Borrow USDC Funds
                    </button>
                </div>
            </div>
            <Modal
                visible={borrowFromPool}
                // outerClassName={"extended"}
                onClose={() => setBorrowfromPool(null)}
            >
                {
                    borrowFromPool && (
                        <Borrow
                            poolAddress={borrowFromPool.address}
                            paymentToken={borrowFromPool.currency}
                            title={borrowFromPool.title}
                            closeModal={() => {
                                setBorrowfromPool(null);
                                history.push('/profile/instant-loans');
                            }}
                            collectionData={collectionsData}
                            onRefreshIconClick={refetchCollectionsData}
                            isCollectionsLoading={isCollectionsLoading}
                            selectedPool={borrowFromPool}
                            selectedCurrency={borrowFromPool.currency}
                            supportedCollections={borrowFromPool.supportedCollections}
                        />
                    )
                }
            </Modal>
        </div>
    </>
    )
}

export default TokenInfo;
