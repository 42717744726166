import { useCalls } from "@usedapp/core";

/**
 * Gracefully calls `useDApp`'s `useCalls`, logging any errors.
 * @param calls a list of contract calls
 * @param args any remaining arguments for `useCalls` {@link useCalls}
 * @returns deconstructed results of `useCalls`
 */

const MINIMUM_RESULT_SIZE = 1;

const useCallsGracefully = (calls = [], ...args) => {

    const results = useCalls(
        calls,
        ...args
    );

    return results.map((result, idx) => {
        if (result && result.error) {
            const call = calls[idx];
            console.error(
                `Error encountered calling ${call.method} on ${call.contract.address}: ${result.error.message}`
            );
            return undefined;
        }
        // early return value if multiple return values
        if (result?.value?.length > MINIMUM_RESULT_SIZE) return result.value;
        return result?.value?.[0];
    });
};

export default useCallsGracefully;
