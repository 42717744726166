import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import cn from "classnames";
import { getAddress } from "ethers/lib/utils";

import styles from "./Profile.module.sass";
import User from "./User";
// import Items from "./Items";
import CollectionsDataWrapper from "./Collections/CollectionsDataWrapper";
// import { loans } from "../../mocks/loans";
import Icon from "../../components/Icon";
import InstantList, { InstantListView } from "../../components/InstantList";
import LockerList from "../../components/LockerList";
import LockerListContributions from "../../components/LockerList/LockerListContributions";
import Pools from "../InstantPools/Pools";
import Borrow from "../../components/Borrow";
import supportedPools from "../InstantPools/Pools/data";
import Modal from "../../components/Modal";

// const navLinks = ["My Collections", "My Contributions", "Collateralized NFT", "Liquidated NFT"];
const navLinks = [
    { title: "Wallet NFTs", icon: "wallet", size: "22" },
    { title: "Active Loans", icon: "lightning", size: "20" },
    { title: "Funded Pools", icon: "bag", size: "22" },
    // "Funded Listings",
    // "Custom Listings",
];

const socials = [
    {
        title: "twitter",
        url: "https://twitter.com/wisetoken",
    },
    {
        title: "instagram",
        url: "https://www.instagram.com/wisetoken",
    },
    {
        title: "facebook",
        url: "https://www.facebook.com/wisetoken.net/",
    },
];

const TAB_LINKS = [
    'wallet-nfts',
    'instant-loans',
    'funded-pools',
    'funded-listings',
    'custom-listings'
];

const Profile = ({
    usersLockers,
    hasParamAndNotUser,
    address,
    account,
    chainId,
    library,
    config
}) => {

    const history = useHistory();
    const { pathname } = useLocation();
    const [activeIndex, setActiveIndex] = useState(0);
    const [visible] = useState(false);

    const [isBorrowModalVisible, setIsBorrowModalVisible] = useState(false);
    const [selectedPool, setSelectedPool] = useState();
    const [borrowCollectionsData, setBorrowCollectionsData] = useState([]);
    const [defaultSelectedToken, setDefaultSelectedToken] = useState();

    const showPastLoans = useSelector((state) => state.settings.showPastLoans);

    const [collectionsDataWrapperKey, setCollectionsDataWrapperKey] = useState(0);

    const activateTab = (index) => {
        // update URL without rerendering through router dom
        window.history.replaceState(null, "", `/profile/${TAB_LINKS[index]}/${address || ''}`);
        setActiveIndex(index);
    };

    useEffect(() => {
        if (pathname.includes('wallet-nfts')) setActiveIndex(0);
        else if (pathname.includes('instant-loans')) setActiveIndex(1);
        else if (pathname.includes('funded-pools')) setActiveIndex(2);
        else if (pathname.includes('funded-listings')) setActiveIndex(3);
        else if (pathname.includes('custom-listings')) setActiveIndex(4);
    }, [pathname]);

    const handleTokenClick = (collection, items, token) => {
        const pool = collection?.pools?.length && supportedPools
            ?.find(supportedPool =>
                // collection.pools contains only Global WETH for now
                supportedPool.address === collection.pools[0]
            );

        if (pool) {
            if (token) {
                const collection = config.getCollectionNameByAddress(token.address);
                setDefaultSelectedToken({
                    tokenId: token.id,
                    tokenAddress: token.address,
                    collection: collection?.name
                });
            } else {
                setDefaultSelectedToken(null);
            }
            setSelectedPool(pool);
            setBorrowCollectionsData(items);
            setIsBorrowModalVisible(true);
        } else {
            const checksumAddr = getAddress(collection.address);
            history.push(`/loan-details/${checksumAddr}`);
        }
    };

    useEffect(() => {
        setIsBorrowModalVisible(false);
    }, [account]);

    const handleDoneMint = () => {
        setActiveIndex(0);
        setCollectionsDataWrapperKey((prevKey) => prevKey + 1);
    };

    return (
        <div className={styles.profile}>
            <div
                className={cn(styles.head, { [styles.active]: visible })}
                style={{
                    backgroundImage: "url(/images/content/bg.jpg)",
                    // backgroundImage: "url(https://wallpaperaccess.com/full/7134013.jpg)",
                }}
            >
            </div>
            <div className={styles.body}>
                <div className={cn("container", styles.container)}>
                    <User
                        className={styles.user}
                        item={socials}
                        activeIndex={activeIndex}
                        onDoneMint={handleDoneMint}
                    />
                    <div className={styles.wrapper}>
                        <div className={styles.nav}>
                            {navLinks.map((x, index) => (
                                <button
                                    className={cn(styles.link, {
                                        [styles.active]: index === activeIndex,
                                    })}
                                    key={index}
                                    onClick={() => activateTab(index)}
                                >
                                    {<Icon name={x.icon} size={x.size} />} {x.title}
                                </button>
                            ))}
                        </div>
                        <div className={styles.group}>
                            <div className={styles.item}>
                                {activeIndex === 0 && (
                                    <CollectionsDataWrapper
                                        key={collectionsDataWrapperKey}
                                        // className={"profile"}
                                        hasParamAndNotUser={hasParamAndNotUser}
                                        config={config}
                                        address={address}
                                        onPreviewTokenClick={handleTokenClick}
                                    />
                                )}
                                {activeIndex === 1 && (
                                    <div style={{ paddingTop: "25px" }}>
                                        <InstantList
                                            // className={"profile"}
                                            lockerData={usersLockers}
                                            library={library}
                                            chainId={chainId}
                                            showOwner={false}
                                            showAll={true}
                                            hideClosedLoans={!showPastLoans}
                                            userOnly={true}
                                            optionalAddress={hasParamAndNotUser && address}
                                            view={InstantListView.PROFILE}
                                        />
                                    </div>
                                )}
                                {activeIndex === 2 && (
                                    <Pools
                                        pageMode={'user'}
                                        wrapperClass={'no-border'}
                                        selectedPools={'all'}
                                        account={account}
                                        optionalAddress={hasParamAndNotUser && address}
                                    // collectionsData={collectionsData}
                                    />
                                )}
                                {activeIndex === 3 && (
                                    <LockerListContributions
                                        className={styles.items}
                                        hasParamAndNotUser={hasParamAndNotUser}
                                        address={address}
                                        chainId={chainId}
                                        library={library}
                                    />
                                )}
                                {activeIndex === 4 && (
                                    <LockerList
                                        className={styles.items}
                                        lockerData={usersLockers}
                                        chainId={chainId}
                                        library={library}
                                        optionalAddress={hasParamAndNotUser && account}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                visible={isBorrowModalVisible}
                onClose={() => setIsBorrowModalVisible(false)}
            >
                {
                    selectedPool && (
                        <Borrow
                            poolAddress={selectedPool.address}
                            paymentToken={selectedPool.currency}
                            title={selectedPool.title}
                            closeModal={() => {
                                setIsBorrowModalVisible(false);
                                history.push('/profile/instant-loans');
                            }}
                            collectionData={borrowCollectionsData}
                            selectedPool={selectedPool}
                            selectedCurrency={selectedPool.currency}
                            supportedCollections={selectedPool.supportedCollections}
                            defaultSelectedToken={defaultSelectedToken}
                        />
                    )
                }
            </Modal>
        </div>
    );
};

export default Profile;
