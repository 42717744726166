import { useEthers } from "@usedapp/core";
import cn from "classnames";

import styles from "./ButtonTVL.module.sass";
import { useTVL } from "../../customHooks/useTVL";
import supportedPools from "../../screens/InstantPools/Pools/data";
import { getPrettyValue } from "../../utils";

const ButtonTVL = () => {

    const { chainId } = useEthers();

    const pools = supportedPools.filter(
        (pool) => pool.chainId === chainId
    );

    const { totalValueLocked } = useTVL(
        pools
    );

    const displayValue = totalValueLocked
        ? getPrettyValue(totalValueLocked)
        : "...";

    return (
        <button
            className={cn(styles.link, {
                [styles.active]: true,
            })}
            onClick={() => {
                console.log('show breakdown popup');
            }}
        >
            Total Value Locked 💰 {displayValue} USD
        </button>
    );
};

export default ButtonTVL;
