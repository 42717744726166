import React from "react";
import cn from "classnames";
import styles from "./RemoveLoan.module.sass";

const RemoveLoan = ({ className }) => {
    return (
        <div className={cn(className, styles.transfer)}>
            <div className={cn("h4", styles.title)}>Remove Loan</div>
            <div className={styles.text}>
                Do you really want to remove your NFT now? You can put it on sale
                anytime later
            </div>
            <div className={styles.btns}>
                <button className={cn("button", styles.button)}>Remove now</button>
                <button className={cn("button-stroke", styles.button)}>Cancel</button>
            </div>
        </div>
    );
};

export default RemoveLoan;
