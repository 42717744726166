const scopes = [
    {
        title: "Satoshibles #23",
        url: "/details",
        image: "https://satoshibles.com/token/22/small/image.png",
        image2x: "https://satoshibles.com/token/24/small/image.png",
        category: "white",
        categoryText: "owns",
        level: "green",
        levelText: "1.00% APR",
        counterVideo: "7",
        counterUser: "160",
    },
    {
        title: "Satoshibles #23",
        url: "/details",
        image: "https://satoshibles.com/token/27/small/image.png",
        image2x: "https://satoshibles.com/token/27/large/image.png",
        category: "white",
        categoryText: "owns",
        level: "green",
        levelText: "1.00% APR",
        counterVideo: "7",
        counterUser: "160",
    },
    {
        title: "Satoshibles #23",
        url: "/details",
        image: "https://satoshibles.com/token/33/small/image.png",
        image2x: "https://satoshibles.com/token/27/large/image.png",
        category: "white",
        categoryText: "owns",
        level: "green",
        levelText: "1.00% APR",
        counterVideo: "7",
        counterUser: "160",
    },
    {
        title: "Satoshibles #23",
        url: "/details",
        image: "https://satoshibles.com/token/32/small/image.png",
        image2x: "https://satoshibles.com/token/33/large/image.png",
        category: "white",
        categoryText: "owns",
        level: "green",
        levelText: "1.00% APR",
        counterVideo: "7",
        counterUser: "160",
    },
];

const buildSocials = (insta, twitter, linkedin, facebook) => {
    const socials = [];
    if (insta) {
        socials.push({
            type: "instagram",
            url: `https://instagram.com/${insta}`,
        });
    }
    if (linkedin) {
        socials.push({
            type: "linkedin",
            url: `https://linkedin.com/in/${linkedin}`,
        });
    }
    if (twitter) {
        socials.push({
            type: "twitter",
            url: `https://twitter.com/${twitter}`,
        });
    }
    if (facebook) {
        socials.push({
            type: "twitter",
            url: `https://twitter.com/${twitter}`,
        });
    }
    return socials;
};

const PETER_BIO = {
    name: "Peter Girr",
    image: "/images/baby-owls/peter.jpeg",
    realImage: "/images/team/peter.png",
    position: "WISE Founder",
    about: `CEO of WiseSoft LLC, Peter has built multiple blockchain projects, his main focus being the economic design of decentralized financial systems.
    This strategy-centered passion developed partly during his training as an intelligence officer in the US Army, partially from his experience as a real estate entrepreneur,
    and was also fueled by his background as a competitive gamer.`,
    scopes: scopes,
    socials: buildSocials(null, null, "peter-girr-7415ab67"),
};

const CHRISTOPH_BIO = {
    name: "Christoph Krpoun",
    image: "/images/baby-owls/4.jpg",
    realImage: "/images/team/christof.png",
    position: "Smoking Talent",
    about: `Christoph did his masters in theoretical physics at the
    elite University Hamburg. He wrote his thesis in corporation with the
    DESY about the creation of primordial black holes in the early universe.
    Furthermore, he completed his second masters in mathematics at the
    Humboldt University Berlin — also an excellence university. In summer 2021,
    he started his PhD in mathematical physics in Regensburg.`,
    scopes: scopes,
    socials: buildSocials(null, null, "christoph-krpoun-2762a4175"),
};

const RENE_BIO = {
    name: "Rene Mastermind",
    image: "/images/baby-owls/27.jpg",
    realImage: "/images/team/rene.png",
    position: "Code Cracker",
    about: `Rene is a published particle physics graduate from an elite university
    in Germany (Exzellenz Universität). He wrote his thesis for CERN,
    the largest research facility in the world. He turned poker professional in 2016.`,
    scopes: scopes,
    socials: buildSocials(null, "ThorbenFurtwngl"),
};

const VITALLY_BIO = {
    name: "Vitally Marinchenko",
    image: "/images/baby-owls/vitally.gif",
    realImage: "/images/team/vitally.png",
    position: "Solidity Samurai",
    about: `Living and breathing Solidity, Vitally is a first-class engineer focusing on the latest
    and most useful solutions in the EVM space. His main goal is to bring
    adoption to crypto, which is why Vitally shares his knowledge as a part-time tutor,
    teaching and coaching Solidity language to anyone interested`,
    scopes: scopes,
    socials: buildSocials("vitally.marinchenko", "77vitally", "vitally"),
};

const THOMAS_BIO = {
    name: "Thomas Foydel",
    image: "/images/baby-owls/204.jpg",
    realImage: "/images/team/thomas.png",
    position: "React Trainer",
    about: `Thomas is a software engineer from Chicago, focused primarily on fullstack web development in the decentralized finance and P2E spaces. With additional background in psychology and education, Thomas aims to build robust applications and allow users to navigate intuitively to satisfy their needs and desires.`,
    scopes: scopes,
    socials: buildSocials(null, null, "thomasfoydel"),
};

// const BRUCE_BIO = {
//     name: "Bruce Darcy",
//     image: "/images/baby-owls/110.jpg",
//     realImage: "/images/team/bruce.png",
//     position: "Solidity Punk",
//     about: `Bruce is a blockchain developer specializing in Solidity, Web3, and EVM environments.
//     He also has backgrounds in High Performance Computing, Machine Learning, and Cyber Security.`,
//     scopes: scopes,
//     socials: buildSocials(null, null, "bruce-darcy-82a55a162"),
// };

/*const KATALINA_BIO = {
    name: "Katalina Vasquez",
    image: "/images/baby-owls/34.jpg",
    realImage: "/images/team/katalina.jpeg",
    position: "Creative Owl",
    about: "",
    scopes: scopes,
    socials: buildSocials(null, null, "katalinavz"),
};*/

const OSAMA_BIO = {
    name: "Osama Miro",
    image: "/images/baby-owls/203.jpg",
    realImage: "/images/baby-owls/203.jpg",
    position: "React Hipster",
    about: `Osama is a Web3 engineer based in NYC. He has experience building scalable front-end applications,
    Dapps, crypto wallets, and generative art. He is motivated by empowering users with innovative and decentralized financial tools.`,
    scopes: scopes,
    socials: buildSocials(null, null, "osamamiro"),
};

const ALICE_BIO = {
    name: "Alice Chen",
    image: "/images/baby-owls/alice.jpeg",
    realImage: "/images/team/alice.png",
    position: "Marketing Owl",
    about: `Alice is a recent USC Marshall Business School Graduate based in Los Angeles.
    She first knew Wise in summer 2021. Having no previous knowledge in crypto, she
    soon immersed herself in the space and is now working on strategies that dive the
    fundamentals and help people understand what Wise could do for them.`,
    scopes: scopes,
    socials: buildSocials(null, null, "tingyuan-alice-chen-294155118"),
};

const AARON_BIO = {
    name: "Aaron Hanson",
    image: "/images/baby-owls/192.jpg",
    realImage: "/images/team/aaron.png",
    position: "Coffee Converter",
    about: "Aaron been writing software for over 30 years. Currnetly working as a principal solidity engineer at the Rainmaker Games company. Original author of the teal paper for the WISE Token project",
    scopes: scopes,
    socials: buildSocials(null, "coffeeconverter", "ildiscgolfer"),
};

/*const DEVIN_BIO = {
    name: "Devin Marty",
    image: "/images/baby-owls/3.jpg",
    realImage: "/images/team/devin.png",
    position: "Owl Position",
    about: "",
    scopes: scopes,
    socials: buildSocials(null, null, "devinmarty"),
};*/

/*const SAPIR_BIO = {
    name: "Sapir Matmon",
    image: "/images/baby-owls/33.jpg",
    realImage: "/images/team/sapir.png",
    position: "Creative Owl",
    about: "",
    scopes: scopes,
    socials: buildSocials(null, null, "sapir-m"),
};*/

const JOWELYN_BIO = {
    name: "Jowelyn Hornilla",
    image: "/images/baby-owls/482.jpg",
    realImage: "/images/team/jowelyn.png",
    position: "Frontend Sunflower",
    about: `Jowelyn is a software engineer with a demonstrated history of developing in various software industries.
    She has a background in web and mobile app development and is currently engrossed in the world of crypto.`,
    scopes: scopes,
    socials: buildSocials(null, "jowelynhornilla", "jowelyn-hornilla-1a1540179"),
};

const JENELYN_BIO = {
    name: "Jenelyn Contillo",
    image: "/images/baby-owls/483.png",
    realImage: "/images/team/jenelyn.png",
    position: "Web3 Mushroom",
    about: `Jenelyn is a seasoned Web Developer with expertise in all aspects of the software development life cycle. Despite her years of experience, she remains eager to learn and expand her skillset. While still relatively new to the world of crypto, she is excited to immerse herself in this rapidly-evolving field.`,
    scopes: scopes,
    socials: buildSocials("jenelyn02141995", "jennaaaiiii", "jenelyn-contillo-b7a53424a"),
};


export const teamList = [
    {
        title: "Core",
        scopes: [
            PETER_BIO,
            CHRISTOPH_BIO,
            RENE_BIO,
            VITALLY_BIO,
            JOWELYN_BIO,
            JENELYN_BIO
        ],
    },
    {
        title: "Solidity",
        scopes: [
            VITALLY_BIO,
            CHRISTOPH_BIO,
            RENE_BIO,
        ],
    },
    {
        title: "Web3",
        scopes: [
            VITALLY_BIO,
            JOWELYN_BIO,
            JENELYN_BIO
        ],
    },
    {
        title: "UI/UX",
        scopes: [VITALLY_BIO],
    },
    {
        title: "Past",
        scopes: [
            AARON_BIO,
            THOMAS_BIO,
            // SAPIR_BIO,
            // KATALINA_BIO,
            ALICE_BIO,
            // BRUCE_BIO,
            OSAMA_BIO,
        ],
    },
];
