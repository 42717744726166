import React from "react";
import { useParams } from "react-router-dom";
import { useEthers } from "@usedapp/core";
// import useOwnedLockerFetch from "../../customHooks/useOwnedLockerFetch";
// import useUserLoans from "../../customHooks/useUserLoans";
import useUserLockers from "../../customHooks/useUserLockers";
import useConfig from "../../customHooks/useConfig";
import Profile from "./Profile";

const ProfileWrapper = () => {

    const {
        chainId,
        library,
        account
    } = useEthers();

    const config = useConfig();
    const { address } = useParams();

    // check for address and not account b/c if no address param then it is user's profile
    const hasParamAndNotUser = address && address !== account;

    // const [usersLoans] = useUserLoans(
        // hasParamAndNotUser && address
    // );

    const [usersLockers] = useUserLockers(
        hasParamAndNotUser && address
    );

    return (
        <Profile
            usersLockers={usersLockers}
            hasParamAndNotUser={hasParamAndNotUser}
            address={address}
            account={account}
            chainId={chainId}
            library={library}
            config={config}
        />
    );
};

export default ProfileWrapper;
