import React, { useState } from "react";
import cn from "classnames";
import styles from "./Teams.module.sass";
import Item from "./Item";
import Dropdown from "../../../components/Dropdown";
import ScrollParallax from "../../../components/ScrollParallax";

// data
import { teamList } from "../../../mocks/teams";

const Teams = () => {
    const options = [];
    teamList.map((x) => options.push(x.title));

    const [direction, setDirection] = useState(options[0]);

    return (
        <div className={cn("section", styles.section)}>
            <div className={cn("container", styles.container)}>
                <h2 className={cn("h2", styles.title)}>The Builders</h2>
                {/*<div className={styles.info}>
                    WISE Team is ready to support DeFi projects along the way from the start till
                    going DAO, we have all the tools!
                </div>*/}
                <div className={styles.nav}>
                    {teamList.map((x, index) => (
                        <button
                            className={cn(styles.btn, {
                                [styles.active]: x.title === direction,
                            })}
                            onClick={() => setDirection(x.title)}
                            key={index}
                        >
                            {x.title}
                        </button>
                    ))}
                </div>
                <Dropdown
                    className={styles.dropdown}
                    value={direction}
                    setValue={setDirection}
                    options={options}
                />
                <div className={styles.list}>
                    {teamList
                        .find((x) => x.title === direction)
                        .scopes.map((x, index) => (
                            <ScrollParallax key={index} className={styles.box}>
                                <Item className={styles.item} item={x} />
                            </ScrollParallax>
                        ))}
                </div>
            </div>
        </div>
    );
};

export default Teams;
