import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Interface } from "ethers/lib/utils";
import { useEthers } from "@usedapp/core";
import { Contract } from "ethers";

import useConfig from "./useConfig";
import LIQUID_LOCKER_ABI from "../ethers/abis/LiquidLocker.json";

const parseLog = (log, contractInterface) => ({
    blockNumber: log.blockNumber,
    ...contractInterface.parseLog(log),
});

const getData = (event) => ({
    user: event.args.claimAddress,
    amount: event.args.claimAmount,
    blockNumber: event.blockNumber,
});

const addTxHash = (data, logs) =>
    data.map((d, i) => ({
        ...d,
        transactionHash: logs[i].transactionHash
    })
);

const addTimestamp = (data, library) =>
    Promise.all(
        data.map(async (d) => {
            const { timestamp } = await library.getBlock(d.blockNumber);
            return { ...d, timestamp };
        })
    );

const lockerInterface = new Interface(
    LIQUID_LOCKER_ABI
);

const parseClaimLog = (log) => parseLog(
    log,
    lockerInterface
);

const useClaimEvents = (lockerAddress, paymentToken) => {

    const config = useConfig();
    const { library } = useEthers();
    const latestTxHash = useSelector((state) => state.settings.latestTxHash);

    const lockerContract = new Contract(
        lockerAddress,
        lockerInterface
    );

    const [claimEvents, setClaimEvents] = useState([]);
    const [combinedClaimEvents, setCombinedClaimEvents] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchLogs = async () => {

            setLoading(true);

            const claimFilter = lockerContract.filters.ClaimMade();

            const claimLogs = await library.getLogs({
                ...claimFilter,
                fromBlock: config.inceptionBlock
            });

            const claimEvents = claimLogs.map(
                parseClaimLog
            );

            const claimData = claimEvents.map(
                getData
            );

            const claimWithTxHash = addTxHash(
                claimData,
                claimLogs
            );

            const claimWithTxAndTime = await addTimestamp(
                claimWithTxHash,
                library
            );

            const claimEventsByUserMap = claimWithTxAndTime.reduce((newObj, curr) => {

                if (newObj[curr.user]) {
                    newObj[curr.user].amount =
                    newObj[curr.user].amount.add(curr.amount);

                    newObj[curr.user].count += 1;
                    return newObj
                }

                newObj[curr.user] = {
                    amount: curr.amount,
                    count: 1
                };

                return newObj;
            }, {});

            // Format events by user map to match paybackEvents array structure for switch toggles
            const claimEventsByUserArr = Object.keys(claimEventsByUserMap).map((userKey) => ({
                    user: userKey,
                    amount: claimEventsByUserMap[userKey].amount,
                    count: claimEventsByUserMap[userKey].count
                })
            );

            setCombinedClaimEvents(
                claimEventsByUserArr
            );

            setClaimEvents(
                claimWithTxAndTime
            );

            setLoading(false);
        };
        if (library) fetchLogs();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lockerAddress, library, latestTxHash]);
    return [claimEvents, combinedClaimEvents, loading];
};

export default useClaimEvents;
