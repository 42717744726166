import React, { useState, useEffect } from "react";
import cn from "classnames";
import Slider from "react-slick";
import { useEthers } from "@usedapp/core";
import InstantCard, { InstantCardModalType } from "../InstantCard";
import styles from "./HotInstant.module.sass";
import Icon from "../Icon";
import Loader from "../Loader";
import useConfig from '../../customHooks/useConfig';
import { TEST_NETWORK_ID } from "../../utils";
import { getBorrowEventTokens } from "../../utils/query/pools";

const NULL_ACCOUNT = null;

const SlickArrow = ({ currentSlide, slideCount, children, ...props }) => (
    <button {...props}>{children}</button>
);

const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 2,
    nextArrow: (
        <SlickArrow>
            <Icon name="arrow-next" size="14" />
        </SlickArrow>
    ),
    prevArrow: (
        <SlickArrow>
            <Icon name="arrow-prev" size="14" />
        </SlickArrow>
    ),
    responsive: [
        {
            breakpoint: 1179,
            settings: {
                slidesToShow: 5,
                slidesToScroll: 2,
            },
        },
        {
            breakpoint: 1023,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
            },
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
            },
        },
    ],
};

const HotInstant = ({ classSection, borrowEvents, borrowEventsTest }) => {

    const { library, chainId } = useEthers();

    const [instantList, setInstantList] = useState();
    const config = useConfig();

    useEffect(() => {

        let mounted = true;
        const stateHandler = (list) => {
            if (mounted) {
                setInstantList(list);
            }
        };

        if (mounted) {
            // reset instant list on account and chainId changes
            setInstantList(null);
        }

        const chainEvents = chainId === TEST_NETWORK_ID
            ? borrowEventsTest
            : borrowEvents;

        if (chainEvents && chainEvents.length) {
            getBorrowEventTokens({
                chainId,
                config,
                library,
                borrowEvents: chainEvents,
                user: NULL_ACCOUNT,
                setStateCallback: stateHandler,
                sortByLatest: true
            });
        }
        return () => {
            mounted = false;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [borrowEvents, borrowEventsTest, chainId]);
    // }, [borrowEvents]);

    // console.log('rerender');

    return (
        <div className={cn(classSection, styles.section)}>
            <div className={cn("container", styles.container)}>
                <div className={styles.wrapper}>
                    {/*<h3 className={cn("h3", styles.title)}>
                        Latest Instant NFT Loans
                    </h3>*/}
                    <div className={styles.box}>
                        <div className={styles.stage}>Check Latest</div>
                        <h3 className={cn("h3", styles.title)}>Instant NFT Loans</h3>
                    </div>
                    <div
                        className={styles.loader}
                        style={{ display: !instantList ? "flex" : "none" }}
                    >
                        <Loader color="white" />
                    </div>
                    <div className={styles.inner}>
                        {instantList && (
                            <Slider className="loan-slider" {...settings}>
                                {instantList.map((data, index) => {
                                    if (!data) return (<div key={index}></div>);
                                    return (
                                        <InstantCard
                                            blockModal={false}
                                            className={styles.card}
                                            modalType={InstantCardModalType.DETAIL}
                                            token={data}
                                            view={"Home"}
                                            key={index}
                                            showOwner
                                        />
                                    )
                                })}
                            </Slider>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HotInstant;
