import React, { useState, useMemo } from "react";
import { useSelector } from "react-redux";
import cn from "classnames";
import { formatUnits } from "ethers/lib/utils";
import { BigNumber } from "ethers";
import { useEthers } from '@usedapp/core';
import CurrencyInput from 'react-currency-input-field';

import { SECONDS_IN_DAY } from "../../utils";
import useConfig from "../../customHooks/useConfig";
import styles from "./Collaterize.module.sass";
import CollaterizeSteps from "./CollaterizeSteps";
import FormattedAmount from "../FormattedAmount";
import {
    PERCENT_OPTIONS,
    ONE_HUNDRED,
    SECONDS_IN_YEAR,
    DEFAULT_DECIMALS
} from "../../utils";

const Collaterize = ({
    className,
    amount,
    setAmount,
    totalAsked,
    paymentTokenAddr,
    borrowingFee,
    title,
    paymentTime,
    contributors,
    closeModal,
    lockerAddress,
    floorPrice,
    collectionName
}) => {
    const { account } = useEthers();
    const conversionRates = useSelector((state) => state.conversions);
    // const [fullAmount, setFullAmount] = useState(false);
    const [invalidAmount, setInvalidAmount] = useState(false);
    const [activeQuickSelect, setActiveQuickSelect] = useState();
    const [transactionHash, setTransactionHash] = useState("");
    const config = useConfig();

    const paymentToken = paymentTokenAddr && config.tokenAddressToNameMapping[paymentTokenAddr];

    const loanLength = paymentTime
        ? Math.floor(paymentTime.toNumber() / SECONDS_IN_DAY)
        : 0;

    const loanInfo = useMemo(
        () => [
            {
                title: "Loan Duration",
                value: `${loanLength} Days`,
            },
            /*{
                title: "Loan Duration",
                value: `${loanLength} Days`,
            },*/
            /*{
                title: "Loan interest",
                value: `${borrowingFee}%`,
            },*/
        ],
        [loanLength]
    );

    // safely format total amount asked
    let askedNum = totalAsked;
    if (!askedNum && !askedNum?._isBigNumber) askedNum = BigNumber.from(0);
    const paymentTokenName = paymentToken;
    const decimals = config.decimals[paymentTokenName];
    const formattedTotal = formatUnits(askedNum, decimals);

    const handleAmountChange = (val) => {
        if (invalidAmount) setInvalidAmount(false);

        setActiveQuickSelect(null);

        // early return for deleted value
        if (!val) {
            setAmount("");
            return;
        }

        // guard for total asked
        const parsedVal = parseFloat(val);
        const parsedFormattedTotal = parseFloat(formattedTotal);

        const total = formatUnits(remainingToTotal, decimals);
        let percent = parsedVal / total * ONE_HUNDRED;
        if (percent === ONE_HUNDRED) {
            percent = 'max';
        }

        const quickValue = PERCENT_OPTIONS[percent]
            ? percent.toString()
            : null;

        setActiveQuickSelect(
            quickValue
        );

        // review the logic here step1
        if (parsedVal <= parsedFormattedTotal) {
            setAmount(val);
        }
        // update switch based on new amount step2
        // if (fullAmount && val < formattedTotal) setFullAmount(false);

        // check equality step3
        // if (parsedVal === parsedFormattedTotal) setFullAmount(true);
    };

    const errorStyle = invalidAmount
        ? {borderBottom: "solid 1px #c73e77"}
        : {};

    const contributedTotal = useMemo(() => {
        // find if user has contributed
        const userObj = contributors.find((obj) => obj.user === account);
        return userObj ? userObj.amount : '0';
    }, [contributors, account]);

    const remainingToTotal = useMemo(() => {
        // find if user has contributed
        const userObj = contributors.find((obj) => obj.user === account);
        return userObj
            ? totalAsked.sub(userObj.amount)
            : totalAsked;
    }, [totalAsked, contributors, account]);

    const handleQuickSelect = (option) => {
        if (!remainingToTotal) return;

        setActiveQuickSelect(
            option
        );

        const total = formatUnits(
            remainingToTotal,
            decimals
        );
        
        let formattedNum = total;
        if (option !== 'max') {
            const percent = option / 100;
            formattedNum = total * percent;
        }
        setAmount(formattedNum.toString());
    };

    const floorPricePercentages = useMemo(() => {
        // convert collection floor and borrowing amount to usd for calc
        const floorPriceUSD = floorPrice * conversionRates['WETH'];
        const contributionAmountUSD = amount * conversionRates[paymentTokenName];
        const totalAskedUSD = formattedTotal * conversionRates[paymentTokenName];

        // if requested is over 100% display difference
        if (floorPriceUSD && contributionAmountUSD) {
            const contributionPercentOfFloor = contributionAmountUSD / floorPriceUSD * ONE_HUNDRED;
            const contributionPercent = parseInt(contributionPercentOfFloor) - ONE_HUNDRED;
            const totalPercentOfFloor = totalAskedUSD / floorPriceUSD * ONE_HUNDRED;
            const totalPercent = parseInt(totalPercentOfFloor) - ONE_HUNDRED;

            return {
                contributionPercent,
                totalPercent
            };
        }
        return null;
    }, [
        floorPrice,
        amount,
        conversionRates,
        paymentTokenName,
        formattedTotal
    ]);

    const floorPriceMessage = useMemo(() => {
        if (!floorPricePercentages) return null;
        // if requested is over 100% display difference
        return floorPricePercentages.contributionPercent > 0
            ? `Amount is ${floorPricePercentages.contributionPercent}% more than the collection floor price`
            : null;

    }, [floorPricePercentages]);

    const tweetMessage = useMemo(() => {

        // generate share message
        if (!floorPricePercentages) return null;
        const borrowingFeeFormated = formatUnits(
            borrowingFee.toString(),
            DEFAULT_DECIMALS
        );
        const loanLengthSeconds = loanLength
            * SECONDS_IN_DAY;

        const apy = parseInt(borrowingFeeFormated)
            * SECONDS_IN_YEAR
            / loanLengthSeconds;

        const url = window.location.href;
        return `You can earn ${Math.round(apy)}% APY in ${paymentToken} for funding this ${collectionName} loan request! ` +
        `The asking amount is ${Math.abs(floorPricePercentages.totalPercent)}% ${floorPricePercentages.totalPercent > 0 ? "above" : "below"} the floor price. ${url}`;
    }, [
        borrowingFee,
        loanLength,
        collectionName,
        floorPricePercentages,
        paymentToken,
    ]);

    return (
        <div className={cn(className, styles.sale)}>
            <div className={cn("h4", styles.title)}>
                Contribute to Loan
            </div>
            <div style={{ display: transactionHash ? "none" : "block" }}>
                <div className={styles.main}>
                    The maximum amount to contribute for this loan to become sole-contributor is
                    {" "}
                    {paymentTokenAddr && (
                        <FormattedAmount
                            amount={totalAsked}
                            token={paymentTokenAddr}
                            label={true}
                        />
                    )}
                </div>
                <div className={styles.main}>
                    You've contriubuted{" "}
                    <FormattedAmount
                        amount={contributedTotal}
                        token={paymentTokenAddr}
                        label={true}
                    />{" "}
                    total, contribute{" "}
                    <FormattedAmount
                        amount={remainingToTotal}
                        token={paymentTokenAddr}
                        label={true}
                    />{" "}
                    more to become sole-contributor
                </div>
                {/*<div className={styles.line}>
                    <div className={styles.icon}>
                        <Icon name="wallet" size="24" />
                    </div>
                    <div className={styles.details}>
                        <div className={styles.info}>Contribute full amount</div>
                        <div className={styles.text}>
                            Become sole-contributor for this loan
                        </div>
                    </div>
                    <Switch
                        className={styles.switch}
                        value={fullAmount}
                        setValue={handleSwitch}
                    />
                </div>*/}
                <div className={styles.table} style={{marginTop: "24px"}}>
                    <div className={styles.row} style={errorStyle}>
                        <div className={styles.col}>Amount</div>
                        <div className={styles.col}>
                            <CurrencyInput
                                id="input-contribution"
                                name="contribution"
                                autoComplete="off"
                                allowNegativeValue={false}
                                className={styles.input}
                                placeholder="Enter Contribution"
                                decimalsLimit={18}
                                value={amount}
                                decimalSeparator="."
                                groupSeparator=","
                                onValueChange={(value) => handleAmountChange(value)}
                            />
                        </div>
                        { amount && (
                            <div
                            className={styles.col}>
                                { paymentToken || "ETH" }
                            </div>
                        )}
                    </div>
                    <div className={styles.inputMessage}>
                        { floorPriceMessage }
                    </div>
                    <div className={styles.row} style={{marginTop: "8px"}}>
                        { Object.keys(PERCENT_OPTIONS).map((key) => (
                                <button
                                    key={key}
                                    className={
                                        cn('button-stroke',
                                            styles.option,
                                            {
                                                'button-active': activeQuickSelect === key

                                            })}
                                    onClick={() => handleQuickSelect(key)}
                                >
                                    { PERCENT_OPTIONS[key] }
                                </button>
                        ))}
                    </div>
                    <div style={{ marginBottom: "16px" }}>
                        {loanInfo.map((x, index) => (
                            <div className={styles.row} key={index}>
                                <div className={styles.col}>{x.title}</div>
                                <div className={styles.col}>{x.value}</div>
                            </div>
                        ))}
                    </div>
                    <div className={styles.main} style={{marginBottom: "0px"}}>
                        Sole-contributor gets direct access to the NFT collateral in case the owner fails to payback
                    </div>
                </div>
            </div>
            <div className={styles.steps}>
                <CollaterizeSteps
                    lockerAddress={lockerAddress}
                    setInvalidAmount={setInvalidAmount}
                    amount={amount}
                    paymentToken={paymentToken}
                    title={title}
                    totalAsked={totalAsked}
                    tweetMessage={tweetMessage}
                    transactionHash={transactionHash}
                    setTransactionHash={setTransactionHash}
                    setAmount={setAmount}
                    closeModal={closeModal}
                />
            </div>
        </div>
    );
};

export default Collaterize;
