import React from "react";
// import { Link } from "react-router-dom";
import cn from "classnames";
import styles from "./LoanSettings.module.sass";
import Control from "../../components/Control";
import TextInput from "../../components/TextInput";
// import TextArea from "../../components/TextArea";
import Icon from "../../components/Icon";
import Preview from "../../screens/LoanDetails/Preview";

const breadcrumbs = [
    {
        title: "Home",
        url: "/",
    },
    {
        title: "Loan Settings",
    },
];

const LoanSettings = () => {
    return (
        <div className={styles.page}>
            <Control className={styles.control} item={breadcrumbs} />
            <div className={cn("section-pt80", styles.section)}>
                <div className={cn("container", styles.container)}>
                    <div className={styles.top}>
                        <h1 className={cn("h2", styles.title)}>Loan Settings</h1>
                        <div className={styles.info}>
                            You can adjust your settings for your loan{" "}{" "}
                            while it is in attraction phase.
                        </div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.col}>
                            <div className={styles.list}>
                                <div className={styles.item}>
                                    <div className={styles.category}>Payback Settings</div>
                                    <div className={styles.fieldset}>
                                        <TextInput
                                            className={styles.field}
                                            label="Payback Amount"
                                            name="Payback Amount"
                                            type="text"
                                            placeholder="Enter Amount"
                                            required
                                        />
                                        <div className={styles.box}>
                                            <TextInput
                                                className={styles.field}
                                                label="Payback Date"
                                                name="Payback Date"
                                                type="text"
                                                placeholder="30 Days"
                                                required
                                            />
                                            <button
                                                className={cn(
                                                    "button-stroke button-small",
                                                    styles.button
                                                )}
                                            >
                                                Calendar
                                            </button>
                                        </div>
                                    </div>
                                    <button
                                        className={cn("button-stroke button-small", styles.button)}
                                    >
                                        <Icon name="plus-circle" size="16" />
                                        <span>Add more interest</span>
                                    </button>
                                </div>
                            </div>
                            <div className={styles.note}>
                                To update your loan settings you should sign message through your
                                wallet. Click 'Save Changes' then sign the message
                            </div>
                            <div className={styles.btns}>
                                <button className={cn("button", styles.button)}>
                                    Save Changes
                                </button>
                                <button className={styles.clear}>
                                    <Icon name="circle-close" size="24" />
                                    Clear Changes
                                </button>
                            </div>
                        </div>
                        <div className={styles.col}>
                            <div className={styles.holder}>
                                <Preview />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoanSettings;
