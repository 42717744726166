import useCallGracefully from './useCallGracefully';
import { getPoolContract } from "../utils/query/interfaces";

export const usePoolBalance = (
    poolAddress
) => {

    const poolContract = getPoolContract(
        poolAddress
    );

    const haveParamsForA = poolAddress && poolContract;
    const totalPoolBalance = useCallGracefully(haveParamsForA &&
        {
            contract: poolContract,
            method: "totalPool",
            args: [],
        }
    ) ?? null;

    return totalPoolBalance
};

export default usePoolBalance;
