import React from "react";
import { Link } from "react-router-dom";
import styles from "./PageList.module.sass";

const PageList = () => {
    return (
        <div className={styles.page}>
            <div className="container">
                <p>
                    <Link to="/">Home Page</Link>
                </p>
                <p>
                    <Link to="/loan-type">Loan Type</Link>
                </p>
                <p>
                    <Link to="/loan-details">Loan Details</Link>
                </p>
                <p>
                    <Link to="/connect-wallet">Connect wallet</Link>
                </p>
                <p>
                    <Link to="/how-it-works">How it works</Link>
                </p>
                <p>
                    <Link to="/activity">Activity</Link>
                </p>
                <p>
                    <Link to="/discover">NFT Listings</Link>
                </p>
                <p>
                    <Link to="/search02">Search Tokens</Link>
                </p>
                <p>
                    <Link to="/profile">Wallet Profile</Link>
                </p>
                <p>
                    <Link to="/loan-settings">Loan Settings</Link>
                </p>
                <p>
                    <Link to="/item">Single Loan View</Link>
                </p>
                <p>
                    <Link to="/token-info">Token Info</Link>
                </p>
            </div>
        </div>
    );
};

export default PageList;
