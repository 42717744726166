import { useEffect, useState } from 'react';

import { MS_IN_SECOND, SECONDS_IN_MN, HOURS_IN_DAY } from '../utils';

const daysDivisor = MS_IN_SECOND * SECONDS_IN_MN * SECONDS_IN_MN * HOURS_IN_DAY;
const hoursDivisor = MS_IN_SECOND * SECONDS_IN_MN * SECONDS_IN_MN;
const minutesDivisor = MS_IN_SECOND * SECONDS_IN_MN;

const getReturnValues = (countDown) => {
    
    const days = Math.floor(countDown / daysDivisor);
    const hours = Math.floor((countDown % daysDivisor) / hoursDivisor);
    const minutes = Math.floor((countDown % hoursDivisor) / minutesDivisor);
    const seconds = Math.floor((countDown % minutesDivisor) / MS_IN_SECOND);

    return [days, hours, minutes, seconds];
};

const useCountdownTimer = (targetDate) => {
    const countDownDate = new Date(targetDate).getTime();

    const [countDown, setCountDown] = useState(
        countDownDate - new Date().getTime()
    );

    useEffect(() => {
        const interval = setInterval(() => {
            setCountDown(countDownDate - new Date().getTime());
        }, MS_IN_SECOND);

        return () => clearInterval(interval);
    }, [countDownDate]);

    return getReturnValues(countDown);
};

export default useCountdownTimer;
