import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    WETH: 1,
    // WISE: 1,
    USDC: 1,
    // USDT: 1,
    // TEST: 1,
};

export const conversionsSlice = createSlice({
    name: "conversions",
    initialState,
    reducers: {
        setConversions: (state, action) => ({
            ...state,
            ...action.payload,
        }),
    },
});

export const { setConversions } = conversionsSlice.actions;

export default conversionsSlice.reducer;
