import React from "react";
import cn from "classnames";
import styles from "./ComingSoon.module.sass";
import ComingSoonMailChimp from "./ComingSoonMailChimp";

const ComingSoon = ({ className, featureDescription }) => {
    return (
        <div className={cn(className, styles.transfer)}>
            <div className={cn("h4", styles.title)}>Coming Very Soon</div>
            <div className={styles.text}>{featureDescription}</div>
            <div className={styles.text}>
                Meanwhile please subscribe to our newsletter to keep track on new feautes and
                releases
            </div>
            <ComingSoonMailChimp styles={styles} />
        </div>
    );
};

export default ComingSoon;
