import { useState, useEffect } from "react";
import { useEthers } from "@usedapp/core";

import useConfig from "./useConfig";
import { getRouterContract } from "../utils/query/interfaces";
import { getMainNetAddress } from "../ethers/nftCollections";


const useCollectionPricingData = (supportedCollections) => {
    const config = useConfig(1);
    const { library } = useEthers();
    const [collectionsWithIpfs, setCollectionsWithIpfs] = useState();

    const routerContract = getRouterContract(
      config.routerAddress,
      library
    );

    useEffect(() => {

        let mounted = true;

        const collectionDetails = supportedCollections.map(
            async (collection) => {
      
                let ipfsHash;
                let merkleRoot;
                let pendingIpfsHash;
                let pendingMerkleRoot;

                const collectionAddress = collection.address
                    || getMainNetAddress(collection.address);
      
                if (collectionAddress) {
                    ipfsHash = await routerContract.merkleIPFS(
                        collectionAddress
                    );
      
                    merkleRoot = await routerContract.merkleRoot(
                        collectionAddress
                    );
      
                    const pendingRoot = await routerContract.pendingRoots(
                        collectionAddress
                    );
      
                    pendingIpfsHash = pendingRoot?.ipfsAddress;
                    pendingMerkleRoot = pendingRoot?.merkleRoot;
                }
      
                return {
                    collection: collection.name,
                    address: collectionAddress,
                    ipfsHash,
                    merkleRoot,
                    pendingIpfsHash,
                    pendingMerkleRoot
                };
            }
        );
      
        if (collectionDetails?.length) {
            Promise.allSettled(collectionDetails).then((result) => {
                mounted && setCollectionsWithIpfs(result.map((res) => res.value));
            });
        }
        return () => {
            mounted = false;
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [supportedCollections]);

    return collectionsWithIpfs;
};

export default useCollectionPricingData;