import React, { useState, useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import { formatUnits } from "ethers/lib/utils";
import { useToken, useEthers } from "@usedapp/core";
import styles from "./Users.module.sass";
import { dollarsFormat, getChainById } from "../../../utils";
import Icon from "../../../components/Icon";
import EnsName from "../../../components/EnsName";
import Switch from "../../../components/Switch";
import FormattedAmount from "../../../components/FormattedAmount";
import Avatar from "../../../components/Avatar";
import PerfectScrollbar from 'react-perfect-scrollbar';
// import 'react-perfect-scrollbar/dist/css/styles.css';

const Users = ({
    className,
    users,
    items,
    contributionEvents,
    paymentTokenName,
    paymentToken,
    floorAsked,
}) => {
    const { account, chainId } = useEthers();

    const chain = getChainById(chainId);
    const paymentTokenInfo = useToken(paymentToken);

    const [showCombined, setShowCombined] = useState(false);

    const toggle = () => {
        if (disabled === false) {
            setShowCombined(!showCombined);
        }
    }

    const sortedEvents = useMemo(() => {
        if (!contributionEvents) return;
        return contributionEvents.reduce((newArr, curr) => {
            if (curr.user === account) newArr.unshift(curr);
            else newArr.push(curr);
            return newArr;
        }, []);
    }, [contributionEvents, account]);

    const sortedUsers = useMemo(() => {
        if (!users) return;
        const usersSorted = [...users];
        const index = usersSorted.findIndex((obj) => obj.user === account);
        usersSorted.unshift(usersSorted.splice(index, 1)[0]);
        return usersSorted;
    }, [users, account]);

    const dataShown = showCombined
        ? sortedUsers
        : sortedEvents;

    const haveData = dataShown && dataShown.length > 0;

    const classActive = haveData
        ? styles.normal
        : styles.grayscale;

    const disabled = haveData === false;
    const contributorsWord = users && users.length === 1
        ? "Contributor"
        : "Contributors";

    const txDetailShown = useCallback((txData) => (
        showCombined
            ? `${txData.events?.length} Transaction${txData.events?.length > 1 ? 's' : ''}`
            : ( <a
                className={styles.link}
                target="_blank"
                rel="noopener noreferrer"
                href={chain.getExplorerTransactionLink(txData.transactionHash)}
                >
                    View Transaction
                </a> )
    ), [showCombined, chain]);

    return (
        <>
        <div className={styles.info}>
            Contributors should fund this loan to reach floor asked amount of {" "}
            {paymentToken && (
                <FormattedAmount
                    amount={floorAsked}
                    token={paymentToken}
                    label={true}
                />
            )}{" "} tokens until this listing expires on {items[1].date}

        </div>
        <div className={cn(styles.users, className)}>
            <PerfectScrollbar>
                <div className={styles.list}>
                    <div className={styles.sum}>
                        <div className={styles.icon}>
                            <Icon name="user" size="20" />
                            <span className={styles.count}>
                                {(users?.length) || 0} {contributorsWord} Total
                            </span>
                        </div>
                        <div className={styles.total}>
                            {dataShown && (
                            <div className={cn(styles.box, classActive)}>
                                <span onClick={toggle}>
                                    Combine Transactions
                                </span>
                                <Switch
                                    disabled={disabled}
                                    value={showCombined}
                                    setValue={setShowCombined}
                                />
                            </div>
                            )}
                        </div>
                    </div>
                    {dataShown?.map((x, index) => (
                        <div key={index}>
                            <div className={styles.item}>
                                <Link to={`/profile/${x.user}`}>
                                    <Avatar
                                        className={styles.avatar}
                                        address={x.user}
                                    />
                                </Link>
                                <div className={styles.details}>
                                    <Link to={`/profile/${x.user}`}>
                                        <div className={styles.name}>
                                            <EnsName address={x.user} />
                                        </div>
                                    </Link>
                                    {x.amount && paymentToken && paymentTokenInfo && (
                                        <div className={styles.amount}>
                                            {dollarsFormat(
                                                // @TODO: grab decimals from config
                                                formatUnits(x.amount, paymentTokenInfo?.decimals)
                                            )}{" "}
                                            {paymentTokenName}
                                        </div>
                                    )}
                                </div>
                                <div className={styles.tx}>
                                    { txDetailShown(x) }
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </PerfectScrollbar>
        </div>
        </>
    );
};

export default Users;
