import React, { useState, useMemo } from "react";
import { useDispatch } from "react-redux";
import cn from "classnames";
import styles from "./ClaimFunds.module.sass";
import { useEthers, shortenIfTransactionHash } from "@usedapp/core";
import lockerAbi from "../../ethers/abis/LiquidLocker.json";
import { Interface } from "ethers/lib/utils";
import { BigNumber, Contract } from "ethers";
import Loader from "../Loader";
import FormattedAmount from "../FormattedAmount";
import Success from "../Transaction";
import { setUpdateLatestTx } from '../../redux/settingsSlice';
import useCallsGracefully from "../../customHooks/useCallsGracefully";
import { getChainById } from "../../utils";
// import { ZERO_ADDRESS } from "../../utils"

const ZERO = BigNumber.from(0);

const IN_PROGRESS_TEXT = "Scraping...";
const CONFIRM_TEXT = "Confirm in Metamask";
const DEFAULT_TEXT = "Proceed with Scrape";
const DONE_TEXT = 'Done';

const ClaimFunds = ({
    props: { lockerAddress, totalCollected, paymentToken, paymentTokenName, close },
}) => {

    const dispatch = useDispatch();
    const [errorMessage, setErrorMessage] = useState("");
    const [transactionComplete, setTransactionComplete] = useState(false);
    const [transactionProcessing, setTransactionProcessing] = useState(false);
    const [transactionHash, setTransactionHash] = useState("");
    const [buttonText, setButtonText] = useState(DEFAULT_TEXT);

    const { account, library, chainId } = useEthers();
    const chain = getChainById(chainId);
    const signer = library.getSigner(account);

    const lockerInterface = new Interface(lockerAbi);
    const lockerContractForCalls = new Contract(lockerAddress, lockerInterface);

    const claimableBalanceCall = {
        contract: lockerContractForCalls,
        method: "claimableBalance",
        args: [],
    };

    const thisUsersContributionCall = {
        contract: lockerContractForCalls,
        method: "contributions",
        args: [account],
    };

    const thisUsersCompensationCall = {
        contract: lockerContractForCalls,
        method: "compensations",
        args: [account],
    };

    const singleProviderCall = {
        contract: lockerContractForCalls,
        method: "singleProvider",
    };

    const calls = [
        claimableBalanceCall,
        thisUsersContributionCall,
        thisUsersCompensationCall,
        singleProviderCall,
    ];

    const loanInfo = useMemo(
        () => [
            {
                title: "Your Contribution",
                value: `10 D`,
            },
            /*{
                title: "Loan Duration",
                value: `${loanLength} Days`,
            },*/
            /*{
                title: "Loan interest",
                value: `${borrowingFee}%`,
            },*/
        ],
        []
    );

    const [
        claimableBalance,
        thisUsersContribution,
        thisUsersCompensation,
        singleProvider
    ] = useCallsGracefully(calls);

    const thisUserContributed = thisUsersContribution && thisUsersContribution.gt(0);

    const thisUsersPercentOfContribution = thisUserContributed
        ? thisUsersContribution.mul(100).div(totalCollected)
        : ZERO;

    const thisUsersClaimableBalance = claimableBalance
        ? claimableBalance.mul(thisUsersPercentOfContribution).div(100)
        : ZERO;

    const remainingClaimable = thisUsersClaimableBalance.sub(thisUsersCompensation || 0);
    const balanceToClaimExists = remainingClaimable.gt(0) || buttonText === DONE_TEXT;

    const lockerContract = new Contract(
        lockerAddress,
        lockerInterface,
        signer
    );

    const claimBalance = async () => {

        if (buttonText === DONE_TEXT) {
            close();
            return;
        }

        if (buttonText !== DEFAULT_TEXT) return;
        if (singleProvider === undefined) return;
        if (transactionProcessing) return;

        setErrorMessage(""); // useToast

        try {

            const { claimInterest } = lockerContract;

            setButtonText(
                CONFIRM_TEXT
            );

            const configuration = process.env.NODE_ENV === "development"
                ? {} //{ gasLimit: "1000000" }
                : {};

            const claimTransaction = await claimInterest(
                configuration
            );

            if (claimTransaction) setTransactionProcessing(true);

            setButtonText(IN_PROGRESS_TEXT);
            const claimReceipt = await claimTransaction.wait();
            setButtonText(DONE_TEXT);

            if (claimReceipt) {
                dispatch(setUpdateLatestTx(claimReceipt.transactionHash));
                setTransactionHash(claimReceipt.transactionHash);
                setTransactionComplete(true);
            }
        } catch (err) {
            // @TODO: show toast
            // console.log(err, 'err');
            setButtonText(DEFAULT_TEXT);
            setTransactionHash("");
        }
        setTransactionProcessing(false);
    };

    return (
        <div>
            <div className={cn("h4", styles.title)}>
                Scrape Rewards
            </div>
            {buttonText !== DONE_TEXT && (
                <div className={styles.details}>
                    <div className={styles.main}>
                        Collect your share of funds, depending on your contribution percentage as shown below
                    </div>
                    <div style={{marginBottom: "16px"}}>
                        <div className={styles.row}>
                            <div className={styles.col}>
                                Payback Funds
                            </div>
                            <div className={styles.col}>
                                <FormattedAmount
                                    amount={claimableBalance}
                                    token={paymentToken}
                                    label={true}
                                />
                            </div>
                        </div>
                    </div>
                    <div style={{marginBottom: "16px"}}>
                        <div className={styles.row}>
                            <div className={styles.col}>
                                {loanInfo[0].title}
                            </div>
                            <div className={styles.col}>
                                {thisUsersPercentOfContribution.toString()}%
                                {/*<FormattedAmount
                                    amount={claimableBalance}
                                    token={paymentToken}
                                    label={true}
                                />*/}
                            </div>
                        </div>
                    </div>
                    <div style={{marginBottom: "16px"}}>
                        <div className={styles.row}>
                            <div className={styles.col}>
                                Scraped Previosly
                            </div>
                            <div className={styles.col}>
                                <FormattedAmount
                                    amount={thisUsersCompensation}
                                    token={paymentToken}
                                    label={true}
                                />
                            </div>
                        </div>
                    </div>
                    <div style={{marginBottom: "16px"}}>
                        <div className={styles.row}>
                            <div className={styles.col}>
                                Available Amount
                            </div>
                            <div className={styles.col}>
                                { remainingClaimable.gte(0) ? (
                                    <FormattedAmount
                                        amount={remainingClaimable}
                                        token={paymentToken}
                                        label={true}
                                    />
                                ) : (
                                    <>
                                        0.00 {paymentTokenName}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className={styles.main} style={{marginTop: "24px"}}>
                        Based on your contributions percent you can scrape funds that have not been scraped yet
                        {/*Congrats! You have funds available to scrape!*/}
                        {/*You do not have funds available to scrape at this time, please check back at a later time*/}
                        {/*You have funds available to scrape!*/}
                        {/*You do not have funds available to scrape at this time*/}
                    </div>
                    {!transactionComplete && (
                        <div style={{display: "none"}}>
                            <div className={styles.info}>
                                <FormattedAmount
                                    amount={claimableBalance}
                                    token={paymentToken}
                                    label={true}
                                />{" "}
                                total claimable balance.
                            </div>
                            <div className={styles.info}>
                                You contributed {thisUsersPercentOfContribution.mul(100).toString()}% of
                                the total collected.
                            </div>
                            <div className={styles.info}>
                                You have been compensated{" "}
                                <FormattedAmount
                                    amount={thisUsersCompensation}
                                    token={paymentToken}
                                    label={true}
                                />
                            </div>
                        </div>
                    )}
                    {!transactionProcessing && !errorMessage && !transactionComplete && (
                        <div className={styles.info} style={{display: "none"}}>
                            {balanceToClaimExists && (
                                <>
                                    <FormattedAmount
                                        amount={remainingClaimable}
                                        token={paymentToken}
                                        label={true}
                                    />{" "}
                                    can be claimed
                                </>
                            )}
                            {!balanceToClaimExists && `No ${paymentTokenName} to claim.`}
                        </div>
                    )}
                </div>
            )}
            {buttonText === DONE_TEXT && (
                <div style={{marginBottom: "30px"}}>
                    <div className={styles.body} >
                        { buttonText === DONE_TEXT && (
                            <span>
                                Congratulations! You've claimed available earnings for providing loan contribution funds
                            </span>
                        )}
                    </div>
                    <div className={styles.row}>
                        <div>View Transaction</div>
                        <div>
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={chain.getExplorerTransactionLink(transactionHash)}
                                className={styles.link}
                            >
                                {shortenIfTransactionHash(transactionHash)}
                            </a>
                        </div>
                    </div>
                </div>
            )}
            {transactionComplete && <Success
                transactionHash={transactionHash}
                close={close}
                txHash={transactionHash}
                title={"You successfully claimed LiquidNFTs.com earnings"}
                showButton={false}
                celebrate={true}
            />}
            <div className={styles.btns}>
                <button
                    // onClick={balanceToClaimExists ? claimBalance : null}
                    onClick={claimBalance}
                    className={cn(
                        "button",
                        styles.button,
                        (buttonText === DEFAULT_TEXT || buttonText === DONE_TEXT) ? "" : "loading",
                        (balanceToClaimExists) ? "" : "disabled"
                    )}
                >
                    {transactionProcessing && (
                        <Loader className={styles.loaderSmall} color="white" />
                    )}
                    {buttonText}
                </button>
            </div>
        </div>
    );
};

export default ClaimFunds;
