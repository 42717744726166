import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Interface } from "ethers/lib/utils";
import { useEthers } from "@usedapp/core";
import { Contract } from "ethers";
import useConfig from "./useConfig";
import FACTORY_ABI from "../ethers/abis/LiquidFactory.json";

const parseLog = (log, contractInterface) => ({
    blockNumber: log.blockNumber,
    ...contractInterface.parseLog(log),
});

const getData = (event) => ({
    user: event.args.backerAddress,
    amount: event.args.contributionAmount,
    blockNumber: event.blockNumber,
});

const addTxHash = (data, logs) =>
    data.map((d, i) => ({
        ...d,
        transactionHash: logs[i].transactionHash
    })
);

const addTimestamp = (data, library) =>
    Promise.all(
        data.map(async (d) => {
            const { timestamp } = await library.getBlock(d.blockNumber);
            return { ...d, timestamp };
        })
    );

const factoryInterface = new Interface(FACTORY_ABI);
const parseContributionLog = (log) => parseLog(log, factoryInterface);

const useContributorsToLocker = (lockerAddress) => {

    const config = useConfig();
    const { library } = useEthers();

    const latestTxHash = useSelector((state) => state.settings.latestTxHash);

    const factoryContract = new Contract(
        config.liquidNFTContract,
        factoryInterface
    );

    const [contributionEvents, setContributionEvents] = useState([]);
    const [contributions, setContributions] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {

        if (!library) return;
        setLoading(true);

        const fetchLogs = async () => {

            const contributionFilter = factoryContract.filters.ContributeToLocker(
                lockerAddress
            );

            const contributionLogs = await library.getLogs({
                ...contributionFilter,
                fromBlock: config.inceptionBlock
            });

            const contributionEvents = contributionLogs.map(
                parseContributionLog
            );

            const contributionData = contributionEvents.map(
                getData
            );

            const contributionsWithTxHash = addTxHash(
                contributionData,
                contributionLogs
            );

            const contributionsWithTxAndTime = await addTimestamp(
                contributionsWithTxHash,
                library
            );

            const contributionsByUsers = contributionData.reduce((prev, c, i) => {
                if (prev[c.user])
                    return {
                        ...prev,
                        [c.user]: {
                            ...prev[c.user],
                            amount: prev[c.user].amount.add(c.amount),
                            events: [...prev[c.user].events, contributionEvents[i]],
                        },
                    };
                return { ...prev, [c.user]: { ...c, events: [contributionEvents[i]] } };
            }, {});

            const contributionsList = Object.values(
                contributionsByUsers
            );

            const sortedContributionList = contributionsList.sort(
                (a, b) => b.amount.sub(a.amount)
            );

            // TO-DO: check this why sometimes no data
            if (sortedContributionList.length === 0) return;
            if (contributionsWithTxAndTime.length === 0) return;

            setContributions(sortedContributionList);
            setContributionEvents(contributionsWithTxAndTime);
            setLoading(false);
        };
        fetchLogs();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lockerAddress, library, latestTxHash]);
    return [contributions, contributionEvents, loading];
};

export default useContributorsToLocker;
