import React from "react";
import cn from "classnames";
import styles from "./ValueProps.module.sass";
import ScrollParallax from "../ScrollParallax";

const items = [
    {
        title: "Pricing Traits for NFT Collections",
        content: "We use Merkle Trees to generate pricing data for NFT traits, allowing users to borrow more with their rare NFTs",
        image: "/images/content/medal.svg",
        alt: "pool",
        icon:
            '<div style="transfrom: scale(1.2)"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M12 24C16.4183 24 20 20.4183 20 16C20 11.5817 16.4183 8 12 8C7.58172 8 4 11.5817 4 16C4 20.4183 7.58172 24 12 24ZM12 21C14.7614 21 17 18.7614 17 16C17 13.2386 14.7614 11 12 11C9.23858 11 7 13.2386 7 16C7 18.7614 9.23858 21 12 21Z" fill="#FCFCFD"/><path d="M15 16C15 17.6569 13.6569 19 12 19C10.3431 19 9 17.6569 9 16C9 14.3431 10.3431 13 12 13C13.6569 13 15 14.3431 15 16Z" fill="#FCFCFD"/><path d="M16.5837 7.11C16.8351 7.23989 17.1486 7.15277 17.2828 6.90363L20.2064 1.4741C20.5651 0.807868 20.0826 0 19.3259 0H15.5937C15.2278 0 14.8911 0.199887 14.7158 0.521148L13.2698 3.17224C13.1884 3.32148 13.1884 3.50185 13.2698 3.65109L14.6615 6.20261C14.7256 6.32007 14.8343 6.40636 14.9621 6.44594C15.5256 6.62044 16.0677 6.84342 16.5837 7.11Z" fill="#FCFCFD"/><path d="M7.41654 7.11024C7.16501 7.23988 6.85152 7.15277 6.71737 6.90363L3.79378 1.4741C3.43504 0.807868 3.91757 0 4.67425 0H8.4064C8.77234 0 9.10906 0.199887 9.28429 0.521148L11.8588 5.24103C12.0434 5.57946 11.7912 5.99498 11.4064 6.01807C10.0186 6.10135 8.65344 6.47273 7.41654 7.11024Z" fill="#FCFCFD"/></svg></div>',
    },
    {
        title: "LASA Calibrations Scale",
        alt: "pool2",
        image: "/images/content/lightning.svg",
        content: "The pools adjust APY according to supply and demand. This value is calibrated on chain in the smart contract",
        icon: '<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="#23262f"  xmlns:v="https://vecta.io/nano"><path fill-rule="evenodd" d="M24 40c8.837 0 16-7.163 16-16S32.837 8 24 8 8 15.163 8 24s7.163 16 16 16zm0 4c11.046 0 20-8.954 20-20S35.046 4 24 4 4 12.954 4 24s8.954 20 20 20z"/><path d="M12 40.002c4.858-3.649 8-9.458 8-16.001S16.858 11.648 12 8a20.12 20.12 0 0 0-2.999 2.771C13.226 13.65 16 18.501 16 24s-2.774 10.35-6.999 13.23c.902 1.022 1.907 1.951 2.999 2.771zM36 7.999c-4.858 3.649-8 9.458-8 16.001a19.97 19.97 0 0 0 8 16.002 20.12 20.12 0 0 0 2.999-2.771C34.774 34.35 32 29.499 32 24s2.774-10.35 6.999-13.23A20.12 20.12 0 0 0 36 7.999z"/></svg>',
    },
    {
        title: "Smart Liquidations Approach",
        alt: "pool3",
        content: "Dutch auctions start at the full value of the defaulted NFT asset and gradually decrease to 50% of the NFT’s value over the next few days if not sold. ",
        icon: '<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="#23262f"  xmlns:v="https://vecta.io/nano"><path fill-rule="evenodd" d="M24 42c7.732 0 14-6.268 14-14s-6.268-14-14-14-14 6.268-14 14 6.268 14 14 14zm0 4c9.941 0 18-8.059 18-18s-8.059-18-18-18S6 18.059 6 28s8.059 18 18 18z"/><path d="M22 20a2 2 0 1 1 4 0v8a2 2 0 1 1-4 0v-8zM20 2a2 2 0 1 0 0 4h8a2 2 0 1 0 0-4h-8zm21.414 6.586a2 2 0 0 0-2.828 2.828l2 2a2 2 0 0 0 2.828-2.828l-2-2z"/></svg>',
        image: "/images/content/stopwatch.svg",
    },
];

const ValueProps = ({ className }) => {
    return (
        <div className={cn(className, styles.section)}>
            <div className={cn("container", styles.container)}>
                <div className={styles.head}>
                    {/*<div className={cn("stage-small", styles.stage)}>
                        New Project Every Week
                    </div>*/}
                    <div
                        className={cn("h2", styles.title)}
                    />
                </div>
                <div className={styles.list}>
                    {items.map((x, index) => (
                        <ScrollParallax className={styles.item} key={index}>
                            <div
                                // style={{}}
                                className={styles.icon}
                            >
                                <img src={x.image} alt={x.alt} />
                            </div>
                            <div className={styles.category}>{x.title}</div>
                            <div className={styles.content}>{x.content}</div>
                        </ScrollParallax>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ValueProps;
