import React from 'react';
import { useHistory } from 'react-router';
import cn from "classnames";

import styles from "./NoData.module.sass";
// import noDataImg from "../../assets/images/icons/searching-data.svg";
import noDataImg from "../../assets/images/icons/wallet-small.png";

const NoData = ({
    buttonEndpoint,
    label,
    pageMode,
    buttonLabel = "Browse Pools",
    hideButton
}) => {

    const history = useHistory();
    const handleClick = () => {
        history.push(`/${buttonEndpoint}`);
    };

    return (
        <div className={styles.container}>
            <div className={styles.row}>
                <img
                    src={noDataImg}
                    alt=""
                    height="200px"
                    width="200px"
                />
            </div>
            {pageMode === 'user' && (
                <>
                    <div className={styles.row}>
                        {label}
                    </div>
                    {
                        !hideButton && (
                            <div className={styles.row}>
                                <button
                                    onClick={handleClick}
                                    style={{ marginBottom: "20px" }}
                                    className={cn("button-small", styles.option)}>
                                    {buttonLabel}
                                </button>
                            </div>
                        )
                    }
                </>
            )}
        </div>
    );
}

export default NoData;
