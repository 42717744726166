import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./Listing.module.sass";
import Icon from "../../../components/Icon";
import { useToken } from "@usedapp/core";
import { formatUnits } from "ethers/lib/utils";
import TokenImage from "../../../components/TokenImage";

import {
    dollarsFormat,
    SECONDS_IN_DAY,
    ZERO_ADDRESS,
    ACTIVE_STATUS,
    EXPIRED_STATUS,
    DEFAULT_DECIMALS
} from "../../../utils";

import loaderImage from "../../../assets/images/loaders/golden.svg";
import TipHolder from "../../../components/TipHolder/TipHolder";
import Avatar from "../../../components/Avatar";

const Listing = ({ className, lockerAddress, filterToken, data }) => {

    if (!data) return (
        <></>
    );

    const {
        tokens,
        paymentRate,
        paymentTime,
        floorAsked,
        totalAsked,
        totalCollected,
        paymentToken,
        paymentTokenName,
        lockerOwner,
        loanStatus,
        contributionPhase,
        floorNotReached,
        singleProvider,
        ownersAddress,
        belowFloorAsked,
        title,
    } = data;

    return paymentToken ? (
        <Display
            props={{
                paymentToken,
                title,
                paymentTokenName,
                tokens,
                paymentRate,
                paymentTime,
                floorAsked,
                totalAsked,
                loanStatus,
                totalCollected,
                className,
                lockerOwner,
                ownersAddress,
                lockerAddress,
                floorNotReached,
                contributionPhase,
                filterToken,
                singleProvider,
                belowFloorAsked,
            }}
        />
    ) : (
        <></>
    );
};

const Display = ({
    props: {
        paymentToken,
        title,
        paymentTokenName,
        tokens,
        paymentRate,
        paymentTime,
        floorAsked,
        totalAsked,
        totalCollected,
        className,
        loanStatus,
        lockerOwner,
        ownersAddress,
        lockerAddress,
        contributionPhase,
        floorNotReached,
        filterToken,
        singleProvider,
        belowFloorAsked,
    },
}) => {

    // const ANY_TOKEN = "Any currency";
    // const DISPLAY_MINIMUM = 1000000;

    const paymentTokenInfo = useToken(paymentToken);
    // const [visible, setVisible] = useState(false);

    const finishedLocker = () => {
        return lockerOwner === ZERO_ADDRESS;
    }

    const expiredLocker = () => {
        return loanStatus === EXPIRED_STATUS;
    }

    const singleFunded = () => {
        return singleProvider && singleProvider !== ZERO_ADDRESS;
    }

    const fundedLocker = () => {
        return (
            floorNotReached === false &&
            contributionPhase === false
        );
    }

    const single = singleFunded() === true;
    const activated = loanStatus === ACTIVE_STATUS;

    let buttonText = "Show Details"

    if (singleFunded() === true) {
        buttonText = "Fully Funded";
    }

    if (finishedLocker() ===  true) {
        buttonText = "Finalized Listing";
    }

    if (expiredLocker() ===  true) {
        buttonText = "Expired Listing";
    }

    if (activated) {
        buttonText = "Activated Listing";
    }

    const isGray = finishedLocker() || expiredLocker();
    /*const expiredLocker = () => {
        return floorNotReached === true
    }

    const uselessLocker = () => {
        return (
            totalAsked < DISPLAY_MINIMUM ||
            lockerOwner === ZERO_ADDRESS
        );
    }*/

    /* const hideToken = () =>  {
        return (
            filterToken.includes(ANY_TOKEN) ||
            filterToken.includes(paymentTokenName)
        );
    } */

    const paymentRateFormated = formatUnits(
        paymentRate.toString(),
        DEFAULT_DECIMALS
    );

    const progress = totalCollected * 100 / totalAsked;

    return (

        <div className={cn(styles.card, className, {
                [styles.funded]: fundedLocker(),
                [styles.expired]: fundedLocker(),
                // [styles.hidden]: hideToken(),
            })}>
            <div className={cn(styles.preview, {
                    [styles.finished]: isGray,
                })}
            >
                <img
                    alt={"loader"}
                    src={loaderImage}
                    style={{
                        width: "100%",
                        transform: "scale(0.2)",
                        filter: "hue-rotate(300deg)",
                        position: "relative"
                    }}
                />
                {tokens[0] && (
                    <TokenImage
                        token={tokens[0]}
                        useIntersection={false}
                        className={styles.img}
                        style={{
                            position: "absolute",
                            top: "0px",
                            left: "0px",
                            backgroundColor: "#638596",
                            borderRadius: "25px",
                            width: "100%"
                        }}
                    />
                )}
                {tokens?.length > 1 && (
                    <div className={styles.categories}>
                        <div className={'status-main'}>
                            Bundle
                        </div>
                    </div>
                )}
                <div className={styles.control}>
                    <Link to={{ pathname: `https://etherscan.io/address/${lockerAddress}`}} target="_blank" rel="noopener noreferrer">
                        <button
                            className={cn(styles.external)}
                        >
                            <div className={styles.open}>
                                <Icon name="arrow-next" size="20" />
                            </div>
                        </button>
                    </Link>
                    <Link to={`/item/${lockerAddress}`}>
                        <button className={cn(
                            "button-small",
                            styles.button,
                            {
                                [styles.finished]: isGray || activated || single,
                            }
                        )}>
                            <span>
                                { buttonText }
                            </span>
                            <Icon name="search" size="16" />
                        </button>
                    </Link>
                </div>
            </div>
            <div className={styles.control}>
                <Link to={{ pathname: `https://etherscan.io/address/${lockerAddress}`}} target="_blank" rel="noopener noreferrer">
                    <button
                        className={cn(styles.external)}
                    >
                        <div className={styles.open}>
                            <Icon name="arrow-next" size="20" />
                        </div>
                    </button>
                </Link>
                <Link target="_blank" rel="noopener noreferrer" to={`/item/${lockerAddress}`}>
                    <button className={cn("button-small", styles.button)}>
                        <span>Show Details</span>
                        <Icon name="search" size="16" />
                    </button>
                </Link>
            </div>
            <div className={styles.details} to={`/loan-details/${lockerAddress}`}>
                <div className={styles.body}>
                    <div className={styles.line}>
                        <div className={styles.title}>{title}</div>
                        <TipHolder
                            borrowingRate={paymentRateFormated}
                            borrowingTime={paymentTime}
                        />
                        {/*<div className={cn(styles.price, {
                                    [styles.finished]: isGray || activated || single,
                                }
                            )}
                        >
                            {paymentRateFormated}% TIP
                        </div>*/}
                    </div>
                    <div className={styles.line}>
                        <div className={styles.users}>
                            <Link className={styles.link} to={`/profile/${ownersAddress}`}>
                                <Avatar
                                    className={styles.avatar}
                                    address={ownersAddress}
                                    showAddress={true}
                                />
                            </Link>
                        </div>
                        <div className={styles.counter}>
                            {paymentTime && Math.floor(paymentTime.toNumber() / SECONDS_IN_DAY )}
                            {""} Day Loan
                        </div>
                    </div>
                </div>
                <div className={cn(styles.progress, {
                            [styles.finished]: isGray || activated,
                        }
                    )}
                    style={{width: `${progress}%`}}
                >
                </div>
                <div className={styles.foot}>
                    <div className={styles.status}>
                        <div>
                            {/*<Icon name="candlesticks-up" size="20" />*/}
                            Floor Asked
                        </div>
                        <span>
                            {floorAsked &&
                                dollarsFormat(
                                    formatUnits(floorAsked, paymentTokenInfo?.decimals)
                                )}{" "}
                            {paymentTokenName}
                        </span>
                    </div>
                    <div className={styles.right}>
                        <div>Total Asked</div>
                        <span>
                            {totalAsked &&
                                dollarsFormat(
                                    formatUnits(totalAsked, paymentTokenInfo?.decimals)
                                )}{" "}
                            {paymentTokenName}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Listing;
