import React, { useState } from "react";
import cn from "classnames";
import styles from "./Control.module.sass";
import FundLoan from "./FundLoan";
import Connect from "../../../components/Connect";
import Contribution from "../../../components/Contribution";
import Accept from "./Accept";
import ClaimFunds from "../../../components/ClaimFunds";
import Collaterize from "../../../components/Collaterize";
import ClaimRefund from "./ClaimRefund";
import SuccessfullyFunded from "./SuccessfullyFunded";
import Payback from "../../../components/Payback";
import Modal from "../../../components/Modal";
import Activate from "../../../components/Activate";

import {
    ACTIVE_STATUS,
    PENDING_STATUS,
    READY_STATUS,
    // CANCELED_STATUS,
    FINISHED_STATUS,
    // LIQUIDATED_STATUS,
    TERMINATED_STATUS,
    EXPIRED_STATUS,
    ZERO_ADDRESS
} from "../../../utils";

const Control = ({
    className,
    listingDetails,
    activePage,
    account,
    items,
    props: {
        ownersAddress,
        lockerOwner,
        paymentToken,
        paymentTokenName,
        totalAsked,
        totalCollected,
        remainingBalance,
        borrowingFee,
        title,
        loanStatus,
        paymentTime,
        lockerAddress,
        singleProvider,
        contributors,
        isStageOne,
        floorPrice,
        collectionName
    },
}) => {
    const [visibleModalPurchase, setVisibleModalPurchase] = useState(false);
    const [visibleModalBid, setVisibleModalBid] = useState(false);
    const [visibleModalAccept, setVisibleModalAccept] = useState(false);
    const [visibleModalSale, setVisibleModalCollaterize] = useState(false);
    const [visibleModalClaimFunds, setVisibleModalClaimFunds] = useState(false);
    const [visibleModalPayback, setVisibleModalPayback] = useState(false);
    const [visibleModalActivate, setVisibleModalActivate] = useState(false);
    const [contributeAmount, setContributeAmount] = useState("");

    const showContribute = ['Information', 'Contributors'];
    // const readyStatus = [PENDING_STATUS, READY_STATUS];
    // const showPayback = ['Transactions'];
    // const showRefunds = ['Refunds'];

    const hasSingleProvider = singleProvider && singleProvider !== ZERO_ADDRESS;

    const isContributor = contributors?.find((contributionObj) => contributionObj.user === account);
    const singleProviderNotUser = hasSingleProvider && singleProvider !== account;
    const showEarlyRefund = isContributor && singleProviderNotUser;

    const disableClass = hasSingleProvider
        ? 'disabled'
        : 'normaled';

    const isOwner = account === ownersAddress;

    return (
        <>
            {loanStatus === PENDING_STATUS && (singleProvider !== lockerOwner && isOwner === false) && (
                <>
                    {showContribute.includes(activePage) &&  (
                        <button
                            className={cn("button", disableClass, styles.contrib, styles.button)}
                            onClick={() => setVisibleModalCollaterize(true)}
                        >
                            { hasSingleProvider === true
                                ? 'Fully Funded by Sole-Contributor'
                                : 'Contribute to Loan'
                            }
                        </button>
                    )}
                    {activePage === 'Transactions' && (
                        <button
                            disabled={true}

                            className={cn("button", "disabled", styles.contrib, styles.button)}
                            onClick={() => setVisibleModalCollaterize(true)}
                        >
                            {isOwner ? 'Payback Loan' : 'Scrape Rewards'}
                        </button>
                    )}
                </>
            )}
            {(loanStatus === READY_STATUS || isOwner)
                    && loanStatus !== EXPIRED_STATUS
                    && loanStatus !== TERMINATED_STATUS
                    && loanStatus !== ACTIVE_STATUS
                    && loanStatus !== FINISHED_STATUS
                    && (
                <button
                    className={cn("button", styles.contrib, styles.button)}
                    onClick={() => setVisibleModalActivate(true)}
                >
                    Activate Listing
                </button>
            )}
            {loanStatus !== TERMINATED_STATUS
                && loanStatus !== READY_STATUS
                && loanStatus !== PENDING_STATUS
                && loanStatus !== EXPIRED_STATUS
                && isOwner === false
                && !showEarlyRefund
                && (
                    <button
                        style={{width: "100%"}}
                        className={cn("button", styles.contrib, styles.button)}
                        onClick={() => setVisibleModalClaimFunds(true)}
                    >
                        Scrape Rewards
                    </button>
                )}
            {(activePage === "Refunds") && isOwner === false && isStageOne === true && (
                <div>
                    <ClaimRefund
                        // lockerOwner={lockerOwner}
                        loanStatus={loanStatus}
                        paymentToken={paymentToken}
                        contributorData={isContributor}
                    />
                </div>
            )}
            {isStageOne === false && showEarlyRefund && (
                <div>
                    <ClaimRefund
                        // lockerOwner={lockerOwner}
                        paymentToken={paymentToken}
                        contributorData={isContributor}
                    />
                </div>
            )}
            {loanStatus === ACTIVE_STATUS && isOwner && (
                <button
                    className={cn('button', styles.contrib, styles.button)}
                    onClick={() => setVisibleModalPayback(true)}
                >
                    Payback Loan
                </button>
            )}
            {loanStatus === FINISHED_STATUS && isOwner && (
                <button
                    className={cn('button', 'disabled', styles.contrib, styles.button)}
                    onClick={() => setVisibleModalPayback(true)}
                >
                    Payed Back
                </button>
            )}
            <Modal visible={visibleModalPurchase} onClose={() => setVisibleModalPurchase(false)}>
                <FundLoan />
                <SuccessfullyFunded />
            </Modal>
            <Modal visible={visibleModalBid} onClose={() => setVisibleModalBid(false)}>
                <Connect />
                <Contribution />
            </Modal>
            <Modal visible={visibleModalAccept} onClose={() => setVisibleModalAccept(false)}>
                <Accept />
            </Modal>
            <Modal visible={visibleModalActivate} onClose={() => setVisibleModalActivate(false)}>
                {paymentToken && lockerAddress && (
                    <Activate
                        items={items}
                        close={() => setVisibleModalActivate(false)}
                        listingDetails={listingDetails}
                    />
                )}
            </Modal>
            <Modal visible={visibleModalSale} onClose={() => setVisibleModalCollaterize(false)}>
                <Collaterize
                    lockerAddress={lockerAddress}
                    amount={contributeAmount}
                    setAmount={setContributeAmount}
                    totalAsked={totalAsked}
                    paymentTokenAddr={paymentToken}
                    title={title}
                    paymentTime={paymentTime}
                    borrowingFee={borrowingFee}
                    contributors={contributors}
                    floorPrice={floorPrice}
                    collectionName={collectionName}
                    closeModal={() => setVisibleModalCollaterize(false)}
                />
            </Modal>
            <Modal
                visible={visibleModalClaimFunds}
                onClose={() => setVisibleModalClaimFunds(false)}
            >
                {lockerAddress && (
                    <ClaimFunds
                        props={{
                            lockerAddress,
                            totalCollected,
                            paymentToken,
                            paymentTokenName,
                            close: () => setVisibleModalClaimFunds(false),
                        }}
                    />
                )}
            </Modal>
            <Modal visible={visibleModalPayback} onClose={() => setVisibleModalPayback(false)}>
                <Payback
                    closeFunction={setVisibleModalPayback}
                    lockerOwner={lockerOwner}
                    paymentToken={paymentToken}
                    totalAsked={totalAsked}
                    totalCollected={totalCollected}
                    remainingBalance={remainingBalance}
                    borrowingFee={borrowingFee}
                    closeModal={() => setVisibleModalPayback(false)}
                />
            </Modal>
        </>
    );
};

export default Control;
