import { useState, useEffect } from "react";
import { Interface } from "ethers/lib/utils";
import { useEthers } from "@usedapp/core";
import { Contract } from "ethers";
import useConfig from "./useConfig";
import FACTORY_ABI from "../ethers/abis/LiquidFactory.json";

const parseLog = (log, contractInterface) => ({
    blockNumber: log.blockNumber,
    ...contractInterface.parseLog(log),
});
const factoryInterface = new Interface(FACTORY_ABI);
const parseContributionLog = (log) => parseLog(log, factoryInterface);

const getData = (event) => ({
    lockerAddress: event.args.lockerAddress,
    amount: event.args.contributionAmount,
    blockNumber: event.blockNumber,
});

const addTxHash = (data, logs) =>
    data.map((d, i) => ({ ...d, transactionHash: logs[i].transactionHash }));

const reduction = (data) =>
    data.reduce((prev, d) => {
        const { lockerAddress } = d;
        if (prev[lockerAddress]) {
            return {
                ...prev,
                [lockerAddress]: {
                    ...prev[lockerAddress],
                    total: prev[lockerAddress].total.add(d.amount),
                    events: [...prev[lockerAddress].events, d],
                },
            };
        } else {
            return {
                ...prev,
                [lockerAddress]: {
                    total: d.amount,
                    lockerAddress: d.lockerAddress,
                    events: [d],
                },
            };
        }
    }, {});

const useContributionFetch = (optionalAddress) => {

    const [contributions, setContributions] = useState([]);
    const [contributionEvents, setContributionEvents] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const config = useConfig();
    const { library, account, chainId } = useEthers();
    const user = optionalAddress ? optionalAddress : account;

    useEffect(() => {

        if (!library) return;
        setLoading(true);

        const fetchLogs = async () => {

            try {

                const factoryContract = new Contract(
                    config.liquidNFTContract,
                    factoryInterface
                );

                const contributionFilter = factoryContract.filters.ContributeToLocker(
                    null,
                    user
                );

                const contributionLogs = await library.getLogs({
                    ...contributionFilter,
                    fromBlock: config.inceptionBlock,
                });

                const contributionEvents = contributionLogs.map(
                    parseContributionLog
                );

                const data = contributionEvents.map(
                    getData
                );

                const dataWithTxHash = addTxHash(
                    data,
                    contributionLogs
                );

                const reduced = reduction(
                    dataWithTxHash
                );

                const reducedContributions = Object.values(
                    reduced
                );

                setContributions(reducedContributions);
                setContributionEvents(dataWithTxHash);

            } catch (err) {
                setError(err);
            }
            setLoading(false);
        };
        if (user) fetchLogs();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [library, user, chainId]);

    return [contributions, contributionEvents, loading, error];
};

export default useContributionFetch;
