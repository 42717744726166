import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import cn from "classnames";
import styles from "./ConnectWallet.module.sass";
import Icon from "../../components/Icon";
import { useEthers } from "@usedapp/core";
import ComingSoon from "../../components/ComingSoon";
import Modal from "../../components/Modal";

const menu = [
    {
        name: "Metamask",
        title: "Connect with Metamask",
        color: "#ff6400",
        active: true,
        picture:
            "https://image.binance.vision/uploads-original/ad75b7b113ac422080d6ce73d379de6e.png",
    },
    {
        name: "Walletconnect",
        title: "Connect with Walletconnect",
        color: "#4da3d6",
        active: false,
        picture:
            "https://image.binance.vision/uploads-original/275962cac8b246adb08c19a868c30096.png",
    },
];

const Connect = () => {
    const { activateBrowserWallet, account } = useEthers();
    const [selected, setSelected] = useState(0);
    const [walletName, setWalletName] = useState(menu[0].name);
    const [picture, setPicture] = useState(menu[0].picture);
    const [visibleModalSoonA, setVisibleModalSoonA] = useState(false);
    const history = useHistory();
    const location = useLocation();

    const changeSelection = (index) => {
        if (index === selected) tryToActivate();
        setWalletName(menu[index].name);
        setPicture(menu[index].picture);
        setSelected(index);
    };

    const tryToActivate = () => {
        if (walletName === 'Walletconnect') {
            setVisibleModalSoonA(true);
            return;
        }
        activateBrowserWallet();
    };

    useEffect(() => {
        if (account) {
            // history.push(`/profile/${account}`);
            const search = location.search;
            const params = new URLSearchParams(search);
            const redirectTo = params.get('redirectTo');

            redirectTo
                ? history.push(redirectTo)
                : history.push(`/profile`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account, history]);

    return (
        <div className={cn("section-pt80", styles.section)}>
            <div className={cn("container", styles.container)}>
                {/*<div className={styles.head}>
                    <Link className={styles.back} to="/">
                        <Icon name="arrow-prev" size="24" />
                        <div className={cn("h2", styles.stage)}>Connect your wallet</div>
                    </Link>
                </div>*/}
                <div className={styles.body}>
                    <div className={styles.menu}>
                        {menu.map((x, index) => (
                            <div
                                onClick={() => {
                                    changeSelection(index);
                                }}
                                className={cn({ [styles.active]: index === selected }, styles.link)}
                                key={index}
                            >
                                <div className={styles.icon} style={{ backgroundColor: x.color }}>
                                    <Icon name="wallet" size="24" />
                                    <Icon name="check" size="18" fill={x.color} />
                                </div>
                                <span>{x.title}</span>
                                <div className={styles.arrow}>
                                    <Icon name="arrow-next" size="14" />
                                </div>
                            </div>
                        ))}
                        <br />
                        <div className={styles.text}>
                            Please make sure your are connected to Ropsten network and you are
                            using secure connection when prompted to connect your wallet. Contact us
                            for any support
                        </div>
                        <div className={styles.btns}>
                            <button
                                onClick={tryToActivate}
                                className={cn("button", styles.button)}
                            >
                                Connect with {walletName}
                            </button>
                            <a href={
                                walletName === 'Metamask'
                                    ? 'https://metamask.io/'
                                    : 'https://walletconnect.com/'
                                }
                                rel="noreferrer"
                                target="_blank"
                            >
                                <button className={cn("button-stroke button", styles.button)}>
                                    Get {walletName}
                                </button>
                            </a>
                        </div>
                    </div>
                    <div className={styles.wrapper}>
                        <div className={styles.bg}>
                            <img srcSet={`${picture} 2x`} src={`${picture}`} alt="Connect Wallet" />
                        </div>
                    </div>
                    <Modal visible={visibleModalSoonA} onClose={() => setVisibleModalSoonA(false)}>
                        <ComingSoon featureDescription={"Wallet Connect feature is not available yet, please use MetaMask for now"} />
                    </Modal>
                </div>
            </div>
        </div>
    );
};

export default Connect;
