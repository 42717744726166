import { useState, useEffect } from "react";
import { useEthers } from "@usedapp/core";
import { Contract } from "ethers";
import { Interface } from "ethers/lib/utils";
import useConfig from "./useConfig";
import FACTORY_ABI from "../ethers/abis/LiquidFactory.json";
// import { ZERO_ADDRESS, INCEPTION_BLOCK } from "../utils/";

const factoryInterface = new Interface(
    FACTORY_ABI
);

const useExistingLockerFetch = (lockerAddress, startBlock = null) => {

    const { library } = useEthers();
    const config = useConfig();

    const factoryContract = new Contract(
        config.liquidNFTContract,
        FACTORY_ABI
    );

    const arr = lockerAddress
        ? [lockerAddress]
        : [];

    const newLockerFilter = factoryContract
        .filters
        .NewLocker(arr);

    const [existingLockers, setExistingLockers] = useState([]);

    useEffect(() => {

        let mounted = true;
        if (!library) return;

        const fetchNewLockerEvents = async () => {

            const fromBlock = startBlock
                ? library._lastBlockNumber - startBlock
                : config.inceptionBlock;

            const getFilteredLogs = (filter) => library.getLogs({
                ...filter,
                fromBlock,
                toBlock: "latest"
            });

            const newLockerEvents = await getFilteredLogs(
                newLockerFilter
            );

            const parseLog = (log, contractInterface) => ({
                blockNumber: log.blockNumber,
                ...contractInterface.parseLog(log),
            });

            const parsedEvents = newLockerEvents && newLockerEvents.map((e) => {
                return parseLog(e, factoryInterface);
            });

            const lockerObjects = parsedEvents && parsedEvents.map(
                ({ blockNumber, args: {
                    ownersAddress,
                    tokensAddress,
                    lockerAddress,
                    singleProvider,
                    floorAsked,
                    totalAsked,
                    paymentTime,
                    paymentRate,
                    paymentToken,
                } }) => ({
                    ownersAddress,
                    tokensAddress,
                    lockerAddress,
                    singleProvider,
                    floorAsked,
                    totalAsked,
                    paymentTime,
                    paymentRate,
                    paymentToken,
                    blockNumber,
                })
            );

            if (lockerObjects && mounted) {
                setExistingLockers(
                    lockerObjects
                );
            }
        };

        fetchNewLockerEvents();
        return () => (mounted = false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [library, lockerAddress, factoryContract.filter]);

    return existingLockers
        .sort((a,b) => b.blockNumber - a.blockNumber)
        .filter((locker) => {
            // placeholder for adding filters
            return locker.blockNumber > config.inceptionBlock
        }
    );
};

export default useExistingLockerFetch;
