import React from "react";
import useGetPoolRates from "../../../../customHooks/useGetPoolRates";
// import styles from "../Pools.module.sass";
import { FETCHING_DATA_MESSAGE } from "../../../../utils";

const Details = ({ poolAddress }) => {

    const pool = useGetPoolRates(
        poolAddress
    );

    return (
        <div>
            <div
                style={{marginBottom: "10px"}}
                className={"status-green"}
            >
                {pool?.borrowStatusContent || FETCHING_DATA_MESSAGE}
            </div>
            <div
                style={{marginLeft: "5px"}}
                className={"status-black"}
            >
                {pool?.lendStatusContent || FETCHING_DATA_MESSAGE}
            </div>
        </div>
    );
};

export default Details;
