import { useEthers } from "@usedapp/core";
import { APPROVE_MAX_VALUE } from "../utils";
import { getTokenContract } from "../utils/query/interfaces";

export const useERC20Approval = ({
    onConfirmation,
    onApproving,
    onApprovalComplete,
    onError,
}) => {

    const {
        library,
        account
    } = useEthers();

    const signer = library.getSigner(
        account
    );

    const call = async (
        approveContractAddress,
        paymentToken
    ) => {

        try {

            onConfirmation && onConfirmation();

            const tokenContract = getTokenContract(
                paymentToken,
                signer
            );

            const approveTransaction = await tokenContract.approve(
                approveContractAddress,
                APPROVE_MAX_VALUE
            );

            onApproving && onApproving(
                approveTransaction.hash
            );

            const approve = await approveTransaction.wait();
            onApprovalComplete && onApprovalComplete(
                approve
            );

            return true;

        } catch (ex) {

            console.log(
                "Error in approve",
                ex
            );

            onError && onError(ex);
            return false;
        }
    }

    return {
        call,
    }
}
