import React from "react";

import useContributionFetch from "../../customHooks/useContributionFetch";
import LockerList from "./index";

const LockerListContributions = ({ 
  className, 
  hasParamAndNotUser, 
  address, 
  chainId, 
  library 
}) => {

    const [contributions] = useContributionFetch(
      hasParamAndNotUser && address
    );

    return (
      <LockerList
          className={className}
          lockerData={[...contributions].reverse()}
          chainId={chainId}
          library={library}
      />
    );
};

export default LockerListContributions;
