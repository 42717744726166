import React, { useState } from "react";
import { shortenIfTransactionHash, useEthers } from "@usedapp/core";
import cn from "classnames";
import styles from "./LiquidateInstant.module.sass";
import styles1 from "../Invest/Invest.module.sass";
import Timeline from "../../components/Timeline";
import useConfig from "../../customHooks/useConfig";
import {
    produceAccurateValue,
    formatDateFormal,
    getChainById,
    getToastErrorMessage,
    getToastSettings,
    EMPTY_ADDRESS,
    MIN_CONFIRMATIONS,
    ZERO_ADDRESS,
    addApprox
} from "../../utils";
import { getPoolContract, getRouterContract } from "../../utils/query/interfaces";
import useCallGracefully from "../../customHooks/useCallGracefully";
import { useERC20Approval } from "../../customHooks/useERC20Approval";
import toast from "react-hot-toast";
import Loader from "../Loader";
import Success from "../Transaction";
import Icon from "../Icon";

const ZERO_DAY = 0;
const CONFIRM_TEXT = "Confirm in Metamask";
const UNLOCK_TEXT = "Unlocking...";
const IN_PROGRESS_TEXT = "Liquidating...";
const DEFAULT_TEXT = "Liquidate Loan";
const VIEW_FINAL_TX_TEXT = "View Closing Transaction";
const VIEW_LQ_TEXT = "View Liquidation Transaction";
const DONE_TEXT = 'Done';

const LiquidateInstant = ({
    className,
    tokenName,
    closeModal,
    // dueDate,
    // startDate,
    tokenTitle,
    // tokenAddress,
    paymentDates,
    paymentToken,
    borrowedAmount,
    borrowTxHash,
    paybackTxHash,
    finalPaybackAmount,
    token,
    isLiquidated,
    transactionHash,
    setTransactionHash
}) => {

    const { chainId, library, account } = useEthers();

    const chain = getChainById(
        chainId
    );

    const config = useConfig();
    const tokenDecimals = config.decimals[paymentToken];

    const [liquidateTxHash, setLiquidateTxHash] = useState();
    const [successfulApproval, setSuccessfulApproval] = useState(false);

    let defaultText = DEFAULT_TEXT;

    if (paybackTxHash) {
        defaultText = VIEW_FINAL_TX_TEXT;
    }

    if (isLiquidated) {
        defaultText = VIEW_LQ_TEXT;
    }

    const [buttonText, setButtonText] = useState(
        defaultText
    );

    const [pendingMetamask, setPendingMetamask] = useState(false);
    const [loading, setLoading] = useState(false);

    const {
        pool,
        nftAddress,
        tokenId,
        merkleIndex,
        merklePrice,
        merkleProof,
        paybackEvents = []
    } = token;

    const hasParams = nftAddress != null &&
        tokenId != null &&
        merkleIndex != null &&
        merklePrice != null &&
        merkleProof != null;

    const poolContract = getPoolContract(
        pool,
        library
    );

    const liquidationPrice = useCallGracefully(
        hasParams && {
            contract: poolContract,
            method: "getCurrentAuctionPrice",
            args: [
                nftAddress,
                tokenId,
                merkleIndex,
                merklePrice,
                merkleProof,
            ]
        });

    const value = produceAccurateValue(
        borrowedAmount,
        tokenDecimals
    );

    const paybackValue = produceAccurateValue(
        finalPaybackAmount,
        tokenDecimals
    );

    const timelinePaybackSteps = paybackEvents
        ?.filter(event => {
            return event.tokenOwner !== ZERO_ADDRESS
                && event.transactionHash !== borrowTxHash
                && (
                    !paybackTxHash
                    || event.transactionHash !== paybackTxHash
                )
                // Only include events after latest loan creation
                && (Number(event.timestamp.toString()) > paymentDates.creationDateTimestamp)
        })
        ?.map(event => {
            const date = new Date(Number(event.timestamp.toString()) * 1000);
            const amount = produceAccurateValue(
                event.transferAmount,
                tokenDecimals
            );
            return {
                value: `${formatDateFormal(date)} - Returned ${addApprox(amount)} ${paymentToken}`,
                txHash: event.transactionHash
            }
        });

    const lastStepAction = isLiquidated ? 'Liquidated' : 'Returned';

    const lastStepValue = paybackTxHash
        ? `${formatDateFormal(paymentDates.finalPaymentObj)} - ${lastStepAction} ${paybackValue} ${paymentToken}`
        : `${formatDateFormal(paymentDates.dueDate)} - Expected Payback`;

    const steps = [
        {
            value: `${formatDateFormal(paymentDates.creationDate)} - Borrowed ${value} ${paymentToken}`,
            txHash: borrowTxHash
        },
        ...timelinePaybackSteps,
        {
            value: lastStepValue,
            txHash: paybackTxHash || null,
            variant:
                isLiquidated
                    ? 'danger'
                    : paybackTxHash
                        ? 'success'
                        : null
        }
    ];

    const closedLoanTxHash = paybackTxHash || liquidateTxHash;
    const isButtonDisabled = paymentDates.daysToPayment >= ZERO_DAY && !closedLoanTxHash && paymentDates.daysToPayment !== -0;

    const approvalDone = successfulApproval;

    // TODO: Create reusable approval steps screen
    const iconColor = approvalDone
        ? "#45B26B"
        : "#9757D7";

    const iconColorB = approvalDone && (buttonText.includes("...") || buttonText === DONE_TEXT)
        ? "#9757D7"
        : "";

    const iconName = approvalDone
        ? 'check'
        : 'lock';

    const iconSize = approvalDone
        ? '20'
        : '24';

    const approval = useERC20Approval({
        onConfirmation: () => {
            setPendingMetamask(true);
            setButtonText(CONFIRM_TEXT)
        },
        onApproving: (transactionHash) => {
            setPendingMetamask(false);
            setLoading(true);
            setTransactionHash && setTransactionHash(transactionHash);
            setButtonText(UNLOCK_TEXT);
        },
        onApprovalComplete: (approval) => {
            setLoading(false);
            if (approval) {
                setSuccessfulApproval(true);
                handleLiquidate();
            }
        },
        onError: (error) => {
            setLoading(false);
            handleError(error);
            setPendingMetamask(false);
            setTransactionHash && setTransactionHash(null);
            setButtonText(
                defaultText
            );
            toast.error(
                getToastErrorMessage(error.code),
                getToastSettings("🚫")
            );
        }
    });

    const handleApprove = () => {

        if (buttonText === DONE_TEXT) {
            closeModal();
        };

        if (paybackTxHash || !hasParams || liquidateTxHash) return;

        approval.call(
            config.routerAddress,
            config.paymentTokens[paymentToken],
        );
    }

    const handleError = (err) => {
        toast.error(
            getToastErrorMessage(err.code),
            getToastSettings("🚫")
        );
    };

    const handleLiquidate = async () => {

        try {

            if (!account) return;
            if (!library) return;

            if (buttonText !== defaultText) return;

            const signer = library.getSigner(
                account
            );

            const routerContract = getRouterContract(
                config.routerAddress,
                signer
            );

            setButtonText(
                CONFIRM_TEXT
            );

            const liquidateNFT = await routerContract.liquidateNFT(
                pool,
                nftAddress,
                tokenId,
                merkleIndex,
                merklePrice,
                merkleProof
            );

            setLoading(true);
            setButtonText(
                IN_PROGRESS_TEXT
            );

            setTransactionHash(
                liquidateNFT.hash
            );

            await liquidateNFT.wait(
                MIN_CONFIRMATIONS
            );

            setLiquidateTxHash(
                liquidateNFT.hash
            );

            setLoading(false);
            setButtonText(DONE_TEXT);

        } catch (err) {
            setLoading(false);
            setButtonText(defaultText);
            handleError(err);
            setTransactionHash && setTransactionHash(null);
            setLiquidateTxHash(null);
            toast.error(
                getToastErrorMessage(err.code),
                getToastSettings("🚫")
            );
        }
    }

    return (
        <div className={cn(className)}>
            <div className={styles1.list}>
                {transactionHash && (
                    <div style={{ marginBottom: "30px" }}>
                        <div className={styles1.body} >
                            {buttonText === DONE_TEXT ? (
                                <span>
                                    Congratulations! Your liquidation transaction is now confirmed repaying the loan to the pool
                                </span>
                            ) : (
                                <span>
                                    Your transaction is now in progress, follow the steps below to finalize your liquidation
                                </span>
                            )}
                        </div>
                        <div className={styles1.row}>
                            <div>View Transaction</div>
                            <div>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={chain.getExplorerTransactionLink(transactionHash)}
                                    className={styles1.link}
                                >
                                    {shortenIfTransactionHash(transactionHash)}
                                </a>
                            </div>
                        </div>
                    </div>
                )}
                {
                    liquidateTxHash ? (
                        <Success
                            txHash={liquidateTxHash}
                            title={`You successfully liquidated loan for ${tokenTitle} owning the NFT now`}
                            showButton={false}
                            celebrate={true}
                            lockerAddress={EMPTY_ADDRESS}
                            displaySocials={false}
                            tweet={""}
                        />
                    ) : (
                        <div
                            className={styles1.item}
                            style={{ display: transactionHash ? 'block' : 'none' }}
                        >
                            <div className={styles1.head}>
                                <div
                                    className={styles1.icon}
                                    style={{ background: iconColor, borderColor: iconColor }}
                                >
                                    <Icon name={iconName} size={iconSize} />
                                </div>
                                <div className={styles1.details}>
                                    <div className={styles1.info}>
                                        Step 1 - Unlock Tokens
                                    </div>
                                    <div className={styles1.text}>
                                        Sends a transaction allowing LiquidNFTs router contract to transfer {paymentToken} on your behalf
                                    </div>
                                </div>
                            </div>
                            <div className={styles1.stepLine} />
                            <div className={styles1.head}>
                                <div
                                    style={{ background: iconColorB, borderColor: iconColorB }}
                                    className={styles1.icon}>
                                    <Icon
                                        name="wallet"
                                        size="24"
                                    />
                                </div>
                                <div className={styles1.details}>
                                    <div className={styles1.info}>
                                        Step 2 - Confirm Liquidation
                                    </div>
                                    <div className={styles1.text}>
                                        Send transaction to confirm liquidating loan
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
            {
                !liquidateTxHash && !transactionHash && (
                    <Timeline
                        items={steps}
                        name={tokenName}
                        title={tokenTitle}
                        isButtonDisabled={isButtonDisabled}
                        // activeIndex={activeIndex}
                        // borrowTxHash={borrowTxHash}
                        paybackTxHash={paybackTxHash}
                        liquidationPrice={liquidationPrice}
                        tokenDecimals={tokenDecimals}
                        paymentToken={paymentToken}
                        isLiquidated={isLiquidated}
                    />
                )
            }
            <a
                style={{ pointerEvents: isButtonDisabled ? "none" : "auto" }}
                target="_blank"
                rel="noopener noreferrer"
                href={closedLoanTxHash && chain.getExplorerTransactionLink(closedLoanTxHash)}
            >
                <button
                    style={{ marginTop: "24px" }}
                    disabled={isButtonDisabled || loading || pendingMetamask}
                    onClick={handleApprove}
                    className={cn(
                        "button",
                        styles.button,
                        (buttonText === DEFAULT_TEXT || buttonText === DONE_TEXT || buttonText.includes('View')) ? "" : "loading",
                        {
                            disabled: isButtonDisabled
                        }
                    )}
                >
                    {loading && (
                        <Loader className={styles.loaderSmall} color="white" />
                    )}
                    { buttonText }
                </button>
            </a>
        </div>
    );
};

export default LiquidateInstant;
