import { useTokenBalance } from "@usedapp/core";
import useCallGracefully from './useCallGracefully';
import { getPoolContract } from "../utils/query/interfaces";

export const usePoolUserInfo = (
    poolAddress,
    userAddress
) => {

    const poolContract = getPoolContract(
        poolAddress
    );

    const haveParamsForA = poolAddress && userAddress && poolContract;
    const userInternalShares = useCallGracefully(haveParamsForA &&
        {
            contract: poolContract,
            method: "internalShares",
            args: [userAddress],
        }
    ) ?? null;

    const userTokenShares = useTokenBalance(
        poolAddress,
        userAddress
    );

    const haveParamsForB = userTokenShares && userInternalShares && poolContract;
    const haveValuesForB = haveParamsForB && (userTokenShares.gt("0")
        || userInternalShares.gt("0")
    );

    const userTokensDeposited = useCallGracefully(haveValuesForB &&
        {
            contract: poolContract,
            method: "calculateWithdrawAmount",
            args: [userInternalShares.add(userTokenShares)],
        }
    ) ?? null;

    return {
        userInternalShares,
        userPoolTokenBalance: userTokenShares,
        userTokensDeposited
    };
};
