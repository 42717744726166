import React, { useState, useEffect } from "react";
import { useEthers } from "@usedapp/core";
import { formatUnits } from "ethers/lib/utils";
// import Slider from "react-slick";
import cn from "classnames";
import Icon from "../../components/Icon";
import styles from "./Item.module.sass";
import Users from "./Users";
import Details from "./Details";
import Control from "./Control";
import Options from "./Options";
import History from "./History";
import Claimings from "./Claimings";
import Refunds from "./Refunds";
// import Progress from "./Progress";
import Steps from "./Steps";
import TokenImage from "../../components/TokenImage";
import FormattedAmount from "../../components/FormattedAmount";
import TipHolder from "../../components/TipHolder/TipHolder";
import loaderImage from "../../assets/images/loaders/golden.svg";
import ExtraOptions from "../../components/ExtraOptions/ExtraOptions";

import {
    SECONDS_IN_DAY,
    MS_IN_SECOND,
    ZERO_ADDRESS,
    EXPIRED_STATUS,
    LIQUIDATED_STATUS,
    READY_STATUS,
    PENDING_STATUS,
    ACTIVE_STATUS,
    TERMINATED_STATUS,
    DEFAULT_DECIMALS,
    FINISHED_STATUS,
    addDays,
    itemsDefault,
} from "../../utils";

const DAYS_BEFORE_ACTIVE = 5;
const DAYS_TO_PAYBACK = DAYS_BEFORE_ACTIVE + 1;

const navLinks = [
    "Information",
    "Contributors",
    "Transactions",
    "Refunds",
    "Scrapings"
];

// const SlickArrow = ({ currentSlide, slideCount, children, ...props }) => (
// <button {...props}>{children}</button>
// );

const PENDING_LISTING_TITLE = "Pending Listing";
const FUNDED_LISTING_TITLE = "Funded Listing";
const EXPIRED_LISTING_TITLE = "Expired Listing";
const TERMINATED_LISTING_TITLE = "Terminated Listing";

const PENDING_LISTING_INFO = "Waiting for contributors to fund the loan";
const FUNDED_LISTING_INFO = "Contains enough funds for activation";
const TERMINATED_LISTING_INFO = "Owner decided to withdraw collateral"

// TO-DO: activate line between states (enabled/disabled based on the phase)

const Item = ({
    lockerAddress,
    listingDetails,
    paybackEvents,
    paybackEventsByUser,
    refundEvents,
    claimEvents,
    claimEventsByUser
 }) => {
    const {
        lockerOwner,
        floorAsked,
        totalAsked,
        paymentRate,
        paymentTime,
        paymentToken,
        tokens,
        paymentTokenName,
        title,
        isBundle,
        totalCollected,
        remainingBalance,
        creationTime,
        contributionPhase,
        floorNotReached,
        nextDueTime,
        singleProvider,
        contributors,
        contributionEvents,
        loanStatus,
        ownersAddress,
        claimableBalance,
        floorPrice,
        collectionName,
        // belowFloorAsked,
    } = listingDetails;

    const { account } = useEthers();
    const [activeIndex, setActiveIndex] = useState(0);
    const [items, setItems] = useState(itemsDefault);
    const [tokenIndex, setTokenIndex] = useState(0);
    const activePage = navLinks[activeIndex];

    // const removed = items && items.slice(0, 2);

    const loanLength = paymentTime
        ? Math.floor(paymentTime.toNumber() / SECONDS_IN_DAY)
        : 0;

    const borrowingFee = paymentRate
        ? paymentRate.toString()
        : 0;

    const whenGrayed = [
        FINISHED_STATUS,
        EXPIRED_STATUS,
        LIQUIDATED_STATUS,
        TERMINATED_STATUS
    ];

    // @TODO: undo this mess
    const notFunded = totalAsked && totalAsked.gt(
        totalCollected
    );
    // const noOwner = lockerOwner && lockerOwner === ZERO_ADDRESS;
    const isActivated = loanStatus === ACTIVE_STATUS;
    const isExpired = contributionPhase === false && floorNotReached === true;
    // const isTerminated = loanStatus === TERMINATED_STATUS;
    const isFinalized = loanStatus === FINISHED_STATUS;
    const isTerminated = loanStatus === TERMINATED_STATUS;

    const grayScale = whenGrayed.includes(loanStatus);
    const notActive = lockerOwner !== ZERO_ADDRESS && nextDueTime && parseInt(nextDueTime.toString()) === 0;

    useEffect(() => {

        if (!paymentTime) return;
        if (!creationTime) return;

        const newItems = itemsDefault;
        const paymentTimeToDays = Math.floor(
            JSON.parse(paymentTime.toString()) / SECONDS_IN_DAY
        );
        // convert UNIX seconds to milliseconds
        const createdDateObj = new Date(JSON.parse(
            creationTime.toString()) * MS_IN_SECOND
        );

        const createdDate = createdDateObj;

        const activationDate = addDays(
            createdDateObj,
            DAYS_BEFORE_ACTIVE
        );

        const paybackDate = addDays(
            createdDateObj,
            DAYS_TO_PAYBACK
        );

        const closingDate = addDays(
            createdDateObj,
            DAYS_BEFORE_ACTIVE + paymentTimeToDays
        );

        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        };

        [createdDate, activationDate, paybackDate, closingDate].forEach((newDate, index) => {

            const date = newDate.toLocaleString('en-US', { hour12: false }).split(" ");
            const time = date[1];
            newItems[index].date = newDate.toLocaleDateString(undefined, options);
            newItems[index].time = time.replace("24", "00");

            // ?? timezone issue ?? need to base on contract timestamp which is London Time (GMT+0);
            // newItems[index].gray = newDate.valueOf() >= new Date().setHours(0, 0, 0, 0).valueOf
            newItems[index].gray = newDate >= new Date() || isExpired || isFinalized || isTerminated   // just comparing like this now
                ? 1
                : 0;
        });
        setItems(newItems);
    }, [creationTime, paymentTime, isExpired, isFinalized, isTerminated]);

    const stageOneItems = [
        PENDING_STATUS,
        EXPIRED_STATUS,
        READY_STATUS,
        TERMINATED_STATUS
    ];

    const isStageOne = stageOneItems.includes(
        loanStatus
    );

    const borrowingFeeFormated = formatUnits(
        borrowingFee.toString(),
        DEFAULT_DECIMALS
    );

    // console.log('tokens', tokens);

    return (
        <div
            style={{ paddingTop: "32px", paddingBottom: "0px" }}
            className={cn("section", styles.section)}
        >
            <div className={styles.head}>
                <div
                    style={{ justifyContent: "space-between" }}
                    className={cn("container", styles.container)}
                >
                    <div className={styles.top}>
                        {!tokens[0] && (
                            <div>
                                <div className={cn("h2", styles.title)}>
                                    Loading Listing
                                </div>
                                <div style={{ marginBottom: "5px" }} className={styles.slogan}>
                                    Fetching data from the blockchain
                                </div>
                            </div>
                        )}
                        { tokens[0] && contributionPhase === false && floorNotReached === false && notActive && (
                            <div>
                                <div className={cn("h2", styles.title)}>
                                    {FUNDED_LISTING_TITLE}
                                </div>
                                <div style={{ marginBottom: "5px" }} className={styles.slogan}>
                                    {FUNDED_LISTING_INFO}
                                </div>
                            </div>
                        )}
                        { tokens[0] && contributionPhase && notActive && (
                            <div>
                                <div className={cn("h2", styles.title)}>
                                    { notFunded
                                        ? PENDING_LISTING_TITLE
                                        : FUNDED_LISTING_TITLE
                                    }
                                </div>
                                <div style={{ marginBottom: "5px" }} className={styles.slogan}>
                                    { notFunded
                                        ? PENDING_LISTING_INFO
                                        : FUNDED_LISTING_INFO
                                    }
                                </div>
                            </div>
                        )}
                        { tokens[0] && loanStatus === FINISHED_STATUS && (
                            <div>
                                <div className={cn("h2", styles.title)}>
                                    Finalized Listing
                                </div>
                                <div style={{ marginBottom: "5px" }} className={styles.slogan}>
                                    Loan is payed back successfully
                                </div>
                            </div>
                        )}
                        { tokens[0] && loanStatus === TERMINATED_STATUS && (
                            <div>
                                <div className={cn("h2", styles.title)}>
                                    {TERMINATED_LISTING_TITLE}
                                </div>
                                <div style={{ marginBottom: "5px" }} className={styles.slogan}>
                                    {TERMINATED_LISTING_INFO}
                                </div>
                            </div>
                        )}
                        { tokens[0] && isExpired && (
                            <div>
                                <div className={cn("h2", styles.title)}>
                                    { EXPIRED_LISTING_TITLE }
                                </div>
                                <div style={{ marginBottom: "5px" }} className={styles.slogan}>
                                    Not enough contributions received
                                </div>
                            </div>
                        )}
                        { tokens[0] && isActivated && (
                            <div>
                                <div className={cn("h2", styles.title)}>
                                    Activated Listing{/*Contribution Phase*/}
                                </div>
                                <div style={{ marginBottom: "5px" }} className={styles.slogan}>
                                    Waiting for NFT owner to payback the loan
                                </div>
                            </div>
                        )}
                        <div className={styles.sorting}>
                            <div style={{ display: "flex" }}>
                                <button
                                    className={cn(styles.link, {
                                        [styles.active]: activeIndex === 0,
                                    })}
                                    onClick={() => setActiveIndex(0)}
                                    // key={index}
                                >
                                    Information
                                </button>
                                <button
                                    className={cn(styles.link, {
                                        [styles.active]: activeIndex === 1,
                                    })}
                                    onClick={() => setActiveIndex(1)}
                                    // key={index}
                                >
                                    Contributors
                                </button>
                                { loanStatus !== EXPIRED_STATUS && (
                                    <button
                                        className={cn(styles.link, {
                                            [styles.active]: activeIndex === 2,
                                        })}
                                        onClick={() => setActiveIndex(2)}
                                        // key={index}
                                    >
                                        Paybacks
                                    </button>
                                )}
                                { (isActivated === true || loanStatus === FINISHED_STATUS) && (
                                    <button
                                        className={cn(styles.link, {
                                            [styles.active]: activeIndex === 4,
                                        })}
                                        onClick={() => setActiveIndex(4)}
                                        // key={index}
                                    >
                                        Scrapings
                                    </button>
                                )}
                                { ((isActivated === false && loanStatus !== FINISHED_STATUS) || (singleProvider !== ZERO_ADDRESS && contributors?.length > 1)) && (
                                    <button
                                        className={cn(styles.link, {
                                            [styles.active]: activeIndex === 3,
                                        })}
                                        onClick={() => setActiveIndex(3)}
                                        // key={index}
                                    >
                                        Refunds {/*({contributors?.length})*/}
                                    </button>
                                )}
                                <ExtraOptions screen={"loan"} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={cn("container", styles.container)}>
                <div className={styles.bg}>
                    <div className={styles.preview}>
                        <div style={{ address: "relative", width: "500px" }}>
                            <div className={styles.categories}>
                                <div className={styles.tags}>
                                    {isBundle && (
                                        <div
                                            className={cn("status-pink", styles.pink, styles.category)}
                                        >
                                            bundle
                                        </div>
                                    )}
                                    {isBundle &&
                                        tokens.map((x, index) => (
                                            <div
                                                className={cn(
                                                    'status-black',
                                                    styles.category,
                                                    { [styles.selected]: tokenIndex === index }
                                                )}
                                                style={{ cursor: "pointer" }}
                                                onClick={() => setTokenIndex(index)}
                                                key={index}
                                            >
                                                # {x.id.toString()}
                                            </div>
                                        )
                                    )}
                                </div>
                                <a
                                    href={`https://etherscan.io/address/${lockerAddress}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <button className={cn(styles.external)}>
                                        <div className={styles.open}>
                                            <Icon name="arrow-next" size="20" />
                                        </div>
                                    </button>
                                </a>
                            </div>
                            <div style={{ height: "500px", width: "500px" }}>
                                {/*<Slider className="creative-slider" {...settings}>
                                    {tokens[0] && (
                                        <TokenImage
                                            token={tokens[0]}
                                            size="500"
                                            style={{ backgroundColor: "#638596", borderRadius: "16px" }}
                                        />
                                    )}
                                </Slider>*/}
                                <img
                                    alt={"loader"}
                                    src={loaderImage}
                                    style={{
                                        width: "100%",
                                        transform: "scale(0.2)",
                                        filter: "hue-rotate(300deg)",
                                        position: "relative"
                                    }}
                                />
                                {tokens[0] && (
                                    <TokenImage
                                        token={tokens[tokenIndex]}
                                        size="500"
                                        style={{
                                            filter: `grayscale(${grayScale ? 90 : 0}%)`,
                                            position: "absolute",
                                            top: "0px",
                                            left: "0px",
                                            backgroundColor: "#638596",
                                            borderRadius: "20px",
                                            width: "100%"
                                        }}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                    <Options
                        items={items}
                        className={styles.options}
                        tokenIndex={tokenIndex}
                        listingDetails={{ ...listingDetails, lockerAddress, borrowingFee }}
                    />
                </div>
                <div className={styles.details}>
                    <h3 className={cn("h4", styles.name)}>{title}</h3>
                    <div className={styles.cost}>
                        <TipHolder
                            borrowingRate={borrowingFeeFormated}
                            borrowingTime={loanLength * SECONDS_IN_DAY}
                            extraClass={styles.price}
                        />
                        {isStageOne === true && (
                            <div className={cn("status-stroke-black", styles.price)}>
                                {paymentToken && (
                                    <FormattedAmount
                                        amount={totalAsked}
                                        token={paymentToken}
                                        label={true}
                                    />
                                )}
                            </div>
                        )}
                        {isStageOne === false && (
                            <div className={cn("status-stroke-black", styles.price)}>
                                {paymentToken && (
                                    <FormattedAmount
                                        amount={totalCollected}
                                        token={paymentToken}
                                        label={true}
                                    />
                                )}
                            </div>
                        )}
                        <div className={styles.counter}>{loanLength} Day Loan</div>
                    </div>
                    {activePage === "Refunds" && (
                        <>
                            <div className={styles.info}>
                                In case floor asked amount is not reached or if a sole-contributor
                                funds entire loan then original contributors can claim refunds back
                            </div>
                            <Refunds
                                refundEvents={refundEvents}
                                paymentTokenName={paymentTokenName}
                                paymentToken={paymentToken}
                                className={styles.users}
                            />
                        </>
                    )}
                    {paymentToken && activePage === "Contributors" && (
                        <Users
                            items={items}
                            floorAsked={floorAsked}
                            contributionEvents={contributionEvents}
                            className={styles.users}
                            users={contributors}
                            paymentTokenName={paymentTokenName}
                            paymentToken={paymentToken}
                        />
                    )}
                    {activePage === "Information" && (
                        <Details
                            isStageOne={isStageOne}
                            remainingBalance={remainingBalance}
                            loanStatus={loanStatus}
                            nextDueTime={nextDueTime}
                            timelineItems={items}
                            totalCollected={totalCollected}
                            finalDate={items[3].date}
                            borrowingFee={borrowingFee}
                            totalAsked={totalAsked}
                            floorAsked={floorAsked}
                            ownersAddress={ownersAddress}
                            lockerOwner={lockerOwner}
                            className={[styles.users]}
                            claimableBalance={claimableBalance}
                            paymentTokenName={paymentTokenName}
                            paymentToken={paymentToken}
                        />
                    )}
                    {activePage === "Owner" && (
                        <Users
                            contributionEvents={contributionEvents}
                            className={styles.users}
                            users={[{ user: lockerOwner }]}
                            paymentTokenName={paymentTokenName}
                            paymentToken={paymentToken}
                        />
                    )}
                    {activePage === "Transactions" && (
                        <History
                            className={styles.users}
                            events={paybackEvents}
                            eventsByUser={paybackEventsByUser}
                            paymentTokenName={paymentTokenName}
                            paymentToken={paymentToken}
                            totalCollected={totalCollected}
                            remainingBalance={remainingBalance}
                            borrowingFee={borrowingFee}
                        />
                    )}
                    {activePage === "Scrapings" && (
                        <Claimings
                            className={styles.users}
                            events={claimEvents}
                            eventsByUser={claimEventsByUser}
                            paymentTokenName={paymentTokenName}
                            paymentToken={paymentToken}
                        />
                    )}
                    <Control
                        className={styles.control}
                        account={account}
                        activePage={activePage}
                        listingDetails={{ ...listingDetails, lockerAddress }}
                        items={items}
                        props={{
                            ownersAddress,
                            lockerOwner,
                            paymentToken,
                            paymentTokenName,
                            totalAsked,
                            loanStatus,
                            totalCollected,
                            remainingBalance,
                            title,
                            singleProvider,
                            paymentTime,
                            paymentRate,
                            borrowingFee,
                            lockerAddress,
                            contributors,
                            isStageOne,
                            floorPrice,
                            collectionName
                        }}
                    />
                </div>
            </div>
            {isStageOne === true && (
                <Steps header={false} info={true} fade={false} items={items} />
            )}
            {isStageOne === false && (
                <Steps header={false} info={true} fade={false} nextDueTime={nextDueTime} items={items.slice(2, 4)} />
            )}
        </div>
    );
};

export default Item;
