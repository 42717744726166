import React, { useState } from "react";
import { useDispatch } from "react-redux";
import cn from "classnames";
import styles from "./Activate.module.sass";
import lockerAbi from "../../ethers/abis/LiquidLocker.json";
import { useEthers } from "@usedapp/core";
import { Contract } from "ethers";
import CurrencyInput from "react-currency-input-field";
import { setUpdateLatestTx } from '../../redux/settingsSlice';
import Loader from "../../components/Loader";
import FormattedAmount from "../../components/FormattedAmount";
import { formatUnits, parseUnits } from "ethers/lib/utils";
// import Success from "./Success";
import Success from "../../components/Transaction";
import useConfig from "../../customHooks/useConfig";

import {
    CONTRIBUTION_PHASE_IN_SECONDS,
    MS_IN_SECOND,
    ONE_HUNDRED,
    PERCENT_OPTIONS,
    // READY_STATUS,
    // ZERO_ADDRESS
} from "../../utils";

// TO-DO: move to constants file;
// const EMPTY_STRING = "";

const DEFAULT_TEXT = "Proceed with Activation";
// const CONFIRM_TEXT = "Confirm in Metamask";
const IN_PROGRESS = "Activating...";
const CONFIRM_TEXT = 'Confirm in Metamask';
const DONE_TEXT = "Done";

const Activate = ({ className, listingDetails, close, items }) => {

    const { library, account } = useEthers();
    const dispatch = useDispatch();
    const config = useConfig();
    const [prepay, setPrepay] = useState("");
    const [activeQuickSelect, setActiveQuickSelect] = useState();
    const [activationComplete, setActivationComplete] = useState(false);
    const [activationProcessing, setActivationProcessing] = useState(false);
    // const [errorMessage, setErrorMessage] = useState(EMPTY_STRING);
    const [loading, setLoading] = useState(false);
    const [transactionHash, setTransactionHash] = useState();
    const [buttonText, setButtonText] = useState(DEFAULT_TEXT);
    const [invalidAmount, setInvalidAmount] = useState(false);

    const {
        lockerAddress,
        paymentToken,
        paymentRate,
        paymentTime,
        totalCollected,
        floorAsked,
        // totalAsked,
        lockerOwner,
        creationTime,
        // loanStatus,
        paymentTokenName,
    } = listingDetails;

    const canActivate = totalCollected && totalCollected.gte(floorAsked);
    const isOwner = lockerOwner === account; // || loanStatus === READY_STATUS;
    // console.log(creationTime, 'creationTime');

    // const paymentTokenInfo = useToken(paymentToken);
    const decimals = config.decimals[paymentTokenName];
    const signer = library.getSigner(account);

    const fiveDaysInMs = CONTRIBUTION_PHASE_IN_SECONDS * MS_IN_SECOND;
    const creationTimeStamp = creationTime ? creationTime.toNumber() : 0;
    const creationTimestampMs = creationTimeStamp * MS_IN_SECOND;
    const creationTimePlusFiveDays = creationTimestampMs + fiveDaysInMs;

    const totalBorrowingFee = paymentRate
        .div("1000000000000000000") // fee is in 1E18 format
        .mul(totalCollected)
        .div(100);

    const formattedTotalBorrow = parseFloat(formatUnits(totalBorrowingFee.toString(), decimals));

    const prepaySafe = prepay || 0;
    // dynamically get percent for any value (used in desc)
    const prepayPercentFromInput = parseFloat(prepaySafe) / formattedTotalBorrow;

    // convert to percentage
    const prepayAsPercentage = prepayPercentFromInput * 100;
    const prepayPercentDisplay = Math.round(prepayAsPercentage)

    // prepay amount used to send to contract
    const prepayAmount = parseUnits(prepaySafe.toString(), decimals);

    // percent used for calc with guard for empty input
    const prepayPercent = prepay
        ? prepayAsPercentage
        : 0;

    const secondsPrepaid = (prepayPercent * paymentTime.toNumber()) / 100;

    const msPrepaid = secondsPrepaid * 1000;
    const msPrepaidFromNow = msPrepaid + creationTimePlusFiveDays;

    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    };

    const nextDueDate = new Date(msPrepaidFromNow).toLocaleDateString(
        undefined,
        options
    );

    const remainigAmount = floorAsked.sub(
        totalCollected
    );

    const handleActivate = async () => {

        if (buttonText === DONE_TEXT) {
            close();
            return;
        }

        if (!prepay) {
            setInvalidAmount(true);
            return;
        }

        if (activationProcessing) return;

        setActivationProcessing(true);
        // setErrorMessage(EMPTY_STRING);

        try {

            const tokenContract = new Contract(
                lockerAddress,
                lockerAbi,
                signer
            );

            setButtonText(CONFIRM_TEXT);
            const tx = await tokenContract.enableLocker(
                prepayAmount.toString(),
                // configuration
            );

            const transactionHash = tx.hash;
            setLoading(true);
            setButtonText(IN_PROGRESS);

            const activationReceipt = await tx.wait();
            dispatch(setUpdateLatestTx(transactionHash));
            setLoading(false);
            setButtonText(DONE_TEXT);

            if (activationReceipt) {
                setTransactionHash(transactionHash);
                setActivationComplete(true);
                setTransactionHash(
                    activationReceipt.transactionHash
                );
            }

        } catch (err) {

            setLoading(false);
            setButtonText(DEFAULT_TEXT);
            setActivationProcessing(false);

            if (err.code === 4001) return;
            // setErrorMessage("Activation failed.");
            // TODO: use toast
        }

        setActivationProcessing(false);
    };

    const handleQuickSelect = (option) => {

        if (invalidAmount) setInvalidAmount(
            false
        );

        const percentKey =
            Object.keys(PERCENT_OPTIONS)
                .find(key =>
                    PERCENT_OPTIONS[key] === option
                );

        setActiveQuickSelect(
            percentKey
        );

        const percentVal = option === "Max"
            ? 100
            : option;

        const prepayPercent = parseInt(
            percentVal
        );

        const prepayAmount = totalBorrowingFee
            .mul(prepayPercent)
            .div(100);

        const prepayFormatted = formatUnits(
            prepayAmount.toString(),
            decimals
        );

        setPrepay(
            prepayFormatted
        );
    };

    const handleAmountChange = (value) => {
        if (invalidAmount) setInvalidAmount(false);
        if (parseFloat(value) > formattedTotalBorrow) return;

        setActiveQuickSelect(null);

        if (!value) {
            setPrepay("");
            return;
        }

        let percent = value / formattedTotalBorrow * ONE_HUNDRED;
        if (percent === ONE_HUNDRED) {
            percent = 'max';
        }

        const quickValue = PERCENT_OPTIONS[percent]
            ? percent.toString()
            : null;

        setActiveQuickSelect(
            quickValue
        );

        setPrepay(value);
    };

    const errorStyle = invalidAmount
        ? {borderBottom: "solid 1px #c73e77"}
        : {};

    return (
        <div className={cn(className, styles.transfer)}>
            <div className={cn("h4", styles.title)}>
                Activate Listing
            </div>
            { activationComplete === true && (
                <div>
                    <div className={styles.text}>
                        Congratulations! Transaction is now confirmed on the blockchain and the loan can be started
                    </div>
                </div>
            )}
            { activationComplete === false && (
                <div className={styles.text}>
                    {canActivate === true
                        ? 'Contributions for this listing reached floor asked amount and can be activated by the owner'
                        : 'To activate this listing the floor asked amount must be reached by contributors within 5 days'
                    }
                </div>
            )}
            { activationComplete && (
                <Success
                    txHash={transactionHash}
                    title={"You successfully activated the LiquidNFTs loan"}
                    showButton={false}
                    celebrate={true}
                />
            )}
            <div className={styles.table}>
                { activationComplete === false && canActivate === false && isOwner && (
                <div className={styles.row}>
                    <div>Floor Asked</div>
                    <div>
                        {floorAsked && (
                            <FormattedAmount
                                amount={floorAsked}
                                token={paymentToken}
                                label={true}
                            />
                        )}
                    </div>
                </div>
                )}
                { activationComplete === false && (
                <div className={styles.row}>
                    <div>Total Contributed</div>
                    <div>
                        {totalCollected && (
                            <FormattedAmount
                                amount={totalCollected}
                                token={paymentToken}
                                label={true}
                            />
                        )}
                    </div>
                </div>
                )}
                { activationComplete === false && isOwner && (
                    <div className={styles.text} style={{marginTop: "16px" }} >
                        To activate this listing please choose prepay amount to determine next payment due date
                    </div>
                )}
                { activationComplete === false
                    && !canActivate
                    && isOwner
                    && (
                    <div className={styles.row}>
                        <div>Contribution Ends</div>
                        <div>
                            {items[1].date}
                        </div>
                    </div>
                )}
                { activationComplete === true
                    && !transactionHash
                    && isOwner
                    && (
                    <div className={styles.row}>
                        <div>Next Payment Due</div>
                        <div>
                            {nextDueDate}
                        </div>
                    </div>
                )}
            </div>
            { canActivate
                && !transactionHash
                && isOwner
                && (
                    <div style={{ marginBottom: "20px" }}>
                        <div className={styles.row} style={errorStyle}>
                            <div className={styles.col} style={{ whiteSpace: 'nowrap' }}>Prepay Amount</div>
                            <div className={styles.col}>
                                <CurrencyInput
                                    id="input-prepay"
                                    autoComplete="off"
                                    name="prepay"
                                    allowNegativeValue={false}
                                    className={styles.input}
                                    placeholder="Enter Amount"
                                    decimalsLimit={18}
                                    value={prepay}
                                    decimalSeparator="."
                                    groupSeparator=","
                                    onValueChange={(value) => handleAmountChange(value)}
                                />
                            </div>
                            { prepay && (
                                <div
                                className={styles.col}>
                                    { paymentTokenName }
                                </div>
                            )}
                        </div>
                        <div className={cn(styles.row, styles.prep)}>
                            { Object.keys(PERCENT_OPTIONS).map((key) => (
                                    <button
                                        key={key}
                                        className={
                                            cn('button-stroke',
                                                styles.option,
                                                {
                                                    'button-active': activeQuickSelect === key
                                                }
                                            )
                                        }
                                        onClick={() => handleQuickSelect(PERCENT_OPTIONS[key])}
                                    >
                                        { PERCENT_OPTIONS[key] }
                                    </button>
                            ))}
                        </div>
                    </div>
            )}
            { !canActivate && isOwner && (
                <div className={styles.text}>
                    Listing needs additional{" "}
                    {remainigAmount && (
                        <FormattedAmount
                            amount={remainigAmount}
                            token={paymentToken}
                            label={true}
                        />
                    )}
                    {" "} contributed before it can be activated and funds issued
                </div>
            )}
            { activationComplete === true && isOwner && (
                <div className={styles.text}>
                    To avoid any penalties being added please strictly follow the provided payment schdule
                </div>
            )}
            {/* canActivate && activationComplete === false &&  (
                <div className={styles.text}>
                    Activating this listing immediately forwards all the contributed tokens to the loan owner
                </div>
            )*/}
            { activationComplete === false
                && !transactionHash
                && canActivate
                && isOwner
                && (
                    <div>
                        <div className={styles.text}>
                            {`
                                Prepaying ${prepay || 0} ${paymentTokenName} or ${prepayPercentDisplay}% of the borrowing fee
                                sets your next due date to ${nextDueDate}
                            `}
                        </div>
                    </div>
            )}
                <>
                    {/*<div className={styles.text}>
                        If the contribution phase is not over yet, the loan can only be activated by the NFT owner
                    </div>*/}
                    { isOwner && (
                        <div className={styles.btns}>
                            <button
                                className={cn(
                                    "button",
                                    styles.button,
                                    (buttonText === DEFAULT_TEXT || buttonText === DONE_TEXT) ? "" : "loading",
                                    (canActivate && isOwner) ? "" : "disabled"
                                )}
                                onClick={handleActivate}
                            >
                                { loading && <Loader className={styles.loaderSmall} color="white" /> }
                                {buttonText}
                            </button>
                            {/*<button className={cn("button-stroke", styles.button)} onClick={close}>
                                Cancel
                            </button>*/}
                        </div>
                    )}
                </>
        </div>
    );
};

export default Activate;
