import React from 'react';
import PropTypes from 'prop-types';

const StatusBadge = ({ fill, width = 25, height = 25 }) => {
    return (
        <svg viewBox="0 0 24 24" width={width} height={height} fill={fill} xmlns="http://www.w3.org/2000/svg">
            <path d="M17.5 8c.46 0 .91-.05 1.34-.12C17.44 5.56 14.9 4 12 4c-.46 0-.91.05-1.34.12C12.06 6.44 14.6 8 17.5 8zM8.08 5.03C6.37 6 5.05 7.58 4.42 9.47c1.71-.97 3.03-2.55 3.66-4.44z" opacity=".3"></path>
            <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 2c2.9 0 5.44 1.56 6.84 3.88-.43.07-.88.12-1.34.12-2.9 0-5.44-1.56-6.84-3.88.43-.07.88-.12 1.34-.12zM8.08 5.03C7.45 6.92 6.13 8.5 4.42 9.47 5.05 7.58 6.37 6 8.08 5.03zM12 20c-4.41 0-8-3.59-8-8 0-.05.01-.1.01-.15 2.6-.98 4.68-2.99 5.74-5.55 1.83 2.26 4.62 3.7 7.75 3.7.75 0 1.47-.09 2.17-.24.21.71.33 1.46.33 2.24 0 4.41-3.59 8-8 8z"></path>
            <circle cx="9" cy="13" r="1.25"></circle>
            <circle cx="15" cy="13" r="1.25"></circle>
        </svg>
    );
}

StatusBadge.propTypes = {
    fill: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number
};

export default StatusBadge;
