import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./Description.module.sass";
import Image from "../../../components/Image";

const Description = () => {
    return (
        <div className={cn("section-bg", styles.section)}>
            <div className={cn("container", styles.container)}>
                <div className={styles.wrap}>
                    {/*<div className={styles.stage}>Set your own terms</div>*/}
                    <h1 className={cn("h1", styles.title)}>
                        Fund NFT Loans
                    </h1>
                    <div className={styles.text}>
                        Contributors earn APY from borrowing fees
                    </div>
                    <div className={styles.btns}>
                        <Link className={cn("button", styles.button)} to="/instant">
                            Instant Pools
                        </Link>
                        <Link className={cn("button-stroke", styles.button)} to="/discover">
                            Custom Listings
                        </Link>
                    </div>
                </div>
                <div className={styles.gallery}>
                    <div className={styles.preview}>
                        <Image
                            srcSet="/images/content/funded-small@2x.png 2x"
                            srcSetDark="/images/content/funded-small@2x.png 2x"
                            src="/images/content/funded-small.png"
                            srcDark="/images/content/funded-small.png"
                            alt="LiquidNFTs"
                        />
                        {/*<Image
                            srcSet="/images/content/funded-pic@2x.png 2x"
                            srcSetDark="/images/content/funded-pic@2x.png 2x"
                            src="/images/content/funded-pic.png"
                            srcDark="/images/content/funded-pic.png"
                            alt="LiquidNFTs"
                        />*/}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Description;
