import React from "react";
import { useEthers } from '@usedapp/core';
import useConfig from '../../customHooks/useConfig';
import Search from './Search';
import useExistingLockerFetch from "../../customHooks/useExistingLockerFetch";
import { BLOCKS_PER_DAY } from "../../utils";

const SearchWrapper = () => {

    const {
        library,
        chainId,
        account,
    } = useEthers();

    const config = useConfig();
    const blockRange = BLOCKS_PER_DAY * 150;

    const existingLockers = useExistingLockerFetch(
        null,
        blockRange
    );

    return (
        <Search
            existingLockers={existingLockers}
            account={account}
            chainId={chainId}
            config={config}
            library={library}
        />
    );
};

export default SearchWrapper;
