import { useState, useEffect } from "react";
// import { useSelector } from "react-redux";
import { Interface } from "ethers/lib/utils";
import { useEthers } from "@usedapp/core";
import useConfig from "./useConfig";
import { TEST_NETWORK_ID } from "../utils";
import { getRouterContract } from "../utils/query/interfaces";
import LIQUID_ROUTER_ABI from "../ethers/abis/LiquidRouter.json";

const parseLog = (log, contractInterface) => ({
    blockNumber: log.blockNumber,
    ...contractInterface.parseLog(log),
});

const getData = (event) => ({
    borrower: event.args.borrower,
    amount: event.args.amount,
    nftAddress: event.args.nftAddress,
    tokenId: event.args.tokenId,
    timestamp: event.args.timestamp,
    pool: event.args.pool,
    blockNumber: event.blockNumber,
});

const addTxHash = (data, logs) =>
    data.map((d, i) => (
        {
            ...d,
            transactionHash: logs[i].transactionHash
        }
    )
);

const liquidRouterInterface = new Interface(
    LIQUID_ROUTER_ABI
);

const parseBorrowedLog = (log) => parseLog(
    log,
    liquidRouterInterface
);

const useBorrowEvents = (poolAddress) => {

    // const latestTxHash = useSelector((state) => state.settings.latestTxHash);
    const { chainId, library } = useEthers();
    const [borrowEvents, setBorrowEvents] = useState();
    const [borrowEventsTest, setBorrowEventsTest] = useState();
    const config = useConfig();

    useEffect(() => {
        let mounted = true;

        const fetchLogs = async () => {

            if (!chainId) return;
            if (!library) return;
            if (config.chainId !== chainId) return;

            // @TODO: Handle loading with empty state,
            // what is returned when no router borrow events is empty
            // ATTENTION: this also triggers twice for mainnet and ropstens
            // for some reason: console.log(borrowedFilter, 'borrowedFilter');

            const routerContract = getRouterContract(
                config.routerAddress
            );

            const borrowedFilter = routerContract.filters.FundsBorrowed();

            const borrowLogs = await library.getLogs({
                ...borrowedFilter,
                fromBlock: config.inceptionBlock
            });

            const borrowEvents = borrowLogs.map(
                parseBorrowedLog
            );

            const borrowData = borrowEvents.map(
                getData
            );

            const borrowWithTxHash = addTxHash(
                borrowData,
                borrowLogs
            );

            let sortedBorrowWithTxHash = borrowWithTxHash.sort(
                (a, b) => b.timestamp.toNumber() - a.timestamp.toNumber()
            );

            if (poolAddress) {
                sortedBorrowWithTxHash = sortedBorrowWithTxHash
                    .filter(event => event.pool.toLowerCase() === poolAddress.toLowerCase());
            }

            if (mounted) {
                chainId === TEST_NETWORK_ID
                    ? setBorrowEventsTest(sortedBorrowWithTxHash)
                    : setBorrowEvents(sortedBorrowWithTxHash);
            }
        };

        fetchLogs();

        return () => {
            mounted = false
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [library, chainId, poolAddress]);

    return [borrowEvents, borrowEventsTest];
};

export default useBorrowEvents;
