/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import cn from "classnames";
import { useEthers } from "@usedapp/core";
import { Link } from "react-router-dom";

import useConfig from '../../customHooks/useConfig';
import { getMainNetAddress } from "../../ethers/nftCollections";
import TokenImage from "../TokenImage";
import FormattedAmount from "../FormattedAmount";
import Modal from "../../components/Modal";
import PaybackInstant from "../../components/PaybackInstant";
import loaderImage from "../../assets/images/loaders/golden.svg";
import LiquidateInstant from "../LiquidateInstant";
import styles from "../LockerCard/LockerCard.module.sass";
import styles1 from "../PaybackInstant/PaybackInstant.module.sass";
import Icon from "../Icon";
import Avatar from "../../components/Avatar";
import { FETCHING_DATA_MESSAGE, getChainById } from "../../utils";

// import { OPENSEA_API, OPENSEA_API_KEY } from "../../utils/config";
import {
    DEFAULT_COLLECTION_NAME,
    getDifferenceBetweenDates,
    formatDaysPast
    // getRelativeTimestamp
} from "../../utils";
import { getPaybackData } from "../../utils/query/pools";
import useGetPoolRates from "../../customHooks/useGetPoolRates";

const ZERO_DAY = 0;
const ONE_DAY = 1;
const DAYS_DUE_WARNING_THRESHOLD = 15;
const DAYS_DUE_DANGER_THRESHOLD = 5;

export const InstantCardModalType = {
    PAYBACK: 'PAYBACK',
    DETAIL: 'DETAIL'
};

const InstantCard = ({
    token,
    className,
    showOwner,
    blockModal,
    parentClass,
    modalType = InstantCardModalType.PAYBACK,
    tokenButtonLabel,
    cardIndex,
    rowLayout
}) => {

    const {
        chainId,
        library
    } = useEthers();

    const chain = getChainById(
        chainId
    );

    const PAYBACK_TIME = 35;

    const {
        id,
        name,
        tokenAddress,
        timestamp,
        borrowRate,
        pool,
        tokenId,
        nftAddress,
        principalTokens,
        lastPaidTime,
        closedLoan,
        liquidatedLoan,
        // isUser,
        amount,
        finalPaybackAmount,
        finalPaymentDate,
        paybackTx,
        borrower,
        loanInterest,
        transactionHash,
        paybackEvents
    } = token;

    const config = useConfig();

    const closedLoanTxHashes = useSelector(
        (state) => state.settings.closedLoanTxHashes
    );

    const extendedLoanIds = useSelector(
        (state) => state.settings.extendedLoanIds
    );

    const [tokenImage, setTokenImage] = useState(
        token?.data?.image
    );

    const poolRates = useGetPoolRates(
        pool
    );

    const [merkleProof, setMerkleProof] = useState();
    const [merklePrice, setMerklePrice] = useState();
    const [merkleIndex, setMerkleIndex] = useState();
    const [minimumPay, setMinimumPay] = useState();

    const [liquidateApprovalTxHash, setLiquidateApprovalTxHash] = useState();

    useEffect(() => {

        let mounted = true;
        const getPaybackInfo = async () => {

            const {
                merkleProof,
                merklePrice,
                merkleIndex,
                minimumPay
            } = await getPaybackData(
                config,
                library,
                token,
                chainId
            );

            if (mounted) {
                setMerkleProof(merkleProof);
                setMerklePrice(merklePrice);
                setMerkleIndex(merkleIndex);
                setMinimumPay(minimumPay);
            }
        };
        if (config && library && token && chainId) {
            getPaybackInfo();
        }
        return () => {
            mounted = false;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token]);

    const closedLoanTx = !paybackTx && closedLoanTxHashes.find(
        closedLoan =>
            closedLoan.nftAddress === nftAddress
            && closedLoan.tokenId?.toString() === tokenId.toString()
    );

    const closedLoanTxHash = closedLoanTx?.txHash;
    const closedLoanTxClosedDate = closedLoanTx?.txClosedDate;
    const closedLoanTxAmount = closedLoanTx?.txAmount;

    const isClosed = closedLoan || closedLoanTxHash;
    const isExtended = !closedLoan && extendedLoanIds.find((id) => id === tokenId.toString());

    const paybackTxHash = paybackTx || closedLoanTxHash;
    const finalPaymentTxDate  = finalPaymentDate || closedLoanTxClosedDate;
    const closedLoanFinalPaybackAmount = finalPaybackAmount || closedLoanTxAmount;

    useEffect(() => {
        // fetch token image if not provisioned via prop
        if (!tokenImage) {
            const fetchTokenData = async () => {
                const tokenNftAddress = getMainNetAddress(
                    nftAddress
                );
                // const path = "api/v1/asset";
                const tokenID = id;
                // const params = "?include_orders=false";
                // const url = `${OPENSEA_API}/${path}/${tokenNftAddress}/${tokenID}/${params}`;
                const url = `https://backend.liquidnfts.com/picture/${tokenNftAddress}/${tokenID}`

                const tokenData = await axios.get(
                    url
                );

                setTokenImage(
                    tokenData
                        ?.data
                        ?.nft
                        ?.image_url
                );
            }
            fetchTokenData();
        }
    }, [tokenImage, id, nftAddress]);

    // const [selectedPool, setSelectedPool] = useState();
    // const [selectedCurrency, setSelectedCurrency] = useState();

    const [visibleModalDetails, setVisibleModalDetails] = useState(false);
    const [visibleModalPayback, setVisibleModalPayback] = useState(false);

    const [timeDifferenceDisplay, setTimeDifferenceDisplay] = useState('');

    const paymentTokenName = tokenAddress
        && config.tokenAddressToNameMapping[tokenAddress];

    const collectionDisplayNames = config.nftAddressToNameMapping[nftAddress];

    const collectionName = nftAddress
        && (collectionDisplayNames?.singleName || DEFAULT_COLLECTION_NAME);

    const tokenTitle = collectionName
        && `${collectionName} #${id}`;

    const isModalTypeDetails = modalType === InstantCardModalType.DETAIL;

    // const borrowTokenAmount = useMemo(() => (
    //     closedLoan
    //         ? amount
    //         : principalTokens
    // ), [closedLoan, amount, principalTokens]);

    const amountDisplay = useMemo(() => {

        if (!tokenAddress) return "0.00";

        return (
            <FormattedAmount
                token={tokenAddress}
                amount={amount}
                label
            />
        )
    }, [tokenAddress, amount]);

    const paymentDates = useMemo(() => {

        const dueDateTimestamp = lastPaidTime;

        const dueDate = new Date(
            dueDateTimestamp * 1000
        );

        dueDate.setDate(
            dueDate.getDate() + PAYBACK_TIME
        );

        const dateDifference = getDifferenceBetweenDates(
            new Date(),
            dueDate
        );

        const creationDate = new Date(
            timestamp * 1000
        );

        const daysToPayment = dateDifference.daysRemaining;

        const finalPaymentObj = finalPaymentTxDate && new Date(
            Number(finalPaymentTxDate.toString()) * 1000
        );

        return {
            daysToPayment,
            dueDate,
            creationDate,
            finalPaymentObj,
            creationDateTimestamp: timestamp
        }

    }, [timestamp, lastPaidTime, finalPaymentTxDate]);

    useEffect(() => {
        let countdownTimer;
        if (paymentDates.daysToPayment === ZERO_DAY) {
            // tomorrow 12am
            const startOfNextDay = new Date();
            startOfNextDay.setHours(
                24,
                0,
                0,
                0
            );

            clearInterval(countdownTimer);
            countdownTimer = setInterval(
                () => {
                    const timeDifference = getDifferenceBetweenDates(
                        new Date(),
                        startOfNextDay
                    );
                    setTimeDifferenceDisplay(
                        `${timeDifference?.hours}:${timeDifference?.minutes}:${timeDifference?.seconds}`
                    );
                }, [1000])
        }
        return () => {
            clearInterval(countdownTimer);
        };
    }, [paymentDates])

    const paymentDisplay = useMemo(() => {

        const isDeadlineExceeded = paymentDates.daysToPayment < ZERO_DAY || paymentDates.daysToPayment === -0;

        const durationDisplay =
            paymentDates.daysToPayment > ZERO_DAY
                ? `In ${paymentDates.daysToPayment} Day${paymentDates.daysToPayment > ONE_DAY ? 's' : ''}`
                : `In ${timeDifferenceDisplay}`;

        const title = isClosed
            ? `Loan ${liquidatedLoan
                ? 'Liquidated'
                : 'Returned'
            }`
            : "Payback Deadline";

        const deadline = isExtended
            ? "In 35 Days"
            : isDeadlineExceeded
                ? 'Time Exceeded'
                : durationDisplay;

        const closedLoanInfo = closedLoanTxHash
            ? { date: new Date(), hash: closedLoanTxHash }
            : { date: paymentDates.finalPaymentObj, hash: paybackTxHash }

        const value = isClosed
            ? formatDaysPast(closedLoanInfo.date)
            : deadline;

        return (
            <div className={styles.status}>
                <div className={styles.label}>{ title }</div>
                <span>
                    { isClosed ? (
                        <a
                            className={styles.closedOnDate}
                            onClick={e => {
                                e.stopPropagation();
                                openLink(
                                    chain?.getExplorerTransactionLink(closedLoanInfo.hash),
                                    "_blank",
                                    "noopener",
                                    "noreferrer"
                                )
                            }}
                        >
                            {value}
                            <span className={styles.icon}>
                                <Icon
                                    name={"arrow-expand"}
                                    size={16}
                                />
                            </span>
                        </a>
                    ) : (
                        <span className={
                            cn({
                                [styles.danger]: !isExtended && isDeadlineExceeded
                            })
                        }>
                            {value}
                        </span>
                    )}
                </span>
            </div>
        );
    }, [
        closedLoanTxHash,
        paymentDates,
        paybackTxHash,
        chain,
        isClosed,
        isExtended,
        liquidatedLoan,
        timeDifferenceDisplay
    ]);

    const progressBaseVal = isExtended && !closedLoan
        ? PAYBACK_TIME
        : paymentDates.daysToPayment;

    const progress = Math.min(Math.max(0, progressBaseVal), PAYBACK_TIME)
        * 100
        / PAYBACK_TIME;

    const getTokenImageData = (tokenImage) => {
        let tokenImageData = { ...token };
        tokenImageData.data.image = tokenImage;
        return tokenImageData;
    };

    const openModal = () => {
        if (modalType === InstantCardModalType.DETAIL || isClosed) {
            openModalDetails();
        } else {
            openModalPayback();
        }
    }

    const openModalDetails = () => {
        if (blockModal) return;
        setVisibleModalDetails(true);
    };

    const openModalPayback = () => {
        if (blockModal) return;
        setVisibleModalPayback(true);
    };

    const tokenButtonDisplay = useMemo(() => {
        if (tokenButtonLabel) {
            return tokenButtonLabel;
        }
        return isModalTypeDetails || isClosed
            ? "Details"
            : "Payback Loan"

    }, [isModalTypeDetails, tokenButtonLabel, isClosed])

    const cardTitle = useMemo(() => {
        return paybackTxHash
            ? "Past Loan Details"
            : liquidateApprovalTxHash
                ? `Liquidate ${paymentTokenName} Loan`
                : "Active Loan Details"
    }, [liquidateApprovalTxHash, paybackTxHash, paymentTokenName]);

    const openLink = (
        url,
        ...options
    ) => {
        window.open(
            url,
            ...options
        );
    }

    return (
        <div className={cn(styles.card, className, parentClass, {
            [styles.tableView]: rowLayout === true
        })}
            onClick={() => {
                if (rowLayout) {
                    openModal()
                }
            }}
        >
            <div
                onClick={openModal}
                className={cn(styles.preview, {
                    [styles.finished]: isClosed,
                })}
                style={{ cursor: "pointer" }}
            >
                <img
                    alt={"loader"}
                    src={loaderImage}
                    style={{
                        width: "100%",
                        transform: "scale(0.2)",
                        filter: "hue-rotate(300deg)",
                        position: "relative",
                    }}
                />
                {tokenImage && (
                    <TokenImage
                        token={getTokenImageData(tokenImage)}
                        useIntersection={false}
                        className={styles.img}
                        style={{
                            position: "absolute",
                            top: "0px",
                            left: "0px",
                            backgroundColor: "#638596",
                            borderRadius: rowLayout ? "10px" : "25px",
                            width: "100%",
                        }}
                    />
                )}
                { // view === "Profile" && (
                    <div
                        className={styles.control}
                        style={{ display: blockModal ? "none" : "flex" }}>
                        <button
                            className={
                                cn(
                                    "button-small",
                                    styles.button,
                                    {
                                        [styles.grayFilter]: isClosed,
                                    })
                            }
                        >
                            {
                                !rowLayout && (
                                    <span>
                                        {
                                            tokenButtonDisplay
                                        }
                                    </span>
                                )
                            }
                            <Icon name="search" size="16" />
                        </button>
                    </div>
                    //)
                }
            </div>
            <div {...(!rowLayout && { style: { marginTop: "14px", height: "26px" } })} >
                <div className={styles.body}>
                    <div className={styles.line}>
                        <div className={styles.title}>
                            { tokenTitle ?? name }
                        </div>
                    </div>
                    {/*<div className={styles.line}>
                        <div className={styles.users}>
                            Pool Address
                        </div>
                        <Link target="_blank" rel="noopener noreferrer" to={`/pool/${pool.pool}`}>
                            { shortenIfAddress(pool.pool)}
                        </Link>
                    </div>*/}
                </div>
            </div>
            {showOwner && (
                <div className={styles.foot}>
                    <div className={styles.status}>
                        <div className={styles.users}>
                            <Link className={styles.link} to={`/profile/${borrower}`}>
                                <Avatar
                                    className={styles.avatar}
                                    address={borrower}
                                    showAddress={true}
                                />
                            </Link>
                        </div>
                    </div>
                </div>
            )}
            {showOwner === false && (
            <div className={styles.foot}>
                <div className={styles.status}>
                    <div>Interest Rate</div>
                    <span>
                        <div style={{ color: "#45B26B", display: "flex" }}>
                            {
                                poolRates?.borrowStatusValue
                                    ? `${poolRates.borrowStatusValue}% APY`
                                    : FETCHING_DATA_MESSAGE
                            }
                            <a
                                href="https://wisesoft.gitbook.io/wise/wise-lending-protocol/lasa-ai"
                                target="_blank"
                                rel="noopener noreferrer"
                                className={styles.tooltipAnchor}
                            >
                                <div
                                    className={cn("tooltip right", styles.tooltip)}
                                    data-text="Dynamic APY"
                                >
                                    <strong>i</strong>
                                </div>
                            </a>
                        </div>
                    </span>
                </div>
            </div>
            )}
            <div className={styles.foot}>
            <div className={styles.status}>
                <div className={styles.label}>Borrow Amount</div>
                <span>
                    <span>{ amountDisplay }</span>
                </span>
            </div>
            </div>
            <div className={styles.progressContainer}>
                <div
                    className={
                        cn(
                            styles.progress,
                            {
                                [styles.green]: progressBaseVal > DAYS_DUE_WARNING_THRESHOLD,
                                [styles.warning]: progressBaseVal <= DAYS_DUE_WARNING_THRESHOLD,
                                [styles.danger]: progressBaseVal <= DAYS_DUE_DANGER_THRESHOLD
                            }
                        )}
                    style={{ width: `${progress}%` }}
                />
            </div>
            <div className={styles.foot}>
                {paymentDisplay}
            </div>
            <Modal
                visible={visibleModalDetails}
                outerClassName={
                    cn({
                        // extended: isModalTypeDetails
                    })
                }
                onClose={() => setVisibleModalDetails(false)}
            >
                <div className={cn("h4", styles1.title)}>
                    { cardTitle }
                </div>
                <LiquidateInstant
                    closeModal={() => setVisibleModalDetails(false)}
                    tokenName={name}
                    tokenTitle={tokenTitle}
                    tokenAddress={tokenAddress}
                    paymentToken={paymentTokenName}
                    borrowedAmount={amount}
                    // borrowedAmount={borrowTokenAmount}
                    // dueDate={}
                    // startDate={}
                    borrowTxHash={transactionHash}
                    paymentDates={paymentDates}
                    paybackTxHash={paybackTxHash}
                    finalPaybackAmount={closedLoanFinalPaybackAmount}
                    token={{
                        pool,
                        nftAddress,
                        tokenId,
                        merkleIndex,
                        merklePrice,
                        merkleProof,
                        paybackEvents
                    }}
                    isLiquidated={liquidatedLoan}
                    transactionHash={liquidateApprovalTxHash}
                    setTransactionHash={setLiquidateApprovalTxHash}
                />
                {/*modalType === InstantCardModalType.DETAIL || isClosed ? (
                    <LiquidateInstant
                        tokenName={name}
                        tokenTitle={tokenTitle}
                        tokenAddress={tokenAddress}
                        paymentToken={paymentTokenName}
                        borrowedAmount={amount}
                        // borrowedAmount={borrowTokenAmount}
                        // dueDate={}
                        // startDate={}
                        borrowTxHash={transactionHash}
                        paymentDates={paymentDates}
                        paybackTxHash={paybackTx}
                        finalPaybackAmount={finalPaybackAmount}
                    />
                ) : tokenAddress && (
                    <PaybackInstant
                        title={name}
                        poolAddress={pool}
                        tokenAddress={tokenAddress}
                        paymentToken={paymentTokenName}
                        principalTokens={principalTokens}
                        borrowedAmount={amount}
                        paymentDates={paymentDates}
                        borrowRate={borrowRate}
                        tokenId={tokenId}
                        nftAddress={nftAddress}
                        merkleProof={merkleProof}
                        merklePrice={merklePrice}
                        merkleIndex={merkleIndex}
                        minimumPay={minimumPay}
                        loanInterest={loanInterest}
                        cardIndex={cardIndex}
                        closeModal={() => setVisibleModalA(false)}
                    />
                )*/}
            </Modal>
            <Modal
                visible={visibleModalPayback}
                outerClassName={
                    cn({
                        // extended: isModalTypeDetails
                    })
                }
                onClose={() => setVisibleModalPayback(false)}
            >
                <div className={cn("h4", styles1.title)}>
                    Payback {paymentTokenName} Loan
                </div>
                {tokenAddress && (
                    <PaybackInstant
                        title={tokenTitle}
                        poolAddress={pool}
                        tokenAddress={tokenAddress}
                        paymentToken={paymentTokenName}
                        principalTokens={principalTokens}
                        borrowedAmount={amount}
                        paymentDates={paymentDates}
                        borrowRate={borrowRate}
                        tokenId={tokenId}
                        nftAddress={nftAddress}
                        merkleProof={merkleProof}
                        merklePrice={merklePrice}
                        merkleIndex={merkleIndex}
                        minimumPay={minimumPay}
                        loanInterest={loanInterest}
                        cardIndex={cardIndex}
                        closeModal={() => setVisibleModalPayback(false)}
                    />
                )}
            </Modal>
        </div>
    );
};

export default InstantCard;
