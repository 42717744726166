import React, { useState } from "react";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import { useEthers } from "@usedapp/core";
import styles from "./More.module.sass";
import Icon from "../Icon";
import { TEST_NETWORK_ID } from "../../utils";
import { getMainNetAddress } from "../../ethers/nftCollections";

const getEtherscanURL = (isTestnet, lockerAddress, collectionName) => {
    const etherscanParam = collectionName === "CryptoPunks"
        ? "tokentxns"
        : "tokentxnsErc721";

    return isTestnet
        ? `https://goerli.etherscan.io/address/${lockerAddress}#${etherscanParam}`
        : `https://etherscan.io/address/${lockerAddress}#${etherscanParam}`;
};

const More = ({ className, listingDetails, tokenIndex }) => {

    const { chainId } = useEthers();
    const [visible, setVisible] = useState(false);

    const {
        lockerAddress,
        tokenAddress,
        collectionName,
        tokens
    } = listingDetails;

    const tokenId = collectionName === "CryptoPunks"
        ? tokens[tokenIndex]?.src?.toString()
        : tokens[tokenIndex]?.id?.toString();

    const isTestnet = chainId === TEST_NETWORK_ID;
    const collectionAddress = isTestnet && tokenAddress
        ? getMainNetAddress(tokenAddress)
        : tokenAddress;

    const items = [
        {
            title: "Opensea Details",
            icon: "image",
            action: () => (
                window.open(
                    `https://opensea.io/assets/${collectionAddress}/${tokenId}`,
                    '_blank'
            )),
        },
        {
            title: "Etherscan Details",
            icon: "info-circle",
            action: () => (
                window.open(
                    getEtherscanURL(isTestnet, lockerAddress, collectionName),
                    '_blank'
            )),
        },
        /*{
            title: "Liquidate Loan",
            icon: "close-circle",
            action: () => setVisibleModalRemoveLoan(true),
        },*/
        /*{
            title: "Report Loan",
            icon: "info-circle",
            action: () => setVisibleModalReport(true),
        },*/
    ];

    return (
        <>
            <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
                <div
                    className={cn(styles.actions, className, {
                        [styles.active]: visible,
                    })}
                >
                    <button
                        className={cn("button-circle-stroke", styles.button)}
                        onClick={() => setVisible(!visible)}
                    >
                        <Icon name="more" size="24" />
                    </button>
                    <div className={styles.body}>
                        {items.map((x, index) => (
                            <div className={styles.item} key={index} onClick={x.action}>
                                <Icon name={x.icon} size="20" />
                                <span>{x.title}</span>
                            </div>
                        ))}
                    </div>
                </div>
            </OutsideClickHandler>
        </>
    );
};

export default More;
