import useConfig from './useConfig';
import useCallGracefully from './useCallGracefully';
import { getRouterContract } from '../utils/query/interfaces';

const useGetPendingRoots = (collectionAddress) => {

    const { routerAddress } = useConfig();

    const routerContract = getRouterContract(
        routerAddress
    );

    const pendingRootsResult = useCallGracefully(collectionAddress &&
        {
            contract: routerContract,
            method: "pendingRoots",
            args: [collectionAddress],
        }
    ) ?? null;

    return pendingRootsResult;
}

export default useGetPendingRoots;
