import { formatUnits } from "ethers/lib/utils";
import supportedPools from "../screens/InstantPools/Pools/data";
import { BORROW_RATE_DECIMALS, LEND_RATE_DECIMALS } from "../utils";
import { getPoolContract } from "../utils/query/interfaces";
import useCallsGracefully from './useCallsGracefully';
import { useEffect, useMemo, useState } from "react";
import axios from "axios";

const POOL_RATE_METHODS = [
    "borrowRate",
    "getCurrentDepositAPY",
    "utilisationRate",
    "badDebt",
    "fee"
];

const PRECISION_FACTOR = 10 ** 18; // 1000000000000000000
const SMALLEST_RATE_VALUE = 0.01;

const useGetPoolRates = (poolAddress) => {

    const [globalsTVL, setGlobalsTVL] = useState();

    const poolContract = getPoolContract(
        poolAddress
    );

    const selectedPool = supportedPools.find(
        (pool) => pool.address === poolAddress
    );

    const base = {
        contract: poolContract,
        args: [],
    };

    const poolRateMethods = POOL_RATE_METHODS.map((method) => ({
        ...base,
        method,
    }));

    const [
        borrowRate,
        ,
        utilisationRate,
        badDebt,
        fee
    ] = useCallsGracefully(
        poolRateMethods
    );

    useEffect(() => {
        const fetch = async () => {
            const result = await axios.get('https://data.wisetoken.net/WISE/globals/tvl.json');
            setGlobalsTVL(result?.data);
        }
        fetch();
    },[]);

    const totalTokensDue = useMemo(() => {
        switch (selectedPool?.currency) {
            case "USDC":
                return globalsTVL?.totalTokensDue_USDC;
            case "WETH":
                return globalsTVL?.totalTokensDue_WETH;
            default:
                return null;
        }
    }, [selectedPool, globalsTVL]);

    const pseudoTotalTokensHeld = useMemo(() => {
        switch (selectedPool?.currency) {
            case "USDC":
                return globalsTVL?.usdcTotalTokensHeld;
            case "WETH":
                return globalsTVL?.ethTotalTokensHeld;
            default:
                return null;
        }
    }, [selectedPool, globalsTVL]);

    const currentDepositAPY = useMemo(() => {
        return (
            borrowRate
            * (PRECISION_FACTOR - fee)
            * (totalTokensDue + badDebt)
            / pseudoTotalTokensHeld
            / PRECISION_FACTOR
        );
    }, [badDebt, borrowRate, fee, pseudoTotalTokensHeld, totalTokensDue]);

    if (borrowRate && currentDepositAPY && utilisationRate) {
        const formattedBorrowRate = formatUnits(
            borrowRate.toString(),
            BORROW_RATE_DECIMALS
        );

        const convertedLendRate = currentDepositAPY * 10 ** selectedPool.tokenDecimals;
        const formattedLendRate = convertedLendRate / 10 ** LEND_RATE_DECIMALS;

        const lendStatusValue = parseFloat(
            formattedLendRate < SMALLEST_RATE_VALUE ? SMALLEST_RATE_VALUE : formattedLendRate
        ).toFixed(2);

        const borrowStatusValue = parseFloat(formattedBorrowRate).toFixed(2);

        const lendStatusContent = `Lend ${lendStatusValue}% APY`;
        const borrowStatusContent = `Borrow ${borrowStatusValue}% APY`;

        return {
            ...selectedPool,
            utilisationRate,
            borrowStatusValue,
            borrowStatusContent,
            lendStatusContent,
            lendStatusValue
        };
    }
}

export default useGetPoolRates;
