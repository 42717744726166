import React, { useState } from "react";
import { useDispatch } from 'react-redux';
import { useEthers, shortenIfTransactionHash } from "@usedapp/core";
import cn from "classnames";
import { ethers } from "ethers";
import toast, { Toaster } from "react-hot-toast";
import { setUpdateLatestTx } from "../../redux/settingsSlice";
import { useCustomAllowance } from "../../customHooks/useCustomAllowance";
import styles from "./Collaterize.module.sass";
import Icon from "../Icon";
import Loader from "../Loader";
import useConfig from "../../customHooks/useConfig";
import Success from "../Transaction";
import FACTORY_ABI from "../../ethers/abis/LiquidFactory.json";
import { parseUnits } from "ethers/lib/utils";
import {
    getToastSettings,
    getChainById,
    getToastErrorMessage
} from "../../utils";
import { useERC20Approval } from "../../customHooks/useERC20Approval";

const CONFIRM_TEXT = "Confirm in Metamask";
const UNLOCK_TEXT = "Unlocking...";
const IN_PROGRESS_TEXT = "Contributing...";
const DEFAULT_TEXT = "Proceed with Contribution";
const DONE_TEXT = 'Done';

const CollaterizeSteps = ({
    className,
    setInvalidAmount,
    amount,
    setAmount,
    paymentToken,
    title,
    transactionHash,
    setTransactionHash,
    closeModal,
    lockerAddress,
    tweetMessage
}) => {
    const config = useConfig();
    const { account, library, chainId } = useEthers();
    const chain = getChainById(chainId);
    const [loading, setLoading] = useState(false);
    const [successfulApproval, setSuccessfulApproval] = useState(false);
    const [buttonText, setButtonText] = useState(DEFAULT_TEXT);
    const [contributionTx, setContributionTx] = useState("");
    const signer = library.getSigner(account);
    const dispatch = useDispatch();

    const approval = useERC20Approval({
        onConfirmation: () => setButtonText(CONFIRM_TEXT),
        onApproving: (transactionHash) => {
            setLoading(true);
            setButtonText(UNLOCK_TEXT);
            setTransactionHash(transactionHash);
        },
        onApprovalComplete: (approval) => {
            setLoading(false);
            if (approval) {
                setSuccessfulApproval(true);
                handleMakeContribution();
            }
        },
        onError: (error) => {
            setLoading(false);
            handleError(error);
            setTransactionHash("");
            setButtonText(
                DEFAULT_TEXT
            );
            toast.error(
                getToastErrorMessage(error.code),
                getToastSettings("🚫")
            );
        }
    })

    const allowance = useCustomAllowance(
        config.paymentTokens[paymentToken],
        account,
        config.liquidNFTContract
    );

    const paymentTokenName = paymentToken;
    const decimals = config.decimals[paymentTokenName];
    const contributionAmount = parseUnits(amount.toString() || "0", decimals || 18);

    // check if allowance is already approved if so go to second step
    // const preApproved = false; //allowance && allowance.gte(contributionAmount);
    const preApproved = allowance && allowance.gte(contributionAmount);

    const handleError = (err) => {
        // @TODO: just fire the toaster
    };

    const handleApprove = async () => {

        if (buttonText === DONE_TEXT) {
            setAmount("");
            closeModal();
        }

        if (!amount) {
            setInvalidAmount(true);
            return;
        }

        if (buttonText !== DEFAULT_TEXT) return;


        if (preApproved) {
            setSuccessfulApproval(true);
            handleMakeContribution();
            return;
        }

        approval.call(
            config.liquidNFTContract,
            config.paymentTokens[paymentToken],
        );

    };

    const handleMakeContribution = async () => {

        if (!amount) {
            setInvalidAmount(true);
            return;
        }

        if (buttonText !== DEFAULT_TEXT) return;

        setButtonText(CONFIRM_TEXT);

        try {

            const factoryContract = new ethers.Contract(
                config.liquidNFTContract,
                FACTORY_ABI,
                signer
            );

            const contributeToLockerTx = await factoryContract.contributeToLocker(
                lockerAddress,
                contributionAmount,
            );

            setTransactionHash(contributeToLockerTx.hash);
            setLoading(true);
            setButtonText(IN_PROGRESS_TEXT);
            const contributedToLocker = await contributeToLockerTx.wait();
            setLoading(false);
            setButtonText(DONE_TEXT);

            if (contributedToLocker) {
                dispatch(setUpdateLatestTx(contributedToLocker.transactionHash));
                setContributionTx(contributedToLocker);
            }

        } catch (err) {
            setLoading(false);
            setButtonText(DEFAULT_TEXT);
            handleError(err);
            setTransactionHash("");
            toast.error(
                getToastErrorMessage(err.code),
                getToastSettings("🚫")
            );
        }
    };

    // dynamic styling constants
    // probably good to place in a config / class
    // duplicate found in PaybackSteps

    const canActivate = true;
    const approvalDone = preApproved || successfulApproval;

    const iconColor = approvalDone
        ? "#45B26B"
        : "#9757D7";

    const iconColorB = approvalDone && (buttonText.includes("...")|| buttonText === DONE_TEXT)
        ? "#9757D7"
        : "";

    const iconName = approvalDone
        ? 'check'
        : 'lock'

    const iconSize = approvalDone
        ? '20'
        : '24'

    return (
        <div className={cn(className, styles.steps)}>
            <div className={styles.list}>
                {transactionHash && (
                    <div style={{marginBottom: "30px"}}>
                        <div className={styles.body} >
                            { buttonText === DONE_TEXT ? (
                                <span>
                                    Congratulations! Your contribution transaction has been confirmed and included into this loan
                                </span>
                            ) : (
                                <span>
                                    Your transaction is now in progress, follow the steps below to finalize your loan contribution
                                </span>
                            )}
                        </div>
                        <div className={styles.row}>
                            <div>View Transaction</div>
                            <div>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={chain.getExplorerTransactionLink(transactionHash)}
                                    className={styles.link}
                                >
                                    {shortenIfTransactionHash(transactionHash)}
                                </a>
                            </div>
                        </div>
                    </div>
                )}
                { contributionTx
                    ? (
                        <Success
                            txHash={transactionHash}
                            title={"You successfully contributed to the LiquidNFTs loan"}
                            showButton={false}
                            celebrate={true}
                            lockerAddress={lockerAddress}
                            displaySocials={true}
                            tweet={tweetMessage}
                        /> )
                    : (
                        <div
                            className={styles.item}
                            style={{ display: transactionHash ? 'block' : 'none' }}
                        >
                            <div className={styles.head}>
                                <div
                                    className={styles.icon}
                                    style={{ background: iconColor, borderColor: iconColor }}
                                >
                                    <Icon name={iconName}  size={iconSize} />
                                </div>
                                <div className={styles.details}>
                                    <div className={styles.info}>
                                        Step 1 - Unlock Tokens
                                    </div>
                                    <div className={styles.text}>
                                        Send transaction giving permission to LiquidNFTs contract transferring {paymentTokenName} tokens on your behalf
                                    </div>
                                </div>
                            </div>
                            <div className={styles.stepLine} />
                            <div className={styles.head}>
                                <div
                                    style={{ background: iconColorB, borderColor: iconColorB }}
                                    className={styles.icon}>
                                    <Icon
                                        name="wallet"
                                        size="24"
                                    />
                                </div>
                                <div className={styles.details}>
                                    <div className={styles.info}>
                                        Step 2 - Confirm Contribution
                                    </div>
                                    <div className={styles.text}>
                                        Send transaction to confirm loan contribution by using {paymentTokenName} token as
                                        a funding asset for this loan
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
                <div className={styles.item}>
                    <button
                        style={{marginTop: "30px"}}
                        onClick={handleApprove}
                        className={cn(
                            "button",
                            styles.button,
                            (buttonText === DEFAULT_TEXT || buttonText === DONE_TEXT) ? "" : "loading",
                            (canActivate) ? "" : "disabled"
                        )}
                    >
                        { loading && <Loader className={styles.loaderSmall} color="white" /> }
                        { buttonText }
                    </button>
                </div>
            </div>
            <Toaster />
        </div>
    );
};

export default CollaterizeSteps;
