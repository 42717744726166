import React, { useEffect, useState } from "react";
// import { useSelector } from "react-redux";
import cn from "classnames";
import { Link, useHistory } from "react-router-dom";
import styles from "./Pools.module.sass";
import Icon from "../../../components/Icon";
import ScrollParallax from "../../../components/ScrollParallax";
import Modal from "../../../components/Modal";
import Invest from "../../../components/Invest";
import Borrow from "../../../components/Borrow";
import supportedPools from "./data";
import Loader from "../../../components/Loader";
import Details from "./Details";
import PoolMigrate from "../../../components/PoolMigrate";
import PoolWithdraw from "../../../components/PoolWithdraw";
import { useEthers, useLookupAddress, shortenIfAddress } from "@usedapp/core";
// import useConfig from "../../../customHooks/useConfig";
import NoData from "../../../components/NoData";
import PoolDeposited from "../../../components/PoolInfo/PoolDeposited";
import { OLD_STR, MIN_PRECISION_THRESHOLD } from "../../../utils";
import { getUserDepositAmount } from "../../../utils/query/pools";

const findPools = (supportedPools, slug, chainId, isUserPage) => {
    // if (slug === 'all') return supportedPools;
    return supportedPools.filter((pool) => {
        // return pool.collectionSlug === slug && pool.chainId === chainId;
        const hideOldPools = !isUserPage && pool.title === OLD_STR;
        // if hide old pool then filter bool should be opposite
        return !hideOldPools && pool.chainId === chainId;
    });
}

const Pools = ({
    selectedPools,
    collectionsData,
    wrapperClass,
    pageMode,
    optionalAddress,
    refetchCollectionsData,
    isCollectionsLoading
}) => {

    const {
        library,
        account,
        chainId
    } = useEthers();

    // const [fundedPools, setFundedPools] = useState([]);
    // const [filteredPools, setFilteredPools] = useState([]);
    // const [displayedPools, setDisplayedPools] = useState([]);
    const [loadingPools, setLoadingPools] = useState(true);

    // const tokenPreferences = useSelector(
        // (state) => state.settings.tokenPreferences
    // );

    // const collectionPreferences = useSelector(
        // (state) => state.settings.collectionPreferences
    // );

    // const closedFundedPools = useSelector(
        // (state) => state.settings.closedFundedPools
    // );

    // const config = useConfig();
    const history = useHistory();

    const user = optionalAddress
        ? optionalAddress
        : account;

    const { ens } = useLookupAddress(user);
    const ensName = ens || shortenIfAddress(user);

    useEffect(() => {
        if (!pageMode) setLoadingPools(false);
    }, [pageMode]);

    const isUserPage = pageMode === 'user';

    const selectedSupportedPools = findPools(
        supportedPools,
        selectedPools,
        chainId,
        isUserPage
    );

    const [poolList, setPoolList] = useState([]);
    const [selectedPool, setSelectedPool] = useState();
    const [selectedCurrency, setSelectedCurrency] = useState();
    const [visibleModalA, setVisibleModalA] = useState(false);
    const [visibleModalB, setVisibleModalB] = useState(false);
    const [visibleModalC, setVisibleModalC] = useState(false);
    const [visibleModalD, setVisibleModalD] = useState(false);

    const [supportedCollections, setSupportedCollections] = useState();

    const openLending = (currency, pool) => {
        setSelectedPool(pool);
        setSelectedCurrency(currency);
        setVisibleModalA(true);
    }

    const openBorrow = (currency, pool, supportedCollections) => {
        setSupportedCollections(supportedCollections);
        setSelectedPool(pool);
        setSelectedCurrency(currency);
        setVisibleModalB(true);
    }

    const openWithdrawing = (currency, pool) => {
        setSelectedPool(pool);
        setSelectedCurrency(currency);
        setVisibleModalC(true);
    }

    const openMigrating = (currency, pool) => {
        setSelectedPool(pool);
        setSelectedCurrency(currency);
        setVisibleModalD(true);
    }

    useEffect(() => {
        let mounted = true;

        const getFundedPools = async () => {

            try {

                setLoadingPools(true);

                const poolsWithBalancePromise = selectedSupportedPools.map(async (pool) => {

                    const balance = await getUserDepositAmount(
                        user,
                        pool.address,
                        pool.tokenDecimals,
                        library
                    );

                    return {
                        ...pool,
                        balance
                    };
                });

                const poolsWithBalanceFulfilled = await Promise.allSettled(
                    poolsWithBalancePromise
                );
                const poolsWithBalance = poolsWithBalanceFulfilled.map((pool) => pool.value);

                const fundedPools = poolsWithBalance.reduce((accum, pool) => {

                    if (pool.title === OLD_STR && pool.balance < MIN_PRECISION_THRESHOLD) {
                        return accum;
                    }

                    accum.push(pool);
                    return accum;
                }, []);

                setPoolList(
                    fundedPools
                );

                // setFundedPools(
                    // fundedPools
                // );

                setLoadingPools(false);

            } catch (err) {
                console.log(err);
            }
        };

        if (mounted) {
            getFundedPools();
        }

        return () => {
            mounted = false;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageMode, user, chainId]);

    return (
        <div
            className={
                cn(styles.section, {
                    [styles.sectionUserView]: isUserPage
                })}
        >
            <div className={cn("container", styles.container, `${wrapperClass}`)}>
                <div className={styles.list}>
                    { !loadingPools && poolList && poolList.length
                        ? poolList.map((x, index) => (
                            <ScrollParallax
                                className={styles.item}
                                key={index}
                            >
                                <Link
                                    to={`/pool/${x.address}`}
                                >
                                    <div
                                        className={styles.preview}
                                        style={{ backgroundColor: x.color, filter: x.deprecated === true ? 'grayscale(1)' : '' }}
                                    >
                                        <div
                                            className={styles.tokenImageContainer}
                                        >
                                            <img
                                                className={styles.tokenImage}
                                                src={x.background}
                                                alt="token"
                                            />
                                        </div>
                                        <img
                                            className={styles.tokenLogo}
                                            src={x.image}
                                            alt="Logo"
                                        />
                                    </div>
                                </Link>
                                <div className={styles.details}>
                                    <div className={styles.subtitle}>
                                        {x.ltv && `${x.ltv} - `}
                                        {x.title} Pool
                                    </div>
                                    <Link
                                        // to={`/instant-pools`}
                                        to={`/pool/${x.address}`}
                                    >
                                        <div className={styles.description}>
                                            {x.description}
                                            {/*poolList[index].address*/}
                                        </div>
                                    </Link>
                                    {isUserPage === false && (
                                        <>
                                            <div
                                                className={cn("button-small button-stroke", styles.borrow)}
                                                onClick={() => {
                                                    openBorrow(
                                                        x.currency,
                                                        x,
                                                        x.supportedCollections
                                                    )
                                                }}
                                            >
                                                <span>Borrow {x.currency}</span>
                                                <Icon className={styles.flip} name="arrow-down" size="10" />
                                            </div>
                                            <div
                                                className={cn("button-small button-stroke", styles.lend)}
                                                onClick={() => {
                                                    openLending(
                                                        x.currency,
                                                        x
                                                    )
                                                }}
                                            >
                                                <span>Lend {x.currency}</span>
                                                <Icon
                                                    className={styles.flap}
                                                    name="arrow-down"
                                                    size="10"
                                                />
                                            </div>
                                        </>
                                    )}
                                    <Link to={`/pool/${x.address}`}>
                                        <div style={{marginTop: "12px", whiteSpace: "nowrap"}}>
                                            {isUserPage === false && (
                                                <Details poolAddress={poolList[index].address} />
                                            )}
                                            {isUserPage === true && (
                                                <PoolDeposited poolInfo={x} account={user} />
                                            )}
                                        </div>
                                    </Link>
                                    {isUserPage === true && x.deprecated === false && (
                                        <div
                                            style={{width: "100%"}}
                                            className={cn("button-small button-stroke", styles.borrow)}
                                            onClick={() => {
                                                openWithdrawing(
                                                    x.currency,
                                                    x
                                                )
                                            }}
                                        >
                                            <span>Withdraw {x.currency}</span>
                                            <Icon className={styles.flip} name="arrow-down" size="10" />
                                        </div>
                                    )}
                                    {isUserPage === true && x.deprecated === true && (
                                        <div
                                            style={{width: "100%"}}
                                            className={cn("button-small button-stroke", styles.borrow)}
                                            onClick={() => {
                                                openMigrating(
                                                    x.currency,
                                                    x
                                                )
                                            }}
                                        >
                                            <span>Migrate {x.currency}</span>
                                            <Icon className={styles.flip} name="arrow-down" size="10" />
                                        </div>
                                    )}
                                </div>
                            </ScrollParallax>
                         ))
                        : <div style={{ display: loadingPools ? "none" : "flex", width: "100%" }}>
                            <NoData pageMode={pageMode} buttonEndpoint="instant-pools" label={`${optionalAddress ? ensName : "You"} do not have any funded pools`} />
                        </div>
                    }
                    <div className={styles.loader} style={{ display: loadingPools ? "flex" : "none" }}>
                        <Loader color="white" />
                    </div>
                </div>
            </div>
            <Modal
                visible={visibleModalA}
                // outerClassName={"extended"}
                onClose={() => setVisibleModalA(false)}
            >
                <div className={cn("h4", styles.title)}>
                    Lend {selectedCurrency} Funds
                </div>
                {selectedPool && (
                    <Invest
                        poolAddress={selectedPool.address}
                        paymentToken={selectedPool.currency}
                        title={selectedPool.title}
                        apy={selectedPool.lendStatusContent}
                        ltv={selectedPool.ltv}
                        closeModal={() => {
                            setVisibleModalA(false);
                            history.push('/profile/funded-pools');
                        }}
                    />
                )}
            </Modal>
            <Modal
                visible={visibleModalB}
                // outerClassName={"extended"}
                onClose={() => setVisibleModalB(false)}
            >
                { selectedPool && (
                    <Borrow
                        poolAddress={selectedPool.address}
                        paymentToken={selectedPool.currency}
                        title={selectedPool.title}
                        closeModal={() => {
                            setVisibleModalB(false);
                            history.push('/profile/instant-loans');
                         }}
                        collectionData={collectionsData}
                        onRefreshIconClick={refetchCollectionsData}
                        isCollectionsLoading={isCollectionsLoading}
                        selectedPool={selectedPool}
                        selectedCurrency={selectedCurrency}
                        supportedCollections={supportedCollections}
                        showPoolDescription
                    />
                )}
            </Modal>
            <Modal
                visible={visibleModalC}
                // outerClassName={"extended"}
                onClose={() => setVisibleModalC(false)}
            >
                <div className={cn("h4", styles.title)}>
                    Remove {selectedCurrency} Funds
                </div>
                <div>
                    <PoolWithdraw
                        closeModal={() => setVisibleModalC(false)}
                        poolAddress={selectedPool?.address}
                        paymentToken={selectedPool?.currency}
                        apy={selectedPool?.lendStatusContent}
                        title={selectedPool?.title}
                        // withdrawDeduction={withdrawDeduction}
                    />
                </div>
            </Modal>
            <Modal
                visible={visibleModalD}
                // outerClassName={"extended"}
                onClose={() => setVisibleModalD(false)}
            >
                <div className={cn("h4", styles.title)}>
                    Migrate {selectedCurrency} Funds
                </div>
                <div>
                    <PoolMigrate
                        closeModal={() => setVisibleModalD(false)}
                        poolAddress={selectedPool?.address}
                        migrateTo={selectedPool?.migrateTo}
                        paymentToken={selectedPool?.currency}
                        title={selectedPool?.title}
                    />
                </div>
            </Modal>
        </div>
    );
};

export default Pools;
