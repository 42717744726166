import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./nftStatus.module.sass";
import Slider from "react-slick";
import Icon from "../Icon";
import { getNftImage } from "../../utils/query/tokens";
import { OWL_MY_BABIES_ADDRESS, SUGGESTED_OWL_ADDRESSES } from "../../utils/config";
import loaderImage from "../../assets/images/loaders/matrix-pink.svg";

const SlickArrow = ({ currentSlide, slideCount, children, ...props }) => (
    <button {...props}>{children}</button>
);

const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 2,
    nextArrow: (
        <SlickArrow>
            <Icon name="arrow-next" size="14" />
        </SlickArrow>
    ),
    prevArrow: (
        <SlickArrow>
            <Icon name="arrow-prev" size="14" />
        </SlickArrow>
    ),
    responsive: [
        {
            breakpoint: 1179,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 2,
            },
        },
        {
            breakpoint: 1023,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
            },
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
            },
        },
        {
            breakpoint: 474,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            },
        },
    ],
};

const Card = ({
    address
}) => {

    const [owlImage, setOwlImage] = useState(loaderImage);

    useEffect(() => {

        const checkAvatar = async (address) => {

            const result = await getNftImage(
                OWL_MY_BABIES_ADDRESS,
                address
            );

            if (result) {
                setOwlImage(result);
            }
        };

        checkAvatar(address);

    }, [address]);

    return (
        <div className={styles.card}>
            <img src={owlImage} alt="Baby Owl" />
            <div
                className={styles.control}
                style={{ display: "flex" }}>
                <a
                    className={cn("button-small", styles.button)}
                    href={`https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/${address}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <span>
                        Opensea
                    </span>
                    <Icon name="search" size="16" />
                </a>
            </div>
        </div>
    );
};

const NFTStatus = ({ className }) => {
    return (
        <div className={cn(className)}>
            <div className={cn("h4", styles.title)}>Personal NFT Status</div>
            <div className={cn(styles.text, styles.divider)}>
                Personalize your profile through specific NFT ownership
            </div>
            <div className={styles.bottomSpace}>
                Upgrade your profile by owning one of the special NFT owl avatars or continue using default shadow profile.
                NFT will be displayed as your Avatar picture across entire platform and access VIP content
            </div>
            <div className={cn(styles.divider)}>
                You will also get recognition status on all Wise Products including staking on
                <a
                    className={styles.pink}
                    href="https://app.wisetoken.net/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {" "}app.wisetoken.net{" "}
                </a>
                and VIP status on Discord server
            </div>
            <div className={styles.slideContainer}>
                <Slider className={cn('nft-status-slider', styles.slider)}  {...settings}>
                    {
                        SUGGESTED_OWL_ADDRESSES
                            .sort(() => 0.5 - Math.random())
                            .map(address => <Card address={address} />)
                    }
                </Slider>
            </div>
            <div className={styles.footer}>
                <a
                    className={styles.website}
                    href="https://owl.town/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Visit Website
                </a>
                <a
                    className={styles.collection}
                    href="https://opensea.io/collection/owlmybabies"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Explore Collection
                </a>
                <a
                    className={styles.discord}
                    href="https://discord.com/invite/P9eDxJsNQB/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Join Discord
                </a>
            </div>
        </div>
    );
};

export default NFTStatus;
