import React from "react";
import cn from "classnames";
import styles from "./FundLoan.module.sass";
import Icon from "../../../../components/Icon";
import LoaderCircle from "../../../../components/LoaderCircle";

const items = [
    {
        title: "0.007",
        value: "WISE",
    },
    {
        title: "Your balance",
        value: "8.498 WISE",
    },
    {
        title: "Service fee",
        value: "0 WISE",
    },
    {
        title: "You will pay",
        value: "0.007 WISE",
    },
];

const FundLoan = ({ className }) => {
    return (
        <div className={cn(className, styles.checkout)}>
            <div className={cn("h4", styles.title)}>Fund Loan</div>
            <div className={styles.info}>
                Contribute to <strong>Satoshibles #27</strong> token loan{" "}
            </div>
            <div className={styles.table}>
                {items.map((x, index) => (
                    <div className={styles.row} key={index}>
                        <div className={styles.col}>{x.title}</div>
                        <div className={styles.col}>{x.value}</div>
                    </div>
                ))}
            </div>
            <br />
            <div className={styles.line}>
                <div className={styles.icon}>
                    <LoaderCircle className={styles.loader} />
                </div>
                <div className={styles.details}>
                    <div className={styles.subtitle}>Funding Loan</div>
                    <div className={styles.text}>
                        Sending transaction with your wallet
                    </div>
                </div>
            </div>
            <div className={styles.attention}>
                <div className={styles.preview}>
                    <Icon name="info-circle" size="32" />
                </div>
                <div className={styles.details}>
                    <div className={styles.subtitle}>This loan is overcollateralized</div>
                    <div className={styles.text}>Fund this loan at your own risk</div>
                </div>
                <div className={styles.avatar}>
                    <img src="/images/baby-owls/23.jpg" alt="Avatar" />
                </div>
            </div>
            <div className={styles.btns}>
                <button className={cn("button", styles.button)}>
                    I understand, continue
                </button>
                <button className={cn("button-stroke", styles.button)}>Cancel</button>
            </div>
        </div>
    );
};

export default FundLoan;
