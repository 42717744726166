import React, { useState } from "react";
import cn from "classnames";
import styles from "./Hero.module.sass";
import Dropdown from "../../../components/Dropdown";
import Icon from "../../../components/Icon";
import Item from "./Item";
import { DISCORD_LINK } from "../../../utils";

const items = [
    {
        title: "General",
        icon: "home",
        items: [
            {
                title: "What is LiquidNFTs?",
                body: `<p><span style="color:white">LiquidNFTs</span> is a fully decentralized peer to peer service that allows you to take out a loan using your NFTs as collateral. Alternatively, you may choose to become a lender, and fund loans for others. There are <span style="color:white">2 types</span> of loans on the platform: Custom loans and Instant Pools.</p><br/>
                <p><span style="color:white">Instant Pools</span> allow most flexibility with your assets, because <span style="color:white">there is no time-lock</span> in the instant pool contracts. Borrowers and lenders may put their assets in the pool and take them out at will, because the terms in each pool are predefined. Simply find a pool that you like, then take out a loan instantly or lend funds to earn profit.</p><br/>
                <p><span style="color:white">Custom listings</span> can be created for any NFT, but require an up to <span style="color:white">5 day “attraction phase”</span> to get the loan funded. You may also choose to fund other custom loan proposals on the platform to earn interest from borrowers.`
            },
            /*{
                title: "Creating custom listings",
                body: `<p>Start by selecting your NFT(s) to use as collateral for your loan. Adjust the other parameters including currency type, loan amount, loan length, and TIP offered to lenders, then submit your loan. You have five full days to get funded, but you may start you loan any time during this period if an acceptable amount of funds come in.</p><br />
                <br>If funds are not coming in, you may adjust your parameters to make your loan more attractive to borrowers. After your loan starts, you must keep your interest payments up to date, or else you will start to default. Defaulting loans have 7 days before the NFT(s) go to auction, but the loan may be redeemed at any time during this period if interest, principal, and late fees are paid in full.</p><br />
                <p>If a fully defaulted loan happens to be funded by one single address, the NFT(s) from that loan get transferred to that address immediately instead of being auctioned.</p>`
            },
            {
                title: "Funding custom listings",
                body: `<p>Visit our <a style="color: white" href="/discover">marketplace</a> and use the provided filters to search through active loan proposals. Your funds will be locked for the duration of the loan, so use the filters provided to find an acceptable time period, currency, and APY%.</p><br />
                <p>Before you fund any loan proposal, make sure the loan to value ratio (LTV) makes sense. If the borrower is asking for more funds than their NFT(s) are worth (or if the floor price drops), they would be incentivized to default, taking the money, and leaving you with their NFT.</p><br />
                <p>To follow our best advice, try to find the highest APY% proposal that also has the biggest gap between the actual value of the NFT(s) and the total asking amount of the loan.</p>`
            },
            {
                title: "Taking loans from Instant Pools",
                body: `<p>There will be at least 6 Instant Pools for each NFT set, so first find the pools in the currency you wish to borrow, and then observe the current APY cost for each of your options. Each pool will allow you to borrow a different percentage of your NFT’s total value, but higher LTV pools will most likely demand higher APYs because of the risk to lenders.</p><br />
                <p>When you start a loan, you pay your interest up to 35 days ahead, based on the current APY in the pool. The APY in your pool may increase or decrease during this time, but your loan will NOT start to default. At the end of your payment period, the pool adjusts your fees appropriately, credits or debits you the difference, and allows you to pay the next period. You may redeem your NFT at any time by paying back your principal and interest to date.</p>`
            },
            {
                title: "Lending funds to Instant Pools",
                body: `<p>Start by choosing the NFT collection you wish to provide funds for and select your currency type (ETH, USDC, etc). Then choose the loan to value percentage (LTV) that fits your risk appetite. Higher LTV pools are riskier, having less of a buffer if floor price for that NFT collection drops.</p><br />
                <p>Once you lend to a pool, you may withdraw your funds and interest at any time, as long as there are enough funds not being utilized in loans.</p><br />
                <p>If you cannot take out your funds, it means the utilization of that pool is nearly 100%, which also means you are earning extremely high APY. This incentivizes lenders to add more funds to the pool and it incentivizes borrowers to pay back principal so their NFTs can be removed. Rest assured, funds will be available to withdraw shortly.</p>`
            },*/
            /*{
                title: "Fees",
                body: `<p>LiquidNFTs charges a platform fee in order to reward stakers on this platform. The fee is equal to 10% of the TIP if WISE is used as currency, or 20% of the TIP for all other currencies.</p><br/>
                <p>The native token for LiquidNFTs is the non-fungibles token ($LQI), and the token sale (TBD) will be a daily auction, with the same amount of tokens up for sale each day for 50 days in a row. All users wanting to participate will have an equal chance to buy tokens, and the best days to invest will be the days that receive the least amount of total funding. The auction will be held in WISE, and 100% of the auction funds will be locked in a massive LQI/WISE liquidity pool on Uniswap. (WISE/ETH is the largest liquidity pool on Uniswap V2, so trading LQI to ETH will never be an issue).</p><br/>
                <p>There will be a referral program during the 50 day auction, open to all aspiring promoters. Investors who use a referral link will receive 10% more $LQI tokens, and the referrer will receive $LQI tokens equal to 10% of all funds invested using their link.</p>`
            }*/
        ],
    },
    {
        title: "Instant Pools",
        icon: "lightning",
        items: [
            {
                title: "Borrowing",
                body: `<p>Locate the pool in the currency you wish to borrow (the top left corner of each pool has a WETH, USDC, or WISE symbol). If you want to borrow WETH, there are 2 options (35% LTV and 70% LTV). The higher loan-to-value pool will allow you to borrow more WETH, but may cost more interest, and if the floor price of your NFT drops, you will need to pay back some principal within 35 days.</p><br />
                <p>Click the borrow button and select the NFT you wish to use for your loan. After borrowing, you don’t need to do anything as long as you repay your loan within 35 days. You may instead choose to extend your loan by paying your interest-only within 35 days of every time you extend (can be done indefinitely). You may redeem your NFT at any time by paying back your principal and accumulated interest to date.</p><br />
                <p>Our on-chain LASA algorithm will be adjusting APY in your pool every day, and the value of your NFT may change during your loan period, but neither of these events will affect your repayment date. As long as your loan is paid back within the 35 day period, your NFT is safe, but you may have to pay back a portion of principal, if you wish to extend a loan for an NFT that has dropped in value.</p>`
            },
            {
                title: "Lending",
                body: `<p>Locate the pool in the currency you wish to lend (the top left corner of each pool has a WETH, USDC, or WISE symbol). If you want to lend WETH, there are 2 options (35% LTV and 70% LTV). The higher loan-to-value pool will offer higher APY but is technically riskier, requiring only a 31% drop in value from the NFTs in the pool to create the possibility of bad debt versus a 66% drop. Since the drop in value would have to happen within a ~2.5 week time span to break our liquidations system, we posit that all of our pools are safely over-collateralized.</p><br />
                <p>Upon lending funds, you will immediately start earning your share of interest for every loan in the pool. You may remove your funds plus interest instantly at any time without penalty as long as funds are available. Our on chain LASA algorithm ensures there should always be funds available for lenders wanting to cash out, since high utilization of funds in the pool drives up APY. Realistically, some borrowers would pay back their loans to avoid the increasing APY long before it became an issue for lenders wanting to withdraw. Additionally, more lenders would add funds to the pool to take advantage of the high APY, both of which events free up funds for lenders wishing to exit. </p><br />
                <p>Our liquidations mechanism is designed to protect lenders’ funds in the event that the NFT market crashes. Since NFT floor prices can be updated every 3 days, and because borrowers are required to pay back their loan within a short time period, lenders’ funds are protected from everything outside of the entire NFT market crashing to zero overnight and not recovering. Drops in floor prices force borrowers to pay back some principal or face liquidation, ensuring the debt within the pool remains healthy. If a market crash is so severe that it does create bad debt, the profits-taking mechanism in the pool re-directs all profit back to the pool until the debt is covered.</p>`
            }
        ],
    },
    {
        title: "Custom Loans",
        icon: "circle-and-square",
        items: [
            {
                title: "Borrowing",
                body: `<p>From the “create new loan” button in your wallet select “custom loan”. Adjust the loan parameters, bearing in mind that lenders may not choose to fund your loan if they deem the terms unfavorable. After creating a loan proposal, you have up to 5 days for lenders to fund you, but you may activate the loan earlier even if only partial funding comes in.</p><br />
                <p>Once your loan is funded and activated, you must pay the agreed upon interest on a daily basis. To avoid gas fees, you may pay ahead as many days as desired.</p><br />
                <p>Pay back the principal by the end of your loan term and you may redeem your NFT. If you fail to pay interest payments or principal, your loan will default. If there is only one lender, then your NFT is transferred to that lender, and if there are multiple lenders, then your NFT is auctioned to cover your loan.</p>`
            },
            {
                title: "Lending",
                body: `<p>On the “custom listings” tab, find a desirable NFT listing to fund. Desirable terms can be higher APY, lower loan-to-value ratio, and either longer or shorter time periods, depending on what kind of investment you are looking for. Once you have found a desirable listing, you may fund it partially or completely.</p><br />
                <p>Partial loans entitle you to your funds back from auctioning the NFT if the borrower defaults, but if you are the only lender, then the NFT is transferred to you if the owner defaults. Because of this, it is recommended that you know what you are doing if you choose to fund custom listings.</p>`
            }
        ],
    },
    {
        title: "Factory Contract",
        icon: "pen",
        items: [
            {
                title: "Factory Contract",
                body: `<p>Factory contract is responsible for creatiing Instant Pools.
                LiquidNFTs intant pool is defined by the borrowing currency and supported collections. Currently LiquidNFTs factory produced 2 pools, one for USDC and one for WETH token. Each pool produced can also have specific loan to value ratio (LTV) that is immutable.</p><br />
                <p>Factory Contract uses specific implementation to produce pool contracts, which would require pool redeployment if the implementation changes. This means that factory cannot influence existing pools behavior, but rather only create new pools  for the system if necessary.</p><br />
                <p>Factory Contract source code can be found on github repository: <a target="_blank" href="https://github.com/wise-foundation/liquidnfts-audit-scope/blob/master/contracts/PoolFactory.sol">https://github.com/wise-foundation/liquidnfts-audit-scope/blob/master/contracts/PoolFactory.sol</a></p><br />
                <p>Factory Contract is deployed at the following address: <a target="_blank" href="https://etherscan.io/address/0x076546b64CbfC30E60a952BF9473D32288ED93F6">0x076546b64CbfC30E60a952BF9473D32288ED93F6</a></p><br />`
            },
        ],
    },
    {
        title: "Router Contract",
        icon: "pen",
        items: [
            {
                title: "Router Contract",
                body: `<p>Details About The Contract...</p><br />`
            },
        ],
    },
    {
        title: "Pools Contract",
        icon: "pen",
        items: [
            {
                title: "Router Contract",
                body: `<p>Details About The Contract...</p><br />`
            },
            {
                title: "Pool Contract",
                body: `<p>Details About The Contract...</p><br />`
            },
            {
                title: "Price Verification - Merkle Trees",
                body: `<p>...</p><br />`
            },
            {
                title: "Price Verification - IPFS Hashes",
                body: `<p>...</p><br />`
            },
        ],
    },
];

const Hero = () => {

    const options = [];
    items.map((x) => options.push(x.title));
    const [direction, setDirection] = useState(options[0]);

    return (
        <div className={cn("section", styles.section)}>
            <div className={cn("container", styles.container)}>
                <div className={styles.top}>
                    {/*<div className={styles.stage}>learn how to get started with liquidNFTs.com</div>*/}
                    <h1 className={cn("h2", styles.title)}>
                        How It Works
                    </h1>
                    <div className={styles.info}>
                        If you have any questions about LiquidNFTs, please read our
                        {" "}
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://wisesoft.gitbook.io/liquidnfts/how-it-works"
                        >
                            docs
                        </a>
                        {" "}
                        and feel free to reach out through
                        {" "}
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={DISCORD_LINK}
                        >
                            discord
                        </a>
                        {" "}server. Our team will be glad to help you any time
                    </div>
                    <Dropdown
                        className={cn("mobile-show", styles.dropdown)}
                        value={direction}
                        setValue={setDirection}
                        options={options}
                    />
                </div>
                <div className={styles.row}>
                </div>
                <div className={styles.row}>
                    <div className={styles.col}>
                        <div className={styles.nav}>
                            {items.map((x, index) => (
                                <div
                                    className={cn(styles.link, {
                                        [styles.active]: x.title === direction,
                                    })}
                                    onClick={() => setDirection(x.title)}
                                    key={index}
                                >
                                    <Icon name={x.icon} size="16" />
                                    <span>{x.title}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div style={{width: "100%"}} className={styles.col}>
                        {items
                            .find((x) => x.title === direction)
                            .items.map((x, index) => (
                                <Item className={styles.item} item={x} key={index} />
                            ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Hero;
