import React, { useState, useMemo, useCallback } from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import Icon from "../../../components/Icon";
import Switch from "../../../components/Switch";
import styles from "./History.module.sass";
import { formatUnits } from "ethers/lib/utils";
import { useToken, useEthers } from "@usedapp/core";
import { dollarsFormat, getChainById } from "../../../utils";
import EnsName from "../../../components/EnsName";
import PerfectScrollbar from 'react-perfect-scrollbar';
import Avatar from "../../../components/Avatar";
// import loaderImage from "../../../assets/images/loaders/cone.svg";

// const NUM_ONE_HUNDRED = 100;

const History = ({
    className,
    events,
    paymentTokenName,
    paymentToken,
    eventsByUser,
    totalCollected,
    remainingBalance,
    borrowingFee,
}) => {
    const paymentTokenInfo = useToken(paymentToken);
    const { chainId } = useEthers();
    const chain = getChainById(chainId);

    /* const totalPaid =  useMemo(() => {
        const hasProps = remainingBalance && totalCollected && borrowingFee && paymentTokenInfo;
        if (!hasProps) return '';
        const balance = formatUnits(remainingBalance, paymentTokenInfo?.decimals);
        const parsedTotal = parseFloat(formatUnits(totalCollected, paymentTokenInfo?.decimals));
        const fee = parsedTotal * (parseFloat(borrowingFee) / NUM_ONE_HUNDRED);
        const totalAmount = parsedTotal + fee;
        return totalAmount - balance;
    }, [
        remainingBalance,
        totalCollected,
        borrowingFee,
        paymentTokenInfo
    ]);*/

    const sortedEvents = useMemo(() => {
        if (!events) return;
        return events.sort((a, b) => a.timestamp < b.timestamp ? 1 : -1)
    }, [events]);

    const [showCombined, setShowCombined] = useState(false);

    const toggle = () => {
        if (disabled === false) {
            setShowCombined(!showCombined);
        }
    }

    /*const sortedUsers = useMemo(() => {
        if (!users) return;
        const usersSorted = [...users];
        const index = usersSorted.findIndex((obj) => obj.user === account);
        usersSorted.unshift(usersSorted.splice(index, 1)[0]);
        return usersSorted;
    }, [users, account]);*/

    const dataShown = showCombined
        ? eventsByUser
        : sortedEvents;

    const haveData = dataShown && dataShown.length > 0;

    const classActive = haveData
        ? styles.normal
        : styles.grayscale;

    const disabled = haveData === false;

    const txDetailShown = useCallback((txData) => (
        showCombined
            ? `${txData.count} Transaction${txData.count > 1 ? 's' : ''}`
            : ( <a
                className={styles.link}
                target="_blank"
                rel="noopener noreferrer"
                href={chain.getExplorerTransactionLink(txData.transactionHash)}
                >
                    View Transaction
                </a> )
    ), [showCombined, chain]);

    return (
        <>
            <div className={styles.info}>
                During payback phase the NFT owner returns total contributed amount plus interest with every payback
                transaction reflected here
            </div>
            <div className={cn(styles.users, className)}>
                <PerfectScrollbar>
                    <div className={styles.list}>
                        <div className={styles.sum}>
                            <div className={styles.icon}>
                                <Icon name="calendar" size="20" />
                                <span className={styles.count}>
                                    {(sortedEvents?.length) || 0} Payback{sortedEvents?.length === 1 ? '' : 's'} Total
                                </span>
                            </div>
                            <div className={styles.total}>
                                {dataShown && (
                                <div className={cn(styles.box, classActive)}>
                                    <span onClick={toggle}>
                                        Combine Transactions
                                    </span>
                                    <Switch
                                        disabled={disabled}
                                        value={showCombined}
                                        setValue={setShowCombined}
                                    />
                                </div>
                                )}
                            </div>
                        </div>
                        {dataShown.map((x, index) => (
                            <div key={index}>
                                <div className={styles.item}>
                                    <Link to={`/profile/${x.user}`}>
                                        <Avatar
                                            className={styles.avatar}
                                            address={x.user}
                                        />
                                    </Link>
                                    <div className={styles.details}>
                                        <Link to={`/profile/${x.user}`}>
                                            <div className={styles.name}>
                                                <EnsName address={x.user} />
                                            </div>
                                        </Link>
                                        {x.amount && paymentToken && paymentTokenInfo && (
                                            <div className={styles.amount}>
                                                {dollarsFormat(
                                                    // @TODO: grab decimals from config
                                                    formatUnits(x.amount, paymentTokenInfo?.decimals)
                                                )}{" "}
                                                {paymentTokenName}
                                            </div>
                                        )}
                                    </div>
                                    <div className={styles.tx}>
                                        { txDetailShown(x) }
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </PerfectScrollbar>
            </div>
        </>
    );
};

export default History;
