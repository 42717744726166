import React, { useState, useMemo } from "react";
import { Link } from "react-router-dom";
import { useEthers, shortenIfTransactionHash } from "@usedapp/core";
import { parseUnits } from "ethers/lib/utils";
import cn from "classnames";
import styles from "./FollowSteps.module.sass";
import Icon from "../../../components/Icon";
import Loader from "../../../components/Loader";
import Success from "../../../components/Transaction";
// import LoaderCircle from "../../../components/LoaderCircle";
// import FormattedAmount from "../../../components/FormattedAmount";
import { Contract } from "ethers";
import useConfig from "../../../customHooks/useConfig";
import { useApprovedForAll } from "../../../customHooks/useApprovedForAll";
import FACTORY_ABI from "../../../ethers/abis/LiquidFactory.json";
import { isPunk } from "../../../ethers/nftCollections";
import punkAbi from "../../../ethers/nftCollections/abis/cryptoPunksABI.json";
import ERC721Abi from "../../../ethers/abis/ERC721.json";
import {
    SECONDS_IN_DAY,
    dollarsFormat,
    SECONDS_IN_YEAR,
    ITEM_VIEW_URL,
    getChainById,
} from "../../../utils";

const DEFAULT_TEXT = 'Proceed with Creation';
const CONFIRM_TEXT = 'Confirm Unlock'
const IN_PROGRESS_A = "Unlocking Tokens...";
const CONFIRM_TEXT_AGAIN = 'Confirm Creation'
const IN_PROGRESS_B = "Creating New Loan...";
const DONE_TEXT = "View Listing";

const SUCCESS_MSG = "You successfully created a new loan proposal! Now share it on social media to increase your chances of getting funded";

const FollowSteps = ({
    className,
    input,
    handleConfirmedLoan,
    floorPricePercentage
}) => {

    const {
        selectedTokenIds,
        selectedCollection,
        paymentToken,
        minimumAmount,
        maximumAmount,
        // amountRange,
        borrowingRate,
        borrowingTime,
    } = input;

    const config = useConfig();
    const { account, library, chainId } = useEthers();
    const chain = getChainById(chainId);

    const [transactionHash, setTransactionHash] = useState("");
    const [buttonText, setButtonText] = useState(DEFAULT_TEXT);
    const [approvalComplete, setApprovalComplete] = useState(false);
    const [completedPunkApprovals, setCompletedPunkApprovals] = useState(0);
    const [createdLiquidLocker, setCreatedLiquidLocker] = useState();

    const collectionIsPunk = isPunk(selectedCollection);

    const [alreadyApprovedThisCollection] = useApprovedForAll(
        selectedCollection,
        account,
        config.liquidNFTContract
    );

    const signer = library.getSigner(account);
    const tokenContract = new Contract(selectedCollection, ERC721Abi, signer);

    /*const handleError = (err) => {
    };*/

    const approvalDone = alreadyApprovedThisCollection || approvalComplete;

    const iconColor = approvalDone
        ? "#45B26B"
        : "#9757D7";

    const iconColorB = approvalDone && (buttonText.includes("...")|| buttonText === DONE_TEXT)
        ? "#9757D7"
        : "";

    const iconName = approvalDone
        ? 'check'
        : 'lock'

    const iconSize = approvalDone
        ? '20'
        : '24'

    const startProcess = async () => {

        if (buttonText !== DEFAULT_TEXT) {
            return;
        }

        try {
            if (alreadyApprovedThisCollection !== true) {
                setButtonText(CONFIRM_TEXT);
                await handleApprove();
            }
            setButtonText(CONFIRM_TEXT_AGAIN);
            await handleCreateLocker();
        }
        catch (e) {
            setButtonText(DEFAULT_TEXT);
            setTransactionHash();
            console.log(e, 'e');
            // @TODO: should be doing toast message
            // setApprovalProcessing(false);
            // handleError(err); // toast it
        }
    }

    const handleApprove = async () => {

        if (collectionIsPunk) {

            const punkContract = new Contract(
                selectedCollection,
                punkAbi,
                signer
            );

            const approvalTx = await punkContract.offerPunkForSaleToAddress(
                selectedTokenIds[completedPunkApprovals],
                0,
                config.liquidNFTContract
            );

            setButtonText(
                IN_PROGRESS_A
            );

            setTransactionHash(
                approvalTx.hash
            );

            const approval = await approvalTx.wait();

            if (approval) {
                setCompletedPunkApprovals((a) => {
                    const newValue = a + 1;
                    if (newValue === selectedTokenIds.length) setApprovalComplete(true);
                    return newValue;
                });
            }

            return;

        }

        const approvalTx = await tokenContract.setApprovalForAll(
            config.liquidNFTContract,
            true
        );

        setButtonText(
            IN_PROGRESS_A
        );

        setTransactionHash(
            approvalTx.hash
        );

        const approval = await approvalTx.wait();

        if (approval) {
            setApprovalComplete(true);
        }
    };

    const handleCreateLocker = async () => {

        const factoryContract = new Contract(
            config.liquidNFTContract,
            FACTORY_ABI,
            signer
        );

        // const paymentTokenName = paymentToken;
        const decimals = config.decimals[paymentToken];

        const min = parseUnits(
            minimumAmount.toString(),
            decimals
        );

        const max = parseUnits(
            maximumAmount.toString(),
            decimals
        );

        const rate = parseUnits(
            parseInt(borrowingRate).toString(),
            18 // @TODO: convert to DECIMALS_CONSTANT
        );

        const args = [
            selectedTokenIds,
            selectedCollection,
            min.toString(),
            max.sub(min).toString(),
            parseInt(borrowingTime) * SECONDS_IN_DAY,
            rate.toString(),
            config.paymentTokens[paymentToken],
        ];

        const createLockerTx = await factoryContract.createLiquidLocker(
            ...args,
            // { gasLimit: 400000 } // @TODO: calculate gas needed dynamically based on token list
        );

        setTransactionHash(
            createLockerTx.hash
        );

        setButtonText(
            IN_PROGRESS_B
        );

        const createdLocker = await createLockerTx.wait();
        handleConfirmedLoan();

        if (createdLocker) {
            for (const event of createdLocker.events) {
                if (event && event.args && event.args.lockerAddress) {
                    // setCreatedLiquidLocker({ address: event.args.lockerAddress });
                    setCreatedLiquidLocker(
                        event.args.lockerAddress
                    );
                }
            }
        }

        setButtonText(DONE_TEXT);
    };

    const stepForm = () => (
        <div>
            <div className={cn(styles.item, styles.success)}>
                <div className={styles.head}>
                    <div
                        className={styles.icon}
                        style={{ background: iconColor, borderColor: iconColor }}
                    >
                        <Icon
                            name={iconName}
                            size={iconSize}
                        />
                        {/*<LoaderCircle className={styles.loader} />*/}
                    </div>
                    <div className={styles.details}>
                        <div className={styles.info}>
                            Step 1 - {" "} Unlock NFTs
                        </div>
                        <div className={styles.text}>
                            Give LiquidNFTs permission to secure your {config.getCollectionNameByAddress(selectedCollection)?.name} NFTs for your loan proposal
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.stepLine} />
            <div className={cn(styles.item, styles.success)}>
                <div className={styles.head}>
                    <div
                        style={{ background: iconColorB, borderColor: iconColorB }}
                        className={styles.icon}>
                        <Icon
                            name="wallet"
                            size="24"
                        />
                    </div>
                    <div className={styles.details}>
                        <div className={styles.info}>
                            Step 2 - Confirm Creation
                        </div>
                        <div className={styles.text}>
                            Confirm the creation of your loan proposal using your {config.getCollectionNameByAddress(selectedCollection)?.name} NFT{selectedTokenIds.length > 1 ? "s" : ""} as collateral
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    const tweetMessage = useMemo(() => {
        // generate share message
        const paymentDurationSeconds = parseInt(borrowingTime) * SECONDS_IN_DAY;
        const apy = parseInt(borrowingRate)
            * SECONDS_IN_YEAR
            / paymentDurationSeconds;
        const collectionNames = config.getCollectionNameByAddress(selectedCollection);
        const collectionName = collectionNames?.name;
        const url = ITEM_VIEW_URL + createdLiquidLocker;

        return `You can earn ${Math.round(apy)}% APY in ${paymentToken} for funding my ${collectionName} loan request! ` +
        `My asking amount is ${Math.abs(floorPricePercentage)}% ${floorPricePercentage > 0 ? "above" : "below"} the floor price. ${url}`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        borrowingTime,
        borrowingRate,
        config.getCollectionNameByAddress,
        createdLiquidLocker,
        floorPricePercentage,
        paymentToken,
        selectedCollection
    ]);

    return (
        <div className={cn(className, styles.steps)}>
            <div className={cn("h4", styles.title)}>
                Create NFT Loan
            </div>
            {transactionHash && (
                <div style={{marginBottom: "30px"}}>
                    <div className={styles.body} >
                        { buttonText === DONE_TEXT ? (
                            <span>
                                Your transaction is complete and your loan proposal is now ready to receive contributions
                            </span>
                        ) : (
                            <span>
                                Your transaction is in progress. Follow the steps below to finalize your loan proposal
                            </span>
                        )}
                    </div>
                    <div className={styles.row}>
                        <div>View Transaction</div>
                        <div>
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={chain.getExplorerTransactionLink(transactionHash)}
                                className={styles.link}
                            >
                                {shortenIfTransactionHash(transactionHash)}
                            </a>
                        </div>
                    </div>
                </div>
            )}
            {!transactionHash && (
                <div style={{overflow: "scroll", paddingRight: "10px"}}>
                    <div className={styles.body} style={{marginTop: "0px"}}>
                        You are about to create a public loan proposal, allowing others to fund your loan
                    </div>
                    <div className={styles.row}>
                        <div>Contribution Phase</div>
                        <div>
                            5 Days
                        </div>
                    </div>
                    <div className={styles.body}>
                        You may redeem your NFT collateral back any time until the floor asked amount is reached
                    </div>
                    <div className={styles.row}>
                        <div>Floor Asked</div>
                        <div>
                            {dollarsFormat(minimumAmount) || 0} {paymentToken}
                        </div>
                    </div>
                    <div className={styles.row}>
                        <div>Total Asked</div>
                        <div>
                            {dollarsFormat(maximumAmount) || 0} {paymentToken}
                        </div>
                    </div>
                    <div className={styles.body} style={{marginBottom: "8px"}}>
                        You may activate your listing immediately if the floor asked amount has been reached
                    </div>
                </div>
            )}

            {transactionHash && buttonText !== DONE_TEXT && stepForm()}
            {buttonText === DONE_TEXT && (
                <div>
                    <Success
                        lockerAddress={createdLiquidLocker}
                        txHash={transactionHash}
                        title={SUCCESS_MSG}
                        showButton={false}
                        celebrate={true}
                        displaySocials={true}
                        tweet={tweetMessage}
                    />
                </div>
            )}

            {buttonText === DONE_TEXT ? (
            <div className={cn(styles.item, styles.done)}>
                <Link to={`/item/${createdLiquidLocker}`}>
                <button onClick={startProcess} className={cn(
                        "button",
                        styles.button,
                        (buttonText === DEFAULT_TEXT || buttonText === DONE_TEXT) ? "" : "loading",
                )}>{buttonText}</button>
                </Link>
            </div>
            ) : (
                <button onClick={startProcess} className={cn(
                        "button",
                        styles.button,
                        (buttonText === DEFAULT_TEXT || buttonText === DONE_TEXT) ? "" : "loading",
                )}>
                    {buttonText.includes("...") && (
                        <div style={{marginRight: "20px"}}>
                            <Loader className={styles.loader} color="white" />
                        </div>
                    )}
                    {buttonText}
                </button>
            )}
        </div>
    );
};

export default FollowSteps;
