import { useState, useEffect } from "react";
// import { useLookupAddress, shortenIfAddress } from "@usedapp/core";
import { shortenIfAddress } from "@usedapp/core";
import { ethers } from "ethers";

// @TODO: move to config / use from config if already present
const API_KEY = "8da7ddd87641452fa02eb9c4a116b391"
const memory = [];

const findMemory = (address) => {
    return memory.find(
        (x) => x.address.includes(
            address
        )
    );
};

const truncate = (str) => {
    const n = 13;
    return (str.length > n)
        ? str.slice(0, 5) + '...' + str.slice(str.length - 8 ,str.length)
        : str;
};

const EnsName = ({ address }) => {

    const [ensName, setEnsName] = useState("fetching...");

    useEffect(() => {

        const fetchEnsName = async (address) => {

            const provider = window.ethereum
                ? new ethers.providers.Web3Provider(window.ethereum, "any")
                : new ethers.providers.InfuraProvider("mainnet", API_KEY);

            const read = findMemory(
                address
            );

            if (read) {
                setEnsName(
                    truncate(
                        read.name
                    )
                );
                return;
            }

            const res = await provider.lookupAddress(
                address
            );

            if (res) {
                memory.push({
                    name: res,
                    address: address
                });

                setEnsName(
                    truncate(
                        res
                    )
                );
                return;
            }

            const shortAddress = shortenIfAddress(
                address
            );

            memory.push({
                name: shortAddress,
                address: address
            });

            setEnsName(
                truncate(
                    shortAddress
                )
            );
        };

        if (address) {
            fetchEnsName(address);
        }

    }, [address])

    return ensName;
};

export default EnsName;
