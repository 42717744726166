import React from "react";
import cn from "classnames";
import useConfig from "../../../customHooks/useConfig";
// import TokenImage from "../../../components/TokenImage";
import styles from "./CollectionSelector.module.sass";
// import ExtraOptions from "../../../components/ExtraOptions/ExtraOptions";

const CollectionSelector = ({ tokens, onSelect, selectedCollection }) => {

    const config = useConfig();

    const collections = processCollections(
        tokens,
        config
    );

    return (
        <div style={{ display: "flex", marginBottom: "0px" }}>
            <div className={styles.sorting}>
                <div className={styles.nav} style={{display: "flex", alignItems: "center"}}>
                    {/*collections.length > 1 &&*/ collections.map((collection, index) => (
                        <button
                            className={cn(styles.link, {
                                [styles.active]: collection.address === selectedCollection,
                            })}
                            type={"button"}
                            onClick={() => {onSelect(collection.address); return false;}}
                            // onClick={() => setCollectionIndex(index)}
                            key={index}
                        >
                            {collection.name}
                        </button>
                    ))}
                    {/*<ExtraOptions screen={"create"} />*/}
                </div>
            </div>
        </div>
    );
};

export default CollectionSelector;

const processCollections = (tokens, config) => {

    return tokens.reduce((prev, token) => {

        const { data, address } = token;

        const collectionNames = config.getCollectionNameByAddress(address);

        const existingCollectionIndex = prev.findIndex(
            (collection) => collection.address === address
        );

        if (existingCollectionIndex !== -1) {
            const existingCollection = prev[existingCollectionIndex];
            const increment = existingCollection.count++;
            existingCollection.counter = `You have ${increment} Token${increment > 1 ? "s" : ""}`;
            existingCollection.gallery.push(token);
            return prev;
        }

        return [
            ...prev,
            {
                address,
                name: collectionNames?.name,
                count: 1,
                counter: `You have 1 Token`,
                buttonClass: "stroke",
                buttonContent: "Show Loans",
                avatar: token,
                gallery: [token],
                punk: data.punk,
            },
        ];
    }, []);
};
