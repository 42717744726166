import React from "react";
// import Hero from "./Hero";
// import Selection from "./Selection";
import Popular from "./Popular";
// import HotLoan from "../../components/HotLoan";
import HotInstant from "../../components/HotInstant";
// import Collections from "./Collections";
// import Discover from "./Discover";
import Description from "./Description";
import Teams from "../Questions/Teams";
import HeroNew from "../Instant/Hero";
// import Program from "./Program";

import Intro from "../../components/Intro";
import ValueProps from "../../components/ValueProps";

const Home = () => {
    return (
        <>
            <HeroNew />
            <Popular />
            {/*<Program />*/}
            {/*<Hero />*/}
            {/*<Selection />*/}
            {/*<Collections />*/}
            {/*<HotLoan classSection="section" />*/}
            <Intro />
            <HotInstant classSection="section" />
            <Description />
            <ValueProps />
            {/*<Discover />*/}
            <Teams />
            {/*<HotLoan classSection="section" />*/}
        </>
    );
};

export default Home;
