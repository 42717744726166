import { formatUnits } from "ethers/lib/utils";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { TEST_NETWORK_ID } from "../utils";
import { getPoolContract } from "../utils/query/interfaces";
import useCallsGracefully from "./useCallsGracefully";
import useConfig from "./useConfig";

export const useTVL = (pools) => {

    const config = useConfig();

    const conversionRates = useSelector(
        (state) => state.conversions
    );

    // request tokens held for all supported pools
    const tokensHeldCalls = pools?.length && pools.map(pool => {
        const poolContract = getPoolContract(
            pool.address
        );
        return {
            contract: poolContract,
            method: "pseudoTotalTokensHeld",
            args: [],
        }
    });

    const pseudoAllTotalTokensHeld = useCallsGracefully(
        tokensHeldCalls?.length && tokensHeldCalls
    );

    // store all tokens held value per pool
    const allTotalTokensHeld = useMemo(() => pseudoAllTotalTokensHeld?.map((tokensHeld, index) => {

        const pool = pools[index];
        const decimals = config.decimals[pool?.currency];

        // treat value as 0 until true rates are fetched
        const conversionRate = conversionRates['CHAINLINK_ETH'] > 1
            ? conversionRates[pool?.currency]
            : 0;

        const usdc = tokensHeld && formatUnits(
            tokensHeld,
            decimals
        ) * conversionRate;

        return {
            pool: pool.address,
            amount: tokensHeld,
            decimals,
            currency: pool?.currency,
            usdc
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [conversionRates, pseudoAllTotalTokensHeld]);

    // dirty way (@TODO: better)
    let totalValueLocked = 0.00;

    // simplify: for testnet return arb value
    if (config.chainId === TEST_NETWORK_ID) {
        return {
            totalLendedFundsUSDC: 0,
            totalPoolBalanceUSDC: 0,
            totalValueLocked: 234932
        }
    }

    allTotalTokensHeld.forEach((data) => {
        totalValueLocked += parseFloat(data.usdc || 0.00);
    });

    return {
        totalLendedFundsUSDC: 0, // recalculate later
        totalPoolBalanceUSDC: 0, // recalculate later
        totalValueLocked: totalValueLocked
    }
}

export default useTVL;
