import React, { useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";
import { Contract } from "ethers";
import cn from "classnames";
import styles from "./User.module.sass";
import Icon from "../../../components/Icon";
import Avatar from "../../../components/Avatar";
import Report from "../../../components/Report";
import NFTStatus from "../../../components/NFTStatus";
import Modal from "../../../components/Modal";
import Dropdown from "../../../components/Dropdown";
import toast from 'react-hot-toast';
import Loader from "../../../components/Loader";
import Checkbox from "../../../components/Checkbox";
// import { FacebookShareButton, TwitterShareButton } from "react-share";
// import { useEthers } from "@usedapp/core";

import {
    useEthers,
    // useLookupAddress,
    shortenIfAddress,
    // useEtherBalance,
    // useTokenBalance,
    // useToken,
    // Mainnet,
    // Ropsten,
} from "@usedapp/core";

import APES_ABI from "../../../ethers/abis/Apes.json";
import MINT_ABI from "../../../ethers/abis/Mint.json";

import {
    getToastSettings,
    getChainById,
    TEST_NETWORK_ID,
} from "../../../utils/";

import { COLLECTION_ADDRESSES_TESTNET } from "../../../ethers/nftCollections/testnet";
import { setShowSupportedOnly, setShowPastLoans } from "../../../redux/settingsSlice";
import { appLocalStorage } from "../../../utils/localstorage";

const mintCollections = [
    "Bored Apes",
    // "DeadFellaz",
    // "Invisible Friends",
    // "CyberKongz",
    // "Moon Birds",
    // "Karafuru",
    "Doodles",
    "Cool Cats",
    // "Pudgy Penguins"
];

const DEFAULT_TEXT = 'Mint Test Tokens';
const CONFIRM_TEXT = 'Confirm in Metamask';
const MINTING_TEXT = 'Minting...';
const DONE_TEXT = '🎉 Done!';

const getRandomApes = () => {
    return Math.floor(Math.random() * 4) + 1;
};

const User = ({ className, item, activeIndex, onDoneMint }) => {

    const dispatch = useDispatch();
    const ensName = useSelector((state) => state.settings.ensName);
    const showSupportedOnly = useSelector((state) => state.settings.showSupportedOnly);
    const showPastLoans = useSelector((state) => state.settings.showPastLoans);
    const [visible] = useState(false);
    // const [visibleShare, setVisibleShare] = useState(false);
    const [visibleModalReport, setVisibleModalReport] = useState(false);
    const [isNFTStatusModalVisible, setIsNFTStatusModalVisible] = useState(false);
    const [buttonText, setButtonText] = useState(DEFAULT_TEXT);
    const [transactionHash, setTransactionHash] = useState();
    const [expand, setExpand] = useState(false);
    // const { activateBrowserWallet, account } = useEthers();
    const { account, chainId, library } = useEthers();
    const chain = getChainById(chainId);
    const { address } = useParams();
    const userAccount = address ? address : account;
    const signer = library && library.getSigner(account);

    const [mintCollection, setMintCollection] = useState(
        mintCollections[0]
    );

    const addressLine = ensName
        ? shortenIfAddress(userAccount)
        : "Consider ENS Purchase";

    const mintTokens = async () => {

        if (buttonText === DONE_TEXT) {
            onDoneMint();

            setButtonText(
                DEFAULT_TEXT
            );
        }

        if (buttonText !== DEFAULT_TEXT) return;

        try {

            if (mintCollection === "Bored Apes") {
                await mintApes();
                return;
            }

            if (mintCollection === "DeadFellaz") {
                await mintNormal(
                    COLLECTION_ADDRESSES_TESTNET.DEAD_FELLAZ
                );
                return;
            }

            if (mintCollection === "Invisible Friends") {
                await mintNormal(
                    COLLECTION_ADDRESSES_TESTNET.INVISIBLE_FRIENDS
                );
                return;
            }

            if (mintCollection === "CyberKongz") {
                await mintNormal(
                    COLLECTION_ADDRESSES_TESTNET.CYBER_KONGZ
                );
                return;
            }
            if (mintCollection === "Moon Birds") {
                await mintNormal(
                    COLLECTION_ADDRESSES_TESTNET.MOON_BIRDS
                );
                return;
            }
            if (mintCollection === "Karafuru") {
                await mintNormal(
                    COLLECTION_ADDRESSES_TESTNET.KARAFURU
                );
                return;
            }
            if (mintCollection === "Doodles") {
                await mintNormal(
                    COLLECTION_ADDRESSES_TESTNET.DOODLES
                );
                return;
            }

            if (mintCollection === "Cool Cats") {
                await mintNormal(
                    COLLECTION_ADDRESSES_TESTNET.COOL_CATS
                );
                return;
            }

            // if (mintCollection === "Pudgy Penguins") {
            //     await mintNormal(
            //         COLLECTION_ADDRESSES_TESTNET.PUDGY_PENGUIN
            //     );
            //     return;
            // }
        } catch (e) {
            console.log(e, 'error');
            setButtonText(DEFAULT_TEXT);
            toast.error(
                e.message,
                getToastSettings("🚫")
            );
        }
    };

    const mintApes = async () => {

        const apesContract = new Contract(
            COLLECTION_ADDRESSES_TESTNET.BORED_APES,
            APES_ABI,
            signer
        );

        const args = [
            getRandomApes()
        ];

        setButtonText(
            CONFIRM_TEXT
        );

        const mintApeTx = await apesContract.mintApe(
            ...args,
            { gasLimit: "600000" } // put close enough as possible to the needed value
        );

        setButtonText(
            MINTING_TEXT
        );

        setTransactionHash(
            mintApeTx.hash
        );

        // const res = await mintApeTx.wait();
        await mintApeTx.wait();
        setButtonText(DONE_TEXT);

        toast.success(
            'Transaction Completed',
            getToastSettings("✅")
        );
    };

    const mintNormal = async (address) => {

        const normalContract = new Contract(
            // can move to config
            address,
            MINT_ABI,
            signer
        );

        const args = [
            getRandomApes()
        ];

        setButtonText(
            CONFIRM_TEXT
        );

        const mintTokenTx = await normalContract.mint(
            ...args,
            { gasLimit: "600000" } // put close enough as possible to the needed value
        );

        setButtonText(
            MINTING_TEXT
        );

        setTransactionHash(
            mintTokenTx.hash
        );

        // const res = await mintTokenTx.wait();
        await mintTokenTx.wait();
        setButtonText(DONE_TEXT);

        toast.success(
            'Transaction Completed',
            getToastSettings("✅")
        );
    };

    const handleShowSupported = () => {

        const showSupportedUpdate = !showSupportedOnly;

        dispatch(
            setShowSupportedOnly(
                showSupportedUpdate
            )
        );

        appLocalStorage.SHOW_SUPPORTED_TOKENS.update(
            showSupportedUpdate
        );
    };

    const handleShowPastLoans = () => {

        const showPastLoansUpdate = !showPastLoans;

        dispatch(
            setShowPastLoans(
                showPastLoansUpdate
            )
        );

        appLocalStorage.SHOW_PAST_LOANS.update(
            showPastLoansUpdate
        );
    };

    return (
        <>
            <div className={cn(styles.user, className)}>
                <Avatar
                    address={address || userAccount}
                    className={styles.avatar}
                    showBadge
                    onClick={() => setIsNFTStatusModalVisible(true)}
                />
                {address ? (
                    <div className={styles.name}>{shortenIfAddress(address)}</div>
                ) : (
                    <div className={styles.name}>{ensName ?? shortenIfAddress(userAccount)}</div>
                )}
                <div className={styles.code}>
                    {address ? (
                        <div className={styles.number}>Consider ENS Purchase</div>
                    ) : (
                        <div className={styles.number}>{addressLine}</div>
                    )}
                    <button className={styles.copy}>
                        <Icon name="wallet" size="16" />
                    </button>
                </div>
                <div className={styles.info}>
                    On this page you will find supported tokens and loans
                </div>
                <div className={cn(styles.details, {
                    [styles.active]: expand
                })}>
                    <div className={styles.control}>
                        <div className={styles.btns}>
                            <a
                                className={styles.link}
                                target="_blank"
                                rel="noopener noreferrer"
                                href={chain?.getExplorerAddressLink(address || userAccount)}
                            >
                                <button
                                    className={cn(
                                        "button button-small",
                                        { [styles.active]: visible },
                                        styles.button
                                    )}
                                // onClick={() => shareProfile()}
                                >

                                    Etherscan Profile
                                </button>
                            </a>
                            <button
                                className={cn(
                                    "button button-small",
                                    { [styles.active]: visible },
                                    styles.button
                                )}
                                onClick={() => setIsNFTStatusModalVisible(true)}
                            >
                                Personal NFT Status
                            </button>
                            <div style={{ display: "none" }}>
                                <Checkbox
                                    className={styles.checkbox}
                                    value={showSupportedOnly}
                                    onChange={handleShowSupported}
                                    content="Show Only Supported"
                                />
                            </div>
                            {activeIndex === 1 &&
                                <div style={{ display: "none" }}>
                                    <Checkbox
                                        className={styles.checkbox}
                                        value={showPastLoans}
                                        onChange={handleShowPastLoans}
                                        content="Show Past Loans"
                                    />
                                </div>}
                            {chainId === TEST_NETWORK_ID && !address && (
                                <>

                                    <div>
                                        <Dropdown
                                            className={styles.dropdown}
                                            value={mintCollection}
                                            setValue={setMintCollection}
                                            options={mintCollections}
                                        />
                                    </div>

                                    <div className={styles.info}>
                                        For testing Instant Pools please mint Bored Apes or Doodles
                                    </div>
                                    <button
                                        className={cn(
                                            "button button-small",
                                            { [styles.active]: visible },
                                            styles.button,
                                            (buttonText === DEFAULT_TEXT || buttonText === DONE_TEXT) ? "" : "loading",
                                            // (buttonText === CONFIRM_TEXT) ? "" : ""
                                        )}
                                        onClick={() => mintTokens()}
                                    >
                                        {buttonText === MINTING_TEXT && (
                                            <div>
                                                <Loader className={styles.loader} color="white" />
                                            </div>
                                        )}
                                        {buttonText}
                                    </button>
                                    {transactionHash && (
                                        <a
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href={chain.getExplorerTransactionLink(transactionHash)}
                                            className={styles.info}
                                        >
                                            View Transaction {/* TO-DO: this link must have a hover effect */}
                                        </a>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                    {chainId === TEST_NETWORK_ID && (
                        <div className={styles.note}>You are on Goerli test-network</div>
                    )}
                    {chainId === 1 && (
                        <div className={styles.note}>You are on Ethereum network</div>
                    )}
                </div>
                <button
                    className={cn(
                        "button-stroke button-small",
                        styles.expand,
                        {
                            [styles.active]: expand
                        }
                    )}
                    onClick={() => setExpand(!expand)}
                >
                    <Icon name="arrow-down" size="16" />
                    Show {expand ? 'Less' : 'More'}
                </button>
            </div>
            <Modal
                visible={visibleModalReport}
                onClose={() => setVisibleModalReport(false)}
            >
                <Report />
            </Modal>
            <Modal
                visible={isNFTStatusModalVisible}
                outerClassName="extended"
                onClose={() => setIsNFTStatusModalVisible(false)}
            >
                <NFTStatus />
            </Modal>
        </>
    );
};

export default User;
