import React, { useCallback, useRef, useEffect } from "react";
import cn from "classnames";
import { useEthers, shortenIfAddress, shortenIfTransactionHash } from "@usedapp/core";
import ReactCanvasConfetti from "react-canvas-confetti";
import toast from 'react-hot-toast';

import styles from "./Transaction.module.sass";
import Icon from "../Icon";
import {
    EMPTY_ADDRESS,
    BASE_TWITTER_URL,
    NEW_PAGE_SETTINGS,
    getToastSettings,
    ITEM_VIEW_URL,
    getChainById,
} from "../../utils/";

const canvasStyles = {
    position: "fixed",
    pointerEvents: "none",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0
};

const Success = ({
    className,
    txHash,
    title,
    showButton,
    celebrate,
    lockerAddress,
    displaySocials,
    tweet,
    isWithdraw,
    shareUrl
}) => {

    const { chainId } = useEthers();
    const chain = getChainById(chainId);

    const locker = lockerAddress
        ? lockerAddress
        : EMPTY_ADDRESS;
        
    const refAnimationInstance = useRef(null);

    const getInstance = useCallback((instance) => {
        refAnimationInstance.current = instance;
    }, []);

    const makeShot = useCallback((particleRatio, opts) => {
        refAnimationInstance.current &&
            refAnimationInstance.current({
                ...opts,
                origin: {y: 0.7},
                particleCount: Math.floor(200 * particleRatio)
            });
    }, []);

    useEffect(() => {
        if (celebrate === true) {
            makeShot(0.10, {
                spread: 55,
                startVelocity: 55
            });
        }
    }, [chainId, celebrate, makeShot]);

    const url = shareUrl || ITEM_VIEW_URL + lockerAddress;
    const socials = [
        {
            title: "twitter",
            action: () => window.open(BASE_TWITTER_URL + encodeURIComponent(tweet), '', NEW_PAGE_SETTINGS),
        },
        {
            title: "share",
            action: async () => {
                await navigator.clipboard.writeText(url);
                toast.success(
                    "Copied to Clipboard!",
                    getToastSettings("✅")
                );
            },
        },
    ];

    return (
        <div className={cn(className, styles.wrap)}>
            <div className={cn("h2", styles.title)}>
                { isWithdraw ? "Bye" : "Yay"}!{" "}
                <span role="img" aria-label="firework">
                    { isWithdraw ? "💸" : "🎉" }
                </span>
            </div>
            <div className={styles.info}>
                {title}
            </div>
            <div className={styles.socials} style={{ display: displaySocials ? "flex" : "none" }}>
                {socials.map((x, index) => (
                    <div className={styles.social} key={index} onClick={x.action}>
                        <Icon name={x.title} size="18" />
                    </div>
                ))}
            </div>
            <div className={styles.table} style={{ display: displaySocials ? "none" : "block" }}>
                <div className={styles.row}>
                    <div className={styles.first}>Transaction Status</div>
                    <div className={styles.col}>
                        Completed
                        <span className={styles.icon}>
                        <Icon
                            name={"check"}
                            size={16}
                        />
                        </span>
                    </div>
                </div>
                { locker === EMPTY_ADDRESS ? (
                    <div className={styles.row}>
                        <div className={styles.first}>Transaction Details</div>
                        <div className={styles.col}>
                            <a
                                className={styles.link}
                                target="_blank"
                                rel="noopener noreferrer"
                                href={chain.getExplorerTransactionLink(txHash)}
                            >
                                { shortenIfTransactionHash(txHash) }
                            </a>
                            <span className={styles.icon}>
                            <Icon
                                name={"check"}
                                size={16}
                            />
                            </span>
                        </div>
                    </div>
            ) : (
                <div className={styles.row}>
                    <div className={styles.first}>Your Listing Address</div>
                    <div className={styles.col}>
                        <a
                            className={styles.link}
                            target="_blank"
                            rel="noopener noreferrer"
                            href={chain.getExplorerAddressLink(locker)}
                        >
                            { shortenIfAddress(locker) }
                        </a>
                        <span className={styles.icon}>
                        <Icon
                            name={"check"}
                            size={16}
                        />
                        </span>
                    </div>
                </div>
            )}
            </div>
            { celebrate && (
                <ReactCanvasConfetti refConfetti={getInstance} style={canvasStyles} />
            )}
            {showButton && (
                <button style={{width: "100%"}} className={cn("button", styles.button)}>Done</button>
            )}
        </div>
    );
};

export default Success;
