import React from "react";
import cn from "classnames";
import styles from "./Contribution.module.sass";

const items = [
    {
        title: "Enter contribution",
        value: "WISE",
    },
    {
        title: "Your balance",
        value: "8.498 WISE",
    },
    {
        title: "Service fee",
        value: "0 WISE",
    },
    {
        title: "Total contribution amount",
        value: "0 WISE",
    },
];

const Contribution = ({ className }) => {
    return (
        <div className={cn(className, styles.checkout)}>
            <div className={cn("h4", styles.title)}>Place a bid</div>
            <div className={styles.info}>
                Contribute for <strong>Satoshibles #27</strong> loan{" "}
            </div>
            <div className={styles.stage}>Your bid</div>
            <div className={styles.table}>
                {items.map((x, index) => (
                    <div className={styles.row} key={index}>
                        <div className={styles.col}>{x.title}</div>
                        <div className={styles.col}>{x.value}</div>
                    </div>
                ))}
            </div>
            <div className={styles.btns}>
                <button className={cn("button", styles.button)}>Place contribution</button>
                <button className={cn("button-stroke", styles.button)}>Cancel</button>
            </div>
        </div>
    );
};

export default Contribution;
