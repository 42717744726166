import React, { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { useEthers, shortenIfTransactionHash } from '@usedapp/core';
import cn from 'classnames';
import { Contract } from 'ethers';

import { setUpdateLatestTx } from '../../../../redux/settingsSlice';
import styles from './ClaimRefund.module.sass';
import Loader from '../../../../components/Loader';
import FormattedAmount from '../../../../components/FormattedAmount';
import { getChainById } from "../../../../utils";
import lockerAbi from '../../../../ethers/abis/LiquidLocker.json';

const DEFAULT_TEXT = 'Proceed with Refund';
const CONFIRM_TEXT = 'Confirm in Metamask';
const PROGRESS_TEXT = 'Refunding...';
const DONE_TEXT = 'Done';

const RefundSteps = ({
    className,
    paymentToken,
    userContribution,
    refundType,
    closeFunction,
    contributorData,
    singleProvider,
}) => {

    const { lockerAddress } = useParams();
    const { account, library, chainId } = useEthers();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [buttonText, setButtonText] = useState(DEFAULT_TEXT);
    const [transactionHash, setTransactionHash] = useState("");

    useEffect(() => {
        if (account === singleProvider) {
            closeFunction();
        }
    }, [account, singleProvider, closeFunction])

    const chain = getChainById(
        chainId
    );

    const initialRefundRef = useRef(
        userContribution
    );

    const signer = library.getSigner(
        account
    );

    const lockerContract = new Contract(
        lockerAddress,
        lockerAbi,
        signer
    );

    const handleClaimRefund = async () => {

        if (buttonText === DONE_TEXT) {
            closeFunction(false);
            return;
        }

        if (buttonText !== DEFAULT_TEXT) return;
        if (!userContribution) return;

        try {

            setButtonText(CONFIRM_TEXT);
            const refundClaim = await lockerContract[refundType](
                account
            );

            setLoading(true);
            setButtonText(PROGRESS_TEXT);
            setTransactionHash(refundClaim.hash);
            await refundClaim.wait();
            dispatch(setUpdateLatestTx(refundClaim.hash));
            setButtonText(DONE_TEXT);
            setLoading(false);

        } catch (err) {
            setLoading(false);
            setButtonText(DEFAULT_TEXT)
            // handleError(err);
            // @TODO: display toast
        }
    };

    const disabledBtn = parseFloat(userContribution?.toString()) === 0 && buttonText !== DONE_TEXT
        ? true
        : false;

    // const reason = refundType === 'refundDueSingle'
        // ? 'Sole-Contributor'
        // : 'Floor not reached';

    const description = refundType === 'refundDueSingle'
        ? 'Due to a sole-contributor funding the entire loan every other contributor is now refunded'
        : 'Due to not reaching floor asked amount of this loan every single contributor is now refunded';

    const beenRefunded = parseFloat(userContribution?.toString()) === 0 && contributorData?.amount.gt(0)
        ? true
        : false;

    return (
        <div className={cn(className, styles.sale)}>
            <div className={cn("h4", styles.title)}>
                Claim Refund
            </div>
            { buttonText === DONE_TEXT && (
            <div className={styles.row}>
                <div className={styles.first}>
                    Transaction Details
                </div>
                <div className={styles.col}>
                    <a
                        className={styles.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        href={chain.getExplorerTransactionLink(transactionHash)}
                    >
                        { shortenIfTransactionHash(transactionHash) }
                    </a>
                    <span className={styles.icon}>
                    </span>
                </div>
            </div>
            )}
            { buttonText !== DONE_TEXT && (
                <>
                    <div className={styles.info}>
                        {description}
                    </div>
                    {/*<div className={styles.row}>
                        <div className={styles.col}>Refund Status</div>
                        <div className={styles.col}>{
                            contributorData?.amount.gt(0)
                                ? status
                                : 'No Refund'
                        }</div>
                    </div>*/}
                    <div className={styles.row}>
                        <div className={styles.col}>
                            Refunded Amount
                        </div>
                        <div className={styles.col}>
                            { beenRefunded === true && (
                                <FormattedAmount
                                    amount={contributorData?.amount}
                                    token={paymentToken}
                                    label={true}
                                />
                            )}
                            { beenRefunded === false && (
                                <FormattedAmount
                                    amount={0}
                                    token={paymentToken}
                                    label={true}
                                />
                            )}
                        </div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.col}>Contributed Amount</div>
                        <div className={styles.col}><FormattedAmount
                            amount={contributorData?.amount}
                            token={paymentToken}
                            label={true}
                        /></div>
                    </div>
                    {/*<div className={styles.row}>
                        <div className={styles.col}>Refund Due Reason</div>
                        <div className={styles.col}>{reason}</div>
                    </div>*/}
                    { buttonText !== DONE_TEXT && (
                        <div className={styles.info} style={{marginTop: "24px"}}>
                            Based on your contribution of
                            {" "}<FormattedAmount
                                amount={contributorData?.amount}
                                token={paymentToken}
                                label={true}
                            /> tokens you can claim them back at any given time
                        </div>
                    )}
                </>
            )}
            { (buttonText === DONE_TEXT || buttonText === PROGRESS_TEXT) && (
                <>
                    <div>
                        { (buttonText === DONE_TEXT) && (
                            <div className={styles.info} style={{marginTop: "24px"}}>
                                Transaction is now confirmed and you have been successfully refunded
                                {' '}
                                <FormattedAmount
                                    amount={initialRefundRef.current}
                                    token={paymentToken}
                                    label={true}
                                /> tokens
                            </div>
                        )}
                    </div>
                </>
            )}
            <div className={styles.details} style={{marginTop: "24px"}}>
                {/*<div className={styles.info}>
                    Due to a reason provided below your account is eligable to claim contributed amount refund
                </div>*/}
                <button
                    onClick={handleClaimRefund}
                    className={cn(
                        "button",
                        styles.button,
                        (buttonText === DEFAULT_TEXT || buttonText === DONE_TEXT) ? "" : "loading",
                        (disabledBtn) ? "disabled" : ""
                    )}
                >
                    {loading && (
                        <Loader className={styles.loaderSmall} color="white" />
                    )}
                    {buttonText}
                </button>
            </div>
        </div>
    );
};

export default RefundSteps;
