import React, { useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import cn from "classnames";

import styles from './ExtraOptions.module.sass';
import Icon from "../Icon";
import Modal from "../Modal";
import Switch from "../Switch";
import Checkbox from "../Checkbox";

import {
    setShowTIP,
    setHiddenOptions,
    setTokenPreferences,
    setCollectionPreferences
} from "../../redux/settingsSlice";

import { appLocalStorage } from "../../utils/localstorage";

export default function ExtraOptions({ screen }) {

    const dispatch = useDispatch();
    const showTIP = useSelector((state) => state.settings.showTIP);
    const hiddenOptions = useSelector((state) => state.settings.hiddenOptions);
    const tokenPreferences = useSelector((state) => state.settings.tokenPreferences);
    const collectionPreferences = useSelector((state) => state.settings.collectionPreferences);
    const [showModal, setShowModal] = useState(false);

    const tokens = useMemo(() => Object.keys(tokenPreferences), [tokenPreferences]);
    // const filters = useMemo(() => Object.keys(collectionPreferences), [collectionPreferences]);

    const handleChange = (isTokenFilter, filter) => {
        if (isTokenFilter) {
            const newOptions = {
                ...tokenPreferences,
                [filter]: !tokenPreferences[filter]
            };
            dispatch(
                setTokenPreferences(
                    newOptions
                )
            );
            appLocalStorage.TOKEN.update(
                newOptions
            );
        } else {
            const newOptions = {
                ...collectionPreferences,
                [filter]: !collectionPreferences[filter]
            };
            dispatch(
                setCollectionPreferences(
                    newOptions
                )
            );
            appLocalStorage.COLLECTION.update(
                newOptions
            );
        }
    };

    const {
        showTimeline,
        showFilters,
        showPreview
    } = hiddenOptions;

    const handleToggleSwitch = () => {
        dispatch(
            setShowTIP(
                !showTIP
            )
        );

        appLocalStorage.TIP.update(
            !showTIP
        );
    };

    const updateOptions = (val, option) => {

        const newOptions = {
            ...hiddenOptions,
            [option]: val
        };

        dispatch(
            setHiddenOptions(
                newOptions
            )
        );

        appLocalStorage.HIDDEN_OPTIONS.update(
            newOptions
        );
    };

    const handleOptionsSwitch = (key) => (
        (val) => updateOptions(val, key)
    );

    const isLoanPage = screen === "loan";
    const isCreatePage = screen === "create";
    const isSearchPage = screen === "search";
    const isPoolPage = screen === "pools";

    return (
        <>
            <div className={styles.rotate} onClick={() => setShowModal(true)}>
                <Icon name="close-circle-fill" size="24" />
                <span>
                    Extra Options
                </span>
            </div>
            <Modal visible={showModal} onClose={() => setShowModal(false)}>
                <div>
                    <div className={cn("h4", styles.title)}>
                        Extra Options
                    </div>
                    <div className={styles.body}>
                        <div className={styles.info}>
                            Here you can adjust default behavior across the dapp
                            and choose your layout preferences
                        </div>
                        {isPoolPage === false && (
                            <div className={styles.row}>
                                <div className={cn(styles.col, styles.oneHalf)}>
                                    <div className={styles.box}>
                                        <div className={styles.category}>
                                            Display Total Interest Percent
                                        </div>
                                        <div className={styles.text}>
                                            {/*This indicates the time frame for the borrowed amount to be repaid with interest*/}
                                            Shows TIP value instead of APY
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.col}>
                                    <Switch
                                        value={showTIP}
                                        setValue={handleToggleSwitch}
                                    />
                                </div>
                            </div>
                        )}
                        {isLoanPage && (
                            <div className={styles.row} style={{marginTop: "16px"}}>
                                <div className={cn(styles.col, styles.oneHalf)}>
                                    <div className={styles.box}>
                                        <div className={styles.category}>
                                            Display Loan Timeline
                                        </div>
                                        <div className={styles.text}>
                                            {/*This indicates the time frame for the borrowed amount to be repaid with interest*/}
                                            Shows loan timeline phases
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.col}>
                                    <Switch
                                        value={showTimeline}
                                        setValue={handleOptionsSwitch("showTimeline")}
                                    />
                                </div>
                            </div>
                        )}
                        {isSearchPage && (
                            <div className={styles.row} style={{marginTop: "16px"}}>
                                <div className={cn(styles.col, styles.oneHalf)}>
                                    <div className={styles.box}>
                                        <div className={styles.category}>
                                            Display Searching Options
                                        </div>
                                        <div className={styles.text}>
                                            {/*This indicates the time frame for the borrowed amount to be repaid with interest*/}
                                            Shows filters and sorting items
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.col}>
                                    <Switch
                                        value={showFilters}
                                        setValue={handleOptionsSwitch("showFilters")}
                                    />
                                </div>
                            </div>
                        )}
                        {isCreatePage && (
                            <div className={styles.row} style={{marginTop: "16px"}}>
                                <div className={cn(styles.col, styles.oneHalf)}>
                                    <div className={styles.box}>
                                        <div className={styles.category}>
                                            Display Collateral Preview
                                        </div>
                                        <div className={styles.text}>
                                            {/*This indicates the time frame for the borrowed amount to be repaid with interest*/}
                                            Shows brief preview for the loan
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.col}>
                                    <Switch
                                        value={showPreview}
                                        setValue={handleOptionsSwitch("showPreview")}
                                    />
                                </div>
                            </div>
                        )}
                        {isPoolPage && (
                            <div className={styles.roller} style={{marginTop: "16px"}}>
                                <div className={styles.category}>
                                    Instant Pools Token Preferences
                                </div>
                                <div className={styles.text}>
                                    {/*This indicates the time frame for the borrowed amount to be repaid with interest*/}
                                    Choose which tokens you want to see
                                </div>
                                <div className={styles.group} style={{marginTop: "16px"}}>
                                    {tokens.map((x, index) => (
                                        <div key={index}>
                                            <Checkbox
                                                className={styles.checkbox}
                                                content={x + " Token"}
                                                value={tokenPreferences[x]}
                                                onChange={() => handleChange(true, x)}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                        {/*isPoolPage && (
                            <div className={styles.roller} style={{marginTop: "16px"}}>
                                <div className={styles.category}>
                                    Instant Pools Risk Preferences
                                </div>
                                <div className={styles.text}>
                                    Choose which level pools you want to see
                                </div>
                                <div className={styles.group} style={{marginTop: "16px"}}>
                                    <div>
                                        <Checkbox
                                            className={styles.checkbox}
                                            content={"Low Risk (40% LTV)"}
                                            value={true}
                                            // onChange={() => handleChange(false, x)}
                                        />
                                    </div>
                                    <div>
                                        <Checkbox
                                            className={styles.checkbox}
                                            content={"Medium Risk (60% LTV)"}
                                            value={true}
                                            // onChange={() => handleChange(false, x)}
                                        />
                                    </div>
                                </div>
                            </div>
                        )*/}
                        {/*isPoolPage && (
                            <div className={styles.roller} style={{marginTop: "16px"}}>
                                <div className={styles.category}>
                                    Instant Pools Collection Preferences
                                </div>
                                <div className={styles.text}>
                                    Choose which collections you want to see
                                </div>
                                <div className={styles.group} style={{marginTop: "16px"}}>
                                    {filters.map((x, index) => (
                                        <div key={index}>
                                            <Checkbox
                                                className={styles.checkbox}
                                                content={x}
                                                value={collectionPreferences[x]}
                                                onChange={() => handleChange(false, x)}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )*/}
                    </div>
                </div>
            </Modal>
        </>
    );
};
