import React, { useState } from "react";
// import { useHistory } from "react-router";
import { Link, NavLink } from "react-router-dom";
import cn from "classnames";
// import { useEthers } from "@usedapp/core";
import styles from "./Footer.module.sass";
import Icon from "../Icon";
import Image from "../Image";

import MailChimp from "../MailChimp";
import { DISCORD_LINK } from "../../utils";
// import { AUDIT_LINK } from "../../utils";

import magentaLogo from "../../assets/images/magentaLogo.svg";
import lightmodeLogo from "../../assets/images/lightmodeLogo.svg";

const menu = [
    {
        title: "Code Audits",
        url: "https://github.com/wise-foundation/liquidnfts-audit-scope/tree/master/audit",
        isExternal: true
    },
    {
        title: "Instant Pools",
        url: "/instant-pools",
    },
    {
        title: "How It Works",
        url: "https://wisesoft.gitbook.io/wise/liquidnfts.com/instant-pools",
        isExternal: true
    },
    {
        title: "Pricing Data",
        url: "/token-info"
    },
    {
        title: "Custom Listings",
        url: "/custom-listings",
    },
    {
        title: "Create NFT Loan",
        url: "/loan-type",
    },
    /*{
        title: "Connect Wallet",
        url: "/connect-wallet",
    },*/
];

// @TODO: move to config
const socials = [
    {
        title: "twitter",
        size: "24",
        url: "https://twitter.com/liquid_nfts",
    },
    {
        title: "discord",
        size: "24",
        url: DISCORD_LINK,
    },
];

const Footer = () => {

    const [visible, setVisible] = useState(false);
    /* const { account, activateBrowserWallet } = useEthers();*/
    /*const history = useHistory();*/

    // const handleWalletConnectClick = () => {
    //     if (account) {
    //         history.push('/profile/wallet-nfts');
    //     } else {
    //         activateBrowserWallet();
    //     }
    // }

    return (
        <footer className={styles.footer}>
            <div className={styles.body}>
                <div className={cn("container", styles.container)}>
                    <div className={styles.col}>
                        <Link className={styles.logo} to="/">
                            <Image
                                className={styles.owl}
                                src={lightmodeLogo}
                                srcDark={magentaLogo}
                                alt="LiquidNFTs"
                            />
                        </Link>
                        <div className={cn(styles.item, { [styles.active]: visible })}>
                            <div className={styles.category} onClick={() => setVisible(!visible)}>
                                Navigation
                                <Icon name="arrow-down" size="24" />
                            </div>
                            <div className={styles.menu}>
                                {/*<Link
                                    className={styles.link}
                                    to={{pathname: AUDIT_LINK}}
                                    target="_blank"
                                >
                                    Audit Report
                                </Link>*/}
                                {menu.map((x, index) =>
                                    x.isExternal ? (
                                        <a href={x.url} key={index} className={styles.link}>
                                            {x.title}
                                        </a>
                                    ) : (
                                        <NavLink
                                            className={styles.link}
                                            activeClassName={styles.active}
                                            to={x.url}
                                            key={index}
                                        >
                                            {x.title}
                                        </NavLink>
                                    )
                                )}
                                {/*<Link
                                    className={cn("button-stroke button-small", styles.button)}
                                    to="/connect-wallet"
                                </Link>*/}
                                {/*<button className={styles.link} onClick={handleWalletConnectClick}>
                                    {
                                        account
                                            ? "Wallet NFTs"
                                            : "Connect Wallet"
                                    }
                                </button>*/}
                            </div>
                        </div>
                    </div>
                    <div className={styles.col}>
                        <div className={styles.category}>WiseSoft Contracts</div>
                        <div className={styles.info}>
                            <p><a target="_blank" rel="noopener noreferrer" href="https://etherscan.io/address/0xB4d2484EF7f91c8D4cd019460Ccbc384C1C24297">LiquidNFTs Router</a></p>
                            <p><a target="_blank" rel="noopener noreferrer" href="https://etherscan.io/address/0x3f302D860b27FB06C794eE0916abC1D3A362Bf7e#code">LiquidNFTs Pool</a></p>
                            <p><a target="_blank" rel="noopener noreferrer" href="https://etherscan.io/address/0x66a0f676479Cee1d7373f3DC2e2952778BfF5bd6#code">WISE Staking Token</a></p>
                            <p><a target="_blank" rel="noopener noreferrer" href="https://etherscan.io/token/0x87a567060769348c7ff1836e4497b84f844ba99b#code">WISEr Reserve Token</a></p>
                        </div>
                    </div>
                    <div className={styles.col}>
                        <div className={styles.category}>Join Whitelist</div>
                        <div className={styles.info}>
                            Join our VIP list to be whitelisted for exclusive drops. Also follow
                            {" "}
                            <a
                                href="https://twitter.com/Wise_Token"
                                target="_blank"
                                rel="noreferrer"
                            >
                                @Wise_Token
                            </a> and
                            {" "}
                            <a
                                href="https://twitter.com/liquid_nfts"
                                target="_blank"
                                rel="noreferrer"
                            >
                                @Liquid_NFTs
                            </a> for the latest updates.
                        </div>
                        <MailChimp className={styles.form} />
                    </div>
                </div>
            </div>
            <div className={styles.foot}>
                <div className={cn("container", styles.container)}>
                    <div className={styles.copyright}>
                        Copyright © 2023 WiseSoft, LLC. All rights reserved
                    </div>
                    <div className={styles.socials}>
                        {socials.map((x, index) => (
                            <a
                                className={styles.social}
                                href={x.url}
                                target="_blank"
                                rel="noopener noreferrer"
                                key={index}
                            >
                                <Icon name={x.title} size={x.size} />
                            </a>
                        ))}
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
