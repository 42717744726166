import {
    TIP_STORAGE_KEY,
    HIDDEN_OPTIONS_STORAGE_KEY,
    TOKEN_STORAGE_KEY,
    COLLECTION_STORAGE_KEY,
    AIRDROP_STORAGE_KEY,
    PRICING_OPTIONS_STORAGE_KEY,
    SHOW_SUPPORTED_KEY,
    SHOW_PAST_LOANS_KEY,
    BANNER_STORAGE_KEY,
    POOL_PAGE_STORAGE_KEY
} from "../utils";

// returns parsed value
const get = (storageKey, item) => {

    let storageValue = localStorage.getItem(
        storageKey
    );

    if (item) {
        storageValue = JSON.parse(storageValue);
    }

    const value = item
        ? storageValue?.[item]
        : storageValue;

    return value
        ? JSON.parse(value)
        : null;
};

const update = (
    storage,
    value,
    item,
) => {

    let newConfig;

    if (item?.length) {

        newConfig = get(
            storage,
            item
        ) ?? {};

        newConfig[item] = value;

    } else {
        newConfig = value;
    }

    localStorage.setItem(
        storage,
        JSON.stringify(
            newConfig
        )
    );
};

const clear = (storage, item) => {

    if (!item) {
        localStorage.removeItem(storage);
        return;
    }

    const newConfig = get(
        storage,
        item
    ) || {};

    delete newConfig[item];

    localStorage.setItem(
        storage,
        JSON.stringify(
            newConfig
        )
    );
};

export const appLocalStorage = {
    TIP: {
        update: (value) => update(
            TIP_STORAGE_KEY,
            value
        ),
        clear: () => clear(
            TIP_STORAGE_KEY
        ),
        get: () => get(
            TIP_STORAGE_KEY
        )
    },
    HIDDEN_OPTIONS: {
        update: (value, item) => update(
            HIDDEN_OPTIONS_STORAGE_KEY,
            value,
            item
        ),
        clear: (item) => clear(
            HIDDEN_OPTIONS_STORAGE_KEY,
            item
        ),
        get: (item) => get(
            HIDDEN_OPTIONS_STORAGE_KEY,
            item
        ),
    },
    TOKEN: {
        update: (value, item) => update(
            TOKEN_STORAGE_KEY,
            value,
            item
        ),
        clear: (item) => clear(
            TOKEN_STORAGE_KEY,
            item
        ),
        get: (item) => get(
            TOKEN_STORAGE_KEY,
            item
        ),
    },
    COLLECTION: {
        update: (value, item) => update(
            COLLECTION_STORAGE_KEY,
            value,
            item
        ),
        clear: (item) => clear(
            COLLECTION_STORAGE_KEY,
            item
        ),
        get: (item) => get(
            COLLECTION_STORAGE_KEY,
            item
        ),
    },
    AIRDROP: {
        update: (value, item) => update(
            AIRDROP_STORAGE_KEY,
            value,
            item
        ),
        clear: (item) => clear(
            AIRDROP_STORAGE_KEY,
            item
        ),
        get: (item) => get(
            AIRDROP_STORAGE_KEY,
            item
        )
    },
    BANNER: {
        update: (value, item) => update(
            BANNER_STORAGE_KEY,
            value,
            item
        ),
        clear: (item) => clear(
            BANNER_STORAGE_KEY,
            item
        ),
        get: (item) => get(
            BANNER_STORAGE_KEY,
            item
        )
    },
    PRICING_OPTIONS: {
        update: (value, item) => update(
            PRICING_OPTIONS_STORAGE_KEY,
            value,
            item
        ),
        clear: (item) => clear(
            PRICING_OPTIONS_STORAGE_KEY,
            item
        ),
        get: (item) => get(
            PRICING_OPTIONS_STORAGE_KEY,
            item
        )
    },
    SHOW_SUPPORTED_TOKENS: {
        update: (value, item) => update(
            SHOW_SUPPORTED_KEY,
            value,
            item
        ),
        clear: (item) => clear(
            SHOW_SUPPORTED_KEY,
            item
        ),
        get: (item) => get(
            SHOW_SUPPORTED_KEY,
            item
        )
    },
    SHOW_PAST_LOANS: {
        update: (value, item) => update(
            SHOW_PAST_LOANS_KEY,
            value,
            item
        ),
        clear: (item) => clear(
            SHOW_PAST_LOANS_KEY,
            item
        ),
        get: (item) => get(
            SHOW_PAST_LOANS_KEY,
            item
        )
    },
    POOL_PAGE_OPTIONS: {
        update: (value, item) => update(
            POOL_PAGE_STORAGE_KEY,
            value,
            item
        ),
        clear: (item) => clear(
            POOL_PAGE_STORAGE_KEY,
            item
        ),
        get: (item) => get(
            POOL_PAGE_STORAGE_KEY,
            item
        )
    },
}