import React, { useMemo } from "react";
import { useEthers } from "@usedapp/core";
import { getChainById, produceAccurateValue } from "../../utils";
import cn from "classnames";
import styles from "./Timeline.module.sass";
import Icon from "../Icon";

const UNPAID_MESSAGE = "If this loan is not payed back on time anyone can liquidate it using dutch auction approach";
const READY_LIQUIDATE = "Payback for this loan has not been done on time, liquidation is now active as duch auction"
const PAID_MESSAGE = "This loan was successfully payed back by the borrower accumulating interest for the lenders";
const LIQUIDATED_MESSAGE = "This loan was liquidated because user did not return borrowed funds before the deadline";

const Timeline = ({
    title,
    name,
    items,
    children,
    // activeIndex,
    isButtonDisabled,
    paybackTxHash,
    liquidationPrice,
    tokenDecimals,
    paymentToken,
    isLiquidated
}) => {

    const { chainId } = useEthers();

    const chain = getChainById(
        chainId
    );

    const handleBorrowLink = (txHash) => {
        if (!txHash) return;

        const url = chain.getExplorerTransactionLink(
            txHash
        );

        const newWindow = window.open(
            url,
            '_blank',
            'noopener,noreferrer'
        );

        if (newWindow) newWindow.opener = null;
    };

    const formattedLiquidationPrice = produceAccurateValue(
        liquidationPrice,
        tokenDecimals
    );

    const loanInfo = useMemo(
        () => {

            const value = liquidationPrice
                ? `${formattedLiquidationPrice} ${paymentToken}`
                : "Fetching...";

            const title = isButtonDisabled
                ? "Starting Liquidation Price"
                : "Current Liquidation Price";

            return [
                {
                    title,
                    value
                },
            ]
        },
        [
            formattedLiquidationPrice,
            liquidationPrice,
            isButtonDisabled,
            paymentToken
        ]
    );

    const footerMessage = useMemo(() => {

        if (isLiquidated) return LIQUIDATED_MESSAGE;
        if (paybackTxHash) return PAID_MESSAGE;

        return isButtonDisabled
            ? UNPAID_MESSAGE
            : READY_LIQUIDATE

    }, [isButtonDisabled, isLiquidated, paybackTxHash]);

    return (
        <div>
            <div className={styles.main}>
                This loan for <span className={styles.white}>{title}</span> displayed as a timeline with all available details up to date
            </div>
            <div className={styles.body}>
                <div className={styles.steps}>
                    {items.map((x, index) => (
                        <div
                            className={cn(
                                styles.step,
                                { [styles.red]: x.variant === 'danger' },
                                { [styles.green]: x.variant === 'success' },
                                { [styles.next]: x.txHash },
                                { [styles.active]: x.txHash },
                                { [styles.clickable]: x.txHash }
                            )}
                            key={index}
                            onClick={() => handleBorrowLink(x.txHash)}
                        >
                            <div
                            className={cn(
                                styles.number,
                                { [styles.active]: isButtonDisabled === false }
                            )}
                            >
                                <Icon
                                className={cn(
                                    styles.last,
                                    { [styles.active]: isButtonDisabled === false }
                                )}
                                    name="lightning"
                                    size={16}
                                />
                            </div>
                            <div className={styles.flexer}>
                            {x.value}
                            {x.txHash && (
                                <Icon
                                    className={styles.external}
                                    name="external"
                                    size="16" viewBoxLarge={true}
                                />
                            )}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className={styles.downer}>
                {
                    footerMessage
                }
            </div>
            {paybackTxHash ? (<></>) : (
                <div style={{ marginTop: "18px" }}>
                    {loanInfo.map((x, index) => { return (
                            <div className={styles.row} key={index}>
                                <div className={styles.col}>{x.title}</div>
                                <div className={styles.col}>{x.value}</div>
                            </div>
                        )
                    })}
                </div>
            )}
        </div>
    );
};

export default Timeline;
