import React, { useState } from "react";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import { useEthers } from "@usedapp/core";
import styles from "./Actions.module.sass";
// import Transfer from "../Transfer";
import Activate from "../Activate";
import RemoveLoan from "../RemoveLoan";
import Cancel from "../Cancel";
// import Report from "../Report";
import ComingSoon from "../ComingSoon";
import Icon from "../Icon";
import Collaterize from "../../components/Collaterize";
import Modal from "../../components/Modal";
import ClaimFunds from "../ClaimFunds";
import Payback from "../Payback";
import EditListing from "../EditListing";
import Liquidate from "../Liquidate/Liquidate";

import {
    ACTIVE_STATUS,
    // PENDING_STATUS,
    EXPIRED_STATUS,
    FINISHED_STATUS,
    LIQUIDATED_STATUS,
    // ZERO_ADDRESS
} from "../../utils";

const Actions = ({ className, listingDetails, items }) => {
    const { account } = useEthers();
    const {
        lockerAddress,
        totalCollected,
        paymentToken,
        paymentTokenName,
        lockerOwner,
        totalAsked,
        remainingBalance,
        borrowingFee,
        paymentRate,
        paymentTime,
        contributors,
        title,
        creationTime,
        floorAsked,
        nextDueTime,
        floorPrice,
        collectionName
    } = listingDetails;

    const [visible, setVisible] = useState(false);
    // const [visibleModalTransfer, setVisibleModalTransfer] = useState(false);
    const [visibleModalActivate, setVisibleModalActivate] = useState(false);
    const [visibleModalRemoveLoan, setVisibleModalRemoveLoan] = useState(false);
    const [visibleModalCancel, setVisibleModalCancel] = useState(false);
    // const [visibleModalReport, setVisibleModalReport] = useState(false);
    const [visibleModalSoonA, setVisibleModalSoonA] = useState(false);
    const [visibleModalSoonB, setVisibleModalSoonB] = useState(false);
    const [visibleModalClaimFunds, setVisibleModalClaimFunds] = useState(false);
    const [visibleModalPayback, setVisibleModalPayback] = useState(false);
    const [visibleModalBid, setVisibleModalBid] = useState(false);
    const [visibleModalEdit, setVisibleModalEdit] = useState(false);
    const [visibleModalLiquidate, setVisibleModalLiquidate] = useState(false);
    const [contributeAmount, setContributeAmount] = useState("");
    const isOwner = lockerOwner === account;

    const itemsTerminate = [
        {
            title: "Claim Refund",
            icon: "coin",
            size: "20",
            action: () => {
                return;
                // setVisibleModalCancel(true)
            },
        },
        {
            title: "Terminate Listing",
            icon: "close-circle",
            size: "20",
            action: () => setVisibleModalCancel(true),
        }
    ]

    const itemsNormal = [
        {
            title: "Activate Listing",
            icon: "arrow-right-square",
            size: "20",
            action: () => setVisibleModalActivate(true),
        },
        {
            title: "Update Listing",
            icon: "add-square",
            size: "20",
            action: () => setVisibleModalEdit(true),
        },
        /*{
            title: "Increase Interest",
            icon: "add-square",
            size: "20",
            action: () => setVisibleModalSoonA(true),
        },
        {
            title: "Decrease Duration",
            icon: "minus-square",
            size: "20",
            action: () => setVisibleModalSoonB(true),
        },
        */
        /*{
            title: "Liquidate Loan",
            icon: "close-circle",
            action: () => setVisibleModalRemoveLoan(true),
        },*/
        {
            title: "Terminate Listing",
            icon: "close-circle",
            size: "20",
            action: () => setVisibleModalCancel(true),
        },
    ];

    const itemsUser = [
        {
            title: "Contribute to Loan",
            icon: "arrow-right-square",
            size: "20",
            action: () => setVisibleModalBid(true),
        },
        /*{
            title: "Increase Interest",
            icon: "add-square",
            size: "20",
            action: () => setVisibleModalSoonA(true),
        },
        {
            title: "Decrease Duration",
            icon: "minus-square",
            size: "20",
            action: () => setVisibleModalSoonB(true),
        },
        */
        /*{
            title: "Liquidate Loan",
            icon: "close-circle",
            action: () => setVisibleModalRemoveLoan(true),
        },*/
        {
            title: "Liquidate Listing",
            icon: "close-circle",
            size: "20",
            action: () => setVisibleModalLiquidate(true),
        },
    ];


    const itemsActive = [
        {
            title: "Liquidate Listing",
            icon: "close-circle",
            size: "20",
            action: () => setVisibleModalLiquidate(true),
        },
    ];

    const activeItemAvailableAction = account === lockerOwner
        ?   {
                title: "Payback Loan",
                icon: "arrow-right-square",
                size: "20",
                action: () => {
                    setVisibleModalPayback(true)
                },
            }
        :
            {
                title: "Scrape Rewards",
                icon: "coin",
                size: "20",
                action: () => {
                    setVisibleModalClaimFunds(true)
                },
            };

    itemsActive.unshift(
        activeItemAvailableAction
    );

    const loanStatus = listingDetails && listingDetails.loanStatus;

    const activeItems = [
        ACTIVE_STATUS,
        FINISHED_STATUS,
        LIQUIDATED_STATUS
    ];

    let displayItems = activeItems.includes(loanStatus)
        ? itemsActive
        : isOwner ? itemsNormal : itemsUser;

    if (loanStatus === EXPIRED_STATUS) {
        displayItems = itemsTerminate
    }

    return (
        <>
            <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
                <div
                    className={cn(styles.actions, className, {
                        [styles.active]: visible,
                    })}
                >
                    <button
                        className={cn("button-circle-stroke", styles.button, styles.favorite)}
                        onClick={() => setVisible(!visible)}
                    >
                        <Icon name="lightning" size="24" />
                    </button>
                    <div className={styles.body}>
                        {displayItems.map((x, index) => (
                            <div className={styles.item} key={index} onClick={x.action}>
                                <Icon name={x.icon} size={x.size} />
                                <span>{x.title}</span>
                            </div>
                        ))}
                    </div>
                </div>
            </OutsideClickHandler>
            <Modal visible={visibleModalActivate} onClose={() => setVisibleModalActivate(false)}>
                {listingDetails.paymentToken && listingDetails.lockerAddress && (
                    <Activate
                        items={items}
                        close={() => setVisibleModalActivate(false)}
                        listingDetails={listingDetails}
                    />
                )}
            </Modal>
            <Modal
                visible={visibleModalRemoveLoan}
                onClose={() => setVisibleModalRemoveLoan(false)}
            >
                <RemoveLoan />
            </Modal>
            <Modal visible={visibleModalCancel} onClose={() => setVisibleModalCancel(false)}>
                <Cancel
                    close={() => setVisibleModalCancel(false)}
                    listingDetails={listingDetails}
                />
            </Modal>
            <Modal visible={visibleModalSoonA} onClose={() => setVisibleModalSoonA(false)}>
                <ComingSoon featureDescription={"This should allow the owner to increse interest of the loan in order to attract more contributors"} />
            </Modal>
            <Modal visible={visibleModalSoonB} onClose={() => setVisibleModalSoonB(false)}>
                <ComingSoon featureDescription={"This should allow the owner to decrease payback date to attract more contributors"} />
            </Modal>
            <Modal visible={visibleModalBid} onClose={() => setVisibleModalBid(false)}>
                <Collaterize
                    lockerAddress={lockerAddress}
                    amount={contributeAmount}
                    setAmount={setContributeAmount}
                    totalAsked={totalAsked}
                    paymentTokenAddr={paymentToken}
                    title={title}
                    paymentTime={paymentTime}
                    borrowingFee={borrowingFee}
                    contributors={contributors}
                    floorPrice={floorPrice}
                    collectionName={collectionName}
                    closeModal={() => setVisibleModalBid(false)}
                />
            </Modal>
            <Modal
                visible={visibleModalClaimFunds}
                onClose={() => setVisibleModalClaimFunds(false)}
            >
                <ClaimFunds
                    props={{
                        lockerAddress,
                        totalCollected,
                        paymentToken,
                        paymentTokenName,
                        close: () => setVisibleModalClaimFunds(false),
                    }}
                />
            </Modal>
            <Modal visible={visibleModalPayback} onClose={() => setVisibleModalPayback(false)}>
                <Payback
                    closeFunction={setVisibleModalPayback}
                    className={styles.control}
                    lockerOwner={lockerOwner}
                    paymentToken={paymentToken}
                    totalAsked={totalAsked}
                    totalCollected={totalCollected}
                    remainingBalance={remainingBalance}
                    borrowingFee={borrowingFee}
                    closeModal={() => setVisibleModalPayback(false)}
                />
            </Modal>
            <Modal visible={visibleModalEdit} onClose={() => setVisibleModalEdit(false)}>
                <EditListing
                    paymentRate={paymentRate}
                    paymentTime={paymentTime}
                    paymentTokenName={paymentTokenName}
                    closeModal={() => setVisibleModalEdit(false)}
                />
            </Modal>
            <Modal visible={visibleModalLiquidate} onClose={() => setVisibleModalLiquidate(false)}>
                <Liquidate
                    lockerAddress={lockerAddress}
                    totalCollected={totalCollected}
                    floorAsked={floorAsked}
                    creationTime={creationTime}
                    loanStatus={loanStatus}
                    nextDueTime={nextDueTime}
                    timelineItems={items}
                    closeModal={() => setVisibleModalEdit(false)}
                />
            </Modal>
        </>
    );
};

export default Actions;
