import useCallsGracefully from "./useCallsGracefully";

/**
 * Gracefully calls `useDApp`'s `useCalls` for one "call", logging any errors.
 * @param calls a list of contract calls
 * @param args any remaining arguments for `useCalls`
 * @returns the first deconstructed result of `useCalls`
 */
const useCallGracefully = (call, ...args) => {

    const [result] = useCallsGracefully(
        [call],
        ...args
    );

    return result;
};

export default useCallGracefully;
