import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
    DEFAULT_POOL_VIEW,
    DEFAULT_BANNER_PREFERENCES,
    DEFAULT_COLLECTION_PREFERENCES,
    DEFAULT_TOKEN_PREFERENCES,
    SHOW_TIP
} from "../utils";
import { appLocalStorage } from "../utils/localstorage";
import {
    // setAirdopPreferences,
    setCollectionPreferences,
    setHiddenOptions,
    setPricingPreferences,
    setShowTIP,
    setTokenPreferences,
    setShowSupportedOnly,
    setShowPastLoans,
    setBannerPreferences,
    setPoolPageOptions
} from "../redux/settingsSlice";

export const useLocalStorage = () => {

    const dispatch = useDispatch();

    useEffect(() => {

        // TIP or APY preference
        const showTip = appLocalStorage
            .TIP
            .get();

        if (showTip) {
            dispatch(
                setShowTIP(
                    showTip
                )
            );
        } else {
            appLocalStorage.TIP.update(
                SHOW_TIP
            );
        }

        // Hidden options local storage
        const hiddenOptions = appLocalStorage
            .HIDDEN_OPTIONS
            .get();

        if (hiddenOptions) {
            dispatch(
                setHiddenOptions(
                    hiddenOptions
                )
            );
        } else {
            appLocalStorage.HIDDEN_OPTIONS.update(
                {
                    showTimeline: true,
                    showFilters: true,
                    showPreview: true
                }
            );
        }

        // Token and collection preferences local storage
        const token = appLocalStorage
            .TOKEN
            .get();

        if (token) {
            dispatch(
                setTokenPreferences(
                    token
                )
            );
        } else {
            appLocalStorage.TOKEN.update(
                DEFAULT_TOKEN_PREFERENCES
            );
        }

        // Collection preferences
        const collection = appLocalStorage
            .COLLECTION
            .get();

        if (collection) {
            dispatch(
                setCollectionPreferences(
                    collection
                )
            );
        } else {
            appLocalStorage.COLLECTION.update(
                DEFAULT_COLLECTION_PREFERENCES
            );
        }

        // Airdrop setting local storage
        // const airdrop = appLocalStorage.AIRDROP.get();
        // if (airdrop) {
        //     dispatch(
        //         setAirdopPreferences(
        //             airdrop
        //         )
        //     );
        // } else {
        //     appLocalStorage.AIRDROP.update(
        //         true,
        //         'isBannerVisible'
        //     );
        // }

        // Banner setting local storage
        const banner = appLocalStorage
            .BANNER
            .get();

        if (banner?.promotion != null) {
            dispatch(
                setBannerPreferences(
                    banner
                )
            );
        } else {
            appLocalStorage.BANNER.update(
                DEFAULT_BANNER_PREFERENCES
            );
            dispatch(
                setBannerPreferences(
                    DEFAULT_BANNER_PREFERENCES
                )
            );
        }

        // Pricing setting local storage
        const pricing = appLocalStorage
            .PRICING_OPTIONS
            .get();

        if (pricing) {
            dispatch(
                setPricingPreferences(
                    pricing
                )
            );
        }

        // Show supported only setting local storage
        const showSupportedOnly = appLocalStorage
            .SHOW_SUPPORTED_TOKENS
            .get();

        if (showSupportedOnly !== null) {
            dispatch(
                setShowSupportedOnly(
                    showSupportedOnly
                )
            );
        }

        const showPastLoans = appLocalStorage
            .SHOW_PAST_LOANS
            .get();

        if (showPastLoans !== null) {
            dispatch(
                setShowPastLoans(
                    showPastLoans
                )
            );
        }

        // Pool Page Options
        const poolPageOptions = appLocalStorage
            .POOL_PAGE_OPTIONS
            .get();

        if (poolPageOptions) {
            dispatch(
                setPoolPageOptions(
                    poolPageOptions
                )
            );
        } else {
            appLocalStorage.POOL_PAGE_OPTIONS.update(
                {
                    listViewType: DEFAULT_POOL_VIEW,
                }
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
}
