import React, { useEffect, useState } from "react";
import cn from "classnames";
import { useHistory } from "react-router-dom";
import styles from "./Intro.module.sass";
import ScrollParallax from "../ScrollParallax";
import useMediaQuery from "../../customHooks/useMediaQuery";
import supportedPools from "../../screens/InstantPools/Pools/data";
import Modal from "../Modal";
import Invest from "../Invest";
import { useEthers } from "@usedapp/core";
// import ComingSoon from "../ComingSoon";
// import Modal from "../Modal";

const Intro = ({ scrollToRef }) => {
    const isMobile = useMediaQuery('(max-width: 767px)');

    const { chainId } = useEthers();

    // const [visibleModalSoonA, setVisibleModalSoonA] = useState(false);
    const [lendToPool, setLendToPool] = useState();

    const history = useHistory();

    const wethPool = supportedPools.find(pool => pool.title !== "Old" && pool.currency === "WETH" && pool.chainId === chainId);
    const usdcPool = supportedPools.find(pool => pool.title !== "Old" && pool.currency === "USDC" && pool.chainId === chainId);

    useEffect(() => {
        setLendToPool(null);
    }, [chainId])


    return (
        <div className={styles.section} ref={scrollToRef}>
            <div className={cn("container", styles.container)}>
                <div className={styles.gallery}>
                    <ScrollParallax className={styles.preview}>
                        <img
                            srcSet="/images/content/offer.png 2x"
                            src="/images/content/offer.png"
                            alt="Pack"
                        />
                    </ScrollParallax>
                    <ScrollParallax className={styles.preview} animateIn="fadeInUp">
                        <img
                            style={{ visibility: isMobile ? 'inherit' : 'hidden' }}
                            srcSet="/images/content/drop-new.png 2x"
                            src="/images/content/drop-new.png"
                            alt="Drop"
                        />
                    </ScrollParallax>
                    {/*<ScrollParallax className={styles.preview} animateIn="fadeInUp">
                        <img
                            srcSet="/images/content/unicorn.png 2x"
                            src="/images/content/unicorn.png"
                            alt="Unicorn"
                        />
                    </ScrollParallax>*/}
                </div>
                {
                    isMobile === false && (
                        <div className={styles.gallery} style={{ zIndex: "4" }}>
                            <ScrollParallax className={styles.preview} animateIn="fadeInUp">
                                <img
                                    style={{ visibility: "hidden" }}
                                    srcSet="/images/content/offer.png 2x"
                                    src="/images/content/offer.png"
                                    alt="Pack"
                                />
                            </ScrollParallax>
                            <ScrollParallax className={styles.preview} animateIn="fadeInUp">
                                <img
                                    srcSet="/images/content/drop-new.png 2x"
                                    src="/images/content/drop-new.png"
                                    alt="Drop"
                                />
                            </ScrollParallax>
                        </div>
                    )
                }
                <div className={styles.wrap}>
                    <h2 className={cn(styles.title, {
                        "h1": !isMobile,
                        "h2": isMobile
                    })}>
                        Fund Instant Pools to Earn
                    </h2>
                    <div className={styles.text}>
                        If you don’t have any NFTs, you can still earn by funding instant pools
                    </div>
                    <div className={styles.btns}>
                        <button className={cn("button", styles.button)} onClick={() => setLendToPool(wethPool)}>
                            Provide WETH Funds
                            {/*Subscribe Now*/}
                        </button>
                        <button className={cn("button-stroke", styles.button)} onClick={() => setLendToPool(usdcPool)}>
                            Provide USDC Funds
                        </button>
                    </div>
                    <Modal
                        visible={lendToPool}
                        onClose={() => setLendToPool(null)}
                    >
                        <div className={cn("h4", styles.title)}>
                            Lend {lendToPool?.currency} Funds
                        </div>
                        {lendToPool && (
                            <Invest
                                poolAddress={lendToPool.address}
                                paymentToken={lendToPool.currency}
                                title={lendToPool.title}
                                apy={lendToPool.lendStatusContent}
                                ltv={lendToPool.ltv}
                                closeModal={() => {
                                    setLendToPool(null);
                                    history.push('/profile/funded-pools');
                                }}
                            />
                        )}
                    </Modal>
                </div>
            </div>
        </div>
    );
};

export default Intro;
