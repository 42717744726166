import React from "react";
import cn from "classnames";
import styles from "./Accept.module.sass";

const items = [
    {
        title: "Service fee",
        value: "0 WISE",
    },
    {
        title: "Total bid amount",
        value: "1.46 WISE",
    },
];

const Accept = ({ className }) => {
    return (
        <div className={cn(className, styles.accept)}>
            <div className={styles.line}>
                <div className={styles.icon}></div>
                <div className={styles.text}>
                    Contribute to <strong>Satoshibles #27</strong> loan{" "}
                </div>
            </div>
            <div className={styles.stage}>1.46 WISE for 1 edition</div>
            <div className={styles.table}>
                {items.map((x, index) => (
                    <div className={styles.row} key={index}>
                        <div className={styles.col}>{x.title}</div>
                        <div className={styles.col}>{x.value}</div>
                    </div>
                ))}
            </div>
            <div className={styles.btns}>
                <button className={cn("button", styles.button)}>Contribute</button>
                <button className={cn("button-stroke", styles.button)}>Cancel</button>
            </div>
        </div>
    );
};

export default Accept;
