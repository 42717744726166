import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useEthers } from "@usedapp/core";

import Borrow from "./Borrow";
import { getTokenAddressFromName } from "../../ethers/nftCollections";
import { DEFAULT_TIMEOUT } from "../../utils";

const filterCollectionData = (collectionData, collectionAddresses) => {
    let res = [];
    const formattedCollectionAddresses = collectionAddresses.map(address => address.toLowerCase());
    for (let i = 0; i < collectionData.length; i++) {
        if (
            formattedCollectionAddresses.includes(
                collectionData[i].address.toLowerCase()
            )) {
            res.push(
                collectionData[i]
            )
        }
    }
    return res;
};

const BorrowDataWrapper = ({
    poolAddress,
    paymentToken,
    closeModal,
    selectedPool,
    selectedCurrency,
    supportedCollections,
    collectionData,
    title,
    defaultSelectedToken,
    showPoolDescription,
    onRefreshIconClick,
    isCollectionsLoading
}) => {
    const [displayedCollections, setDisplayedCollections] = useState();
    const selectedTokenId = useSelector((state) => state.settings.selectedTokenId);
    const { chainId, account } = useEthers();

    useEffect(() => {
        let mounted = true;
        const getDisplayedCollections = () => {
            const collectionAddresses = getTokenAddressFromName(
                chainId,
                supportedCollections
            );

            const collectionAddrsLowerCase = collectionAddresses.map((addr) => (
                addr.toLowerCase()
            ));

            let poolCollections = filterCollectionData(
                collectionData,
                collectionAddrsLowerCase
            );

            if (!mounted) return;

            if (selectedTokenId) {
                poolCollections = poolCollections.reduce((accum, currPool) => {
                    currPool.gallery = currPool.gallery.filter((token) => (
                        token.id.toString() !== selectedTokenId
                    ));
                    accum.push(currPool);
                    return accum;
                }, []);
            }

            // only update state if user has tokens
            if (poolCollections && poolCollections.length) {
                setDisplayedCollections(
                    poolCollections
                );
            }
        }
        const isOwner = collectionData 
        && collectionData.length 
        && collectionData[0].avatar.owner === account;
        const hasCollectionData = collectionData && collectionData.length;
        if ((isOwner && hasCollectionData) || selectedTokenId) getDisplayedCollections();
    }, [  // eslint-disable-line react-hooks/exhaustive-deps
        chainId, 
        collectionData,
        selectedTokenId,
    ]);

    useEffect(() => {
        // display no tokens msg if there is collectionData but no displayed collections
        let mounted = true;
        const timeout = setTimeout(() => {
            if (collectionData && !displayedCollections && mounted) {
                setDisplayedCollections([]);
            }
        }, DEFAULT_TIMEOUT);
        return () => {
            clearTimeout(timeout);
            mounted = false;
        }
    }, [collectionData, displayedCollections]);

    useEffect(() => {
        // reset loading flow on account change
        let mounted = true;
        const notOwner = displayedCollections 
            && displayedCollections.length 
            && displayedCollections[0].avatar.owner !== account;
        if (mounted && notOwner) {
            setDisplayedCollections(null);
        }
        return () => {
            mounted = false;
        }
    }, [account, displayedCollections]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Borrow
            poolAddress={poolAddress}
            paymentToken={paymentToken}
            title={title}
            closeModal={closeModal}
            collectionData={collectionData}
            selectedPool={selectedPool}
            selectedCurrency={selectedCurrency}
            displayedCollections={displayedCollections}
            defaultSelectedToken={defaultSelectedToken}
            showPoolDescription={showPoolDescription}
            onRefreshIconClick={onRefreshIconClick}
            isCollectionsLoading={isCollectionsLoading}
        />
    );
};

export default BorrowDataWrapper;
