import React, { useState, useMemo, useCallback } from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import Icon from "../../../components/Icon";
import Switch from "../../../components/Switch";
import styles from "./Claimings.module.sass";
import { formatUnits } from "ethers/lib/utils";
import { useToken, useEthers } from "@usedapp/core";
import { dollarsFormat } from "../../../utils";
import EnsName from "../../../components/EnsName";
import Avatar from "../../../components/Avatar";
import { getChainById } from "../../../utils";
import PerfectScrollbar from 'react-perfect-scrollbar';

const Claimings = ({
    className,
    events,
    paymentTokenName,
    paymentToken,
    eventsByUser,
}) => {
    const paymentTokenInfo = useToken(paymentToken);
    const { chainId } = useEthers();
    const chain = getChainById(chainId);

    const sortedEvents = useMemo(() => {
        if (!events) return;
        return events.sort((a, b) => a.timestamp < b.timestamp ? 1 : -1)
    }, [events]);

    const [showCombined, setShowCombined] = useState(false);

    const toggle = () => {
        if (disabled === false) {
            setShowCombined(!showCombined);
        }
    }

    const dataShown = showCombined
        ? eventsByUser
        : sortedEvents;

    const haveData = dataShown && dataShown.length > 0;

    const classActive = haveData
        ? styles.normal
        : styles.grayscale;

    const disabled = haveData === false;

    const txDetailShown = useCallback((txData) => (
        showCombined
            ? `${txData.count} Transaction${txData.count > 1 ? 's' : ''}`
            : ( <a
                className={styles.link}
                target="_blank"
                rel="noopener noreferrer"
                href={chain.getExplorerTransactionLink(
                    txData.transactionHash
                )}
                >
                    View Transaction
                </a> )
    ), [showCombined, chain]);

    return (
        <>
            <div className={styles.info}>
                After each payback all funds are distributed proportionaly between all contributors
                through claimings and historically displayed below
            </div>
            <div className={cn(styles.users, className)}>
                <PerfectScrollbar>
                    <div className={styles.list}>
                        <div className={styles.sum}>
                            <div className={styles.icon}>
                                <Icon name="search" size="20" />
                                <span className={styles.count}>
                                    {(sortedEvents?.length) || 0} Scraping{sortedEvents?.length === 1 ? '' : 's'} Total
                                </span>
                            </div>
                            <div className={styles.total}>
                                {dataShown && (
                                <div className={cn(styles.box, classActive)}>
                                    <span onClick={toggle}>
                                        Combine Transactions
                                    </span>
                                    <Switch
                                        disabled={disabled}
                                        value={showCombined}
                                        setValue={setShowCombined}
                                    />
                                </div>
                                )}
                            </div>
                        </div>
                        {dataShown.map((x, index) => (
                            <div key={index}>
                                <div className={styles.item}>
                                    <Link to={`/profile/${x.user}`}>
                                        <Avatar
                                            className={styles.avatar}
                                            address={x.user}
                                        />
                                    </Link>
                                    <div className={styles.details}>
                                        <Link to={`/profile/${x.user}`}>
                                            <div className={styles.name}>
                                                <EnsName address={x.user} />
                                            </div>
                                        </Link>
                                        {x.amount && paymentToken && paymentTokenInfo && (
                                            <div className={styles.amount}>
                                                {dollarsFormat(
                                                    // @TODO: grab decimals from config
                                                    formatUnits(x.amount, paymentTokenInfo?.decimals)
                                                )}{" "}
                                                {paymentTokenName}
                                            </div>
                                        )}
                                    </div>
                                    <div className={styles.tx}>
                                        { txDetailShown(x) }
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </PerfectScrollbar>
            </div>
        </>
    );
};

export default Claimings;
