import React, {
    useState,
    useMemo
} from 'react';
// import { useSelector } from 'react-redux';
//import { Link } from "react-router-dom";
import cn from "classnames";
import styles from "./InstantPools.module.sass";
//import { BORROW_RATE_OPTION, BORROW_TIMES } from "../../utils";
//import { ethers } from 'ethers';
import { useParams } from "react-router";
// import CollectionSelector from "../LoanDetails/CollectionSelector";
// import ExtraOptions from "../../components/ExtraOptions/ExtraOptions";
import Pools from "./Pools";

import {
    useEthers,
    // useToken,
    // shortenIfAddress,
} from "@usedapp/core";

//custom hooks
import useTokenFetch from "../../customHooks/useTokenFetch";
import { useCollectionsWithIpfsHash } from '../../customHooks/useCollectionsWithIpfsHash';
import useConfig from "../../customHooks/useConfig";
import { processCollections } from '../../utils/query/tokens';
import PricingTreeModal from '../../components/PricingTreeModal';
import ButtonTVL from '../../components/ButtonTVL';
// import useGetOwnerNFTs from '../../customHooks/useGetOwnerNFTs';

const GLOBAL = "all";
const DEFAULT_SELECTED_PRICING_COLLECTION_NAME = "Bored Apes";
// const GLOBAL_KEY = "Global Pools";
// const ALL_KEY = "All Pools"

// const tokenCollectionsTestnet = {
    // "All Pools": "all",
    // "Global Pools": "global",
    // "Bored Apes": "bored-apes",
    // "CyberKongz": "cyber-kongz",
    // "Mutant Apes": "mutant-apes",
    // "Cryptopunks": "cryptopunks",
    // "The Humanoids": "humanoids",
    // "Doodles": "doodles",
    // "Cool Cats": "cool-cats",
// };

// const tokenCollectionsMainnet = {
    // "All Pools": "all",
    // "Global Pools": "global",
    // "Bored Apes": "bored-apes",
    // "CyberKongz": "cyber-kongz",
    // "Mutant Apes": "mutant-apes",
    // "Doodles": "doodles",
    // "Cryptopunks": "cryptopunks",
    // "Mutant Apes": "bored-apes",
    // "The Humanoids": "humanoids",
    // "Satoshibles": "satoshibles",
    // "Cool Cats": "cool-cats",
// };

const InstantPools = () => {

    // const collectionPreferences = useSelector(
        // (state) => state.settings.collectionPreferences
    // );

    const { account } = useEthers();
    const { pool = GLOBAL } = useParams();
    const config = useConfig();

    const { address } = useParams();
    const [visibleModal, setVisibleModal] = useState(false);
    // check for address and not account b/c if no address param then it is user's profile
    const hasParamAndNotUser = address && address !== account;

    //const { tokens } = useTokenFetch( // old way
    // const { tokens } = useGetOwnerNFTs(
    //     hasParamAndNotUser && address
    // );

    const { tokens, refetch, loading: isCollectionsLoading } = useTokenFetch(
        hasParamAndNotUser && address
    );

    const collectionsData = processCollections(
        tokens,
        config,
        hasParamAndNotUser
    );

    // const tokenCollections = chainId === TEST_NETWORK_ID
        // ? tokenCollectionsTestnet
        // : tokenCollectionsMainnet;

    /* const tokenCollectionsFiltered = useMemo(() => (
        Object.keys(tokenCollections).reduce((accum, curr) => {
            if (collectionPreferences[curr]) {
                accum[curr] = tokenCollections[curr];
            }
            if (!accum[ALL_KEY]) {
                accum[ALL_KEY] = tokenCollections[ALL_KEY]
            }
            if (!accum[GLOBAL_KEY]) {
                accum[GLOBAL_KEY] = tokenCollections[GLOBAL_KEY]
            }
            return accum;
        }, {})
    ), [tokenCollections, collectionPreferences]);*/

    // const handleTab = (newRoute) => {
        // history.push(`/instant-pools/${newRoute}`);
    // };

    const supportedCollections = useCollectionsWithIpfsHash();

    const defaultSelectedPricingCollection = useMemo(() => {
        return (
            supportedCollections?.find(item => item.collection === DEFAULT_SELECTED_PRICING_COLLECTION_NAME)
            || supportedCollections?.[0]
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visibleModal, supportedCollections]);

    return (
        <>
            <div style={{ paddingTop: "32px", paddingBottom: "0px" }} className={cn("section", styles.section)}>
                <div className={styles.head}>
                    <div style={{ justifyContent: "space-between" }} className={cn("container", styles.container)}>
                        <div className={styles.top}>
                            <div>
                                <div className={cn("h2", styles.title)}>
                                    Instant NFT Pools
                                </div>
                                <div style={{ marginBottom: "5px" }} className={styles.slogan}>
                                    Click on the pool avatar to see details
                                </div>
                            </div>
                            <div className={styles.sorting}>
                                <div className={styles.nav}>
                                    <ButtonTVL />
                                    <button
                                        className={cn(styles.link, {
                                            [styles.active]: false,
                                        })}
                                        onClick={() => {
                                            setVisibleModal(true);
                                        }}
                                    >
                                        Token Trait Values
                                    </button>
                                    <a
                                        className={cn(styles.link, {
                                            [styles.active]: false,
                                        })}
                                        href="https://wisesoft.gitbook.io/wise/wise-lending-protocol/lasa-ai"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Dynamic APY
                                    </a>
                                    {/*<ExtraOptions screen={"pools"} />*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <Pools
                    selectedPools={pool}
                    collectionsData={collectionsData}
                    refetchCollectionsData={refetch}
                    isCollectionsLoading={isCollectionsLoading}
                />
            </div>
            <PricingTreeModal
                item={defaultSelectedPricingCollection}
                defaultIpfsHash={defaultSelectedPricingCollection?.ipfsHash}
                supportedCollections={supportedCollections}
                visibleModal={visibleModal}
                onClose={() => setVisibleModal(false)}
            />
        </>
    );
};

export default InstantPools;
