import React from "react";
import Collections from "./index";
import styles from "../Profile.module.sass";
import { processCollections } from "../../../utils/query/tokens";
// import useGetOwnerNFTs from "../../../customHooks/useGetOwnerNFTs";
import cn from "classnames";
import useTokenFetch from "../../../customHooks/useTokenFetch";

const CollectionsDataWrapper = ({ hasParamAndNotUser, config, address, onPreviewTokenClick, className }) => {

    // const { tokens, loading } = useGetOwnerNFTs(
    //     address
    // );

    const { tokens, loading } = useTokenFetch(
        address
    );

    const collectionsData = processCollections(
        tokens,
        config,
        hasParamAndNotUser
    );

    return (
        <Collections
            className={cn(styles.followers, className)}
            items={collectionsData}
            notUser={hasParamAndNotUser}
            loading={loading}
            onPreviewTokenClick={onPreviewTokenClick}
        />
    );
};

export default CollectionsDataWrapper;
