import { useState, useMemo } from "react";
import cn from "classnames";
import toast, { Toaster } from "react-hot-toast";
import CurrencyInput from "react-currency-input-field";
import { Contract, ethers, utils } from "ethers";
import { formatUnits } from "ethers/lib/utils";
import {
    shortenIfTransactionHash,
    useEtherBalance,
    useEthers,
    useTokenBalance,
    // useGasPrice
} from "@usedapp/core";


import WRAPPED_ETHER_ABI from "../../ethers/abis/WrappedEther.json";
import useConfig from "../../customHooks/useConfig";

import {
    EMPTY_ADDRESS,
    getToastSettings,
    getChainById,
    getToastErrorMessage,
} from "../../utils";

import Icon from "../Icon";
import Loader from "../Loader";
import Success from "../Transaction";
import SwapArrows from "../../assets/images/icons/SwapArrows";
import styles from "./WrappingService.module.sass";
import FormattedAmount from "../FormattedAmount";
import {
    PERCENT_OPTIONS,
    toFixedNoRound,
    ONE_HUNDRED,
    MIN_PRECISION_THRESHOLD
} from "../../utils";

const ETH_TO_WETH_RATE = 1;
const CONFIRM_TEXT = "Confirm in Metamask";
const WRAPPING_ETH = "Wrapping ETH...";
const UNWRAPPING_WETH = "Unwrapping WETH...";
const DONE_TEXT = 'Done';
const CURRENCY_ETH = "ETH";
const CURRENCY_WETH = "WETH";

const WrappingService = ({
    handleClose
}) => {
    const [fromAmount, setFromAmount] = useState();
    const [toAmount, setToAmount] = useState();

    const [fromCurrency, setFromCurrency] = useState(CURRENCY_ETH);
    const [toCurrency, setToCurrency] = useState(CURRENCY_WETH);

    const [transactionHash, setTransactionHash] = useState();
    const [buttonText, setButtonText] = useState(`Wrap ${fromCurrency} to ${toCurrency}`);
    const [isLoading, setIsLoading] = useState(false);

    const [activeQuickSelect, setActiveQuickSelect] = useState();

    const { formatEther } = utils;
    const { library, account, chainId } = useEthers();
    const chain = getChainById(chainId);
    const config = useConfig(chainId);

    const signer = library.getSigner(account);
    // const feeData = useGasPrice();

    const tokenName = config.tokenAddressToNameMapping[config.wrappedEtherAddress];
    const tokenDecimals = config.decimals[tokenName];

    const isWrap = fromCurrency === CURRENCY_ETH;

    const wrapText = isWrap
        ? "Wrap"
        : 'Unwrap';

    const isTransactionInProgress = isLoading || buttonText === CONFIRM_TEXT;

    const balanceETH = useEtherBalance(account);

    const balanceWETH = useTokenBalance(
        config.wrappedEtherAddress,
        account
    );

    const balanceDisplay = useMemo(() => {
        const formattedEthBalance = balanceETH
            ? toFixedNoRound(Number(formatEther(balanceETH)), 2)
            : " ";

        // @TODO: temp fix for values lower than 0.01 in toFixedNoRound logic
        const safeBalance = formattedEthBalance === "-0.00"
            ? "<0.01"
            : formattedEthBalance;

        const displayGreaterThan = balanceWETH
            && Number(formatUnits(balanceWETH, tokenDecimals)) < MIN_PRECISION_THRESHOLD;

        return (
            <div>
                Currently your wallet balance is
                {" "}
                <span style={{color: "white"}}>{safeBalance} ETH</span>
                {" "}
                and
                {" "}
                <span style={{color: "white"}}>{ displayGreaterThan
                    ? "<0.01 WETH"
                    : <FormattedAmount
                        amount={balanceWETH}
                        token={config.wrappedEtherAddress}
                        label={true}
                    />
                }</span> which you can swap at 1-to-1 rate
            </div>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [balanceETH, balanceWETH, tokenDecimals]);

    const wrappedEther = new Contract(
        config.wrappedEtherAddress,
        WRAPPED_ETHER_ABI,
        signer
    );

    const updateActiveQuickSelect = (value) => {
        if (!balanceETH || !balanceWETH) return;

        const maxAmountETH = formatEther(balanceETH);

        const maxAmountWETH = formatUnits(
            balanceWETH,
            tokenDecimals
        );

        const fromCurrency = isWrap
            ? maxAmountETH
            : maxAmountWETH;

        let percent = Number(
            (value / Number(fromCurrency) * ONE_HUNDRED).toFixed()
        );

        if (percent === ONE_HUNDRED) {
            percent = 'max';
        }

        setActiveQuickSelect(PERCENT_OPTIONS[percent]
            ? percent.toString()
            : null
        );
    };

    const handleInputOnChange = (value) => {
        setFromAmount(value);
        setToAmount(!isNaN(value) ? value * ETH_TO_WETH_RATE : "");
        updateActiveQuickSelect(value);
    }

    const handleOutputOnChange = (value) => {
        setToAmount(value);
        setFromAmount(!isNaN(value) ? value * ETH_TO_WETH_RATE : "");
        updateActiveQuickSelect(value);
    }

    const handleError = (err) => {
        toast.error(
            getToastErrorMessage(err.code),
            getToastSettings("🚫")
        );
    };

    const handleSwapCurrencies = () => {
        if (isTransactionInProgress) return;
        const from = fromCurrency;
        const to = toCurrency;
        setFromCurrency(to);
        setToCurrency(from);
        setButtonText(
            `${to === CURRENCY_ETH ? 'Wrap' : 'Unwrap'} ${to} to ${from}`
        )
    };

    const handleQuickSelect = (option) => {
        if (!balanceETH || !balanceWETH) return;

        const maxAmountETH = formatEther(balanceETH);

        const maxAmountWETH = formatUnits(
            balanceWETH,
            tokenDecimals
        );

        const fromCurrency = isWrap ? maxAmountETH : maxAmountWETH;

        let formattedNum = fromCurrency;
        if (option !== 'max') {
            const percent = option / 100;
            formattedNum = fromCurrency * percent;
        }

        const precision = Number(formattedNum) < MIN_PRECISION_THRESHOLD
            ? 4
            : 2;

        formattedNum = toFixedNoRound(parseFloat(Number(formattedNum)), precision);

        setFromAmount(formattedNum.toString());
        setToAmount(formattedNum.toString());
        setActiveQuickSelect(option);
    };

    const handleSubmit = async () => {

        if (buttonText === DONE_TEXT && handleClose) {
            handleClose();
            return;
        }

        try {
            setButtonText(
                CONFIRM_TEXT
            );

            let wrapTransaction;

            const amount = ethers.utils.parseUnits(
                fromAmount.toString(),
            );

            // @TODO: in separate ticket get gas estimate to subtract from ETH
            // to correctly wrap all eth with enough left for gas

            // const gas = feeData || BigNumber.from(0);
            // const maxEthBalMinusGas = balanceETH.sub(gas);
            // const fromCurrencyMax = isWrap ? maxEthBalMinusGas : balanceWETH;

            // const amount = activeQuickSelect === 'max'
            //     ? fromCurrencyMax
            //     : inputAmount;

            if (isWrap) {
                wrapTransaction = await wrappedEther.deposit({
                    value: amount
                });
                setButtonText(
                    WRAPPING_ETH
                );
            } else {
                wrapTransaction = await wrappedEther.withdraw(amount);
                setButtonText(
                    UNWRAPPING_WETH
                );
            }

            setIsLoading(true);


            const confirmedWrapTx = await wrapTransaction.wait();

            setIsLoading(false);

            setTransactionHash(
                confirmedWrapTx.transactionHash
            );

            setButtonText(DONE_TEXT);
        } catch (ex) {
            setButtonText(`${wrapText} ${fromCurrency} to ${toCurrency}`);
            setIsLoading(false);
            setTransactionHash(null);
            handleError(ex);
        }
    };

    const fromAmountExceeding = useMemo(() => {

        if (!balanceETH || !balanceWETH) return;
        const maxAmountETH = formatEther(balanceETH);

        const maxAmountWETH = formatUnits(
            balanceWETH,
            tokenDecimals
        );

        const fromCurrencyMax = isWrap
            ? maxAmountETH
            : maxAmountWETH;

        return Number(fromAmount) > Number(fromCurrencyMax);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [balanceETH, balanceWETH, tokenDecimals, fromAmount, isWrap]);

    const displayExceededError = fromAmountExceeding && !transactionHash;

    return (
        <div>
            <div className={cn("h4", styles.title)}>Wrapping Service</div>
            <div className={styles.main}>
                {
                    transactionHash
                        ? 'Congratulations! Your wrapping transaction is now confirmed and you can now fund the pools'
                        : isLoading
                            ? `Your transaction is now in progress. ${wrapText}ping ${fromAmount} ${fromCurrency} to ${toAmount} ${toCurrency}.`
                            : balanceDisplay
                }
            </div>
            {transactionHash && (
                <>
                    <div className={cn(styles.row, styles.rowDivider)} style={{ display: 'flex' }}>
                        <div>View Transaction</div>
                        <div>
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={chain.getExplorerTransactionLink(transactionHash)}
                                className={styles.link}
                            >
                                {shortenIfTransactionHash(transactionHash)}
                            </a>
                        </div>
                    </div>
                    <Success
                        txHash={transactionHash}
                        title={`You've ${wrapText.toLowerCase()}ped ${fromAmount} ${fromCurrency} to ${toAmount} ${toCurrency}`}
                        showButton={false}
                        celebrate={true}
                        lockerAddress={EMPTY_ADDRESS}
                        displaySocials={false}
                        tweet={""}
                    />
                </>
            )}
            {!isLoading && !transactionHash && (
                <div>
                    <div className={cn(styles.row, styles.columned)}>
                        <div className={cn(styles.col, styles.inputContainer)}>
                            <div className={styles.row}>
                                <div className={cn(styles.col, styles.buttonIcon)}>
                                    <Icon name="wallet" size={20} />
                                </div>
                                <div className={styles.col}>
                                    <CurrencyInput
                                        id="input-borrow"
                                        name="borrow"
                                        autoComplete="off"
                                        allowNegativeValue={false}
                                        className={styles.input}
                                        placeholder="Input Value"
                                        decimalsLimit={18}
                                        value={fromAmount}
                                        decimalSeparator="."
                                        groupSeparator=","
                                        onValueChange={handleInputOnChange}
                                        disabled={isTransactionInProgress}
                                    />
                                </div>
                                {fromAmount && (
                                    <div className={styles.col}>{fromCurrency}</div>
                                )}
                            </div>
                        </div>
                        <div className={
                            cn(styles.swapCurrency, {
                                [styles.disabled]: isTransactionInProgress
                            })
                        }
                            onClick={handleSwapCurrencies}>
                            <div className={styles.arrows}><SwapArrows />
                                <span className={styles.ratio}>1.00 {fromCurrency} = 1.00 {toCurrency}</span>
                            </div>
                        </div>
                        <div className={cn(styles.col, styles.inputContainer)}>
                            <div className={styles.row}>
                                <div className={cn(styles.col, styles.buttonIcon)}>
                                    <Icon name="wallet" size={20} />
                                </div>
                                <div className={styles.col}>
                                    <CurrencyInput
                                        id="input-borrow"
                                        name="borrow"
                                        autoComplete="off"
                                        allowNegativeValue={false}
                                        className={styles.input}
                                        placeholder="Output Value"
                                        decimalsLimit={18}
                                        value={toAmount}
                                        decimalSeparator="."
                                        groupSeparator=","
                                        onValueChange={handleOutputOnChange}
                                        disabled={isTransactionInProgress}
                                    />
                                </div>
                                {toAmount && (
                                    <div className={styles.col}>{toCurrency}</div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className={styles.row} style={{ marginTop: "8px" }}>
                        { Object.keys(PERCENT_OPTIONS).map((key) => (
                            <button
                                key={key}
                                className={cn('button-stroke', styles.option, {
                                    'button-active': activeQuickSelect === key
                                })}
                                onClick={() => handleQuickSelect(key)}
                            >
                                { PERCENT_OPTIONS[key] }
                            </button>
                        ))}
                    </div>
                </div>
            )}
            <button
                className={cn(
                    "button",
                    styles.button,
                    displayExceededError ? "disabled" : "",
                    {
                        loading: isTransactionInProgress
                    }
                )}
                onClick={handleSubmit}
                disabled={displayExceededError || isTransactionInProgress}
            >
                {isLoading && <Loader className={styles.loaderSmall} color="white" />}
                { displayExceededError ? "Not Enough Funds" : buttonText}
            </button>
            <Toaster />
        </div>
    )
}

export default WrappingService;
