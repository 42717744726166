import React from "react";
import InstantList from "./InstantList";
import useBorrowEvents from "../../customHooks/useBorrowEvents";

// @TODO: move where it belongs into config
export const InstantListView = {
    POOL: "POOL",
    PROFILE: "PROFILE"
}

const InstantListWrapper = ({
    className,
    optionalAddress,
    showAll,
    hideClosedLoans,
    userOnly,
    showOwner,
    blockModal,
    poolAddress,
    view,
    tableLayout
}) => {

    const [borrowEvents, borrowEventsTest] = useBorrowEvents(
        poolAddress
    );

    return (
        <InstantList
            showAll={showAll}
            hideClosedLoans={hideClosedLoans}
            showOwner={showOwner}
            userOnly={userOnly}
            blockModal={blockModal}
            borrowEvents={borrowEvents}
            borrowEventsTest={borrowEventsTest}
            className={className}
            optionalAddress={optionalAddress}
            view={view}
            tableLayout={tableLayout}
        />
    );
};

export default InstantListWrapper;
