import React, {useState, useEffect} from "react";
import useConfig from "../../../customHooks/useConfig";
import styles from "./TokenSelector.module.sass";
import Slider from "react-slick";
import Icon from "../../../components/Icon";
import CardSimple from "../../../components/CardSimple";
// TO-DO: move to file where all the constants are stored
const DEFAULT_NAME = "Token";

const SlickArrow = ({ currentSlide, slideCount, children, ...props }) => (
    <button{...props}>{children}</button>
);

const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    waitForAnimate: true,
    beforeChange: (current, next) => {
        // this.setState({ activeSlide: next });
    },
    afterChange: (current) => {
        // this.setState({ activeSlide2: current });
    },
    nextArrow: (
        <SlickArrow>
            <Icon name="arrow-next" size="14" />
        </SlickArrow>
    ),
    prevArrow: (
        <SlickArrow>
            <Icon name="arrow-prev" size="14" />
        </SlickArrow>
    ),
    responsive: [
        {
            breakpoint: 1179,
            settings: {
                slidesToShow: 4,
            },
        },
        {
            breakpoint: 1023,
            settings: {
                slidesToShow: 4,
            },
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 4
            },
        },
    ],
};

const TokenSelector = ({
    account,
    tokens,
    filteredTokens,
    selectedCollection,
    onSelectToken,
    selectedTokenIds,
    confirmedTokenIds
}) => {

    const config = useConfig();
    const collectionNames = config.getCollectionNameByAddress(selectedCollection);
    const collectionName = collectionNames?.singleName || DEFAULT_NAME;

    // TO-DO: move to helper
    const getName = (id) => {
        return `${collectionName} #${id.toNumber()}`;
    }

    const checkIfDisabled = (id) => {
        if (!confirmedTokenIds.length) return false;
        return confirmedTokenIds.find((confirmedToken) => (
            confirmedToken.eq(id)
        ));
    };

    const [keyRoller, setKeyRoller] = useState(
        Math.random()
    );

    useEffect(() => {
        setKeyRoller(Math.random());
    }, [selectedCollection, account, tokens])

    return (
        <div className={styles.wrapper}>
            <div key={keyRoller} className={styles.inner}>
                <Slider className="loan-slider loan-slider-special" {...settings}>
                    {filteredTokens.map((token, index) => {
                        const isPunk = !!token.data.punkIndex;
                        const id = isPunk ? token.data.punkIndex : token.id;
                        const disabled = checkIfDisabled(id);
                        const item = {
                            title: getName(id),
                            image: token.data.image,
                            image2x: token.data.image
                        }
                        return (
                            <div key={id} onClick={() => { if (!disabled) onSelectToken(id) }}>
                                <CardSimple
                                    selectedTokenIds={selectedTokenIds}
                                    index={index}
                                    id={id}
                                    isPunk={isPunk}
                                    className={styles.card}
                                    item={item}
                                    disabled={disabled}
                                />
                            </div>
                        );
                    })}
                </Slider>
            </div>
        </div>
    );
};

export default TokenSelector;
