import React, { useState, useEffect } from "react";
import toast, { Toaster } from 'react-hot-toast';
import { useEthers } from "@usedapp/core";
// import CookieConsent from "react-cookie-consent";
import { Link, NavLink } from "react-router-dom";
import cn from "classnames";
import styles from "./Header.module.sass";
import Icon from "../Icon";
import Image from "../Image";
// import Notification from "./Notification";
import User from "./User";

import {
    // AUDIT_LINK,
    TEST_NETWORK_ID
} from "../../utils";

import magentaLogo from "../../assets/images/magentaLogo.svg";
import lightmodeLogo from "../../assets/images/lightmodeLogo.svg";

import textLogoWhite from "../../assets/images/textLogoWhite.svg";
import textLogoBlack from "../../assets/images/textLogoBlack.svg";

const nav = [
    // {
    //     url: "/instant-pools",
    //     title: "Instant Pools"
    // },
    {
        url: "/pool/0x3f302D860b27FB06C794eE0916abC1D3A362Bf7e",
        title: "WETH Pool"
    },
    {
        url: "/pool/0x85162c81D0Ef84b21230eB4bf3cAb073B71F3c38",
        title: "USDC Pool",
    },
    {
        url: "/token-info",
        title: "Pricing Data",
    },
    {
        url: 'https://wisesoft.gitbook.io/wise/liquidnfts.com/instant-pools',
        title: "Documents",
        isExternal: true
    },
    /*{
        url: "/how-it-works",
        title: "How It Works",
    },*/
];

const Headers = () => {

    const [visibleNav, setVisibleNav] = useState(false);
    const [search, setSearch] = useState("");

    const handleSubmit = (e) => {
        alert();
    };

    const {
        account,
        chainId,
        activateBrowserWallet
    } = useEthers();

    useEffect(() => {

        if (!chainId) return;

        // if (!chainId || chainId === 3 || chainId === 1) {
        if (chainId === 1 || chainId === TEST_NETWORK_ID) {
            toast.dismiss();
            return;
        };

        toast.dismiss();
        toast.loading(
            (t) => (
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <span style={{ marginLeft: "-2px" }}>Unsupported Network Detected</span>
                    <div className={styles.reset} onClick={() => toast.dismiss(t.id)}>
                        <Icon name="close-circle-fill" size="24" />
                    </div>
                </div>
            ),
            {
                // duration: 4000,
                position: 'center-top',
                // Styling
                style: {
                    marginTop: "70px",
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                },
                className: '',
                // Custom Icon
                icon: '🔌',
                // Change colors of success/error/loading icon
                iconTheme: {
                    primary: '#000',
                    secondary: '#fff',
                },
                // Aria
                ariaProps: {
                    role: 'status',
                    'aria-live': 'polite',
                },
            });

    }, [chainId]);

    return (
        <header className={styles.header}>
            <div className={cn("container", styles.container)}>
                <Link className={styles.logo} to="/">
                    <Image
                        className={styles.owl}
                        src={lightmodeLogo}
                        srcDark={magentaLogo}
                        alt="LiquidNFTs"
                    />
                    <Image
                        className={styles.text}
                        src={textLogoBlack}
                        srcDark={textLogoWhite}
                        alt="LiquidNFTs"
                    />
                </Link>
                <div className={cn(styles.wrapper, { [styles.active]: visibleNav })}>
                    <nav className={styles.nav}>
                        {/*<Link
                            className={styles.link}
                            onClick={() => setVisibleNav(!visibleNav)}
                        >
                            Instant WETH Pool
                        </Link>
                        <Link
                            className={styles.link}
                            onClick={() => setVisibleNav(!visibleNav)}
                        >
                            Instant USDC Pool
                        </Link>*/}
                        {nav.map((x, index) => (
                            <NavLink
                                className={
                                    cn(styles.link, x.className)
                                }
                                activeClassName={styles.active}
                                to={{
                                    pathname: x.url
                                }}
                                key={index}
                                onClick={
                                    () => setVisibleNav(false)
                                }
                                target={x.isExternal ? '_blank' : '_self'}
                            >
                                {x.title}
                            </NavLink>
                        ))}
                        {!account && (
                            <button
                                className={cn("button-small", styles.navButton)}
                                onClick={activateBrowserWallet}
                            >
                                Connect Wallet
                            </button>
                        )}
                    </nav>
                    <form
                        className={styles.search}
                        action=""
                        onSubmit={() => handleSubmit()}
                    >
                        <input
                            className={styles.input}
                            type="text"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            name="search"
                            placeholder="Search"
                            required
                        />
                        <button className={styles.result}>
                            <Icon name="search" size="20" />
                        </button>
                    </form>
                    <Link
                        className={cn("button-small", styles.button)}
                        to="/instant-pools"
                        onClick={() => setVisibleNav(false)}
                    >
                        Instant NFT Pools
                    </Link>
                </div>
                {/*<Notification className={styles.notification} />*/}
                <Link
                    className={cn("button-small", styles.button)}
                    to="/instant-pools"
                >
                    Instant NFT Pools
                </Link>
                {account ? (
                    <User className={styles.user} />
                ) : (
                    <button
                        className={cn("button-stroke button-small", styles.button)}
                        onClick={activateBrowserWallet}
                    >
                        Connect Wallet
                    </button>
                )}
                <button
                    className={cn(styles.burger, { [styles.active]: visibleNav })}
                    onClick={() => setVisibleNav(!visibleNav)}
                >
                </button>
            </div>
            {/*<div className={styles.warning}>
                This project is in beta undergoing public-testing and currently available on Ropsten network only
            </div>*/}
            {/*<CookieConsent
                style={{
                    paddingLeft: "30px",
                    paddingRight: "30px"
                }}
                buttonText="Yes, I understand"
                buttonStyle={{
                    margin: "15px",
                    marginTop: "20px",
                    marginBottom: "20px",
                    padding: "12px",
                    width: "150px",
                    borderRadius: "19px",
                    color: "white",
                    background: "rgb(255 104 29)",
                    fontWeight: "bold"
                }}
            >
                This project is in beta undergoing public testing process and currently available on Ropsten network only
            </CookieConsent>*/}
            <div><Toaster /></div>
        </header>
    );
};

export default Headers;
