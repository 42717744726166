import React, { useState, useMemo } from "react";
import cn from "classnames";
import { BigNumber } from "ethers";
import useConfig from "../../customHooks/useConfig";
import styles from "./PaybackInstant.module.sass";
import PaybackInstantSteps from "./PaybackInstantSteps";
import useCallsGracefully from "../../customHooks/useCallsGracefully";
import { getPoolContract } from "../../utils/query/interfaces";
import {
    // DAYS_IN_YEAR,
    PAYBACK_TIME,
    // BORROW_RATE_DECIMAL,
    formatDateFormal,
    // MIN_PRECISION_THRESHOLD,
    // DEFAULT_FALLBACK_NUM,
    produceAccurateValue,
    addApprox
} from "../../utils";

//@TODO: read from utils
const PERCENT_OPTIONS = {
    0: 'Extend Loan',
    1: 'Finalize Loan'
};

const PaybackInstant = ({
    className,
    title,
    poolAddress,
    paymentToken,
    closeModal,
    borrowedAmount,
    principalTokens,
    paymentDates,
    borrowRate,
    tokenId,
    nftAddress,
    merkleProof,
    merklePrice,
    merkleIndex,
    minimumPay,
    loanInterest,
    cardIndex
}) => {

    const [transactionHash, setTransactionHash] = useState("");
    const [optionSelected, setOptionSelected] = useState("0");

    const config = useConfig();
    const decimals = config.decimals[paymentToken];
    const collectionDisplayNames = config.getCollectionNameByAddress(nftAddress);
    // const ADJUSTMENT_MUL = 1.001;

    const poolContract = getPoolContract(
        poolAddress
    );

    const {
        interestValue,
        extendLoanValue,
        endLoanValue,
        endLoanDisplay,
        minimumPayValue
    } = useMemo(() => {

        if (!loanInterest && !minimumPay) return "---";

        let minimumPayValue = minimumPay || BigNumber.from(0);
        minimumPayValue = minimumPayValue.mul(1001).div(1000);

        const loanInterestValue = loanInterest || BigNumber.from(0);

        const extendLoanValue = loanInterestValue.add(
            minimumPayValue
        );

        const endLoanValue = loanInterestValue.add(
            principalTokens.mul(1001).div(1000)
        );

        const endLoanDisplay = produceAccurateValue(
            endLoanValue,
            decimals
        );

        return {
            extendLoanValue,
            endLoanValue,
            endLoanDisplay,
            interestValue: loanInterestValue,
            minimumPayValue
        }
    }, [loanInterest, minimumPay, decimals, principalTokens]);

    const [loanData] = useCallsGracefully(
        poolAddress && poolContract ? [
            {
                contract: poolContract,
                method: "currentLoans",
                args: [
                    nftAddress,
                    tokenId
                ],
            },
        ] : []
    );

    const [tokensFromBorrowShares] = useCallsGracefully(
        poolAddress && poolContract && loanData?.borrowShares ? [
            {
                contract: poolContract,
                method: "getTokensFromBorrowShares",
                args: [
                    loanData?.borrowShares
                ],
            },
        ] : []
    );

    const extendLoanTransferAmount = useMemo(
        () => loanData &&
            tokensFromBorrowShares &&
            extendLoanValue.add(tokensFromBorrowShares).sub(loanData.principalTokens),
        [loanData, tokensFromBorrowShares, extendLoanValue]
    );

    const extendLoanDisplay =
        extendLoanTransferAmount
        && produceAccurateValue(
            extendLoanTransferAmount,
            decimals
        );

    const interestAmount = useMemo(
        () =>
            loanData &&
            tokensFromBorrowShares &&
            interestValue
                .add(tokensFromBorrowShares)
                .sub(loanData.principalTokens),

        [loanData, tokensFromBorrowShares, interestValue]
    );

    const interestAmountDisplay =
        interestAmount
        && produceAccurateValue(
            interestAmount,
            decimals
        );

    const payAmountDisplay = useMemo(() => {
        return optionSelected === "0"
            ? extendLoanDisplay
            : endLoanDisplay
    }, [optionSelected, endLoanDisplay, extendLoanDisplay]);

    const payDate = useMemo(() => (
        formatDateFormal(paymentDates.dueDate)
    ), [paymentDates]);

    const extendLoadPayDate = useMemo(() => {
        const payDate = new Date()
        payDate.setDate(payDate.getDate() + PAYBACK_TIME);
        return formatDateFormal(payDate)
    }, []);

    const loanInfo = useMemo(
        () => [
            {
                index: ["0", "1"],
                title: "Interest Amount Due",
                value: `${addApprox(interestAmountDisplay)} ${paymentToken}`,
            },
            {
                index: ["0"],
                title: "Principal Amount Due",
                value:
                    `${produceAccurateValue(
                        minimumPayValue,
                        decimals
                    )
                    } ${paymentToken}`,
            },
            {
                index: ["1"],
                title: "Remaining Principal",
                value:
                    `${produceAccurateValue(
                        principalTokens,
                        decimals
                    )
                    } ${paymentToken}`,
            },
            {
                index: ["0", "1"],
                title: "Payment Deadline",
                value: `${payDate}`,
            },
            /*{
                title: "Extended Due Date",
                value: `October 22, 2022`,
            },*/
            /*{
                title: "Loan interest",
                value: `${borrowingFee}%`,
            },*/
        ], [interestAmountDisplay, paymentToken, minimumPayValue, decimals, principalTokens, payDate]
    );

    return (
        <div className={cn(className, styles.sale)}>
            <div style={{ display: transactionHash ? "none" : "block" }}>
                <div className={styles.main}>
                    This loan for <span className={styles.white}>{collectionDisplayNames.singleName} #{tokenId.toString()}</span>{" "}
                    originally created on {formatDateFormal(paymentDates.creationDate)}
                    {" "} borrowing {" "}
                    <span className={styles.pink}>{produceAccurateValue(borrowedAmount, decimals)}{" "}{paymentToken}{" "}</span>
                    as total
                </div>
                <div className={styles.main}>
                    You can extend this loan by paying due amount only or finalize loan by paying full amount back
                </div>
                <div className={styles.row} style={{marginTop: "8px"}}>
                    { Object.keys(PERCENT_OPTIONS).map((key) => (
                        <button
                            key={key}
                            style={{width: '49%'}}
                            className={cn(
                                'button-stroke',
                                styles.option,
                                optionSelected === key ? 'button-active': ''
                            )}
                            onClick={() => {
                                setOptionSelected(key)
                            }}
                        >
                            { PERCENT_OPTIONS[key] }
                        </button>
                    ))}
                </div>
                <div style={{ marginBottom: "16px" }}>
                    {loanInfo.map((x, index) => {
                        if (x.index.includes(optionSelected)) {
                        return (
                            <div className={styles.row} key={index}>
                                <div className={styles.col}>{x.title}</div>
                                <div className={styles.col}>{x.value}</div>
                            </div>
                        )} else {
                            return (
                                <></>
                            )
                        }}
                    )}
                </div>
                <div className={styles.main} style={{marginBottom: "0px"}}>
                    {
                        optionSelected === "0"
                            ? <>
                                By proceeding you'll pay{" "}
                                <span className={styles.pink}>{addApprox(extendLoanDisplay)} {paymentToken}</span>
                                {" "}as due amount, extending this loan till{" "}
                                <span className={styles.white}>{extendLoadPayDate}</span>
                            </>
                            : <>
                                By proceeding you'll pay{" "}
                                <span className={styles.pink}>{payAmountDisplay} {paymentToken}</span>
                                {" "}to the pool, finalizing this loan and receiving NFT back
                            </>
                    }
                </div>
            </div>
            <div className={styles.steps}>
                <PaybackInstantSteps
                    poolAddress={poolAddress}
                    extendLoanValue={extendLoanValue}
                    endLoanValue={endLoanValue}
                    paymentToken={paymentToken}
                    title={title}
                    transactionHash={transactionHash}
                    setTransactionHash={setTransactionHash}
                    closeModal={closeModal}
                    tokenId={tokenId}
                    nftAddress={nftAddress}
                    merkleProof={merkleProof}
                    merklePrice={merklePrice}
                    merkleIndex={merkleIndex}
                    optionSelected={optionSelected}
                    paymentDates={paymentDates}
                    extendLoadPayDate={extendLoadPayDate}
                    amountDisplay={payAmountDisplay}
                    cardIndex={cardIndex}
                />
            </div>
        </div>
    );
};

export default PaybackInstant;
