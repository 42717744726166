import React, { useState, useEffect, useMemo } from "react";
import { useParams, useHistory } from "react-router";
import { useSelector } from "react-redux";
import { useEthers } from "@usedapp/core";
import cn from "classnames";

import styles from "../LoanDetails.module.sass";
import Dropdown from "../../../components/Dropdown";
import Icon from "../../../components/Icon";
import NumberInput from "../../../components/NumberInput";
// import Switch from "../../../components/Switch";
// import Loader from "../../../components/Loader";
// import Choose from "../../../components/Choose";
import Modal from "../../../components/Modal";
import Preview from "../Preview";
// import Cards from "../Cards";
import FollowSteps from "../FollowSteps";
// import BuyCrypto from "../BuyCrypto";
import useTokenFetch from "../../../customHooks/useTokenFetch";
import useValidateCreateLockerInput from "../../../customHooks/useValidateCreateLockerInput";
import CollectionSelector from "../CollectionSelector";
import TokenSelector from "../TokenSelector";
import { BORROW_TIMES, BORROW_RATE_OPTION, ONE_HUNDRED } from "../../../utils";
import { getCollectionData } from "../../../utils/query/tokens";
import { getMainNetAddress } from "../../../ethers/nftCollections";

const MAX_BUNDLE = 5; // @TODO: move to config
const COVERAGE_PERCENT = 50;

const paymentTokenOptions = ["WISE", "WETH", "USDC"];

export const InstantLoan = () => {
    const { account } = useEthers();
    const { tokens } = useTokenFetch();
    const { collection } = useParams();
    const history = useHistory();
    const conversionRates = useSelector((state) => state.conversions);
    const showPreview = useSelector((state) => state.settings.hiddenOptions.showPreview);

    const [borrowingRates, setBorrowingRates] = useState(
        BORROW_RATE_OPTION[paymentTokenOptions[0]]["14 Days"]
    );
    const [borrowingRate, setBorrowingRate] = useState(borrowingRates[0]);
    const [paymentToken, setPaymentToken] = useState(paymentTokenOptions[0]);
    const [minimumAmount, setMinimumAmount] = useState("1000");
    const [maximumAmount, setMaximumAmount] = useState("2000");
    const [borrowingTime, setBorrowingTime] = useState(BORROW_TIMES[0]);
    const amountRange = false;
    //const [amountRange, setAmountRange] = useState(false);
    const [visibleModal, setVisibleModal] = useState(false);
    const [visiblePreview, setVisiblePreview] = useState(false);
    const [selectedCollection, setSelectedCollection] = useState(collection);
    const [selectedTokenIds, setSelectedTokenIds] = useState([]);
    const [confirmedTokenIds, setConfirmedTokenIds] = useState([]);
    const [collectionFloorPrice, setCollectionFloorPrice] = useState();

    const input = {
        selectedTokenIds,
        selectedCollection,
        paymentToken,
        minimumAmount,
        maximumAmount,
        amountRange,
        borrowingRate,
        borrowingTime,
    };

    const {
        valid,
        //errors
    } = useValidateCreateLockerInput(input);

    const handleSelectToken = (id) => {
        setSelectedTokenIds((prev) => {
            const res = selectedTokenIds.includes(id)
                ? prev.filter((x) => x !== id)
                : [...prev, id];
            if (res.length > MAX_BUNDLE) return prev;
            return res.length === 0 ? prev : res;
        });
    };

    const handleConfirmedLoan = () => {
        setConfirmedTokenIds(selectedTokenIds);
        setSelectedTokenIds([]);
    };

    // const resetCollectionSelection = () => {
    // setSelectedTokenIds([]);
    // setSelectedCollection(null);
    // };

    // const columnHalfOrThird = cn(styles.col, amountRange
    // ? styles.oneThird
    // : styles.oneHalf
    // );

    const openTransactionModal = () => {
        if (!valid) return;
        setVisibleModal(true);
    };

    // const handleMinimum = ({ target }) => setMinimumAmount(target.value);
    // const integersOnly = (event) => event.charCode <= 47 && event.preventDefault();
    // const handleMaximum = ({ target }) => setMaximumAmount(target.value);
    // const handleBorrowingTime = ({ target }) => setBorrowingTime(target.value);
    // const handleBorrowingRate = ({ target }) => setBorrowingRate(target.value);

    const handleMinimumAndMaximum = (value) => {
        if (!value) {
            setMaximumAmount("");
            setMinimumAmount("");
            return;
        }
        const absValue = Math.abs(value);
        setMinimumAmount((absValue * COVERAGE_PERCENT) / 100);
        setMaximumAmount(absValue);
    };

    const [filteredTokens, setFilteredTokens] = useState([]);
    const [whenSelected, setWhenSelected] = useState(null);

    useEffect(() => {
        if (selectedCollection) {
            const byCollection = tokens.filter((token) => token.address === selectedCollection);

            const byOwner = byCollection.filter((token) => token.owner === account);

            setFilteredTokens(byOwner);
        }
    }, [tokens, selectedCollection, account]);

    const tokenOrTokens = filteredTokens?.length === 1 ? "token" : "tokens";

    useEffect(() => {
        if (filteredTokens[0]) {
            const tokenIndex = filteredTokens[0].id || filteredTokens[0].data.punkIndex;

            if (tokenIndex !== whenSelected) {
                setSelectedTokenIds([tokenIndex]);

                setWhenSelected(tokenIndex);
            }
        }
    }, [selectedCollection, filteredTokens, whenSelected]);

    useEffect(() => {
        const borrowRates = BORROW_RATE_OPTION[paymentToken][borrowingTime];
        setBorrowingRates(borrowRates);
        setBorrowingRate(borrowRates[0]);
    }, [paymentToken, borrowingTime]);

    useEffect(() => {
        setSelectedTokenIds([]);
        // setSelectedCollection(null);
        setWhenSelected(null);
    }, [account]);

    useEffect(() => {
        const defaultCollection =
            tokens?.find(
                (token) => token.address?.toLowerCase().trim() === collection?.toLowerCase().trim()
            ) || tokens[0];

        if (defaultCollection) {
            setSelectedCollection(defaultCollection?.address);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tokens]);

    useEffect(() => {
        let mounted = true;
        const fetchFloorPrice = async () => {
            const collectionAddrMain = getMainNetAddress(selectedCollection);
            const collectionData = await getCollectionData(collectionAddrMain);
            const fetchedFloorPrice =
                collectionData && collectionData.floorPrice ? collectionData.floorPrice : "--";
            if (mounted) setCollectionFloorPrice(fetchedFloorPrice);
        };
        if (selectedCollection) fetchFloorPrice();
        return () => {
            mounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCollection]);

    const handleSelectedCollection = (address) => {
        setSelectedCollection(address);
        history.push(`/loan-details/${address}`);
    };

    const floorPricePercentage = useMemo(() => {
        // convert collection floor and borrowing amount to usd for calc
        const floorPriceUSD = collectionFloorPrice * conversionRates["WETH"];
        const borrowingAmountUSD = maximumAmount * conversionRates[paymentToken];
        if (floorPriceUSD && borrowingAmountUSD) {
            const borrowingPercentOfFloor = (borrowingAmountUSD / floorPriceUSD) * ONE_HUNDRED;
            return parseInt(borrowingPercentOfFloor) - ONE_HUNDRED;
        }
        return null;
    }, [collectionFloorPrice, maximumAmount, conversionRates, paymentToken]);

    const floorPriceMessage = useMemo(() => {
        if (!floorPricePercentage) return null;
        // if requested is over 100% display difference
        return floorPricePercentage > 0
            ? `You are requesting ${floorPricePercentage}% more than the floor price of this collection`
            : null;
    }, [floorPricePercentage]);

    return (
        <>
            {/*<BuyCrypto />*/}
            <div style={{ paddingTop: "32px" }} className={cn("section", styles.section)}>
                <div className={styles.head}>
                    <div
                        style={{ justifyContent: "space-between" }}
                        className={cn("container", styles.container)}
                    >
                        <div className={styles.top}>
                            <div>
                                <div className={cn("h2", styles.title)}>New Instant Loan</div>
                                <div style={{ marginBottom: "5px" }} className={styles.slogan}>
                                    Borrow funds using NFTs as a collateral
                                </div>
                            </div>
                            <div className={styles.info}>
                                <CollectionSelector
                                    tokens={tokens}
                                    selectedCollection={selectedCollection}
                                    onSelect={handleSelectedCollection}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={cn("container", styles.container)}>
                    <div className={cn({ [styles.fullWidth]: !showPreview }, styles.wrapper)}>
                        {/*<div className={styles.option}>
                            <div className={styles.box}>
                            </div>
                            <div className={styles.category}>
                                Bundle Select
                            </div>
                            <Switch value={amountRange} setValue={setAmountRange} />
                        </div>*/}
                        <div className={styles.form} action="">
                            <div className={styles.list}>
                                <div className={styles.item}>
                                    <div>
                                        <div className={styles.format}>
                                            <div style={{ marginBottom: "10px" }}>
                                                <div
                                                    className={styles.text}
                                                    style={{ marginBottom: "20px" }}
                                                >
                                                    Selected {selectedTokenIds?.length || 0} out of{" "}
                                                    {filteredTokens?.length || 0} {tokenOrTokens}
                                                </div>
                                                <TokenSelector
                                                    account={account}
                                                    tokens={tokens}
                                                    filteredTokens={filteredTokens}
                                                    selectedCollection={selectedCollection}
                                                    onSelectToken={handleSelectToken}
                                                    selectedTokenIds={selectedTokenIds}
                                                    confirmedTokenIds={confirmedTokenIds}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.item}>
                                    <div className={styles.fieldset}>
                                        <div className={styles.row}>
                                            <div className={cn(styles.col, styles.oneHalf)}>
                                                <div className={styles.field}>
                                                    <div className={styles.label}>
                                                        Select Currency
                                                    </div>
                                                    <Dropdown
                                                        className={styles.dropdown}
                                                        value={paymentToken}
                                                        setValue={setPaymentToken}
                                                        options={paymentTokenOptions}
                                                    />
                                                </div>
                                            </div>
                                            <div className={cn(styles.col, styles.oneHalf)}>
                                                <NumberInput
                                                    className={styles.field}
                                                    label={`${
                                                        amountRange ? "Minimum" : "Borrowing"
                                                    } Amount`}
                                                    name={`${
                                                        amountRange ? "Minimum" : "Borrowing"
                                                    } Amount`}
                                                    onChange={handleMinimumAndMaximum}
                                                    value={maximumAmount}
                                                    placeholder={`Amount in ${paymentToken}`}
                                                    required
                                                />
                                                {/* <div className={styles.field}>
                                                    <div className={styles.label}>
                                                        Borrowing Rate
                                                    </div>
                                                    <Dropdown
                                                        className={styles.dropdown}
                                                        value={borrowingRate}
                                                        setValue={setBorrowingRate}
                                                        options={borrowingRateOptions}
                                                    />
                                                </div> */}
                                            </div>
                                        </div>
                                        <div className={styles.row}>
                                            <div className={cn(styles.col, styles.oneHalf)}>
                                                <div className={styles.box}>
                                                    <div className={styles.category}>
                                                        Total Loan Duration
                                                    </div>
                                                    <div className={styles.text}>
                                                        {/*This indicates the time frame for the borrowed amount to be repaid with interest*/}
                                                        You obligations are interest-only payments
                                                        for the duration of the loan and principal
                                                        at the end
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={cn(styles.col, styles.oneHalf)}>
                                                <div className={styles.field}>
                                                    <div className={styles.label}>
                                                        Select Total Duration
                                                    </div>
                                                    <Dropdown
                                                        className={styles.dropdown}
                                                        value={borrowingTime}
                                                        setValue={setBorrowingTime}
                                                        options={BORROW_TIMES}
                                                    />
                                                </div>
                                                {/* <div className={styles.field}>
                                                    <div className={styles.label}>
                                                        Borrowing Rate
                                                    </div>
                                                    <Dropdown
                                                        className={styles.dropdown}
                                                        value={borrowingRate}
                                                        setValue={setBorrowingRate}
                                                        options={borrowingRateOptions}
                                                    />
                                                </div> */}
                                            </div>
                                        </div>
                                        <div className={styles.row}>
                                            <div className={cn(styles.col, styles.oneHalf)}>
                                                <div className={styles.box}>
                                                    <div className={styles.category}>
                                                        Total Interest Percent
                                                    </div>
                                                    <div className={styles.text}>
                                                        Offering higher value will motivate
                                                        contributors to fund your loan more quickly
                                                        than other loans
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={cn(styles.col, styles.oneHalf)}>
                                                <div className={styles.field}>
                                                    <div className={styles.label}>
                                                        Select Interest Percent
                                                    </div>
                                                    <Dropdown
                                                        className={styles.dropdown}
                                                        value={borrowingRate}
                                                        setValue={setBorrowingRate}
                                                        options={borrowingRates}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className={styles.row}>
                                            <div className={cn(styles.col)}>
                                                LiquidNFTs charges a fee in order to reward stakers on this platform. The fee equals 10% of the TIP if WISE is used as currency, or 20% on all other currencies. (Token sale TBD)
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                            <div className={styles.options}>
                                {/*<div className={styles.option}>
                                    <div className={styles.box}>
                                        <div className={styles.category}>
                                            Borrowing Amount Range
                                        </div>
                                        <div className={styles.text}>
                                            Allows to specify smaller amount required for the loan
                                            to happen
                                        </div>
                                    </div>
                                    <Switch value={amountRange} setValue={setAmountRange} />
                                </div>*/}
                                {/*<div className={styles.category}>
                                    Floor Asked
                                </div>
                                <div className={styles.text}>
                                    Choose an exiting collection or create a new one
                                </div>
                                <Cards className={styles.cards} items={items} />*/}
                            </div>
                            <div className={styles.foot}>
                                <button
                                    className={cn("button-stroke tablet-show", styles.button)}
                                    onClick={() => setVisiblePreview(true)}
                                    type="button"
                                >
                                    Show Preview
                                </button>
                                <button
                                    disabled={valid === false}
                                    className={cn("button", styles.button, valid ? "" : "disabled")}
                                    onClick={openTransactionModal}
                                    type="button"
                                >
                                    <span>Create New Loan</span>
                                    <Icon name="arrow-next" size="16" />
                                </button>
                                <span className={styles.text}>{floorPriceMessage}</span>
                                {/*<div className={styles.saving}>
                                    <span>Auto saving</span>
                                    <Loader className={styles.loader} />
                                </div>*/}
                            </div>
                        </div>
                    </div>
                    <Preview
                        showPreview={showPreview}
                        visiblePreview={visiblePreview}
                        className={cn(styles.preview, { [styles.active]: visiblePreview })}
                        onClose={() => setVisiblePreview(false)}
                        loanData={input}
                        tokens={tokens}
                    />
                </div>
            </div>
            <Modal visible={visibleModal} onClose={() => setVisibleModal(false)}>
                <FollowSteps
                    className={styles.steps}
                    input={input}
                    handleConfirmedLoan={handleConfirmedLoan}
                    floorPricePercentage={floorPricePercentage}
                />
            </Modal>
        </>
    );
};
