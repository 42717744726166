import React, { useEffect, useState } from "react";
import cn from "classnames";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import toast, { Toaster } from "react-hot-toast";
import Icon from "../Icon";
import TextInput from "../TextInput";

// -- Possible to move to CONFIG --
const ID = "5cf9b29989";
const MAILCHIMP_API = "https://liquidnfts.us5.list-manage.com/subscribe/";
const USER = "61e26c9aac8f35828dccb5970";
const url = `${MAILCHIMP_API}post?u=${USER}&amp;id=${ID}`;

const MailChimp = ({ styles, toast }) => {
    const [email, setEmail] = useState("");

    return (
        <MailchimpSubscribe
            url={url}
            render={({ subscribe, status, message }) => (
                <div>
                    <TextInput
                        className={styles.field}
                        label="Email Address"
                        name="Email"
                        placeholder="Please fill out this field"
                        required="required"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <div className={styles.btns}>
                        <button
                            onClick={() => subscribe({ EMAIL: email, group: "new-features" })}
                            className={cn("button", styles.button)}
                        >
                            Subscribe Now
                        </button>
                    </div>
                    {status === "error" && (
                        <Toast styles={styles} content="">
                            <div
                                className={styles.error}
                                dangerouslySetInnerHTML={{ __html: message }}
                            />
                        </Toast>
                    )}
                    {status === "success" && (
                        <Toast styles={styles} content="Successfully subscribed!" />
                    )}
                    {status === "sending" && <Toast styles={styles} content="subscribing..." />}
                    <div>
                        <Toaster />
                    </div>
                </div>
            )}
        />
    );
};

const Toast = ({ content, styles, children }) => {
    useEffect(() => {
        toast.dismiss();
        toast.loading(
            (t) => (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <span style={{ marginLeft: "-2px" }}>{content}</span>
                    {children}
                    <div className={styles.reset} onClick={() => toast.dismiss(t.id)}>
                        <Icon name="close-circle-fill" size="24" />
                    </div>
                </div>
            ),
            {
                duration: 7000,
                position: "right-bottom",
                style: {
                    marginTop: "70px",
                    borderRadius: "10px",
                    background: "#333",
                    color: "#fff",
                },
                className: "",
                icon: "🔌",
                iconTheme: {
                    primary: "#000",
                    secondary: "#fff",
                },
                ariaProps: {
                    role: "status",
                    "aria-live": "polite",
                },
            }
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return <></>;
};

export default MailChimp;
