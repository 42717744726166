import { useState, useEffect } from "react";
// import { MINIMUM_THRESHOLD } from "../utils";

const useValidateCreateLockerInput = (input) => {

    const {
        selectedTokenIds,
        selectedCollection,
        paymentToken,
        minimumAmount,
        maximumAmount,
        amountRange,
        borrowingRate,
        borrowingTime,
    } = input;

    const [valid, setValid] = useState(false);
    const [errors, setErrors] = useState([]);

    useEffect(() => {

        let isValid = true;
        let errorMessages = [];

        if (selectedTokenIds.length < 1) {
            isValid = false;
            errorMessages.push(
                "Must select at least one NFT"
            );
        }

        if (!selectedCollection) {
            isValid = false;
            errorMessages.push(
                "Must select a token collection"
            );
        }

        if (minimumAmount === "") {
            isValid = false;
            errorMessages.push(
                `${amountRange ? "Minimum" : "Borrowing"} amount required`
            );
        }

        if (amountRange && Number(minimumAmount) > Number(maximumAmount)) {
            isValid = false;
            errorMessages.push(
                "Maximum cannot be less than minimum"
            );
        }

        if (Number(minimumAmount) < 0.1 || Number(maximumAmount) < 0.1) {
            isValid = false;
            errorMessages.push(
                "Amounts cannot be less than minimum"
            );
        }
        if (borrowingTime === "") {
            isValid = false;
            errorMessages.push(
                "Borrowing time is required"
            );
        }

        setValid(isValid);
        setErrors(errorMessages);

    }, [
        selectedTokenIds,
        selectedCollection,
        paymentToken,
        minimumAmount,
        maximumAmount,
        amountRange,
        borrowingRate,
        borrowingTime,
    ]);
    return { valid, errors };
};

export default useValidateCreateLockerInput;
