import axios from "axios";
import { ethers, BigNumber } from 'ethers';
import { formatUnits } from "ethers/lib/utils";
import { isPunk, getMainNetAddress } from "../../ethers/nftCollections";
import { determineStatus } from "../index";
import { ZERO_ADDRESS } from "../index";
import LIQUID_LOCKER_ABI from "../../ethers/abis/LiquidLocker.json";

import { TEST_NETWORK_ID } from "../../utils";
import { getCollectionData } from "./tokens";
import {
    DEFAULT_COLLECTION_NAME,
    LISTING_DETAILS_METHODS,
    DEFAULT_DECIMALS,
    SECONDS_IN_YEAR
} from "../index";
import { OPENSEA_API, OPENSEA_API_KEY } from "../config";


const getData = async (getTokens, tokenAddress, collectionAddress) => {

    const tokenIds = getTokens
        ? [...getTokens]
        : [];

    const size = 30;
    const chunks = [];

    while (tokenIds.length) {
        chunks.push(
            tokenIds.splice(
                0,
                size
            )
        );
    }

    const chunkResponses = await Promise.all(
        chunks.map(async (chunk) => {
            const tokenIds = chunk.map((id) => `token_ids=${id.toNumber()}`).join("&");
            const opensea = `${OPENSEA_API}/api/v1/assets?${tokenIds}&asset_contract_address=${collectionAddress}&order_direction=desc`;
            const res = await axios.get(opensea, {
                crossdomain: true,
                headers: {
                    "X-API-KEY": OPENSEA_API_KEY,
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
                }
            });
            const { assets } = res.data;
            const dataChunk = assets.map((a) => ({
                address: tokenAddress,
                id: BigNumber.from(a.token_id),
                data: { image: a.image_url },
            }));
            return dataChunk;
        })
    );

    return chunkResponses.flat();
};

export const getListingDetails = async (
    lockerAddress,
    library,
    getCollectionNameByAddress,
    tokenAddressToNameMapping,
    originalOwner,
    chainId
) => {

    try {

        const lockerContract = await new ethers.Contract(
            lockerAddress,
            LIQUID_LOCKER_ABI,
            library // @NOTE -> since this is just regular .call() we don't need signer here (library as provider is enough )
        );

        // Query locker methods and get promises
        let lockerDetails = LISTING_DETAILS_METHODS.map(async (method) => {
            const res = await lockerContract[method]();
            return res;
        });

        lockerDetails = await Promise.all(
            lockerDetails
        );

        const [
            floorAsked,
            totalAsked,
            getTokens,
            globals,
            paymentToken,
            totalCollected,
            remainingBalance,
            floorNotReached,
            creationTime,
            contributionPhase,
            nextDueTime,
            singleProvider,
            belowFloorAsked,
        ] = lockerDetails;

        const {
            paymentRate,
            paymentTime,
            tokenAddress,
            lockerOwner,
        } = globals || {};

        const tokenIsPunk = isPunk(
            tokenAddress
        );

        const collectionAddrMain = getMainNetAddress(
            tokenAddress
        );

        const collectionAddress = chainId === TEST_NETWORK_ID && tokenAddress
            ? collectionAddrMain
            : tokenAddress;

        let tokensWithData;

        // Get token data logic split for punk or not
        if (!tokenIsPunk) {
            tokensWithData = await getData(
                getTokens,
                tokenAddress,
                collectionAddress
            );
        }

        const punkTokens = getTokens
            ? getTokens.map((token) => ({
                    src: token,
                    data: { punk: true, punkIndex: token },
                    address: tokenAddress,
                }))
            : [];

        const tokens = tokenIsPunk
            ? punkTokens
            : tokensWithData;

        const collectionNames = getCollectionNameByAddress(tokenAddress) || DEFAULT_COLLECTION_NAME;
        const collectionName = collectionNames?.name;

        const paymentTokenName = paymentToken
            ? tokenAddressToNameMapping[paymentToken]
            : "";

        const isBundle = getTokens?.length > 1;
        const length = getTokens?.length ? getTokens?.length : 0;
        const title = getTokens?.length === 1
            ? `${collectionNames?.singleName} #${getTokens[0]}`
            : `${length} ${collectionName?.replace("The ", "")}`;

        const percentFloorAskedCollected = (totalCollected && totalCollected.gt(0)
            ? totalCollected.mul(100).div(floorAsked)
            : BigNumber.from(0)
        ).toNumber();

        const loanStatus = determineStatus({
            contributionPhase,
            floorNotReached,
            creationTime,
            nextDueTime,
            remainingBalance,
            lockerOwner,
            belowFloorAsked,
        });

        const ownersAddress = lockerOwner === ZERO_ADDRESS
            ? originalOwner
            : lockerOwner;

        const borrowingRate = formatUnits(
                paymentRate.toString(),
                DEFAULT_DECIMALS
            );

        const borrowingAPY = parseInt(borrowingRate)
            * SECONDS_IN_YEAR
            / paymentTime;

        const collectionData = await getCollectionData(collectionAddrMain);
        const floorPrice = collectionData && collectionData.floorPrice
            ? collectionData.floorPrice
            : "--";

        const listingData = {
            tokens,
            paymentRate,
            paymentTime,
            tokenAddress,
            lockerOwner,
            floorAsked,
            totalAsked,
            paymentToken,
            totalCollected,
            remainingBalance,
            floorNotReached,
            creationTime,
            contributionPhase,
            collectionName,
            paymentTokenName,
            nextDueTime,
            title,
            isBundle,
            percentFloorAskedCollected,
            ownersAddress,
            loanStatus,
            lockerAddress,
            singleProvider,
            belowFloorAsked,
            borrowingAPY,
            floorPrice
        };

        return listingData;

    } catch (err) {
        console.log(err);
    }
};

export const getLockerMethods = async (lockerMethods, lockerAddress, library) => {
    const lockerContract = await new ethers.Contract(
        lockerAddress,
        LIQUID_LOCKER_ABI,
        library
    );
    let lockerDetails = lockerMethods.map(async (method) => {
        const res = await lockerContract[method]();
        return res;
    });

    lockerDetails = await Promise.all(
        lockerDetails
    );
    return lockerDetails;
}
