import { useState, useEffect } from "react";
import { useEthers } from "@usedapp/core";
import { Contract } from "ethers";
import { Interface } from "ethers/lib/utils";
import useConfig from "./useConfig";
import factory from "../ethers/abis/LiquidFactory.json";

const useUserLockers = (optionalAddress, sort, filter) => {

    const {
        library,
        account,
        chainId
    } = useEthers();

    const config = useConfig();
    const user = optionalAddress || account;

    const [existingLockers, setExistingLockers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const parseLog = (log, contractInterface) => ({
        blockNumber: log.blockNumber,
        ...contractInterface.parseLog(log),
    });

    useEffect(() => {

        let mounted = true;
        if (!library) return;
        setLoading(true);

        const factoryInterface = new Interface(
            factory
        );

        const factoryContract = new Contract(
            config.liquidNFTContract,
            factory
        );

        const getFilteredLogs = (filter) => {
            return library.getLogs({
                ...filter,
                fromBlock: config.inceptionBlock,
                toBlock: "latest",
            });
        }

        const fetchNewLockerEvents = async () => {

            const newLockerFilter = factoryContract.filters.NewLocker(
                null,
                user
            );

            const newLockerEvents = await getFilteredLogs(
                newLockerFilter
            );

            const factoryLogParser = (e) => parseLog(
                e,
                factoryInterface
            );

            const parsedEvents = newLockerEvents?.map(
                factoryLogParser
            );

            const argFlattener = ({ blockNumber, args }) => ({
                ...args,
                blockNumber
            });

            const lockerObjects = parsedEvents?.map(
                argFlattener
            );

            if (lockerObjects && mounted) setExistingLockers(
                lockerObjects
            );
        };

        try {
            fetchNewLockerEvents();
        } catch (err) {
            setError(err);
        }

        setLoading(false);
        return () => (mounted = false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [library, user, chainId]);

    const sortFunction = sort || ((a, b) => b.blockNumber - a.blockNumber);
    const filterFunction = filter || ((locker) => locker.blockNumber > config.inceptionBlock);
    const lockers = existingLockers.sort(sortFunction).filter(filterFunction);
    const lockerAddresses = lockers.map((l) => l.lockerAddress);

    return [lockerAddresses, lockers, loading, error];
};

export default useUserLockers;
