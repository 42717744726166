// import cryptoPunksImage from "../../assets/images/cryptopunks.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/opacity.css";

const PunkImage = ({ token: { data, alt }, size, style, ...otherprops }) => {
    // const [mounted, setMounted] = useState(false);
    // useEffect(() => {
    // setTimeout(() => setMounted(true), 0);
    // }, [setMounted]);

    const punkIndex = data.punkIndex.toNumber();

    // const scale = size / 24;
    // const yOffset = Math.floor(punkIndex / 100) * 24;
    // const xOffset = (punkIndex % 100) * 24;
    // const dimensions = 24 * scale;
    // const adjustment = 50 / scale;

    const paddedIndex = punkIndex.toString().padStart(4, "0");

    return (
        <LazyLoadImage
            // alt={token.alt}
            height={size}
            src={`https://www.larvalabs.com/public/images/cryptopunks/punk${paddedIndex}.png`}
            width={size}
            effect="opacity"
            delayTime="500"
            style={{ ...style, background: "rgb(96, 129, 145)" }}
            // useIntersectionObserver={useIntersection}
            wrapperProps={{
                style: {
                    imageRendering: "pixelated",
                    overflow: "hidden",
                },
            }}
            {...otherprops}
        />
    );
};

/*

<div style={{
    opacity: mounted ? "1" : "0",
    transition: "opacity .3s",
    width: `${dimensions}px`,
    height: `${dimensions}px`,
    background: "#638596",
    backgroundSize: "cover",
    ...style,
    }}
>
    <span
        role="img"
        aria-label={alt}
        style={{
            marginLeft: "50%",
            marginTop: "50%",
            imageRendering: "pixelated",
            width: "24px",
            height: "24px",
            display: "inline-block",
            transformOrigin: "center center",
            background: `url(${cryptoPunksImage}) ${2400 - xOffset}px ${2400 - yOffset}px`,
            transform: `scale(${scale}) translateY(-${adjustment}%) translateX(-${adjustment}%)`,
        }}
    />
</div>

*/

export default PunkImage;
