import React, { useMemo, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import cn from "classnames";
// import Slider from "react-slick";
// import { Link } from "react-router-dom";
import styles from "./Hero.module.sass";
import Icon from "../../../components/Icon";
import { getPoolContract, getRouterContract } from "../../../utils/query/interfaces";
import { useParams } from "react-router-dom";
import { useEthers, shortenIfAddress } from "@usedapp/core";
import { getTokenAddressFromName } from "../../../ethers/nftCollections";
import usePoolBalance from "../../../customHooks/usePoolBalance";
import useUserLockers from "../../../customHooks/useUserLockers";
import useConfig from "../../../customHooks/useConfig";
import InstantList, { InstantListView } from "../../../components/InstantList";
import Modal from "../../../components/Modal";
import Invest from "../../../components/Invest";
import Borrow from "../../../components/Borrow";
// import useTokenFetch from "../../../customHooks/useTokenFetch";
import useCallsGracefully from "../../../customHooks/useCallsGracefully";
import useMediaQuery from "../../../customHooks/useMediaQuery";
import { processCollections } from "../../../utils/query/tokens";

import {
    getTimeAgo,
    produceAccurateValue,
    FETCHING_DATA_MESSAGE,
} from "../../../utils";

import Item from "../Item";
import styles2 from "../Item/Hero.module.sass";
import useIpfsHashEvents from "../../../customHooks/useIpfsHashEvents";
// import useGetOwnerNFTs from "../../../customHooks/useGetOwnerNFTs";
import useTokenFetch from "../../../customHooks/useTokenFetch";
import { appLocalStorage } from "../../../utils/localstorage";
import { useDispatch, useSelector } from "react-redux";
import { setPoolPageOptions } from "../../../redux/settingsSlice";

const UTILISATION_RATE_PRECISION = 16;

/*const SlickArrow = ({ currentSlide, slideCount, children, ...props }) => (
    <button {...props}>{children}</button>
);*/

const TABLE_VIEW_MODE = {
    GRID: "GRID",
    LIST: "LIST"
}

const Hero = ({ pool, poolAddress }) => {

    const poolPageOptions = useSelector((state) => state.settings.poolPageOptions);

    const [tableViewMode, setTableViewMode] = useState(TABLE_VIEW_MODE.GRID);

    const dispatch = useDispatch();

    useEffect(() => {
        if (poolPageOptions?.listViewType) {
            setTableViewMode(poolPageOptions.listViewType);
        }
    }, [poolPageOptions]);
    /*
    const settings = {
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        arrows: false,
    };*/

    const {
        chainId,
        library,
        account
    } = useEthers();

    const config = useConfig();
    const { address } = useParams();
    const history = useHistory();

    const [supportedCollections, setSupportedCollections] = useState([]);

    // check for address and not account b/c if no address param then it is user's profile
    const hasParamAndNotUser = address && address !== account;

    const routerContract = getRouterContract(
        config.routerAddress,
        library
    );

    const poolContract = getPoolContract(
        poolAddress
    );

    const { nftUpdateRootLogs } = useIpfsHashEvents();

    useEffect(() => {
        let mounted = true;
        const collectionDetails = pool?.supportedCollections?.map(
            async (collection) => {

                let ipfsHash;
                let merkleRoot;
                let pendingIpfsHash;
                let pendingMerkleRoot;

                const collectionAddress = getTokenAddressFromName(
                    chainId,
                    [collection]
                )?.[0];

                if (collectionAddress) {
                    ipfsHash = await routerContract.merkleIPFS(
                        collectionAddress
                    );

                    merkleRoot = await routerContract.merkleRoot(
                        collectionAddress
                    );

                    const pendingRoot = await routerContract.pendingRoots(
                        collectionAddress
                    );

                    pendingIpfsHash = pendingRoot?.ipfsAddress;
                    pendingMerkleRoot = pendingRoot?.merkleRoot;
                }

                return {
                    collection,
                    address: collectionAddress,
                    ipfsHash,
                    merkleRoot,
                    pendingIpfsHash,
                    pendingMerkleRoot
                };
            }
        );

        if (collectionDetails?.length) {
            Promise.all(collectionDetails).then(result => {
                mounted && setSupportedCollections(result);
            });
        }
        return () => {
            mounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pool, chainId]);

    const items = useMemo(() => {
        return [
            {
                title: "General",
                icon: "home",
                items: supportedCollections?.map(supportedPool => ({
                    title: supportedPool?.collection,
                    ipfsHash: supportedPool?.ipfsHash,
                    merkleRoot: supportedPool?.merkleRoot,
                    upcomingHash: false,
                    tokenName: pool?.currency,
                    collectionAddress: supportedPool?.address
                })),
            },
        ];
    }, [supportedCollections, pool]);

    const options = [];

    items.map((x) => options.push(
        x.title
    ));

    const [direction, setDirection] = useState(
        options[0]
    );

    const [usersLockers] = useUserLockers(
        hasParamAndNotUser && address
    );

    const [isLendModalVisible, setIsLendModalVisible] = useState(false);
    const [isBorrowModalVisible, setIsBorrowModalVisible] = useState(false);
    const decimals = config.decimals[pool?.currency];

    const poolTokenBalance = usePoolBalance(
        poolAddress
    );

    const pseudoTotalTokensHeldCall = {
        contract: poolContract,
        method: "pseudoTotalTokensHeld",
        args: [],
    };

    const timeStampLastInteractionCall = {
        contract: poolContract,
        method: "timeStampLastInteraction",
        args: [],
    };

    const badDebtCall = {
        contract: poolContract,
        method: 'badDebt',
        args: []
    };

    const [pseudoTotalTokensHeld, timeStampLastInteraction, badDebt] = useCallsGracefully(
        poolAddress && poolContract ? [
            pseudoTotalTokensHeldCall,
            timeStampLastInteractionCall,
            badDebtCall
        ] : []
    );

    // const { tokens } = useTokenFetch( // old way
    // const { tokens } = useGetOwnerNFTs(
    //     hasParamAndNotUser && address
    // );

    const { tokens, refetch: refetchCollectionsData, loading: isCollectionsLoading } = useTokenFetch(
        hasParamAndNotUser && address
    );

    const collectionsData = processCollections(
        tokens,
        config,
        hasParamAndNotUser
    );

    const lendedFunds = useMemo(
        () => {
            if (pseudoTotalTokensHeld && poolTokenBalance && decimals) {
                const tokenDebt = pseudoTotalTokensHeld
                    .sub(
                        poolTokenBalance
                    )
                    .sub(
                        badDebt ?? 0
                    );

                return produceAccurateValue(
                    tokenDebt,
                    decimals
                );
            }
            return "0.00";
        },
        [
            pseudoTotalTokensHeld,
            poolTokenBalance,
            decimals,
            badDebt
        ]
    );

    const poolBalanceDetails = useMemo(() => {

        const lastInteractionDate = timeStampLastInteraction && getTimeAgo(
            timeStampLastInteraction * 1000
        );

        const utilisationRatePercentage = pool?.utilisationRate &&
            pool?.utilisationRate
            / 10 ** UTILISATION_RATE_PRECISION;

        const formattedPoolBalance = produceAccurateValue(
            poolTokenBalance,
            decimals
        );

        return [
            {
                title: "Pool Balance",
                content: formattedPoolBalance && pool?.currency && (
                    <>
                        {formattedPoolBalance} {pool?.currency}
                    </>
                ),
                icon: "candlesticks",
                size: "20",
            },
            {
                title: "Lended Funds",
                color: "#c73d77",
                content: pool?.currency && (
                    <>{lendedFunds} {pool?.currency}</>
                ),
                icon: "wallet",
                size: "20",
            },
            /*{
                title: "Pool Created",
                content: poolCreationDate,
                icon: "calendar",
                size: "16",
            },*/
            {
                title: "Utilisation Rate",
                content: utilisationRatePercentage && `${utilisationRatePercentage.toFixed(2)} Percent`,
                color: "#45B26B",
                icon: "scoreboard",
                size: "20",
            },
            {
                title: "Last Interaction",
                content: lastInteractionDate,
                icon: "clock",
                size: "16",
            },
            /*{
                title: "Upcoming Update",
                content: "Not Scheduled",
                // content: "In 72 Hours",
                icon: "clock",
                size: "16",
            }*/
        ];
    }, [
        pool,
        poolTokenBalance,
        lendedFunds,
        timeStampLastInteraction,
        decimals
    ]);

    const isTablet = useMediaQuery('(max-width: 1023px)');
    const isMobile = useMediaQuery('(max-width: 767px)');

    const poolDetails = useMemo(() => {
        return (
            <>
                <div className={styles.group}>
                    {poolBalanceDetails.map((x, index) => (
                        <div className={styles.item} key={index}>
                            <div className={styles.icon}>
                                <Icon name={x.icon} size={x.size} />
                            </div>
                            <h3 className={styles.parameter}>{x.title}</h3>
                            <h3 className={styles.content} style={{ color: x.color }}>
                                {x.content}
                            </h3>
                        </div>
                    ))}
                </div>
                <div className={styles.btns}>
                    <button
                        className={cn("button-stroke", styles.button, "button-active")}
                        disabled={!pool}
                        onClick={() => {
                            setIsBorrowModalVisible(true);
                        }}>

                        Borrow {pool?.currency}
                        <Icon className={cn(styles.arrow, styles.flip)} name="arrow-down" size="10" />
                    </button>
                    <button
                        className={cn("button-stroke", styles.button, "button-active")}
                        disabled={!pool}
                        onClick={() => {
                            setIsLendModalVisible(true);
                        }}>
                        Lend {pool?.currency}
                        <Icon className={styles.arrow} name="arrow-down" size="10" />
                    </button>
                </div>
            </>
        );
    }, [pool, poolBalanceDetails]);

    const handleListViewChange = (value) => {
        const newConfig = {
            listViewType: value,
        }
        dispatch(
            setPoolPageOptions(
                newConfig
            )
        );

        appLocalStorage.POOL_PAGE_OPTIONS.update(
            newConfig
        );

        setTableViewMode(value);
    }

    return (
        <div className={cn("section", "pool-page", styles.section)}>
            <div className={cn("container", styles.container)}>
                <div className={styles.row}>
                    <div className={styles.col}>
                        {pool && (
                            <div>
                                <div className={styles.details}>
                                    <div
                                        onClick={() => {
                                            setIsBorrowModalVisible(true);
                                        }}
                                        style={{ cursor: "pointer" }}
                                        className={cn("status-green",
                                            styles.status,
                                            "status-hover"
                                        )}
                                    >
                                        {pool?.borrowStatusContent || FETCHING_DATA_MESSAGE}
                                    </div>
                                    <div
                                        onClick={() => {
                                            setIsLendModalVisible(true);
                                        }}
                                        style={{ marginLeft: "2px", cursor: "pointer" }}
                                        className={cn("status-black",
                                            "status-hover",
                                            styles.status
                                        )}
                                    >
                                        {pool?.lendStatusContent || FETCHING_DATA_MESSAGE}
                                    </div>
                                    <a
                                        href="https://wisesoft.gitbook.io/wise/wise-lending-protocol/lasa-ai"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className={styles.tooltipAnchor}
                                    >
                                        <div
                                            className={cn("tooltip right", styles.tooltip)}
                                            data-text="Dynamic APY - Click to Learn More"
                                        >
                                            <strong>i</strong>
                                        </div>
                                    </a>
                                </div>
                                <h2 className={cn("h3", styles.title)}>
                                    {pool?.ltv} - {pool?.title} Pool
                                </h2>
                                <div className={styles.info}>
                                    {pool?.description} - Pool Deployed at{" "}
                                    <a rel="noreferrer" target={"_blank"} href={`${config.etherscanAddress}/address/${poolAddress}#tokentxns`}>
                                        {shortenIfAddress(poolAddress)}
                                    </a>
                                    {
                                        !isMobile && (
                                            <div className={styles.viewToolbar}>
                                                <div onClick={() => handleListViewChange(TABLE_VIEW_MODE.GRID)}>
                                                    <Icon
                                                        name="grid"
                                                        size="18"
                                                        className={cn(styles.switch, {
                                                            [styles.active]: tableViewMode === TABLE_VIEW_MODE.GRID
                                                        })}

                                                    />
                                                </div>
                                                <div onClick={() => handleListViewChange(TABLE_VIEW_MODE.LIST)}>
                                                    <Icon
                                                        name="list"
                                                        size="18"
                                                        className={cn(styles.switch, {
                                                            [styles.active]: tableViewMode === TABLE_VIEW_MODE.LIST
                                                        })}

                                                    />
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        )}
                        {
                            isTablet === true && (
                                poolDetails
                            )
                        }

                        <div className={styles.wrap}>
                            {
                                tableViewMode === TABLE_VIEW_MODE.LIST && !isMobile && (
                                    <div className={styles.tableHeader}>
                                        <span className={styles.header}>Pic</span>
                                        <span className={styles.header}>Token Id</span>
                                        <span className={styles.header}>Borrower</span>
                                        <span className={styles.header}>Amount</span>
                                        <span className={styles.header}>Deadline</span>
                                        <span className={styles.header}>Progress</span>
                                    </div>
                                )
                            }
                            {poolAddress && (
                                <InstantList
                                    poolAddress={poolAddress}
                                    showAll={false} // this needs to be only by pool address
                                    hideClosedLoans={true}
                                    showOwner={true}
                                    className={"pool"}
                                    lockerData={usersLockers}
                                    library={library}
                                    chainId={chainId}
                                    optionalAddress={hasParamAndNotUser && address}
                                    view={InstantListView.POOL}
                                    tableLayout={tableViewMode === TABLE_VIEW_MODE.LIST && !isMobile}
                                />
                            )}
                        </div>
                    </div>
                    <div className={styles.col}>
                        {
                            isTablet === false && (
                                poolDetails
                            )
                        }
                        <div className={styles.text}>
                            <p>
                                Pricing for collections supported by this pool is based on JSON data stored in
                                LiquidNFTs smart contract as Merkle Roots available by IPFS hash
                            </p>
                        </div>
                        <br />
                        <div className={styles2.row}>
                            <div style={{ display: "none" }} className={styles2.col}>
                                <div className={styles2.nav}>
                                    {items.map((x, index) => (
                                        <div
                                            className={cn(styles2.link, {
                                                [styles2.active]: x.title === direction,
                                            })}
                                            onClick={() => setDirection(x.title)}
                                            key={index}
                                        >
                                            <Icon name={x.icon} size="16" />
                                            <span>{x.title}!</span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className={styles2.col}>
                                {items
                                    .find((x) => x.title === direction)
                                    .items.map((x, index) => (
                                        <Item
                                            className={styles2.item}
                                            item={x}
                                            key={index}
                                            supportedCollections={supportedCollections}
                                            logs={nftUpdateRootLogs?.[x.collectionAddress]}
                                        />
                                    ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                visible={isLendModalVisible}
                onClose={() => setIsLendModalVisible(false)}
            >
                <div className={cn("h4", styles.title)}>
                    Lend {pool?.currency} Funds
                </div>
                <Invest
                    poolAddress={poolAddress}
                    paymentToken={pool?.currency}
                    title={pool?.title}
                    apy={pool?.lendStatusValue}
                    ltv={pool?.ltv}
                    closeModal={() => {
                        setIsLendModalVisible(false);
                        history.push('/profile/funded-pools');
                    }}
                />
            </Modal>
            <Modal
                visible={isBorrowModalVisible}
                onClose={() => setIsBorrowModalVisible(false)}
            >
                <Borrow
                    poolAddress={poolAddress}
                    paymentToken={pool?.currency}
                    title={pool?.title}
                    closeModal={() => {
                        setIsBorrowModalVisible(false);
                        history.push('/profile/instant-loans');
                    }}
                    collectionData={collectionsData}
                    onRefreshIconClick={refetchCollectionsData}
                    isCollectionsLoading={isCollectionsLoading}
                    selectedPool={pool}
                    selectedCurrency={pool?.currency}
                    supportedCollections={pool?.supportedCollections}
                    showPoolDescription
                />
            </Modal>
        </div>
    );
};

export default Hero;
