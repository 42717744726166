import React, { useState, useEffect, useMemo } from "react";
import cn from "classnames";
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useEthers } from "@usedapp/core";
import styles from "./InstantList.module.sass";
import InstantCard, { InstantCardModalType } from "../InstantCard";
import NoData from "../NoData";
import Loader from "../Loader";
import { TEST_NETWORK_ID } from "../../utils";
import useConfig from '../../customHooks/useConfig';
import { getBorrowEventTokens } from "../../utils/query/pools";
import { InstantListView } from ".";
import { shortenIfAddress, useLookupAddress } from "@usedapp/core";

const InstantList = ({
    className,
    borrowEvents,
    borrowEventsTest,
    optionalAddress,
    showOwner,
    showAll,
    hideClosedLoans,
    userOnly,
    blockModal,
    view,
    tableLayout
}) => {

    const {
        account,
        library,
        chainId
    } = useEthers();

    const [instantList, setInstantList] = useState();
    const config = useConfig();

    const user = optionalAddress
        ? optionalAddress
        : account;

    const { ens } = useLookupAddress(user);
    const ensName = ens || shortenIfAddress(user);

    const isPoolView = view === InstantListView.POOL;
    const isProfileView = view === InstantListView.PROFILE;

    useEffect(() => {

        let mounted = true;

        const stateHandler = (list) => {
            if (mounted) {
                setInstantList(list);
            }
        };

        if (mounted) {
            // reset instant list on account and chainId changes
            setInstantList(null);
        }

        const parseEvents = chainId === TEST_NETWORK_ID
            ? borrowEventsTest
            : borrowEvents;

        if (
            parseEvents
            && parseEvents.length
            && (account || isPoolView || isProfileView)
        ) {
            getBorrowEventTokens({
                chainId,
                config,
                library,
                borrowEvents: parseEvents,
                user,
                setStateCallback: stateHandler,
                showAll,
                // hideClosedLoans,
                userOnly,
            });
        }
        return () => {
            mounted = false;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [borrowEvents, borrowEventsTest, account, chainId]);

    useEffect(() => {
        if (account && instantList) {
            setInstantList(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account]);

    const filteredList = useMemo(() => {
        // Skip iteration until present
        if (instantList && instantList.length) {
            // hide loans if toggle for showPastLoans is in use
            return instantList.filter((loan) => {
                if (hideClosedLoans) {
                    return !loan.closedLoan
                }
                return loan;
            });
        }
        return instantList;
    }, [instantList, hideClosedLoans]);

    return (
        <div className={cn(styles.items, className)}>
            <PerfectScrollbar
                className={cn(styles.scrollIndicators, {
                    [styles.tableView]: tableLayout === true
                })}>
                <div
                    style={{
                        display: !filteredList || !filteredList?.length ? "none" : "flex",
                    }}
                    className={
                        cn(
                            styles.list,
                            {
                                [styles.poolViewList]: isPoolView,
                                [styles.profileViewList]: isProfileView
                            }
                        )}
                >
                    {filteredList && filteredList.map((data, index) => {
                        if (!data) return (<div key={index}></div>);

                        const isPaybackModalType = data.borrower === account && isPoolView === false;

                        const modalType = isPaybackModalType
                            ? InstantCardModalType.PAYBACK
                            : InstantCardModalType.DETAIL;

                        const tokenButtonLabel = isPoolView && isPaybackModalType
                            ? 'Payback'
                            : null;

                        return (
                            <InstantCard
                                showOwner={showOwner}
                                className={styles.card}
                                parentClass={className}
                                blockModal={blockModal}
                                modalType={modalType}
                                token={data}
                                key={index}
                                showAll={showAll}
                                tokenButtonLabel={tokenButtonLabel}
                                cardIndex={index}
                                rowLayout={tableLayout}
                            />
                        )
                    })}
                    <div
                        className={styles.loader}
                        style={{ display: !instantList ? "block" : "none" }}
                    >
                        <Loader color="white" />
                    </div>
                    <div
                        className={styles.loader}
                        style={{ display: filteredList && !filteredList.length ? "flex" : "none" }}
                    >
                        <NoData
                            buttonEndpoint="instant-pools"
                            label="No Active Loans"
                            pageMode={isProfileView ? 'user' : null}
                            buttonLabel="Create NFT Loan"
                            hideButton={user !== account}
                        />
                    </div>
                </div>
            </PerfectScrollbar>
            <div
                className={(cn(styles.loader, styles.loaderContainer))}
                style={{ display: !instantList ? "flex" : "none" }}
            >
                <Loader color="white" />
            </div>
            <div
                className={styles.loader}
                style={{ display: instantList && !instantList.length ? "flex" : "none" }}
            >
                <NoData
                    buttonEndpoint="instant-pools"
                    label={`${optionalAddress ? ensName : "You"} did not borrow from any pools`}
                />
            </div>
        </div>
    );
};

export default InstantList;
