import { createSlice } from "@reduxjs/toolkit"
import { DEFAULT_POOL_VIEW } from "../utils";

const initialState = {
    ensName: null,
    latestTxHash: false,
    selectedTokenId: null,
    showTIP: false,
    hiddenOptions: {
        showTimeline: true,
        showFilters: true,
        showPreview: true
    },
    tokenPreferences: {
        // "WISE": true,
        "USDC": true,
        "WETH": true
    },
    collectionPreferences: {
        "Bored Apes": true,
        // "Mutant Apes": true,
        "Doodles": true,
        // "Cool Cats": true,
        // "Satoshibles": true,
        // "CyberKongz": true
    },
    airdropPreferences: {
        isBannerVisible: false
    },
    bannerPreferences: {
        promotion: false,
    },
    extendedLoanIds: [],
    closedFundedPools: [],
    closedLoanTxHashes: [],
    showSupportedOnly: true,
    showPastLoans: false,
    poolPageOptions: {
        listViewType: DEFAULT_POOL_VIEW
    }
};

export const settingsSlice = createSlice({
    name: "settings",
    initialState,
    reducers: {
        setEnsName: (state, action) => {
            state.ensName = action.payload
        },
        setUpdateLatestTx: (state, action) => {
            state.latestTxHash = action.payload
        },
        setClosedLoanTxHashes: (state, action) => {
            state.closedLoanTxHashes = action.payload
        },
        setExtendedLoanIds: (state, action) => {
            state.extendedLoanIds = action.payload
        },
        setClosedFundedPools: (state, action) => {
            state.closedFundedPools = action.payload
        },
        setSelectedTokenId: (state, action) => {
            state.selectedTokenId = action.payload
        },
        setShowTIP: (state, action) => {
            state.showTIP = action.payload
        },
        setHiddenOptions: (state, action) => {
            state.hiddenOptions = action.payload
        },
        setTokenPreferences: (state, action) => {
            state.tokenPreferences = action.payload
        },
        setCollectionPreferences: (state, action) => {
            state.collectionPreferences = action.payload
        },
        setAirdopPreferences: (state, action) => {
            state.airdropPreferences = action.payload
        },
        setBannerPreferences: (state, action) => {
            state.bannerPreferences = action.payload
        },
        setShowSupportedOnly: (state, action) => {
            state.showSupportedOnly = action.payload
        },
        setPricingPreferences: (state, action) => {
            state.pricingPreferences = action.payload
        },
        setShowPastLoans: (state, action) => {
            state.showPastLoans = action.payload
        },
        setPoolPageOptions: (state, action) => {
            state.poolPageOptions = action.payload
        },
    },
});

export const {
    setEnsName,
    setUpdateLatestTx,
    setClosedLoanTxHashes,
    setExtendedLoanIds,
    setSelectedTokenId,
    setShowTIP,
    setHiddenOptions,
    setTokenPreferences,
    setCollectionPreferences,
    setAirdopPreferences,
    setBannerPreferences,
    setClosedFundedPools,
    setShowSupportedOnly,
    setPricingPreferences,
    setShowPastLoans,
    setPoolPageOptions
} = settingsSlice.actions;

export default settingsSlice.reducer;
