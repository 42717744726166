import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Interface } from "ethers/lib/utils";
import { useEthers } from "@usedapp/core";
import { Contract } from "ethers";
import useConfig from "./useConfig";

import LIQUID_LOCKER_ABI from "../ethers/abis/LiquidLocker.json";

const parseLog = (log, contractInterface) => ({
    blockNumber: log.blockNumber,
    ...contractInterface.parseLog(log),
});

const getData = (event) => ({
    user: event.args.paymentAddress,
    amount: event.args.paymentAmount,
    blockNumber: event.blockNumber,
});

const addTxHash = (data, logs) =>
    data.map((d, i) => ({
        ...d,
        transactionHash: logs[i].transactionHash
    })
);

const addTimestamp = (data, library) =>
    Promise.all(
        data.map(async (d) => {

            const { timestamp } = await library.getBlock(
                d.blockNumber
            );

            return {
                ...d,
                timestamp
            };
        })
    );

const lockerInterface = new Interface(
    LIQUID_LOCKER_ABI
);

const parsePaybackLog = (log) => parseLog(
    log,
    lockerInterface
);

const usePaybackEvents = (lockerAddress, paymentToken) => {

    const config = useConfig();
    const { library } = useEthers();
    const latestTxHash = useSelector((state) => state.settings.latestTxHash);

    const lockerContract = new Contract(
        lockerAddress,
        lockerInterface
    );

    const [paybackEvents, setPaybackEvents] = useState([]);
    const [paybackEventsByUser, setPaybackEventsByUser] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchLogs = async () => {

            setLoading(true);

            const paybackFilter = lockerContract.filters.PaymentMade();

            const paybackLogs = await library.getLogs({
                ...paybackFilter,
                fromBlock: config.inceptionBlock
            });

            const paybackEvents = paybackLogs.map(
                parsePaybackLog
            );

            const paybackData = paybackEvents.map(
                getData
            );

            const paybackWithTxHash = addTxHash(
                paybackData,
                paybackLogs
            );

            const paybackWithTxAndTime = await addTimestamp(
                paybackWithTxHash,
                library
            );

            // const paymentTokenName = config.tokenAddressToNameMapping[paymentToken];
            // const decimals = config.decimals[paymentTokenName];

            const paybackEventsByUserMap = paybackWithTxAndTime.reduce((newObj, curr) => {

                if (newObj[curr.user]) {
                    newObj[curr.user].amount =
                    newObj[curr.user].amount.add(curr.amount);

                    newObj[curr.user].count += 1;
                    return newObj
                }

                newObj[curr.user] = {
                    amount: curr.amount,
                    count: 1
                };

                return newObj;
            }, {});

            // Format events by user map to match paybackEvents array structure for switch toggles
            // console.log(paybackEventsByUserMap, 'paybackEventsByUserMap');

            const paybackEventsByUserArr = Object.keys(paybackEventsByUserMap).map((userKey) => ({
                    user: userKey,
                    amount: paybackEventsByUserMap[userKey].amount,
                    count: paybackEventsByUserMap[userKey].count
                })
            );

            setPaybackEvents(
                paybackWithTxAndTime
            );

            setPaybackEventsByUser(
                paybackEventsByUserArr
            );

            setLoading(false);
        };

        if (library && paymentToken) fetchLogs();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lockerAddress, library, latestTxHash, paymentToken]);

    return [paybackEvents, paybackEventsByUser,loading];
};

export default usePaybackEvents;
