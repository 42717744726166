import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { withRouter, useLocation } from "react-router-dom";
import { clearAllBodyScrollLocks } from "body-scroll-lock";
import { useLookupAddress, useEthers } from "@usedapp/core";
import { ethers } from 'ethers';

import UNISWAP_ROUTER_ABI from "../../ethers/abis/UniswapV2Router2.json";
import CHAINLINK_PRICING_CONTRACT_ABI from "../../ethers/abis/ChainlinkPricingContract.json";

import cn from "classnames";

import {
    // setBannerPreferences,
    setEnsName
} from "../../redux/settingsSlice";
import { setConversions } from "../../redux/conversionsSlice";
import styles from "./Page.module.sass";
import Header from "../Header";
import Footer from "../FooterNew";
import useConfig from "../../customHooks/useConfig";
import { useSelector } from "react-redux";

// import Icon from "../Icon";
// import { appLocalStorage } from "../../utils/localstorage";
// import useMediaQuery from "../../customHooks/useMediaQuery";

const Page = ({ children }) => {
    const { pathname } = useLocation();
    const dispatch = useDispatch();
    const { account, library } = useEthers();
    const { ens } = useLookupAddress(account);
    const config = useConfig();
    // const history = useHistory();

    // const isMobile = useMediaQuery('(max-width: 767px');

    // const airdropPreferences = useSelector(
    //     (state) => state.settings.airdropPreferences
    // );

    const bannerPreferences = useSelector(
        (state) => state.settings.bannerPreferences
    );

    useEffect(() => {
        window.scrollTo(0, 0);
        clearAllBodyScrollLocks();
    }, [pathname]);

    useEffect(() => {
        dispatch(
            setEnsName(
                ens
            )
        );
        // eslint-disable-line react-hooks/exhaustive-deps
    }, [ens, dispatch]);

    useEffect(() => {

        const fetchConversionRates = async () => {

            try {

                const WETH_ADDRESS = config.paymentTokens.WETH;
                const USDT_ADDRESS = config.paymentTokens.USDT;
                const WISE_ADDRESS = config.paymentTokens.WISE;

                const uniswap = new ethers.Contract(
                    config.uniswapV2Router2,
                    UNISWAP_ROUTER_ABI,
                    library,
                );

                const uniswapWISEtoWETH = await uniswap.getAmountsOut(
                    1,
                    [WETH_ADDRESS, WISE_ADDRESS]
                );

                const uniswapWETHtoUSDT = await uniswap.getAmountsOut(
                    10 ** config.decimals.USDT,
                    [USDT_ADDRESS, WETH_ADDRESS]
                );

                const uniswapWethUsdPrice = 1 / ethers.utils.formatEther(
                    uniswapWETHtoUSDT[1]
                );

                const uniswapWiseUsdPrice = uniswapWethUsdPrice / uniswapWISEtoWETH[1];

                const pricingContract = new ethers.Contract(
                    config.chainlinkPricingContract,
                    CHAINLINK_PRICING_CONTRACT_ABI,
                    library
                );

                const chainlinkETHtoUSD = await pricingContract.latestAnswer();
                const decimals = await pricingContract.decimals();

                const chainlinkEthUsdPrice = chainlinkETHtoUSD / 10 ** decimals;
                // const chainlinkWiseUsdPrice = chainlinkEthUsdPrice / uniswapWISEtoWETH[1];

                dispatch(
                    setConversions({
                        WETH: uniswapWethUsdPrice,
                        WISE: uniswapWiseUsdPrice,
                        CHAINLINK_ETH: chainlinkEthUsdPrice,
                        // CHAINLINK_WISE: chainlinkWiseUsdPrice
                    })
                );

            } catch (err) {

                console.log(
                    "conversion fetch error: ",
                    err
                );
            }
        };

        fetchConversionRates();
    }, [account]); // eslint-disable-line react-hooks/exhaustive-deps

    // const isAirdropBannerVisible = airdropPreferences?.isBannerVisible;

    const isPromotionBannerVisible = bannerPreferences?.promotion;

    /*const handleClosePromotionBanner = (event) => {
        event.stopPropagation();
        const newConfig = {
            promotion: false
        };
        appLocalStorage.BANNER.update(newConfig);
        dispatch(
            setBannerPreferences({
                newConfig
            })
        )
    }*/

    return (
        <div
            className={
                cn(styles.page, {
                    [styles.withBanner]: isPromotionBannerVisible
                })
            }
        >
            <Header />
            {/* {isAirdropBannerVisible && (
                <div
                    className={styles.drop}
                    onClick={() => {
                        if (isMobile) {
                            history.push('/how-it-works');
                        }
                    }}
                >
                    {
                        isMobile
                            ? 'Incoming Airdrop'
                            : 'Borrow and Lend from Instant Pools to participate in LiquidNFTs upcoming Airdrop'
                    }
                </div>
            )} */}
            {/* {
                isPromotionBannerVisible && (
                    <div
                        className={styles.banner}
                    >
                        <a href="https://app.wisetoken.net/buy-wiser"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            The 50 day WISEr token sale is live now!
                        </a>
                        <button className={styles.close} onClick={handleClosePromotionBanner}>
                            <Icon name="close" size="9" />
                        </button>
                    </div>
                )
            } */}
            <div className={styles.inner}>{children}</div>
            <Footer />
        </div>
    );
};

export default withRouter(Page);
