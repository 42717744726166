import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Interface } from "ethers/lib/utils";
import { useEthers } from "@usedapp/core";
import { Contract } from "ethers";
import useConfig from "./useConfig";

import LIQUID_LOCKER_ABI from "../ethers/abis/LiquidLocker.json";

const parseLog = (log, contractInterface) => ({
    blockNumber: log.blockNumber,
    ...contractInterface.parseLog(log),
});

const getData = (event) => ({
    user: event.args.refundAddress,
    amount: event.args.refundAmount,
    blockNumber: event.blockNumber,
});

const addTxHash = (data, logs) =>
    data.map((d, i) => ({ ...d, transactionHash: logs[i].transactionHash }));

const addTimestamp = (data, library) =>
    Promise.all(
        data.map(async (d) => {
            const { timestamp } = await library.getBlock(d.blockNumber);
            return { ...d, timestamp };
        })
    );

const lockerInterface = new Interface(LIQUID_LOCKER_ABI);
const parseRefundLog = (log) => parseLog(log, lockerInterface);

const useRefundEvents = (lockerAddress) => {

    const config = useConfig();
    const { library } = useEthers();
    const latestTxHash = useSelector((state) => state.settings.latestTxHash);
    const lockerContract = new Contract(lockerAddress, lockerInterface);
    const [refundEvents, setRefundEvents] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchLogs = async () => {

            setLoading(true);

            const refundFilter = lockerContract.filters.RefundMade();

            const refundLogs = await library.getLogs({
                ...refundFilter,
                fromBlock: config.inceptionBlock
            });

            const refundEvents = refundLogs.map(
                parseRefundLog
            );

            const refundData = refundEvents.map(
                getData
            );

            const refundWithTxHash = addTxHash(
                refundData,
                refundLogs
            );

            const refundWithTxAndTime = await addTimestamp(
                refundWithTxHash,
                library
            );

            setRefundEvents(
                refundWithTxAndTime
            );

            setLoading(false);
        };
        if (library) fetchLogs();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lockerAddress, library, latestTxHash]);
    return [refundEvents, loading];
};

export default useRefundEvents;
