import React, { useRef } from "react";
import Hero from "./Hero";
// import Demo from "./Demo";
import Intro from "../../components/Intro";
import ValueProps from "../../components/ValueProps";
import Teams from "../Questions/Teams";
import Program from "./Program";

// import Description from "./Description";
// import Control from "../../components/Control";
// import styles from "../../screens/LoanType/LoanType.module.sass";

// const breadcrumbs = [
//     {
//         title: "Home",
//         url: "/",
//     },
//     {
//         title: "Instant Pools",
//     },
// ];

const Instant = () => {

    const scrollToRef = useRef(null);

    return (
        <>
            {/*<Control className={styles.control} item={breadcrumbs} />*/}
            <Hero scrollToRef={scrollToRef} />
            {/*<Hero2 />*/}
            {/*<Program scrollToRef={scrollToRef} />*/}
            {/*<Demo />*/}
            <Program />
            <Intro />
            <ValueProps />
            {/*<Description />*/}
            <Teams />
        </>
    );
};

export default Instant;
