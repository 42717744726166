import { useState, useEffect } from "react";
// import { Interface } from "ethers/lib/utils";
import { getTokenCollections } from "../ethers/nftCollections";
// import { Contract } from "ethers";
// import useCallsGracefully from "./useCallsGracefully";
import { getMetaData } from "./useTokenFetch";

const MAX_SIZE = 30;

const useEnumerableAddressTokens = (userAccount, chainId) => {

    const [enumerableAddressTokens, setEnumerableAddressTokens] = useState([]);
    const [loading, setIsLoading] = useState(false);

    const {
        enumerable,
    } = getTokenCollections(chainId);

    const fetch = async () => {
        setIsLoading(true);
        try {
            if (enumerable?.length) {
                setEnumerableAddressTokens([]);
            }

            // console.log(enumerable, 'calling with enum');

            // @TODO: transform array "enumerable"
            // to following format - neededFormat
            const neededFormat = {
                '0xBC4CA0EdA7647A8aB7C2061c2E118A18a936f13D': Array(MAX_SIZE), // bored apes
                '0x60E4d786628Fea6478F785A6d7e704777c86a7c6': Array(MAX_SIZE), // mutants
                '0x1A92f7381B9F03921564a437210bB9396471050C': Array(MAX_SIZE), // cool cats
                '0x8a90CAb2b38dba80c64b7734e58Ee1dB38B8992e': Array(MAX_SIZE), // doodles
                '0xED5AF388653567Af2F388E6224dC7C4b3241C544': Array(MAX_SIZE), // azuki
                '0x282bdd42f4eb70e7a9d9f40c8fea0825b7f68c5d': Array(MAX_SIZE), // punkv1
                '0xb7F7F6C52F2e2fdb1963Eab30438024864c313F6': Array(MAX_SIZE), // wpunks
                '0x23581767a106ae21c074b2276D25e5C3e136a68b': Array(MAX_SIZE), // moonbirds
                '0x49cF6f5d44E70224e2E23fDcdd2C053F30aDA28B': Array(MAX_SIZE) // clonex
            };

            const results = await getMetaData(
                neededFormat,
                chainId,
                userAccount
            );

            setEnumerableAddressTokens(
                results?.length
                    ? results.flat()
                    : []
            );
        } catch (ex) {
            console.log(ex);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        let mounted = true;

        if (mounted && userAccount) {
            fetch();
        }
        return () => (mounted = false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [enumerable, userAccount]);

    return { enumerableAddressTokens, loading, refetch: fetch };
};

export default useEnumerableAddressTokens;