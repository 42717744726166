import { useState } from "react";
import cn from "classnames";
import styles from "./Tree.module.sass";

const Item = ({
    item,
    level,
    hasChildren,
    selected,
    onToggle
}) => {
    return (
        <div
            className={
                cn(styles.item, {
                    [styles.expandable]: hasChildren
                })}
            style={{
                paddingLeft: `${level * 24}px`,
            }}
            onClick={onToggle}
        >
            {hasChildren && (
                <span
                    className={
                        cn(styles.toggle, {
                            [styles.warning]: item.toggleVariant === "warning"
                        })
                    }
                >
                    {
                        selected ? "-" : "+"
                    }
                </span>
            )}
            {item.label}
        </div>
    );
}

const Branch = ({ item, level }) => {

    const [selected, setSelected] = useState(
        item.selected
        ?? false
    );

    const hasChildren = Boolean(
        Array.isArray(item.children)
        && item.children.length
    );

    const renderItems = () => {
        if (hasChildren) {
            const newLevel = level + 1;

            return item.children.map((child, index) => (
                <Branch
                    item={child}
                    level={newLevel}
                    key={`branch-c-${index}`}
                />
            ))
        }
        return null;
    }

    const toggleSelected = () => {
        setSelected(!selected);
    }

    return (
        <>
            <Item
                item={item}
                level={level}
                selected={selected}
                hasChildren={hasChildren}
                onToggle={toggleSelected}
            />
            {selected && renderItems()}
        </>
    );
}

export const Tree = ({ data }) => {
    return (
        <div className={styles.tree}>
            {data.map((item, index) => (
                <Branch
                    item={item}
                    level={0}
                    key={`branch-${index}`}
                />
            ))}
        </div>
    );
}