import { useEthers } from "@usedapp/core";
import { Contract } from "ethers";
import ERC721_ABI from "../ethers/abis/ERC721.json";
import useConfig from "./useConfig";

export const useNftApproval = ({
    onConfirmation,
    onApproving,
    onApprovalComplete,
    onError,
}) => {
    const { library, account } = useEthers();
    const signer = library.getSigner(account);
    const config = useConfig();

    const call = async (tokenAddress, tokenId) => {
        try {
            onConfirmation && onConfirmation();

            const nftContract = new Contract(
                tokenAddress,
                ERC721_ABI,
                signer
            );

            const approveTransaction = await nftContract.approve(
                config.routerAddress,
                tokenId
            );

            onApproving && onApproving(approveTransaction.hash);

            const approve = await approveTransaction.wait();

            onApprovalComplete && onApprovalComplete(approve);

            return true;

        } catch (ex) {
            console.log("Error in approve", ex);

            onError && onError(ex);

            return false;

        }
    }

    return {
        call,
    }

}