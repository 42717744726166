import React from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import styles from "./LoanType.module.sass";
// import Control from "../../components/Control";

/*const breadcrumbs = [
    {
        title: "Home",
        url: "/",
    },
    {
        title: "Collateralize NFT",
    },
];*/

const items = [
    {
        url: "/instant-pools",
        buttonText: "Instant NFT Loan",
        image: "/images/content/instant.png",
        image2x: "/images/content/instant.png",
    },
    {
        url: "/loan-details",
        buttonText: "Custom Listing",
        image: "/images/content/custom3.png",
        image2x: "/images/content/custom3.png",
    },
];

const LoanType = () => {
    return (
        <div className={styles.page}>
            {/*<Control className={styles.control} item={breadcrumbs} />*/}
            <div className={cn("section-pt80", styles.section)}>
                <div className={cn("container", styles.container)}>
                    <div className={styles.top}>
                        <h1 className={cn("h2", styles.title)}>Create NFT Loan</h1>
                        <div className={styles.info}>
                            Choose <span>"Instant NFT Loan"</span> if you want to instantly borrow from our liquidity pools
                            or <span>"Custom Listing"</span> if you want to go through the customized 4-stage loan process
                        </div>
                    </div>
                    <div className={styles.list}>
                        {items.map((x, index) => (
                            <Link className={styles.item} key={index} to={x.url}>
                                <div className={styles.preview}>
                                    <img srcSet={`${x.image2x} 2x`} src={x.image} alt="Loan Type" />
                                </div>
                                <div className={cn("button-stroke", styles.button)}>
                                    {x.buttonText}
                                </div>
                            </Link>
                        ))}
                    </div>
                    <div className={styles.note}>
                        We strongly recommend to try Instant Loans
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoanType;
