import React from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import styles from "./Program.module.sass";
import Icon from "../../../components/Icon";
import ScrollParallax from "../../../components/ScrollParallax";
// import Demo from "../Demo";

const items = [
    {
        title: "Create New Loan",
        url: "/loan-details",
        color: "#9757D7",
        images: "/images/content/megaphone.svg",
        alt: "clock",
        scale: "1.2",
        opacity: "1"
    },
    {
        title: "Custom Listings",
        url: "/discover",
        color: "#3eb4c7",
        images: "/images/content/wallet.svg",
        alt: "wallet",
        scale: "1.2",
        opacity: "1"
    },
    {
        title: "Instant Pool",
        url: "/instant",
        color: "#c73e77",
        images: "/images/content/lightning.svg",
        alt: "lightning",
        scale: "1",
        opacity: "0.7"
    },
];

const Program = ({ scrollToRef }) => {
    return (
        <div className={cn("section-bg", styles.book)} ref={scrollToRef}>
            <div className={cn("container", styles.container)}>
                <div className={styles.wrap}>
                    <div className={styles.col}>
                        <div className={cn("stage-small", styles.stage)}>
                            Instant Pools are coming soon, meanwhile you can explore all other cool features LiquidNFTs offer
                        </div>
                        <div className={styles.list}>
                            {items.map((x, index) => (
                                <ScrollParallax className={styles.item} key={index}>
                                    <Link className={styles.link} style={{opacity: `${x.opacity}`}} to={x.url}>
                                        <div
                                            className={styles.icon}
                                            style={{ backgroundColor: x.color, transform: `scale(${x.scale})` }}
                                        >
                                            <img src={x.images} alt={x.alt} />
                                        </div>
                                        <div className={styles.subtitle}>{x.title}</div>
                                        <div className={styles.arrow}>
                                            <Icon name="arrow-right-empty" size="14" />
                                        </div>
                                    </Link>
                                </ScrollParallax>
                            ))}
                        </div>
                    </div>
                    <div className={styles.col}>
                        <div className={styles.preview}>
                            <img
                                srcSet="/images/content/box-new.png 2x"
                                src="/images/content/box-new.png"
                                alt="Box"
                            />
                        </div>
                        <div className={styles.info}>
                            Instant Pools are coming soon, meanwhile you can learn more about other details and features
                        </div>
                        <Link className={cn("button", styles.button)} to="/questions">
                            Learn More
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Program;
