import React from "react";
import { useParams } from "react-router";

import useContributorsToLocker from "../../customHooks/useContributorsToLocker";
import usePaybackEvents from "../../customHooks/usePaybackEvents";
import useRefundEvents from "../../customHooks/useRefundEvents";
import useListingDetails from "../../customHooks/useListingDetails";
import useClaimEvents from "../../customHooks/useClaimEvents";
import Item from "./Item";

const ItemWrapper = () => {

    const { lockerAddress } = useParams();

    const listingDetails = useListingDetails(
        lockerAddress
    );

    const [refundEvents] = useRefundEvents(
        lockerAddress
    );

    const { paymentToken } = listingDetails;

    const [paybackEvents, paybackEventsByUser] = usePaybackEvents(
        lockerAddress,
        paymentToken
    );

    const [claimEvents, claimEventsByUser] = useClaimEvents(
        lockerAddress,
        paymentToken
    );

    const [contributors, contributionEvents] = useContributorsToLocker(
        lockerAddress
    );

    listingDetails.contributors = contributors;
    listingDetails.contributionEvents = contributionEvents;

    return (
        <Item
            lockerAddress={lockerAddress}
            listingDetails={listingDetails}
            contributors={contributors}
            contributionEvents={contributionEvents}
            paybackEvents={paybackEvents}
            paybackEventsByUser={paybackEventsByUser}
            refundEvents={refundEvents}
            claimEvents={claimEvents}
            claimEventsByUser={claimEventsByUser}
        />
    );
};

export default ItemWrapper;
