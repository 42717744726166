
// old icons:
// https://raw.githubusercontent.com/iamlucnguyen/react-native-cryptocurrency-hexagon-icons/master/assets/originals/usdc.svg
// https://raw.githubusercontent.com/iamlucnguyen/react-native-cryptocurrency-hexagon-icons/master/assets/originals/eth.svg
// https://raw.githubusercontent.com/iamlucnguyen/react-native-cryptocurrency-hexagon-icons/master/assets/originals/usdt.svg

const supportedPoolsData = [
    {
        title: "WETH",
        description: "Supports 6 Popular Collections",
        color: "#000000",
        token: "0x...",
        collateralFactor: "70000000000000000000",
        address: "0x3f302D860b27FB06C794eE0916abC1D3A362Bf7e",
        // address: "0xFC477A2F8A14dEAEd095F6ba14F0145fa6202F3C",
        image: "/images/icons/eth.svg",
        background: "/images/collections/global-pool.jpg",
        status: "green",
        risk: "black",
        deprecated: false,
        collectionSlug: "global",
        currency: "WETH",
        tokenDecimals: 18,
        riskContent: "Medium Risk",
        supportedCollections: ["Azuki", "Bored Apes", "CloneX", "Cool Cats", "Doodles", "Moon Birds", "Mutant Apes", "Wrapped Punks", "Wrapped Punks V1"],
        ltv: "70% LTV",
        creation: "1667401531", // timestamp
        chainId: 1
    },
    {
        title: "USDC",
        description: "Supports 2 Popular Collections",
        color: "#000000",
        token: "0x...",
        collateralFactor: "35000000000000000000",
        address: "0x85162c81D0Ef84b21230eB4bf3cAb073B71F3c38",
        image: "/images/icons/usdc.svg",
        background: "/images/collections/global-pool.jpg",
        status: "green",
        risk: "black",
        deprecated: false,
        tokenDecimals: 6,
        collectionSlug: "global",
        currency: "USDC",
        riskContent: "Low Risk",
        supportedCollections: ["Azuki", "Bored Apes", "CloneX", "Cool Cats", "Doodles", "Moon Birds", "Mutant Apes", "Wrapped Punks", "Wrapped Punks V1"],
        ltv: "35% LTV",
        creation: "1664824369", // timestamp
        chainId: 1
    },
    {
        title: "Old",
        description: "Supports 6 Popular Collections",
        color: "#000000",
        token: "0x...",
        collateralFactor: "70000000000000000000",
        address: "0xD0fa18f97F2EE57249198221d261817aA6dE334e",
        // address: "0xFC477A2F8A14dEAEd095F6ba14F0145fa6202F3C",
        image: "/images/icons/eth.svg",
        background: "/images/collections/global-pool.jpg",
        status: "green",
        risk: "black",
        deprecated: true,
        migrateTo: "0x3f302D860b27FB06C794eE0916abC1D3A362Bf7e",
        collectionSlug: "global",
        currency: "WETH",
        tokenDecimals: 18,
        riskContent: "Medium Risk",
        supportedCollections: ["Azuki", "Bored Apes", "CloneX", "Cool Cats", "Doodles", "Moon Birds", "Mutant Apes", "Wrapped Punks"],
        ltv: "70% LTV",
        creation: "1664824369", // timestamp
        chainId: 1
    },
    {
        title: "Old",
        description: "Supports 2 Popular Collections",
        color: "#000000",
        token: "0x...",
        collateralFactor: "35000000000000000000",
        address: "0x9c0E786C152B00ae4A796EBBf56e5e526F6fe900",
        image: "/images/icons/usdc.svg",
        background: "/images/collections/global-pool.jpg",
        status: "green",
        risk: "black",
        deprecated: true,
        migrateTo: "0x85162c81D0Ef84b21230eB4bf3cAb073B71F3c38",
        tokenDecimals: 6,
        collectionSlug: "global",
        currency: "USDC",
        riskContent: "Low Risk",
        supportedCollections: ["Azuki", "Bored Apes", "CloneX", "Cool Cats", "Doodles", "Moon Birds", "Mutant Apes", "Wrapped Punks"],
        ltv: "35% LTV",
        creation: "1664824369", // timestamp
        chainId: 1
    },
    /*{
        title: "Global",
        description: "Supports 6 Popular Collections",
        color: "#000000",
        token: "0x...",
        collateralFactor: "35000000000000000000",
        address: "0xc06aFC1C5B4f6deF6116Cf93838A4F4e1910254c",
        // address: "0xFC477A2F8A14dEAEd095F6ba14F0145fa6202F3C",
        image: "/images/icons/eth.svg",
        background: "/images/collections/global-pool.jpg",
        status: "green",
        risk: "black",
        collectionSlug: "global",
        currency: "WETH",
        tokenDecimals: 18,
        riskContent: "Low Risk",
        supportedCollections: ["Azuki", "Bored Apes", "CloneX", "Cool Cats", "Doodles", "Moon Birds", "Mutant Apes"],
        ltv: "35% LTV",
        creation: "1664824369", // timestamp
        chainId: 1
    },*/
    {
        title: "Global",
        description: "Supports 2 Popular Collections",
        color: "#000000",
        token: "0x...",
        collateralFactor: "70000000000000000000",
        address: "0xDdd66B3bcc22103b58518974DA66F3BF7EF53DD5",
        // address: "0xFC477A2F8A14dEAEd095F6ba14F0145fa6202F3C",
        image: "/images/icons/eth.svg",
        background: "/images/collections/global-pool.jpg",
        status: "green",
        risk: "black",
        deprecated: false,
        collectionSlug: "global",
        currency: "WETH",
        tokenDecimals: 18,
        statusContent: "20% APY",
        riskContent: "Low Risk",
        supportedCollections: ["Doodles", "Bored Apes", "Cool Cats", "Wrapped Punks"],
        ltv: "70% LTV",
        creation: "1662670726", // timestamp
        chainId: 5
    },
    {
        title: "Global",
        description: "Supports 2 Popular Collections",
        color: "#000000",
        token: "0x...",
        collateralFactor: "35000000000000000000",
        address: "0x72D75295ecEbEE27b362Be81324D89388E7D2231",
        image: "/images/icons/usdc.svg",
        background: "/images/collections/global-pool.jpg",
        status: "green",
        risk: "black",
        deprecated: false,
        tokenDecimals: 6,
        collectionSlug: "global",
        currency: "USDC",
        riskContent: "Low Risk",
        supportedCollections: ["Doodles", "Bored Apes", "Cool Cats", "Wrapped Punks"],
        ltv: "35% LTV",
        creation: "1662630005", // timestamp
        chainId: 5
    },
    {
        title: "Old",
        description: "Supports 2 Popular Collections",
        color: "#000000",
        token: "0x...",
        collateralFactor: "35000000000000000000",
        address: "0x3b5d9c7EC077B774a75bD412f9777C5D751A70Cd",
        // address: "0xFC477A2F8A14dEAEd095F6ba14F0145fa6202F3C",
        image: "/images/icons/eth.svg",
        background: "/images/collections/global-pool.jpg",
        status: "green",
        risk: "black",
        collectionSlug: "global",
        currency: "WETH",
        deprecated: true,
        migrateTo: "0xDdd66B3bcc22103b58518974DA66F3BF7EF53DD5",
        statusContent: "20% APY",
        riskContent: "Low Risk",
        supportedCollections: ["Doodles", "Bored Apes", "Cool Cats", "Wrapped Punks"],
        ltv: "35% LTV",
        creation: "1662630005", // timestamp
        chainId: 5
    },
    {
        title: "Old",
        description: "Supports 2 Popular Collections",
        color: "#000000",
        token: "0x...",
        collateralFactor: "35000000000000000000",
        address: "0x72D75295ecEbEE27b362Be81324D89388E7D2231",
        image: "/images/icons/usdc.svg",
        background: "/images/collections/global-pool.jpg",
        status: "green",
        risk: "black",
        tokenDecimals: 6,
        deprecated: true,
        migrateTo: "0x72D75295ecEbEE27b362Be81324D89388E7D2231",
        collectionSlug: "global",
        currency: "USDC",
        riskContent: "Low Risk",
        supportedCollections: ["Doodles", "Bored Apes", "Cool Cats", "Wrapped Punks"],
        ltv: "35% LTV",
        creation: "1662630005", // timestamp
        chainId: 5
    },
    {
        title: "Doodles",
        description: "Individual Pool For 1 Collection",
        color: "#000000",
        token: "0x...",
        collateralFactor: "35000000000000000000",
        address: "0x59534ee3cb527d41138356525bfa7105cd5ada47",
        image: "/images/icons/eth.svg",
        background: "/images/collections/doodles.png",
        status: "green",
        risk: "black",
        collectionSlug: "doodles",
        currency: "WETH",
        riskContent: "Low Risk",
        supportedCollections: ["Doodles"],
        ltv: "35% LTV",
        creation: "1662630005", // timestamp
        chainId: 3
    },
    /*{
        title: "Global",
        description: "Supports 2 Popular Collections",
        color: "#000000",
        token: "0x...",
        collateralFactor: "50000000000000000000",
        address: "0x4A1c19D22877022fD04133391608eEa137724a31",
        image: "/images/icons/usdc.svg",
        background: "/images/collections/global-pool.jpg",
        status: "green",
        risk: "black",
        collectionSlug: "global",
        currency: "USDC",
        riskContent: "Low Risk",
        supportedCollections: ["Doodles"],
    },
    {
        title: "Doodles",
        description: "Individual Pool For 1 Colletion",
        color: "#000000",
        token: "0x...",
        collateralFactor: "50000000000000000000",
        address: "0x86472b49Edc26a591b25D31ebdd9e32A18140Be3",
        image: "/images/icons/usdc.svg",
        background: "/images/collections/doodles.png",
        status: "green",
        risk: "black",
        collectionSlug: "doodles",
        currency: "USDC",
        riskContent: "Low Risk",
        supportedCollections: ["Doodles"],
    },*/
    /*{
        title: "Global",
        description: "Supports 2 Popular Collections",
        color: "#000000",
        token: "0x...",
        collateralFactor: "25000000000000000000",
        address: "0xF253fBb86306758DEC824883DeF2122FF61E66a1",
        image: "/images/icons/eth.svg",
        background: "/images/collections/global-pool.jpg",
        status: "green",
        risk: "black",
        collectionSlug: "global",
        currency: "WETH",
        riskContent: "Low Risk",
        supportedCollections: ["Bored Apes", "CyberKongz"],
    },
    {
        title: "Global",
        description: "Supports 2 Popular Collections",
        color: "#000000",
        address: "0x972414113F1a211b6Fd225a77037545B8744b842",
        token: "0x...",
        collateralFactor: "25000000000000000000",
        image: "/images/icons/usdc.svg",
        background: "/images/collections/global-pool.jpg",
        status: "green",
        risk: "black",
        collectionSlug: "global",
        currency: "USDT",
        riskContent: "Low Risk",
        supportedCollections: ["Bored Apes", "CyberKongz"],
    },*/
    /*{
        title: "Global WISE Pool",
        description: "Supports 12 Popular Collections",
        color: "#EF466F",
        image: "/images/icons/eth.svg",
        background: "/images/content/global-pool.jpg",
        status: "green",
        risk: "black",
        collectionSlug: "global",
        currency: "WISE",
        riskContent: "Low Risk",
    },*/
    /*{
        title: "Global WISE Pool",
        description: "Supports 12 Popular Collections",
        color: "#EF466F",
        image: "/images/icons/eth.svg",
        background: "/images/content/global-pool.jpg",
        status: "yellow",
        risk: "black",
        collectionSlug: "global",
        currency: "WISE",
        riskContent: "Medium Risk",
    },*/
    /*{
        title: "Bored Apes",
        description: "Supports Single Collection",
        color: "#000000",
        token: "0x...",
        collateralFactor: "25000000000000000000",
        address: "0xc5C4251905936cC77865FbBb0C06349bb1a080d9",
        image: "/images/icons/eth.svg",
        background: "/images/collections/bayc.png",
        status: "green",
        risk: "black",
        collectionSlug: "bored-apes",
        currency: "WETH",
        riskContent: "Low Risk",
        supportedCollections: ["Bored Apes"],
    },
    {
        title: "Bored Apes",
        description: "Supports Single Collection",
        color: "#000000",
        token: "0x...",
        collateralFactor: "25000000000000000000",
        address: "0x2E317f98d3A45896bF4B4689F36f49D83a14C3eE",
        image: "/images/icons/usdc.svg",
        background: "/images/collections/bayc.png",
        status: "green",
        risk: "black",
        collectionSlug: "bored-apes",
        currency: "USDT",
        riskContent: "Low Risk",
        supportedCollections: ["Bored Apes"],
    },
    {
        title: "CyberKongz",
        description: "Supports Single Collection",
        color: "#000000",
        token: "0x...",
        collateralFactor: "25000000000000000000",
        address: "0x58CC64E99cB5fb27f53f1B2F3F7662FFBFAe1c9c",
        image: "/images/icons/eth.svg",
        background: "/images/collections/kongz.gif",
        status: "green",
        risk: "black",
        collectionSlug: "cyber-kongz",
        currency: "WETH",
        riskContent: "Low Risk",
        supportedCollections: ["CyberKongz"],
    },
    {
        title: "CyberKongz",
        description: "Supports Single Collection",
        color: "#000000",
        token: "0x...",
        collateralFactor: "25000000000000000000",
        address: "0xAdA5192B61B2dD758540a2C4b6fcA8423cEbe19A",
        image: "/images/icons/usdc.svg",
        background: "/images/collections/kongz.gif",
        status: "green",
        risk: "black",
        collectionSlug: "cyber-kongz",
        currency: "USDT",
        riskContent: "Low Risk",
        supportedCollections: ["CyberKongz"],
    },*/
    /*{
        title: "Azuki",
        description: "Supports Single Collection",
        color: "#000000",
        address: "0x01E1B5a037d048427Fe2Db13e3692AF9D13D7eB7",
        image: "/images/icons/eth.svg",
        background: "https://pbs.twimg.com/media/FI1uXXcUcAAcUi1.jpg",
        status: "green",
        risk: "black",
        collectionSlug: "azuki",
        currency: "WETH",
        riskContent: "Low Risk",
    },*/
];

export const supportedPools = supportedPoolsData.map(pool => {
    const supportedPoolsCount = pool.supportedCollections.length;
    return {
        ...pool,
        description: `Supports ${supportedPoolsCount} Popular Collection${supportedPoolsCount > 1 ? 's' : ''}`
    }
});


export default supportedPools;
