import { ERC20Interface } from "@usedapp/core";
import { Contract } from "ethers";
import { Interface } from "ethers/lib/utils";
import POOL_ABI from "../../ethers/abis/LiquidPool.json";
import ROUTER_ABI from "../../ethers/abis/LiquidRouter.json";

const PoolInterface = new Interface(
    POOL_ABI
);

export const getPoolContract = (
    poolAddress,
    ...args
) => {
    return (
        poolAddress
            ? new Contract(
                poolAddress,
                PoolInterface,
                ...args
            )
            : null
    );
}

export const getRouterContract = (
    routerAddress,
    ...args
) => {
    return (
        routerAddress
            ? new Contract(
                routerAddress,
                ROUTER_ABI,
                ...args
            )
            : null
    );
}

export const getTokenContract = (
    tokenAddress,
    signer = null
) => {

    if (tokenAddress && signer) {
        return new Contract(
            tokenAddress,
            ERC20Interface,
            signer
        );
    }

    if (tokenAddress) {
        return new Contract(
            tokenAddress,
            ERC20Interface,
        );
    }

    return null;
}
