import React, { useState, useMemo, useCallback } from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import { useEthers, shortenIfAddress } from '@usedapp/core';
import CurrencyInput from 'react-currency-input-field';
import { formatUnits, parseUnits } from "ethers/lib/utils";
import { ethers } from "ethers";
import Dropdown from "../Dropdown";
import toast, { Toaster } from "react-hot-toast";
import Loader from "../Loader";
import { usePoolUserInfo } from "../../customHooks/usePoolUserInfo";
import useConfig from "../../customHooks/useConfig";
import styles from "../Invest/Invest.module.sass";
import FormattedAmount from "../FormattedAmount";
import Success from "../Transaction";
import Icon from "../Icon";
import { usePoolGeneralInfo } from "../../customHooks/usePoolGeneralInfo";
import { useCustomAllowance } from "../../customHooks/useCustomAllowance";

import {
    PERCENT_OPTIONS,
    getToastSettings,
    EMPTY_ADDRESS,
    ONE_HUNDRED,
    getPreciseValue,
    produceAccurateValue,
    getPrettyValue,
    DEFAULT_FIXED_DECIMALS,
} from "../../utils";

import {
    getPoolContract,
    getRouterContract,
} from "../../utils/query/interfaces";

import { useERC20Approval } from "../../customHooks/useERC20Approval";

const UNLOCK_TEXT = "Unlocking...";
const CONFIRM_TEXT = "Confirm in Metamask";
const DEFAULT_TEXT = "Migrate Funds";
const MIGRATING_TEXT = "Migrating Funds...";
const DONE_TEXT = "Done";
const NO_FUNDS = "0.00";

const PoolMigrate = ({
    className,
    poolAddress,
    migrateTo,
    paymentToken,
    closeModal,
    title
}) => {

    // const dispatch = useDispatch();
    const { account, library } = useEthers();
    const [amount, setAmount] = useState("");
    const [activeQuickSelect, setActiveQuickSelect] = useState();
    const [buttonText, setButtonText] = useState(DEFAULT_TEXT);
    const [invalidAmount, setInvalidAmount] = useState(false);
    const [transactionHash, setTransactionHash] = useState("");
    const [showSteps, setShowSteps] = useState(false);
    const [successfulApproval, setSuccessfulApproval] = useState(false);

    // should be other default pool
    const [selectedPool, setSelectedPool] = useState(
        migrateTo
    );

    const config = useConfig();
    const poolOptions = [];

    const signer = library && library.getSigner(
        account
    );

    const paymentTokenAddress = config.paymentTokens[paymentToken];
    const decimals = config.decimals[paymentToken];

    const allowance = useCustomAllowance(
        config.paymentTokens[paymentToken],
        account,
        config.routerAddress
    );

    const migrateAmount = parseUnits(amount.toString() || "0", decimals || 18);

    const preApproved = allowance && allowance.gte(
        migrateAmount
    );

    const { userTokensDeposited } = usePoolUserInfo(
        poolAddress,
        account
    );

    const userTokensMax = userTokensDeposited && Number(
        formatUnits(
            userTokensDeposited,
            decimals
        )
    );

    const { tokensHeld, tokenDebt } = usePoolGeneralInfo(
        poolAddress
    );

    const balance = produceAccurateValue(
        tokensHeld,
        decimals
    );

    const loanInfo = useMemo(() => {

        const debt = produceAccurateValue(
            tokenDebt,
            decimals
        );

        // check if amount is greater than pool balance
        const amountInvalid = Number(amount) > Number(balance);

        return [
            {
                title: "Current Pool Debt",
                value: `${debt} ${paymentToken}`,
                error: false
            },
            {
                title: "Current Pool Balance",
                value: `${balance} ${paymentToken}`,
                error: balance ? amountInvalid : false
            },
        ]
    }, [tokenDebt, decimals, balance, amount, paymentToken]);

    const handleAmountChange = (val) => {

        if (invalidAmount) setInvalidAmount(false);
        setInvalidAmount(false);

        // clear quickSelect
        setActiveQuickSelect(null);

        // early return for deleted value
        if (!val) {
            setAmount("");
            return;
        }

        setAmount(val);

        // WHAT IS THIS?
        const amountInvalid = (
            userTokensDeposited && Number(val) > userTokensMax) || (Number(val) > Number(balance)
        );

        if (amountInvalid) {
            setInvalidAmount(true);
        }

        if (userTokensDeposited) {

            const total = formatUnits(
                userTokensDeposited,
                decimals
            );

            let percent = val / total * ONE_HUNDRED;
            if (percent === ONE_HUNDRED) {
                percent = 'max';
            }

            const quickValue = PERCENT_OPTIONS[percent]
                ? percent.toString()
                : null;

            setActiveQuickSelect(
                quickValue
            );
        }
    };

    const handleOnPoolChange = (value, index) => {
        setSelectedPool(
            value
        );
    }

    const noWrap = {
        whiteSpace: "nowrap"
    }

    const handleQuickSelect = (option) => {
        if (!userTokensDeposited) return;

        const total = formatUnits(
            userTokensDeposited,
            decimals
        );

        const currentPoolBalance = tokensHeld && formatUnits(
            tokensHeld,
            decimals
        );

        const maxMigrateAmount = Number(total) > currentPoolBalance
            ? currentPoolBalance
            : total;

        let formattedNum = maxMigrateAmount;
        if (option !== 'max') {
            const percent = option / 100;
            formattedNum = maxMigrateAmount * percent;
        }

        if (Number(formattedNum) > currentPoolBalance) formattedNum = currentPoolBalance;

        formattedNum = getPreciseValue(
            formattedNum
        );

        setAmount(formattedNum);
        setInvalidAmount(false);
        setActiveQuickSelect(option)
    };

    const approval = useERC20Approval({
        onConfirmation: () => setButtonText(CONFIRM_TEXT),
        onApproving: () => setButtonText(UNLOCK_TEXT),
        onApprovalComplete: (approval) => {
            setButtonText(DEFAULT_TEXT);
            if (approval) {
                setSuccessfulApproval(true);
                handleMigrate();
            }
        },
        onError: (error) => {
            setShowSteps(false);
            setButtonText(
                DEFAULT_TEXT
            );
            toast.error(
                error.code,
                getToastSettings("🚫")
            );
        }
    })

    const handleApprove = async () => {

        if (buttonText === DONE_TEXT) {
            closeModal();
        }

        if (!amount || invalidAmount) {
            setInvalidAmount(true);
            if (amount > balance) {
                toast.error(
                    `Migration Amount Exceeds Current Pool Balance`,
                    getToastSettings("🚫")
                );
            }
            return;
        }

        // Update modal ui to display steps
        setShowSteps(true);

        if (preApproved) {
            setSuccessfulApproval(true);
            handleMigrate();
            return;
        }

        approval.call(
            config.routerAddress,
            config.paymentTokens[paymentToken]
        );
    };

    const handleMigrate = async () => {

        try {

            if (buttonText !== DEFAULT_TEXT) return;

            const routerContract = getRouterContract(
                config.routerAddress,
                signer
            );

            const poolContract = getPoolContract(
                poolAddress,
                signer
            );

            const currentPoolShare = await poolContract.getCurrentPoolShares();
            const pseudoTotalTokens = await poolContract.pseudoTotalTokensHeld();

            setButtonText(
                CONFIRM_TEXT
            );

            const amountMigrate = ethers.utils.parseUnits(
                amount,
                decimals
            );

            const migrateShares = amountMigrate
                .mul(currentPoolShare)
                .div(pseudoTotalTokens);

            const configuration = process.env.NODE_ENV === "development"
                ? { gasLimit: "400000" }
                : { gasLimit: "400000" };

            const migrateTransaction = await routerContract.moveFunds(
                migrateShares,
                poolAddress,
                migrateTo,
                configuration
            );

            setButtonText(
                MIGRATING_TEXT
            );

            const migrateTX = await migrateTransaction.wait();
            setButtonText(DONE_TEXT);

            if (migrateTX) {
                setShowSteps(false);
                setTransactionHash(
                    migrateTX.transactionHash
                );
            }

        } catch (err) {
            console.log(err)
            setShowSteps(false);
            toast.error(
                err.code,
                getToastSettings("🚫")
            );

            setButtonText(
                DEFAULT_TEXT
            );
        }
    }

    const getPoolLink = useCallback((address) => {
        return config.chainId === 1
            ? `/pool/${address}`
            : `${config.etherscanAddress}/address/${address}`;
    }, [
        config.chainId,
        config.etherscanAddress
    ]);

    const isSubmitButtonDisabled = buttonText !== DONE_TEXT && (
        (!invalidAmount && amount?.length && !Number(amount))
        || !userTokensDeposited
        || !userTokensDeposited._isBigNumber
        || produceAccurateValue(userTokensDeposited, decimals) === NO_FUNDS
    );

    const poolMigrationLinks = useMemo(() => (
        <div className={styles.main}>
            Migrating from{" "}
            <Link
                className={styles.whiteLink}
                to={{
                    pathname: getPoolLink(poolAddress)
                }}
                rel="noopener noreferrer"
                target="_blank"
            >
                {shortenIfAddress(poolAddress)}{" "}
            </Link>
            to{" "}
            <Link
                className={styles.whiteLink}
                to={{
                    pathname: getPoolLink(migrateTo)
                }}
                target="_blank"
                rel="noopener noreferrer"
            >
                {shortenIfAddress(migrateTo)}
            </Link>
        </div>
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ), [poolAddress, migrateTo]);

    const iconColor = successfulApproval
        ? "#45B26B"
        : "#9757D7";

    const iconColorB = successfulApproval && (buttonText.includes("...")|| buttonText === DONE_TEXT)
        ? "#9757D7"
        : "";

    const iconName = successfulApproval
        ? 'check'
        : 'lock';

    const iconSize = successfulApproval
        ? '20'
        : '24';

    return (
        <div className={cn(className, styles.sale)}>
            <div style={{ display: transactionHash || showSteps ? "none" : "block" }}>
                <div className={styles.main}>
                    Based on your shares, you can migrate up to
                    {" "}
                    {paymentTokenAddress && (
                        <span className={styles.pink}>
                            <FormattedAmount
                                // noTruncate={true}
                                optionalPrecision={DEFAULT_FIXED_DECIMALS}
                                amount={userTokensDeposited}
                                token={paymentTokenAddress}
                                label={true}
                            />
                        </span>
                    )} capped by current pool balance
                </div>
                { poolMigrationLinks }
                {/*
                <div className={styles.field} style={{width: "50%"}}>
                    <div className={styles.label}>
                        Migrate From
                    </div>
                    <Dropdown
                        value={selectedPool}
                        options={
                            poolOptions?.map(option => option.label)
                            || []
                        }
                        setValue={handleOnPoolChange}
                    />
                </div>*/}
                <div className={styles.field} style={{display: "none"}}>
                    <div className={styles.label}>
                        Migrate From
                    </div>
                    <Dropdown
                        value={selectedPool}
                        options={
                            poolOptions?.map(option => option.label)
                            || []
                        }
                        setValue={handleOnPoolChange}
                    />
                </div>
                <div className={styles.table} style={{marginTop: "24px"}}>
                    <div
                        className={cn(styles.row,
                            {
                                [styles.borderError]: invalidAmount
                            })}
                    >
                        <div className={styles.col} style={noWrap}>
                            Migration Amount
                        </div>
                        <div className={styles.col}>
                            <CurrencyInput
                                autoComplete="off"
                                id="input-contribution"
                                name="contribution"
                                allowNegativeValue={false}
                                className={styles.input}
                                placeholder="Enter Value"
                                decimalsLimit={2}
                                value={amount}
                                decimalSeparator="."
                                groupSeparator=","
                                onValueChange={(value) => handleAmountChange(value)}
                            />
                        </div>
                        { amount && (
                            <div
                            className={styles.col}>
                                { paymentToken || "ETH" }
                            </div>
                        )}
                    </div>
                    <div className={styles.row} style={{ marginTop: "8px" }}>
                        { Object.keys(PERCENT_OPTIONS).map((key) => (
                            <button
                                key={key}
                                className={cn('button-stroke', styles.option, {
                                    'button-active': activeQuickSelect === key
                                })}
                                onClick={() => handleQuickSelect(key)}
                            >
                                { PERCENT_OPTIONS[key] }
                            </button>
                        ))}
                    </div>
                    <div style={{ marginBottom: "16px" }}>
                        {loanInfo.map((x, index) => (
                            <div
                                className={styles.row}
                                key={index}
                                style={{ borderColor: x.error ? "#c73e77" : "#23262F" }}
                            >
                                <div className={styles.col}>{x.title}</div>
                                <div className={styles.col}>{x.value}</div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className={styles.main} style={{marginBottom: "0px"}}>
                    The total available amount you can migrate depends on the current pool debt and balance
                </div>
            </div>
            <div
                className={styles.item}
                style={{ display: showSteps ? 'block' : 'none' }}
            >
                <div className={styles.title}>
                    { poolMigrationLinks }
                </div>
                <div className={styles.head}>
                    <div
                        className={styles.icon}
                        style={{ background: iconColor, borderColor: iconColor }}
                    >
                        <Icon name={iconName}  size={iconSize} />
                    </div>
                    <div className={styles.details}>
                        <div className={styles.info}>
                            Step 1 - Unlock Tokens
                        </div>
                        <div className={styles.text}>
                            Sends a transaction allowing LiquidNFTs to transfer {paymentToken} on your behalf
                        </div>
                    </div>
                </div>
                <div className={styles.stepLine} />
                <div className={styles.head}>
                    <div
                        style={{ background: iconColorB, borderColor: iconColorB }}
                        className={styles.icon}>
                        <Icon
                            name="wallet"
                            size="24"
                        />
                    </div>
                    <div className={styles.details}>
                        <div className={styles.info}>
                            Step 2 - Confirm Migration
                        </div>
                        <div className={styles.text}>
                            Send transaction to confirm migration to new pool
                        </div>
                    </div>
                </div>
            </div>
            {transactionHash && (
                <div>
                    <Success
                        txHash={transactionHash}
                        title={`You successfully migrated ${getPrettyValue(amount)} ${paymentToken} between LiquidNFTs pools`}
                        showButton={false}
                        celebrate={true}
                        lockerAddress={EMPTY_ADDRESS}
                        isWithdraw={false}
                    />
                </div>
            )}
            <div className={styles.item}>
                <button
                    style={{marginTop: "24px"}}
                    onClick={handleApprove}
                    disabled={buttonText === MIGRATING_TEXT || isSubmitButtonDisabled}
                    className={cn(
                        "button",
                        styles.button,
                        (buttonText === DEFAULT_TEXT || buttonText === DONE_TEXT) ? "" : "loading",
                        {
                            "disabled": isSubmitButtonDisabled
                        }
                    )}
                >
                    { (buttonText === MIGRATING_TEXT || buttonText === UNLOCK_TEXT)
                        && <Loader className={styles.loaderSmall} color="white" /> }
                    { buttonText }
                </button>
            </div>
            <Toaster />
        </div>
    );
};

export default PoolMigrate;
