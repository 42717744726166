import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import axios from 'axios';
import axiosRetry from 'axios-retry';

import { store } from './redux/store';
import App from './App';
import DappProvider from './ethers/DappProvider';

// Globally set axios retry configuation
axiosRetry(axios, { retries: 3 });

ReactDOM.render(
    <React.StrictMode>
        <DappProvider>
            <Provider store={store}>
                <App />
            </Provider>
        </DappProvider>
    </React.StrictMode>,
    document.getElementById('root')
);
