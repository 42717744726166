import React from 'react';
import useCountdownTimer from '../../customHooks/useCountdownTimer';
import styles from "./CountdownTimer.module.sass";

const CountdownTimer = ({
    targetDate,
    onClick,
    completeLabel
}) => {

    const [days, hours, minutes, seconds] = useCountdownTimer(
        targetDate
    );

    if (days + hours + minutes + seconds <= 0) {
        return (
            <button className={styles.link} onClick={onClick}>
                {completeLabel}
            </button>
        )
    } else {
        return (
            <span>
                {days}d {hours}h {minutes}m {seconds}s
            </span>
        );
    }
};

export default CountdownTimer;
