import { useEthers } from "@usedapp/core";
import { useState } from "react"
import { getRouterContract } from "../utils/query/interfaces";
import useConfig from "./useConfig";

export const useFinishUpdateRoot = () => {

    const config = useConfig();
    const { library, account } = useEthers();
    const [loading, setLoading] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const [error, setError] = useState();

    const call = async (collectionAddress) => {

        setConfirm(true);

        try {

            if (!collectionAddress) return;
            if (!account) return;

            if (confirm) return;
            if (loading) return;

            const signer = library.getSigner(
                account
            );

            const routerContract = getRouterContract(
                config.routerAddress,
                signer
            );

            const updateTX = await routerContract.finishUpdateRoot(
                collectionAddress
            );

            setLoading(true);
            await updateTX.wait();
            setLoading(false);
            setConfirm(false);

        } catch (ex) {
            setError(ex);
            setLoading(false);
            setConfirm(false);
        }
    }

    return {
        loading,
        error,
        confirm,
        call
    }
}
