import React, { useState } from "react";
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { utils } from "ethers";
import { formatUnits } from "ethers/lib/utils";
import {
    useEthers,
    shortenIfAddress,
    useEtherBalance,
    useTokenBalance,
} from "@usedapp/core";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import styles from "./User.module.sass";
import Icon from "../../Icon";
import useConfig from "../../../customHooks/useConfig";
import { dollarsFormat } from "../../../utils";
import magentaLogo from "../../../assets/images/magentaLogo.svg";
import lightmodeLogo from "../../../assets/images/lightmodeLogo.svg";
import Image from "../../Image";
import Modal from "../../Modal";
import Avatar from "../../../components/Avatar";
// import Switch from "../../../components/Switch";
import WrappingService from "../../WrappingService";

// import { setAirdopPreferences }  from "../../../redux/settingsSlice"
// import { appLocalStorage } from "../../../utils/localstorage";

const User = ({ className }) => {

    // const dispatch = useDispatch();
    const { account } = useEthers();
    const config = useConfig();

    const { formatEther } = utils;

    const etherBalance = useEtherBalance(account);
    const ensName = useSelector((state) => state.settings.ensName);
    // const airdropPreferences = useSelector((state) => state.settings.airdropPreferences);

    const [visible, setVisible] = useState(false);
    const [isWrappingServiceVisible, setIsWrappingServiceVisible] = useState(false);

    const wiseBalance = useTokenBalance(
        config.paymentTokens.WISE,
        account
    );

    const wiseAmount = wiseBalance
        ? dollarsFormat(formatUnits(wiseBalance))
        : "0.00";

    // const switchNetwork = chainId === 3
    //     ? "Mainnet"
    //     : "Ropsten"

    // const ropsten = async () => {

    //     const chain = chainId === 1
    //         ? '0x3'
    //         : '0x1';

    //     await window.ethereum.request({
    //         method: 'wallet_switchEthereumChain',
    //         params: [{ chainId: chain }]
    //     });
    // }

    // const isAirdropBannerVisible = airdropPreferences.isBannerVisible;

    // const toggleAirdropBannerVisibility = () => {
    //     const newConfig = {
    //         isBannerVisible: !isAirdropBannerVisible
    //     };
    //     dispatch(
    //         setAirdopPreferences(
    //             newConfig
    //         )
    //     );
    //     appLocalStorage.AIRDROP.update(
    //         newConfig
    //     );
    // }

    const items = [
        {
            title: "Wallet NFTs",
            icon: "wallet",
            url: "/profile/wallet-nfts",
        },
        {
            title: "Active Loans",
            icon: "lightning",
            url: "/profile/instant-loans",
        },
        {
            title: "Funded Pools",
            icon: "bag",
            url: "/profile/funded-pools",
        },
        /*{
            title: "Funded Listings",
            icon: "bag",
            url: "/profile/funded-listings",
        },
        {
            title: "Custom Listings",
            icon: "lock",
            url: "/profile/custom-listings",
        },*/
        // {
        //     title: `Switch to ${switchNetwork}`,
        //     icon: "report",
        //     url: "/connect-wallet",
        //     onClick: ropsten,
        // },
        {
            title: `Wrapping Service`,
            icon: "coin",
            onClick: () => setIsWrappingServiceVisible(true),
        },
        // {
        //     title: "Disconnect Wallet",
        //     icon: "exit",
        //     url: "/connect-wallet",
        //     onClick: deactivate,
        // },
        // {
        //     title: "Hide Airdrop Banner",
        //     icon: "close-circle",
        //     onClick: toggleAirdropBannerVisibility,
        //     children: (
        //         <Switch
        //             value={!airdropPreferences.isBannerVisible}
        //             className={styles.hideBannerSwitch}
        //         />
        //     )
        // },
    ];

    const handleCloseWrappingService = () => {
        return setIsWrappingServiceVisible(false);
    }

    return (
        <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
            <div className={cn(styles.user, className)}>
                <div className={styles.head} onClick={() => setVisible(!visible)}>
                    <Avatar address={account} className={styles.avatar} />
                    <div className={styles.wallet}>{ensName ?? shortenIfAddress(account)}</div>
                </div>
                {visible && (
                    <div className={styles.body}>
                        <div className={styles.line}>
                            <div className={styles.preview}>
                                <img src="/images/content/etherium-circle.jpg" alt="Etherium" />
                            </div>
                            {etherBalance && (
                                <div className={styles.details}>
                                    <div className={styles.info}>Ethereum Balance</div>
                                    <div className={styles.price}>
                                        {parseFloat(formatEther(etherBalance.toString())).toFixed(
                                            etherBalance.gte(utils.parseEther("100")) ? 0 : 3
                                        )}{" "}
                                        ETH
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className={styles.line}>
                            <div className={styles.preview}>
                                <Image
                                    className={styles.wise}
                                    src={lightmodeLogo}
                                    srcDark={magentaLogo}
                                    alt="Wise Token"
                                />
                            </div>
                            <div className={styles.details}>
                                <div className={styles.info}>Wise Token Balance</div>
                                <div className={styles.price}>{wiseAmount} WISE</div>
                            </div>
                        </div>
                        <div className={styles.menu}>
                            {items.map((x, index) =>
                                x.url ? (
                                    x.url.startsWith("http") ? (
                                        <a
                                            className={styles.item}
                                            href={x.url}
                                            rel="noopener noreferrer"
                                            key={index}
                                        >
                                            <div className={styles.icon}>
                                                <Icon name={x.icon} size="20" />
                                            </div>
                                            <div className={styles.text}>{x.title}</div>
                                        </a>
                                    ) : (
                                        <Link
                                            className={styles.item}
                                            to={x.url}
                                            onClick={() => {
                                                if (x.onClick) x.onClick();
                                                setVisible(!visible);
                                            }}
                                            key={index}
                                        >
                                            <div className={styles.icon}>
                                                <Icon name={x.icon} size="20" />
                                            </div>
                                            <div className={styles.text}>{x.title}</div>
                                        </Link>
                                    )
                                ) : (
                                        <div
                                            className={styles.item}
                                            key={index}
                                            onClick={x.onClick}
                                        >
                                            <div className={styles.icon}>
                                                <Icon name={x.icon} size="20" />
                                            </div>
                                            <div className={styles.text}>{x.title}</div>
                                            {x.children}
                                        </div>
                                )
                            )}
                        </div>
                    </div>
                )}
            </div>
            <Modal
                // outerClassName="extended"
                visible={isWrappingServiceVisible}
                onClose={handleCloseWrappingService}
            >
                <WrappingService handleClose={handleCloseWrappingService} />
            </Modal>
        </OutsideClickHandler>
    );
};

export default User;
