import React, { useState } from "react";
// import { Link } from "react-router-dom";
import { useEthers } from "@usedapp/core";
import { Contract } from "ethers";
import cn from "classnames";
import lockerAbi from "../../ethers/abis/LiquidLocker.json";
import styles from "./Cancel.module.sass";
import Loader from "../Loader";
import FormattedAmount from "../../components/FormattedAmount";
import { getChainById } from "../../utils";

import {
  // CircularProgressbar,
  // CircularProgressbarWithChildren,
  // buildStyles
} from "react-circular-progressbar";

import "react-circular-progressbar/dist/styles.css";

const DEFAULT_TEXT = "Proceed with Termination";
const CONFIRM_TEXT = "Confirm in Metamask";
const IN_PROGRESS = "Terminating...";
const DONE = "Done";

const Cancel = ({ className, close, listingDetails }) => {

    const { library, account, chainId } = useEthers();
    const chain = getChainById(chainId);
    const signer = library.getSigner(account);

    const [transactionHash, setTransactionHash] = useState();
    const [buttonText, setButtonText] = useState(DEFAULT_TEXT);

    const {
        lockerAddress,
        floorAsked,
        paymentToken,
        // floorNotReached,
        lockerOwner,
        // creationTime,
        totalCollected,
        belowFloorAsked,
    } = listingDetails;

    // const nowSeconds = Date.now() / 1000; // will this be accurate in timezones?
    const isOwner = lockerOwner === account;
    // const secondsSinceCreation = nowSeconds - creationTime.toNumber();
    // const daysPassed = secondsSinceCreation / SECONDS_IN_DAY;
    // const fiveDaysHavePassed = daysPassed > 5;
    const canBeTerminated = belowFloorAsked;

    const remainigAmount = floorAsked.sub(
        totalCollected
    );

    const cancelLoan = async () => {

        if (buttonText === DONE) close();
        if (buttonText !== DEFAULT_TEXT) return;

        const lockerContract = new Contract(
            lockerAddress,
            lockerAbi,
            signer
        );

        // not sure we need this, I would try to get rid of this
        const configuration = process.env.NODE_ENV === "development"
            ? { gasLimit: "1000000" }
            : {};

        try {

            setButtonText(CONFIRM_TEXT);
            const tx = await lockerContract.disableLocker(
                configuration
            );

            setButtonText(IN_PROGRESS);
            setTransactionHash(tx.transactionHash);

            await tx.wait();
            setButtonText(DONE);

        } catch (err) {
            setButtonText(DEFAULT_TEXT);
            // TO-DO: install reactToast or someting similar and do:
            // showToast(err.errorText);
        }
    };
    return (
        <div className={cn(className, styles.transfer)}>
            <div className={cn("h4", styles.title)}>
                Terminate Listing
            </div>
            <div>
                <div className={styles.text}>
                    Listing can be terminated by the owner only if floor asked amount has not been reached yet
                </div>
                <div className={styles.row}>
                    <div>Floor Asked</div>
                    <div>
                        {floorAsked && (
                            <FormattedAmount
                                amount={floorAsked}
                                token={paymentToken}
                                label={true}
                            />
                        )}
                    </div>
                </div>
                <div className={styles.row}>
                    <div>Total Contributed</div>
                    <div>
                        {totalCollected && (
                            <FormattedAmount
                                amount={totalCollected}
                                token={paymentToken}
                                label={true}
                            />
                        )}
                    </div>
                </div>
            </div>
            <div className={styles.text} style={{marginTop: "24px"}}>
                In case additional {remainigAmount && (
                    <FormattedAmount
                        amount={remainigAmount}
                        token={paymentToken}
                        label={true}
                    />
                )} are contributed for this listing then it can no longer be terminated
            </div>
            <div className={styles.btns}>
                <button
                    className={cn(
                        "button",
                        styles.button,
                        (buttonText === DEFAULT_TEXT || buttonText === DONE) ? "" : "loading",
                        (canBeTerminated && isOwner) ? "" : "disabled"
                    )}
                    onClick={canBeTerminated ? cancelLoan : null}
                >
                    {buttonText === IN_PROGRESS && (
                        <div style={{marginRight: "20px"}}>
                            <Loader className={styles.loader} color="white" />
                        </div>
                    )}
                    {buttonText}
                </button>
                {transactionHash && (
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={chain.getExplorerTransactionLink(transactionHash)}
                        style={{ marginTop: "8px", display: "flex", color: "white", justifyContent: "center" }}
                    >
                        View Transaction {/* TO-DO: this link must have a hover effect */}
                    </a>
                )}
            </div>
        </div>
    );
};

export default Cancel;
