import axios from "axios";
import { ethers, BigNumber } from 'ethers';
import { Interface, formatUnits } from "ethers/lib/utils";

import LIQUID_ROUTER_ABI from "../../ethers/abis/LiquidRouter.json";
import { TEST_NETWORK_ID, DEFAULT_FIXED_DECIMALS, toPrecision } from "../../utils";

import {
    BACKEND_URL,
    collectionAddressToNameMainnet,
    collectionAddressToNameTestnet,
    ZERO_ADDRESS,
    // THIRTY_MIN_MS,
    getDeadline,
    // BORROW_RATE_DECIMALS,
    // LEND_RATE_DECIMALS
} from "../index";

import {
    getPoolContract,
    getRouterContract
} from "./interfaces";

// @TODO: refactor all hooks to use the following helpers?

const parseLog = (log, contractInterface) => ({
    blockNumber: log.blockNumber,
    ...contractInterface.parseLog(log),
});

const liquidRouterInterface = new Interface(
    LIQUID_ROUTER_ABI
);

const parseBorrowedLog = (log) => parseLog(
    log,
    liquidRouterInterface
);

const addTxHash = (data, logs) =>
    data.map((d, i) => (
        {
            ...d,
            transactionHash: logs[i].transactionHash
        }
    )
);

export const getPaybackEvents = async (library, config) => {

    const routerContract = await new ethers.Contract(
        config.routerAddress,
        liquidRouterInterface,
        library
    );

    const paybackFilter = routerContract.filters.FundsReturned();

    const paybackLogs = await library.getLogs({
        ...paybackFilter,
        fromBlock: config.inceptionBlock
    });

    const paybackEvents = paybackLogs.map(
        parseBorrowedLog
    );

    const paybackData = paybackEvents.map((event) => ({
        nftAddress: event.args.nftAddress,
        pool: event.args.pool,
        timestamp: event.args.timestamp,
        tokenId: event.args.tokenId,
        tokenOwner: event.args.tokenOwner,
        transferAmount: event.args.transferAmount
    }));

    const paybackWithTxHash = addTxHash(
        paybackData,
        paybackLogs
    );

    return paybackWithTxHash;
}

export const getLiquidationEvents = async (library, config) => {
    const routerContract = getRouterContract(
        config.routerAddress,
    );

    const liquidatedFilter = routerContract.filters.Liquidated();

    const liquidatedLogs = await library.getLogs({
        ...liquidatedFilter,
        fromBlock: config.inceptionBlock
    });

    const liquidationEvents = liquidatedLogs.map(
        parseBorrowedLog
    );

    const liquidationData = liquidationEvents.map((event) => ({
        nftAddress: event.args.nftAddress,
        timestamp: event.args.timestamp,
        tokenId: event.args.tokenId,
        liquidator: event.args.liquidator,
        discountAmount: event.args.discountAmount
    }));

    const liquidationWithTxHash = addTxHash(
        liquidationData,
        liquidatedLogs
    );

    return liquidationWithTxHash;

}

const sortBorrowEvents = (borrowEvents, showAll, sortByLatest) => {
    if (!borrowEvents || (borrowEvents && !borrowEvents.length)) {
        return borrowEvents;
    }
    if (showAll) {
        return borrowEvents
            .sort((a, b) => a.timestamp > b.timestamp ? 1 : -1)
            .sort((a, b) => a.lastPaidTime < b.lastPaidTime ? 1 : -1)
            .sort((a, b) =>
                a.closedLoan && a.finalPaymentDate < b.finalPaymentDate
                    ? 1
                    : -1
            )
            .sort((a) => a.closedLoan ? 1 : -1)
    }

    // get latest time for chronological decreasing list
    borrowEvents.sort((a, b) => {
        const latestTimeA = a.timestamp > a.lastPaidTime
            ? a.timestamp
            : a.lastPaidTime;

        const latestTimeb = b.timestamp > b.lastPaidTime
            ? b.timestamp
            : b.lastPaidTime;

        return latestTimeA > latestTimeb ? 1 : -1
    });

    // sort with nearest payment date first
    if (!sortByLatest) {
        borrowEvents.sort((a, b) =>
            !a.closedLoan && a.finalPaymentDate < b.finalPaymentDate
                ? 1
                : -1
        );
    }

    // sort with active loans first
    return borrowEvents.sort((a) => a.closedLoan ? 1 : -1);
}

export const getBorrowEventTokens = async ({
    chainId,
    config,
    library,
    borrowEvents,
    user,
    setStateCallback,
    showAll,
    sortByLatest,
    // hideClosedLoans,
    userOnly,
}) => {
    const emptyStateUpdate = [];

    try {

        // Get payback events for closed loans
        const paybackEvents = await getPaybackEvents(
            library,
            config
        );

        // Get liquidation events
        const liquidationEvents = await getLiquidationEvents(
            library,
            config
        );

        const closedPaybackEvents = paybackEvents?.filter(
            paybackEvent => paybackEvent.tokenOwner === ZERO_ADDRESS
        );

        let userBorrowEvents = Object.values(

            borrowEvents.reduce((accumulatedEvents, currentEvent) => {

                // attach paybackEvents
                currentEvent.paybackEvents =
                    paybackEvents
                        ?.filter(event =>
                            event.tokenId?.toString() === currentEvent.tokenId?.toString()
                            && event.nftAddress === currentEvent.nftAddress
                            && event.pool === currentEvent.pool)
                        ?.sort((a, b) => a.timestamp > b.timestamp ? 1 : -1);

                // Filter by user
                const isUser = currentEvent.borrower === user;
                if (userOnly && !isUser) return accumulatedEvents;

                // add to object for filtering
                currentEvent.isUser = isUser;

                const closedPaybackEvent = closedPaybackEvents.find((closePaybackEvent) => {
                    return closePaybackEvent.nftAddress === currentEvent.nftAddress
                        && closePaybackEvent.tokenId.toString() === currentEvent.tokenId.toString()
                        && closePaybackEvent.timestamp.gt(currentEvent.timestamp)
                });

                const liquidationEvent = liquidationEvents.find((liquidationEvent) => {
                    return liquidationEvent.nftAddress === currentEvent.nftAddress
                        && liquidationEvent.tokenId.toString() === currentEvent.tokenId.toString()
                        && liquidationEvent.timestamp.gt(currentEvent.timestamp)
                });

                const paybackId = closedPaybackEvent
                    ?.tokenId
                    ?.toString()
                    || liquidationEvent
                        ?.tokenId
                        ?.toString();

                const hasFinalPay =
                    paybackId !== null &&
                    paybackId !== undefined;

                const isLiquidated = liquidationEvent != null;
                // If paid off add final payment timestamp and txhash to object
                if (hasFinalPay) {
                    currentEvent.finalPaybackAmount = closedPaybackEvent?.transferAmount || liquidationEvent?.discountAmount;
                    currentEvent.finalPaymentDate = closedPaybackEvent?.timestamp || liquidationEvent?.timestamp;
                    currentEvent.paybackTx = closedPaybackEvent?.transactionHash || liquidationEvent?.transactionHash;
                } else {
                    currentEvent.finalPaymentDate = 0;
                }

                // Add loan status to object for next loop
                currentEvent.closedLoan = hasFinalPay || isLiquidated;
                currentEvent.liquidatedLoan = isLiquidated;

                // DEPRECATED to handle hiding once all pools are returned
                // Hide closed loans for individual pool page
                // if (hideClosedLoans && hasFinalPay) return accumulatedEvents;

                // Check if loan exists in our hash map
                const existingEvent = accumulatedEvents[currentEvent.tokenId.toString()];

                if (existingEvent) {
                    if (showAll) {
                        // If loan of an existing token in map exists and both are closed
                        accumulatedEvents[existingEvent.timestamp.toString()] = existingEvent;
                        accumulatedEvents[currentEvent.tokenId.toString()] = currentEvent;
                    } else {
                        // If home page make all shown loans unique nfts
                        if (currentEvent.timestamp > existingEvent.timestamp) {
                            accumulatedEvents[currentEvent.tokenId.toString()] = currentEvent;
                        }
                    }
                } else {
                    accumulatedEvents[currentEvent.tokenId.toString()] = currentEvent;
                }

                return accumulatedEvents;

            }, {})
        );

        if (!userBorrowEvents.length) {
            setStateCallback(
                emptyStateUpdate
            );
            return;
        }

        let poolInfo = userBorrowEvents.map(async (borrow) => {

            // const deadline = getDeadline();

            const poolContract = getPoolContract(
                borrow.pool,
                library
            );

            // @TODO: Combine to single call if possible
            const poolToken = await poolContract.poolToken();
            const borrowRate = await poolContract.borrowRate();

            const currentLoanInfo = await poolContract.currentLoans(
                borrow.nftAddress,
                borrow.tokenId
            );

            // let loanInterest;

            // // Only query loan interest on active loan
            // if (!borrow.finalPaymentDate) {
            //     loanInterest = await poolContract.getLoanInterest(
            //         borrow.nftAddress,
            //         borrow.tokenId,
            //         deadline
            //     );
            // }

            return {
                poolToken,
                borrowRate,
                principalTokens: currentLoanInfo.principalTokens,
                nextPaymentDueTime: currentLoanInfo.nextPaymentDueTime,
                lastPaidTime: currentLoanInfo.lastPaidTime,
                // loanInterest
            };
        });

        poolInfo = await Promise.allSettled(
            poolInfo
        );

        let poolLoanInterests = userBorrowEvents.map(async (borrow) => {

            const deadline = getDeadline();

            const poolContract = getPoolContract(
                borrow.pool,
                library
            );

            let loanInterest;

            // Only query loan interest on active loan
            if (!borrow.finalPaymentDate) {
                // console.log("LOAN INTEREST READ", borrow.tokenId.toString())
                loanInterest = await poolContract.getLoanInterest(
                    borrow.nftAddress,
                    borrow.tokenId,
                    deadline
                );
                // console.log("LOAN INTEREST SUCCESS", borrow.tokenId.toString(), loanInterest)
            }

            return loanInterest;
        });

        poolLoanInterests = await Promise.allSettled(
            poolLoanInterests
        );

        const borrowEventsWithImage = userBorrowEvents.reduce((arr, curr, index) => {

            // Consolidate all fetched data to one object
            curr.name = chainId === TEST_NETWORK_ID
                ? collectionAddressToNameTestnet[curr.nftAddress]
                : collectionAddressToNameMainnet[curr.nftAddress];

            curr.tokenAddress = poolInfo[index].value?.poolToken;
            curr.borrowRate = poolInfo[index].value?.borrowRate;
            curr.principalTokens = poolInfo[index].value?.principalTokens;
            curr.nextPaymentDueTime = poolInfo[index].value?.nextPaymentDueTime;
            curr.lastPaidTime = poolInfo[index].value?.lastPaidTime;
            curr.loanInterest = poolLoanInterests[index].value;

            curr.data = {
                punk: false,
            };

            curr.id = curr.tokenId;
            arr.push(curr);

            return arr;
        }, []);

        const sortedEvents = sortBorrowEvents(
            borrowEventsWithImage,
            showAll,
            sortByLatest
        );

        setStateCallback(
            sortedEvents
        );

    } catch(err) {
        console.log(err)
        setStateCallback(emptyStateUpdate);
    }
}

export const getPaybackData = async (config, library, borrowData, chainId) => {

    try {
        // Get merkle tree data for each token
        let collectionHash;

        const routerContract = getRouterContract(
            config.routerAddress,
            library
        );

        const merkleIPFS = await routerContract.merkleIPFS(
            borrowData.nftAddress
        );

        if (merkleIPFS?.length) {
            collectionHash = merkleIPFS;
        }

        const tokenId = borrowData.tokenId.toString();
        const collectionName = chainId === TEST_NETWORK_ID
            ? collectionAddressToNameTestnet[borrowData.nftAddress]
            : collectionAddressToNameMainnet[borrowData.nftAddress];

        const url = `${BACKEND_URL}/collections/${collectionName}/${collectionHash}/${tokenId}`
        const merkleResponse = await axios.get(url);

        const poolContract = getPoolContract(
            borrowData.pool,
            library
        );

        const merklePrice = BigNumber.from(
            merkleResponse.data.amount
        );

        const deadline = getDeadline();

        const minimiumPayResponse = await poolContract.getPrincipalPayBackMinimum(
            borrowData.nftAddress,
            borrowData.tokenId,
            merklePrice,
            deadline
        );

        return {
            merkleIndex: merkleResponse.data.index,
            merkleProof: merkleResponse.data.proof,
            merklePrice: merkleResponse.data.amount,
            minimumPay: minimiumPayResponse
        };
    } catch(err) {
        console.log(err);
        return { minimumPay: BigNumber.from(0) };
    }
};

export const getUserDepositAmount = async (
    userAddress,
    poolAddress,
    decimals,
    library,
) => {

    try {

        const poolContract = getPoolContract(
            poolAddress,
            library
        );

        const internalShares = await poolContract.internalShares(
            userAddress
        );

        const userTokenShares = await poolContract.balanceOf(
            userAddress
        );

        const amountParam = internalShares.add(
            userTokenShares
        );

        const userTokensDeposited = await poolContract.calculateWithdrawAmount(
            amountParam
        );

        const formattedDeposit = formatUnits(
            userTokensDeposited,
            decimals
        );

        return toPrecision(
            formattedDeposit,
            DEFAULT_FIXED_DECIMALS
        );

    } catch(err) {
        console.log(err)
        return null;
    }
};
