import React from "react";

const Image = ({
    className,
    src,
    srcDark,
    // srcSet,
    // srcSetDark,
    alt
}) => {

    // const darkMode = useDarkMode(true);

    return (
        <img
            className={className}
            // srcSet={darkMode.value ? srcSetDark : srcSet}
            // srcSet={srcSetDark}
            src={srcDark}
            alt={alt}
        />
    );
};

export default Image;
